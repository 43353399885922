import React from "react";
import {
  Typography,
  TextField,
  Button,
  Grid,
  InputLabel,
  Divider,
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { JobPostApi } from "../../apis";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const CreateJobPostStep3 = ({
  formData,
  setFormData,
  backStep,
  submitForm,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const formik = useFormik({
    initialValues: formData,

    onSubmit: async (values) => {
      try {
        const res = await JobPostApi.createJobPost(values);
        // Update state or perform other actions based on API response
        setFormData(values);
        navigate("/v1/recruiter/my-job-posts"); // Navigate to job post page
      } catch (error) {
        // Handle API error
        console.error("Error submitting form:", error);
      }
    },
  });

  const handleDescriptionInputChange = (event) => {
    const { value } = event.target;
    formik.setFieldValue("description.description", value);
    formik.setFieldTouched("description.description", true);

    setFormData({
      ...formData,
      description: {
        ...formData.description,
        description: value,
      },
    });
  };

  const handleAdditionalInputChange = (event) => {
    const { value } = event.target;
    formik.setFieldValue("description.additionalInfo", value);
    formik.setFieldTouched("description.additionalInfo", true);

    setFormData({
      ...formData,
      description: {
        ...formData.description,
        additionalInfo: value,
      },
    });
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid
        container
        spacing={3}
        sx={{ paddingTop: 1, paddingLeft: 1, backgroundColor: "#F5F5F5" }}
      >
        <Grid item xs={12}>
          <InputLabel
            mt={2}
            sx={{ fontWeight: "600", fontSize: "16px", color: "text.primary" }}
          >
            {t("recruiter.create-job-post.Description")}
          </InputLabel>
          <TextField
            fullWidth
            multiline
            rows={4}
            placeholder={t("recruiter.create-job-post.Role Overview")}
            name="description.description"
            value={formik.values.description?.description || ""}
            onChange={handleDescriptionInputChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.description?.description &&
              Boolean(formik.errors.description?.description)
            }
            helperText={
              formik.touched.description?.description &&
              formik.errors.description?.description
            }
          />
        </Grid>
        <Grid item xs={12}>
          <InputLabel
            mt={2}
            sx={{ fontWeight: "600", fontSize: "16px", color: "text.primary" }}
          >
            {t("employee.background.Additional Information")}
          </InputLabel>
          <TextField
            fullWidth
            multiline
            rows={4}
            placeholder={t("employee.background.Type here")}
            name="description.additionalInfo"
            value={formik.values.description?.additionalInfo || ""}
            onChange={handleAdditionalInputChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.description?.additionalInfo &&
              Boolean(formik.errors.description?.additionalInfo)
            }
            helperText={
              formik.touched.description?.additionalInfo &&
              formik.errors.description?.additionalInfo
            }
          />
        </Grid>

        <Grid item xs={12}>
          <Divider />
          <Grid container spacing={2} justifyContent="space-between">
            <Grid item>
              <Button
                type="button"
                onClick={backStep}
                variant="outlined"
                sx={{
                  mt: 3,
                  mb: 2,
                  backgroundColor: "white",
                  borderColor: "primary.main",
                  color: "text.secondary",
                  "&:hover": {
                    backgroundColor: "text.secondary",
                  },
                }}
              >
                <Typography
                  component="h2"
                  variant="body1"
                  sx={{
                    alignSelf: "flex-start",
                    fontSize: "15px",
                    fontWeight: "bold",
                    textTransform: "none",
                    color: "primary.main",
                    padding: "2px",
                  }}
                >
                  {t("employee.background.Back")}
                </Typography>
              </Button>
            </Grid>
            <Grid item>
              <Button
                type="submit"
                variant="standard"
                onClick={submitForm}
                sx={{
                  mt: 3,
                  mb: 2,
                  backgroundColor: "secondary.main",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "secondary.main",
                  },
                }}
              >
                <Typography
                  component="h2"
                  variant="body1"
                  sx={{
                    fontSize: "15px",
                    fontWeight: "bold",
                    textTransform: "none",
                    color: "text.primary",
                    padding: "2px",
                  }}
                >
                  {t("recruiter.create-job-post.submit")}
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default CreateJobPostStep3;
