import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Box, Typography, Grid, Divider, Chip } from "@mui/material";
import { OnBoardingApi } from "../../../../apis";
import UploadImage from "../../../../components/GlobalComponent/UploadImage";
import AddIcon from "@mui/icons-material/Add";
import Loader from "../../../../components/GlobalComponent/Loader";
import { useTranslation } from "react-i18next";

const PreviewAndSubmitForm = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState(null);
  const location = useLocation();

  // Function to render company perks that are true
  const renderCompanyPerks = () => {
    const perks = [];

    try {
      let companyPerks = {};

      // Check if userData and company_perks are available and it's an object
      if (
        userData &&
        userData?.company_perks &&
        typeof userData?.company_perks === "object"
      ) {
        companyPerks = userData?.company_perks;

        Object.keys(companyPerks).forEach((perk) => {
          if (companyPerks[perk]) {
            perks.push(
              <Chip
                key={perk}
                label={perk.replace(/_/g, " ")} // Replace underscores with spaces for display
                style={{ margin: "5px" }}
              />
            );
          }
        });
      }
    } catch (error) {
      console.error("Error parsing company perks:", error);
    }

    return perks;
  };

  useEffect(() => {
    const fetchOnBoardedUserData = async () => {
      try {
        const res = await OnBoardingApi.getOnBoarding(); // Adjust API call as needed

        setUserData(res.data.profile);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching on-boarded user data:", error);
        setLoading(false);
      }
    };

    fetchOnBoardedUserData();
  }, []);

  if (loading) {
    return (
      <div>
        <Loader loading={loading} />
      </div>
    );
  }

  return (
    <div>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography
          ml={1}
          sx={{
            fontWeight: "600",
            fontSize: "22px",
            marginLeft: "-4px",
            marginBottom: "15px",
          }}
        >
          {t("recruiter.preview.Profile Preview")}
        </Typography>
      </Box>

      <Typography
        ml={1}
        sx={{
          fontWeight: "600",
          fontSize: "16px",
          marginLeft: "-4px",
          marginBottom: "15px",
        }}
      >
        {t("employee.aboutyou.title")}
      </Typography>

      <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
        <Grid container spacing={2}>
          <Grid item>
            <Box
              sx={{
                width: "100px",
                height: "100px",
                border: "1px solid black",
                borderRadius: "5px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: "15px",
              }}
            >
              {userData?.profile_photo_url ? (
                <UploadImage
                  imageUrl={`${process.env.REACT_APP_API_BASE_URL}/public/image/${userData?.profile_photo_url}`}
                  altText={userData?.profile_photo_url}
                  style={{
                    maxWidth: "100%",
                    maxHeight: "100%",
                    objectFit: "cover",
                    borderRadius: "5px",
                  }}
                />
              ) : (
                <AddIcon sx={{ fontSize: 30, color: "black" }} />
              )}
            </Box>
          </Grid>
          <Grid item>
            <Typography sx={{ fontWeight: "600", fontSize: "16px" }}>
              {userData?.full_name || "N/A"}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                mr: 0,
                textDecoration: "none",
                color: "gray",
                fontSize: "11px",
              }}
            >
              +{userData?.phone_number || "N/A"}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                mr: 0,
                textDecoration: "none",
                color: "gray",
                fontSize: "11px",
              }}
            >
              {userData?.linkedin_url || "N/A"}
            </Typography>
          </Grid>
        </Grid>
      </Box>

      <Divider sx={{ my: 2 }} />

      <Box>
        <Typography
          ml={1}
          sx={{
            fontWeight: "600",
            fontSize: "22px",
            marginLeft: "-4px",
            marginBottom: "15px",
          }}
        >
          {t("recruiter.preview.Company Information")}
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
          <Grid container spacing={2}>
            <Grid item>
              <Box
                sx={{
                  width: "100px",
                  height: "100px",
                  border: "1px solid black",
                  borderRadius: "5px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: "15px",
                }}
              >
                {userData?.company_profile_photo_url ? (
                  <UploadImage
                    imageUrl={`${process.env.REACT_APP_API_BASE_URL}/public/image/${userData?.company_profile_photo_url}`}
                    altText={userData?.company_profile_photo_url}
                    style={{
                      maxWidth: "100%",
                      maxHeight: "100%",
                      objectFit: "cover",
                      borderRadius: "5px",
                    }}
                  />
                ) : (
                  <AddIcon sx={{ fontSize: 30, color: "black" }} />
                )}
              </Box>
            </Grid>
            <Grid item>
              <Typography sx={{ fontWeight: "600", fontSize: "16px" }}>
                {userData?.company_name || "N/A"}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  mr: 0,
                  textDecoration: "none",
                  color: "gray",
                  fontSize: "11px",
                }}
              >
                {userData?.company_location || "N/A"}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  mr: 0,
                  textDecoration: "none",
                  color: "gray",
                  fontSize: "11px",
                }}
              >
                {userData?.company_website || "N/A"}
              </Typography>
            </Grid>
          </Grid>
        </Box>

        {/* <Typography mt={2} sx={{ fontWeight: "600", fontSize: "16px", color: "text.primary" }}>
          Company Slogan
        </Typography>
        <Typography mt={2} sx={{ fontSize: "14px", color: "text.primary" }}>
          {userData?.company_slogan || "N/A"}
        </Typography> */}
        <Typography
          mt={2}
          sx={{ fontWeight: "600", fontSize: "16px", color: "text.primary" }}
        >
          {t("recruiter.preview.Company Size")}
        </Typography>
        <Typography mt={2} sx={{ fontSize: "14px", color: "text.primary" }}>
          {userData?.company_size || "N/A"}
        </Typography>
        <Typography
          mt={2}
          sx={{ fontWeight: "600", fontSize: "16px", color: "text.primary" }}
        >
          {t("recruiter.preview.Company Sector")}
        </Typography>
        <Typography mt={2} sx={{ fontSize: "14px", color: "text.primary" }}>
          {userData?.company_domain || "N/A"}
        </Typography>
        <Typography
          mt={2}
          sx={{ fontWeight: "600", fontSize: "16px", color: "text.primary" }}
        >
          {t("recruiter.company-profile.Company Overview")}
        </Typography>
        <Typography mt={2} sx={{ fontSize: "14px", color: "text.primary" }}>
          {userData?.company_overview || "N/A"}
        </Typography>
      </Box>

      <Divider sx={{ my: 2 }} />

      <Box>
        <Typography
          ml={1}
          mt={2}
          sx={{
            fontWeight: "600",
            fontSize: "22px",
            marginLeft: "-4px",
            marginBottom: "15px",
          }}
        >
          {t("recruiter.company-profile.Company Perks")}
        </Typography>

        {/* Render chips for company perks */}
        {renderCompanyPerks()}
      </Box>
    </div>
  );
};

export default PreviewAndSubmitForm;
