import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Formik, Form } from "formik";
import { OnBoardingApi } from "../../../../apis";
import AddIcon from "@mui/icons-material/Add";
import * as yup from "yup";
import dayjs from "dayjs";

import {
  Box,
  Typography,
  Button,
  Grid,
  FormControl,
  TextField,
  InputLabel,
  MenuItem,
  Select,
  Checkbox,
  FormControlLabel,
  Divider,
  Container,
} from "@mui/material";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker.css";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  datePicker: {
    width: "100%", // Default width
  },
}));
function multiParse(input) {
  let result = input;
  try {
    while (typeof result === "string") {
      result = JSON.parse(result);
    }

    // Handle the case when result is an array
    if (Array.isArray(result)) {
      return result; // Return the array as-is
    }
    // Handle object case
    else if (typeof result === "object" && result !== null) {
      result = JSON.stringify(result, null, 2); // Convert object to string for display
    }
  } catch (e) {
    console.error("Error parsing input:", e);
  }
  return result;
}
const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const submitForm = async (values) => {
  try {
    // Convert top_specialities to JSON string
    const updatedValues = {
      ...values,

      preferences: {
        ...values.preferences,
        employment_type: values.preferences?.employment_type
          ? JSON.stringify(values.preferences.employment_type)
          : {},
      },
      about_you: {
        ...values.about_you,
        salary_range: values.about_you?.salary_range
          ? JSON.stringify(values.about_you.salary_range)
          : {},
        profile_photo_url: values.about_you.profile_photo_url, // Include profile_photo_url
      },
    };

    const res = await OnBoardingApi.OnBoarding(updatedValues);
  } catch (err) {
    console.error(err);
  }
};

const BackgroundForm = ({ formik }) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchOnBoardedUserData = async () => {
      try {
        const res = await OnBoardingApi.getOnBoarding();
        function multiParse(input) {
          let result = input;
          try {
            while (typeof result === "string") {
              result = JSON.parse(result);
            }
          } catch (e) {
            console.error("Error parsing input:", e);
          }
          return result;
        }
        if (res.data && res.data.profile) {
          const parsedTopSpecialities = multiParse(
            res?.data?.profile?.top_specialities || {}
          );
          const parsedEmployementType = multiParse(
            res?.data?.profile?.employment_type || {}
          );
          const salaryRange = multiParse(
            res?.data?.profile?.salary_range || "{}"
          );
          const workExperience = multiParse(
            res?.data?.profile?.work_experience || "[]"
          );
          const education = multiParse(res?.data?.profile?.education || "[]");
          const languages = multiParse(res?.data?.profile?.languages || "[]");
          const experienceYears = multiParse(
            res?.data?.profile?.experienceYears || "[]"
          );

          const remoteWorkValue = res?.data?.profile?.remote_work ? "1" : "0";
          const relocationValue = res?.data?.profile?.relocation ? "1" : "0";
          const trueTopSpecialities = Object.entries(parsedTopSpecialities)
            .filter(([_, value]) => value) // Keep only entries where value is true
            .map(([key]) => key) // Extract the key (specialty name)
            .join(", "); // Join the names into a single string

          // Result

          formik.setValues({
            ...formik.values,
            preferences: {
              professional_area: res?.data?.profile?.professional_area || "",
              total_years_experience:
                res?.data?.profile.total_years_experience || "",
              top_specialities: parsedTopSpecialities,
              experienceYears: experienceYears || {},
              employment_type: {
                fullTime: parsedEmployementType?.fullTime || false,
                partTime: parsedEmployementType?.partTime || false,
              },
              job_search_status: res?.data?.profile?.job_search_status || "",
              remote_work: remoteWorkValue || "",
              relocation: relocationValue || "",
            },
            about_you: {
              resume_url: res?.data?.profile.resume_url || "",
              profile_photo_url: res?.data?.profile?.profile_photo_url || "",
              full_name: res?.data?.profile?.full_name || "",
              phone_number: res?.data?.profile?.phone_number || "",
              whatsapp_number: res?.data?.profile?.whatsapp_number || "",
              current_city: res?.data?.profile?.current_city || "",
              salary_range: {
                from: salaryRange?.from || "",
                to: salaryRange?.to || "",
              },
              linkedin_url: res?.data?.profile?.linkedin_url || "",
              personal_blog_url: res?.data?.profile?.personal_blog_url || "",
            },
            background: {
              work_experience:
                workExperience?.length > 0
                  ? workExperience
                  : [
                      {
                        company_name: workExperience?.company_name || "",
                        job_title: workExperience?.job_title || "",
                        is_current: workExperience?.is_current || false,
                        start: workExperience?.start || "",
                        end: workExperience?.end || "",
                        description: workExperience?.description || "",
                      },
                    ],
              education:
                education?.length > 0
                  ? education
                  : [
                      {
                        university_or_collage:
                          education?.university_or_collage || "",
                        graduation_year: education?.graduation_year || "",
                        field_of_study: education?.field_of_study || "",
                        degree: education?.degree || "",
                      },
                    ],
              languages:
                languages?.length > 0
                  ? languages
                  : [
                      {
                        language: languages?.language || "",
                        language_level: languages?.language_level || "",
                      },
                    ],
              objective: res?.data?.profile?.objective || "",
              achievements: res?.data?.profile?.achievements || "",
              personal_characteristics:
                res?.data?.profile?.personal_characteristics || "",
            },
          });
        } else {
        }
        setLoading(false);
      } catch (error) {
        console.error("Error fetching on-boarded user data:", error);
        setLoading(false);
      }
    };

    fetchOnBoardedUserData();
  }, []);
  const navigate = useNavigate();
  const { values, handleChange } = formik;
  const classes = useStyles();

  const [workExperiences, setWorkExperiences] = useState(
    values.work_experience || [
      {
        company_name: "",
        job_title: "",
        is_current: false,
        start: null, // Initialize with null or a default date object
        end: null, // Initialize end date with null or a default date object
        description: "",
      },
    ]
  );
  const [addedWorkExperiences, setAddedWorkExperiences] = useState([false]);

  const handleWorkExperienceChange = (index, field, value) => {
    const updatedExperiences = [...workExperiences];
    updatedExperiences[index][field] = value;
    setWorkExperiences(updatedExperiences);
    formik.setFieldValue(
      `background.work_experience[${index}].${field}`,
      value
    );
  };

  const handleDateChange = (date, dateType, index) => {
    const formattedDate = dayjs(date).format("MMMM DD, YYYY");

    // Make sure background.work_experience is initialized properly
    if (!formik.values.background.work_experience) {
      formik.setFieldValue("background.work_experience", []);
    }

    // Make a copy of the array of work experiences
    const updatedWorkExperiences = [
      ...formik.values.background.work_experience,
    ];

    // Ensure index is within bounds
    if (index >= 0 && index < updatedWorkExperiences.length) {
      // Update the start date of the specific work experience entry
      updatedWorkExperiences[index] = {
        ...updatedWorkExperiences[index],
        start: formattedDate,
      };

      // Set the updated value (formatted date) back into Formik's form state
      formik.setFieldValue(
        `background.work_experience`,
        updatedWorkExperiences
      );
    } else {
      console.error(`Invalid index ${index} for background.work_experience`);
    }
  };

  const handleStartDateChange = (index, date) => {
    const formattedDate = dayjs(date).format("YYYY-MM-DD");

    const updatedWorkExperiences = [
      ...formik.values.background.work_experience,
    ];
    updatedWorkExperiences[index].start = formattedDate;

    formik.setFieldValue(
      `background.work_experience[${index}].start`,
      formattedDate
    );
  };

  const handleEndDateChange = (index, date) => {
    const formattedDate = dayjs(date).format("YYYY-MM-DD");

    const updatedWorkExperiences = [
      ...formik.values.background.work_experience,
    ];
    updatedWorkExperiences[index].end = formattedDate;

    formik.setFieldValue(
      `background.work_experience[${index}].end`,
      formattedDate
    );
  };

  const handleAddWorkExperience = () => {
    setWorkExperiences([
      ...workExperiences,
      {
        company_name: "",
        job_title: "",
        is_current: false,
        start: null, // Initialize with null or a default date object
        end: null, // Initialize end date with null or a default date object
        description: "",
      },
    ]);
    setAddedWorkExperiences([...addedWorkExperiences, true]);
    formik.setFieldValue(
      `background.work_experience[${workExperiences.length}].company_name`,
      ""
    );
    formik.setFieldValue(
      `background.work_experience[${workExperiences.length}].job_title`,
      ""
    );
    formik.setFieldValue(
      `background.work_experience[${workExperiences.length}].is_current`,
      ""
    );
    formik.setFieldValue(
      `background.work_experience[${workExperiences.length}].start`,
      ""
    );
    formik.setFieldValue(
      `background.work_experience[${workExperiences.length}].end`,
      ""
    );
    formik.setFieldValue(
      `background.work_experience[${workExperiences.length}].description`,
      ""
    );
  };

  const handleRemoveWorkExperience = (index) => {
    const updatedExperiences = [...workExperiences];
    updatedExperiences.splice(index, 1);
    setWorkExperiences(updatedExperiences);
    const updatedAddedExperience = [...addedWorkExperiences];
    updatedAddedExperience.splice(index, 1);
    setAddedWorkExperiences(updatedAddedExperience);
    formik.setFieldValue(`background.work_experience`, updatedExperiences);
  };

  const [languages, setLanguages] = useState(
    values.languages || [{ language: "", language_level: "" }]
  );
  const [addedLanguages, setAddedLanguages] = useState([false]);

  const handleLanguageChange = (index, field, value) => {
    const updatedLanguages = [...languages];
    updatedLanguages[index][field] = value;
    setLanguages(updatedLanguages);
    formik.setFieldValue(`background.languages[${index}].${field}`, value);
  };

  const handleAddLanguage = () => {
    setLanguages([...languages, { language: "", language_level: "" }]);
    setAddedLanguages([...addedLanguages, true]);
    formik.setFieldValue(
      `background.languages[${languages.length}].language`,
      ""
    );
    formik.setFieldValue(
      `background.languages[${languages.length}].language_level`,
      ""
    );
  };

  const handleRemoveLanguage = (index) => {
    const updatedLanguages = [...languages];
    updatedLanguages.splice(index, 1);
    setLanguages(updatedLanguages);

    const updatedAddedLanguages = [...addedLanguages];
    updatedAddedLanguages.splice(index, 1);
    setAddedLanguages(updatedAddedLanguages);

    // Update Formik values when removing language
    formik.setFieldValue(`background.languages`, updatedLanguages);
  };

  const [educations, setEducations] = useState(
    values.education || [
      {
        university_or_collage: "",
        graduation_year: "",
        field_of_study: "",
        degree: "",
      },
    ]
  );
  const [addedEducations, setAddedEducations] = useState([false]);

  const handleEducationChange = (index, field, value) => {
    const updatedEducations = [...educations];
    updatedEducations[index][field] = value;
    setEducations(updatedEducations);
    formik.setFieldValue(`background.education[${index}].${field}`, value);
  };

  const handleAddEducation = () => {
    setEducations([
      ...educations,
      {
        university_or_collage: "",
        graduation_year: "",
        field_of_study: "",
        degree: "",
      },
    ]);
    setAddedEducations([...addedEducations, true]);
    formik.setFieldValue(
      `background.education[${educations.length}].university_or_collage`,
      ""
    );
    formik.setFieldValue(
      `background.education[${educations.length}].graduation_year`,
      ""
    );
    formik.setFieldValue(
      `background.education[${educations.length}].field_of_study`,
      ""
    );
    formik.setFieldValue(
      `background.education[${educations.length}].degree`,
      ""
    );
  };

  const handleRemoveEducation = (index) => {
    const updatedEducations = [...educations];
    updatedEducations.splice(index, 1);
    setEducations(updatedEducations);

    const updatedAddedEducations = [...addedEducations];
    updatedAddedEducations.splice(index, 1);
    setAddedEducations(updatedAddedEducations);

    // Update Formik values when removing language
    formik.setFieldValue(`background.education`, updatedEducations);
  };

  const handleSubmitForm = (e) => {
    e.preventDefault();
  };

  return (
    <form onSubmit={handleSubmitForm}>
      <Box>
        <Typography
          ml={1}
          sx={{
            mt: 2,
            fontWeight: "600",
            fontSize: "22px",
            marginLeft: "-4px",
            marginBottom: "15px",
          }}
        >
          {t("employee.background.Work Experience")}
        </Typography>
        <Box>
          <Grid container spacing={2}>
            {formik.values.background.work_experience.map(
              (experience, index) => (
                <React.Fragment key={index}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <InputLabel
                      mt={2}
                      sx={{
                        alignSelf: "flex-start",
                        fontWeight: "600",
                        fontSize: "14px",
                        color: "text.primary",
                        mb: 0.5,
                      }}
                    >
                      {t("employee.background.Company Name")}
                    </InputLabel>
                    <TextField
                      name={`background.work_experience[${index}].company_name`}
                      placeholder={t("employee.background.Company Name")}
                      variant="outlined"
                      fullWidth
                      value={experience.company_name}
                      onChange={(e) =>
                        handleWorkExperienceChange(
                          index,
                          "company_name",
                          e.target.value
                        )
                      }
                      sx={{ mb: 2 }}
                    />
                    {formik.touched.background?.work_experience?.[index]
                      ?.company_name &&
                    formik.errors.background?.work_experience?.[index]
                      ?.company_name ? (
                      <Typography
                        variant="body2"
                        sx={{
                          color: "#C70039",
                          textAlign: "left",
                          fontSize: "12px",
                        }}
                      >
                        {
                          formik.errors.background.work_experience[index]
                            .company_name
                        }
                      </Typography>
                    ) : null}
                  </Grid>

                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <InputLabel
                      mt={2}
                      sx={{
                        alignSelf: "flex-start",
                        fontWeight: "600",
                        fontSize: "14px",
                        color: "text.primary",
                      }}
                    >
                      {t("employee.background.Job Title")}
                    </InputLabel>
                    <TextField
                      name={`background.work_experience[${index}].job_title`}
                      placeholder={t("employee.background.Job Title")}
                      variant="outlined"
                      fullWidth
                      value={experience.job_title}
                      onChange={(e) =>
                        handleWorkExperienceChange(
                          index,
                          "job_title",
                          e.target.value
                        )
                      }
                      sx={{ mb: 2 }}
                    />
                    {formik.touched.background?.work_experience?.[index]
                      ?.job_title &&
                    formik.errors.background?.work_experience?.[index]
                      ?.job_title ? (
                      <Typography
                        variant="body2"
                        sx={{
                          color: "#C70039",
                          textAlign: "left",
                          fontSize: "12px",
                        }}
                      >
                        {
                          formik.errors.background.work_experience[index]
                            .job_title
                        }
                      </Typography>
                    ) : null}
                  </Grid>

                  {!experience.is_current && (
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                      <InputLabel
                        mt={2}
                        sx={{
                          fontWeight: "600",
                          fontSize: "16px",
                          color: "text.primary",
                          mb: 0.5,
                        }}
                      >
                        {t("employee.background.Started")}
                      </InputLabel>

                      <Box sx={{ position: "relative", py: "20px", mr: 2 }}>
                        <div className="calendar">
                          <Grid item xs={12}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DatePicker
                                renderInput={(props) => (
                                  <TextField {...props} />
                                )}
                                name={`background.work_experience[${index}].start`}
                                value={
                                  experience.start
                                    ? dayjs(experience.start)
                                    : null
                                } // Ensure `value` is a `dayjs` object
                                onChange={(newValue) =>
                                  handleStartDateChange(index, newValue)
                                }
                                inputFormat="MM/dd/yyyy"
                                views={["month", "year"]}
                                sx={{ width: "100%" }}
                              />
                            </LocalizationProvider>
                          </Grid>
                        </div>
                      </Box>
                    </Grid>
                  )}
                  <Box></Box>
                  {!experience.is_current && (
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                      <InputLabel
                        mt={2}
                        sx={{
                          fontWeight: "600",
                          fontSize: "16px",
                          color: "text.primary",
                          mb: 0.5,
                        }}
                      >
                        {t("employee.background.Finished")}
                      </InputLabel>

                      <Box sx={{ position: "relative", py: "20px", mr: 2 }}>
                        <div className="calendar">
                          <Grid item xs={12}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DatePicker
                                renderInput={(props) => (
                                  <TextField {...props} />
                                )}
                                name={`background.work_experience[${index}].end`}
                                value={
                                  experience.end ? dayjs(experience.end) : null
                                } // Ensure `value` is a `dayjs` object
                                onChange={(newValue) =>
                                  handleEndDateChange(index, newValue)
                                }
                                inputFormat="MM/dd/yyyy"
                                views={["month", "year"]}
                                sx={{ width: "100%" }}
                              />
                            </LocalizationProvider>
                          </Grid>
                        </div>
                      </Box>
                      <FormControlLabel
                        control={
                          <Checkbox
                            sx={{
                              alignSelf: "flex-start",
                              color: "gray",
                              "&.Mui-checked": { color: "secondary.main" },
                            }}
                            name={`background.work_experience[${index}].is_current`}
                            checked={experience.is_current}
                            onChange={(e) =>
                              handleWorkExperienceChange(
                                index,
                                "is_current",
                                e.target.checked
                              )
                            }
                          />
                        }
                        label={t("employee.background.current")}
                      />
                    </Grid>
                  )}
                  {experience.is_current && (
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                      <InputLabel
                        mt={2}
                        sx={{
                          fontWeight: "600",
                          fontSize: "16px",
                          color: "text.primary",
                          mb: 0.5,
                        }}
                      >
                        {t("employee.background.Started")}
                      </InputLabel>

                      <Box sx={{ position: "relative", py: "20px", mr: 2 }}>
                        <div className="calendar">
                          <Grid item xs={12}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DatePicker
                                renderInput={(props) => (
                                  <TextField {...props} />
                                )}
                                name={`background.work_experience[${index}].start`}
                                value={
                                  experience.start
                                    ? dayjs(experience.start)
                                    : null
                                } // Ensure `value` is a `dayjs` object
                                onChange={(newValue) =>
                                  handleStartDateChange(index, newValue)
                                }
                                inputFormat="MM/dd/yyyy"
                                views={["month", "year"]}
                                sx={{ width: "100%" }}
                              />
                            </LocalizationProvider>
                          </Grid>
                        </div>
                      </Box>
                    </Grid>
                  )}
                  <Box></Box>
                  {experience.is_current && (
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                      <Box
                        sx={{ position: "relative", py: "20px", ml: 10, mt: 4 }}
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              sx={{
                                alignSelf: "flex-start",
                                color: "gray",
                                "&.Mui-checked": { color: "secondary.main" },
                              }}
                              name={`background.work_experience[${index}].is_current`}
                              checked={experience.is_current}
                              onChange={(e) =>
                                handleWorkExperienceChange(
                                  index,
                                  "is_current",
                                  e.target.checked
                                )
                              }
                            />
                          }
                          label={t("employee.background.current")}
                        />
                      </Box>
                    </Grid>
                  )}

                  <Grid item xs={12}>
                    <Typography
                      component="h2"
                      variant="body1"
                      sx={{
                        alignSelf: "flex-start",
                        fontSize: "14px",
                        fontWeight: "bold",
                        textTransform: "none",
                        color: "black",
                        paddingTop: "5px",
                        mb: 0.5,
                      }}
                    >
                      {t("employee.background.Description")}
                    </Typography>
                    <TextField
                      name={`background.work_experience[${index}].description`}
                      placeholder={t("employee.background.Description")}
                      variant="outlined"
                      fullWidth
                      multiline
                      rows={3}
                      value={experience.description}
                      onChange={(e) =>
                        handleWorkExperienceChange(
                          index,
                          "description",
                          e.target.value
                        )
                      }
                      sx={{ mb: 2 }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    {addedWorkExperiences[index] && ( // Only show remove button for added languages
                      <Grid item xs={12}>
                        <Button
                          onClick={() => handleRemoveWorkExperience(index)}
                          variant="standard"
                          sx={{
                            mb: 2,
                            backgroundColor: "secondary.main",
                            color: "text.secondary",
                            "&:hover": {
                              backgroundColor: "secondary.main",
                            },
                          }}
                        >
                          {t("employee.background.Remove")}
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                </React.Fragment>
              )
            )}
            <Grid item xs={12}>
              <Button
                type="button"
                variant="standard"
                onClick={handleAddWorkExperience}
                sx={{
                  backgroundColor: "white",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "white",
                  },
                }}
              >
                <AddIcon
                  sx={{
                    mt: -0.5,
                    ml: -3,
                    backgroundColor: "white",
                    color: "secondary.main",
                    "&:hover": {
                      backgroundColor: "white",
                    },
                  }}
                  onClick={() => navigate("/preferences")}
                />
                <Typography
                  component="h2"
                  variant="body1"
                  sx={{
                    fontSize: "15px",
                    fontWeight: "bold",
                    textTransform: "none",
                    color: "secondary.main",
                    padding: "2px",
                  }}
                >
                  {t("employee.background.Add More")}
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </Box>

        <Typography
          ml={1}
          sx={{
            mt: 2,
            fontWeight: "600",
            fontSize: "22px",
            marginLeft: "-4px",
            marginBottom: "15px",
          }}
        >
          {t("employee.background.Education")}
        </Typography>

        <Grid container spacing={2}>
          {formik.values.background.education.map((education, index) => (
            <React.Fragment key={index}>
              <Grid item xs={12} sm={6}>
                <InputLabel
                  mt={2}
                  sx={{
                    fontWeight: "600",
                    fontSize: "14px",
                    color: "text.primary",
                    mb: 0.5,
                  }}
                >
                  {t("employee.background.University/College *")}
                </InputLabel>
                <TextField
                  name={`background.education[${index}].university_or_collage`}
                  value={education.university_or_collage}
                  onChange={(e) =>
                    handleEducationChange(
                      index,
                      "university_or_collage",
                      e.target.value
                    )
                  }
                  placeholder={t("employee.background.Universityplaceholder *")}
                  variant="outlined"
                  fullWidth
                  sx={{ mb: 2 }}
                />
                {formik.touched.background?.education?.[index]
                  ?.university_or_collage &&
                formik.errors.background?.education?.[index]
                  ?.university_or_collage ? (
                  <Typography
                    variant="body2"
                    sx={{
                      color: "#C70039",
                      textAlign: "left",
                      fontSize: "12px",
                    }}
                  >
                    {
                      formik.errors.background.education[index]
                        .university_or_collage
                    }
                  </Typography>
                ) : null}
              </Grid>

              <Grid item xs={12} sm={6}>
                <InputLabel
                  mt={2}
                  sx={{
                    fontWeight: "600",
                    fontSize: "14px",
                    color: "text.primary",
                    mb: 0.5,
                  }}
                >
                  {t("employee.background.Graduation Year *")}
                </InputLabel>
                <TextField
                  name={`background.education[${index}].graduation_year`}
                  value={education.graduation_year}
                  onChange={(e) =>
                    handleEducationChange(
                      index,
                      "graduation_year",
                      e.target.value
                    )
                  }
                  placeholder={t(
                    "employee.background.GraduationYearplaceholder"
                  )}
                  variant="outlined"
                  fullWidth
                  sx={{ mb: 2 }}
                />
                {formik.touched.background?.education?.[index]
                  ?.graduation_year &&
                formik.errors.background?.education?.[index]
                  ?.graduation_year ? (
                  <Typography
                    variant="body2"
                    sx={{
                      color: "#C70039",
                      textAlign: "left",
                      fontSize: "12px",
                    }}
                  >
                    {formik.errors.background.education[index].graduation_year}
                  </Typography>
                ) : null}
              </Grid>

              <Grid item xs={12} sm={6}>
                <InputLabel
                  mt={2}
                  sx={{
                    fontWeight: "600",
                    fontSize: "14px",
                    color: "text.primary",
                    mb: 0.5,
                  }}
                >
                  {t("employee.background.Field of Study *")}
                </InputLabel>
                <TextField
                  name={`background.education[${index}].field_of_study`}
                  value={education.field_of_study}
                  onChange={(e) =>
                    handleEducationChange(
                      index,
                      "field_of_study",
                      e.target.value
                    )
                  }
                  placeholder={t(
                    "employee.background.Fieldofstudy.placeholder"
                  )}
                  variant="outlined"
                  fullWidth
                  sx={{ mb: 2 }}
                />
                {formik.touched.background?.education?.[index]
                  ?.field_of_study &&
                formik.errors.background?.education?.[index]?.field_of_study ? (
                  <Typography
                    variant="body2"
                    sx={{
                      color: "#C70039",
                      textAlign: "left",
                      fontSize: "12px",
                    }}
                  >
                    {formik.errors.background.education[index].field_of_study}
                  </Typography>
                ) : null}
              </Grid>

              <Grid item xs={12} sm={6}>
                <InputLabel
                  mt={2}
                  sx={{
                    fontWeight: "600",
                    fontSize: "14px",
                    color: "text.primary",
                    mb: 0.5,
                  }}
                >
                  {t("employee.background.Degree *")}
                </InputLabel>
                <FormControl fullWidth sx={{ mb: 2 }}>
                  <Select
                    name={`background.education[${index}].degree`}
                    value={education.degree}
                    onChange={(e) =>
                      handleEducationChange(index, "degree", e.target.value)
                    }
                    displayEmpty
                    renderValue={(selected) => {
                      if (selected === "") {
                        return (
                          <span style={{ color: "#aaa" }}>
                            {t("employee.background.Select Degree")}
                          </span>
                        );
                      }
                      return selected;
                    }}
                  >
                    <MenuItem value="" disabled>
                      <em>{t("employee.background.Select Degree")}</em>
                    </MenuItem>
                    <MenuItem
                      value={t("employee.background.Bachelor's Degree")}
                    >
                      {t("employee.background.Bachelor's Degree")}
                    </MenuItem>
                    <MenuItem value={t("employee.background.Master's Degree")}>
                      {t("employee.background.Master's Degree")}
                    </MenuItem>
                    <MenuItem value={t("employee.background.Doctorate Degree")}>
                      {t("employee.background.Doctorate Degree")}
                    </MenuItem>
                  </Select>
                  {formik.touched.background?.education?.[index]?.degree &&
                  formik.errors.background?.education?.[index]?.degree ? (
                    <Typography
                      variant="body2"
                      sx={{
                        color: "#C70039",
                        textAlign: "left",
                        fontSize: "12px",
                      }}
                    >
                      {formik.errors.background.education[index].degree}
                    </Typography>
                  ) : null}
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                {addedEducations[index] && ( // Only show remove button for added languages
                  <Grid item xs={12}>
                    <Button
                      onClick={() => handleRemoveEducation(index)}
                      variant="standard"
                      sx={{
                        mb: 2,
                        backgroundColor: "secondary.main",
                        color: "text.secondary",
                        "&:hover": {
                          backgroundColor: "secondary.main",
                        },
                      }}
                    >
                      {t("employee.background.Remove")}
                    </Button>
                  </Grid>
                )}
              </Grid>
            </React.Fragment>
          ))}
          <Grid item xs={12}>
            <Button
              type="button"
              variant="standard"
              onClick={handleAddEducation}
              sx={{
                backgroundColor: "white",
                color: "white",
                "&:hover": {
                  backgroundColor: "white",
                },
              }}
            >
              <AddIcon
                sx={{
                  mt: -0.5,
                  ml: -3,
                  backgroundColor: "white",
                  color: "secondary.main",
                  "&:hover": {
                    backgroundColor: "white",
                  },
                }}
              />
              <Typography
                component="h2"
                variant="body1"
                sx={{
                  fontSize: "15px",
                  fontWeight: "bold",
                  textTransform: "none",
                  color: "secondary.main",
                  padding: "2px",
                }}
              >
                {t("employee.background.Add More")}
              </Typography>
            </Button>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Box mt={2}>
            <Grid container spacing={2}>
              {formik.values.background.languages.map((language, index) => (
                <React.Fragment key={index}>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <InputLabel
                      mt={2}
                      sx={{
                        fontWeight: "600",
                        fontSize: "14px",
                        color: "text.primary",
                        mb: 0.5,
                      }}
                    >
                      {t("employee.background.Language *")}
                    </InputLabel>
                    <FormControl fullWidth>
                      <Select
                        name={`background.languages[${index}].language`}
                        value={language.language}
                        onChange={(e) =>
                          handleLanguageChange(
                            index,
                            "language",
                            e.target.value
                          )
                        }
                        onBlur={formik.handleBlur}
                        displayEmpty
                        renderValue={(selected) => {
                          if (selected === "") {
                            return (
                              <span style={{ color: "#aaa" }}>
                                {t("employee.background.Select Language")}
                              </span>
                            );
                          }
                          return selected;
                        }}
                      >
                        <MenuItem value="" disabled>
                          <em>{t("employee.background.Select Language")}</em>
                        </MenuItem>
                        <MenuItem value={t("employee.background.English")}>
                          {t("employee.background.English")}
                        </MenuItem>
                        <MenuItem value={t("employee.background.Spanish")}>
                          {t("employee.background.Spanish")}
                        </MenuItem>
                        <MenuItem value={t("employee.background.French")}>
                          {t("employee.background.French")}
                        </MenuItem>
                        <MenuItem value={t("employee.background.German")}>
                          {t("employee.background.German")}
                        </MenuItem>
                        <MenuItem value={t("employee.background.Italian")}>
                          {t("employee.background.Italian")}
                        </MenuItem>
                        <MenuItem value={t("employee.background.Portuguese")}>
                          {t("employee.background.Portuguese")}
                        </MenuItem>
                        <MenuItem
                          value={t("employee.background.Mandarin Chinese")}
                        >
                          {t("employee.background.Mandarin Chinese")}
                        </MenuItem>
                        <MenuItem value={t("employee.background.Japanese")}>
                          {t("employee.background.Japanese")}
                        </MenuItem>
                        <MenuItem value={t("employee.background.Russian")}>
                          {t("employee.background.Russian")}
                        </MenuItem>
                        <MenuItem value={t("employee.background.Arabic")}>
                          {t("employee.background.Arabic")}
                        </MenuItem>
                      </Select>
                    </FormControl>
                    {formik.touched.background?.languages?.[index]?.language &&
                    formik.errors.background?.languages?.[index]?.language ? (
                      <Typography
                        variant="body2"
                        sx={{
                          color: "#C70039",
                          textAlign: "left",
                          fontSize: "12px",
                        }}
                      >
                        {formik.errors.background.languages[index].language}
                      </Typography>
                    ) : null}
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <InputLabel
                      mt={2}
                      sx={{
                        fontWeight: "600",
                        fontSize: "14px",
                        color: "text.primary",
                        mb: 0.5,
                      }}
                    >
                      {t("employee.background.Level *")}
                    </InputLabel>
                    <FormControl fullWidth>
                      <Select
                        name={`background.languages[${index}].level`}
                        value={language.language_level}
                        onChange={(e) =>
                          handleLanguageChange(
                            index,
                            "language_level",
                            e.target.value
                          )
                        }
                        onBlur={formik.handleBlur}
                        displayEmpty
                        renderValue={(selected) => {
                          if (selected === "") {
                            return (
                              <span style={{ color: "#aaa" }}>
                                {t("employee.background.Select Level")}
                              </span>
                            );
                          }
                          return selected;
                        }}
                      >
                        <MenuItem value="" disabled>
                          <em>{t("employee.background.Select Level")}</em>
                        </MenuItem>
                        <MenuItem value={t("employee.background.Beginner")}>
                          {t("employee.background.Beginner")}
                        </MenuItem>
                        <MenuItem value={t("employee.background.Intermediate")}>
                          {t("employee.background.Intermediate")}
                        </MenuItem>
                        <MenuItem value={t("employee.background.Advanced")}>
                          {t("employee.background.Advanced")}
                        </MenuItem>
                        <MenuItem value={t("employee.background.Native")}>
                          {t("employee.background.Native")}
                        </MenuItem>
                      </Select>
                      {formik.touched.background?.languages?.[index]
                        ?.language_level &&
                      formik.errors.background?.languages?.[index]
                        ?.language_level ? (
                        <Typography
                          variant="body2"
                          sx={{
                            color: "#C70039",
                            textAlign: "left",
                            fontSize: "12px",
                          }}
                        >
                          {
                            formik.errors.background.languages[index]
                              .language_level
                          }
                        </Typography>
                      ) : null}
                    </FormControl>
                  </Grid>
                  {addedLanguages[index] && (
                    <Grid item xs={12}>
                      <Button
                        onClick={() => handleRemoveLanguage(index)}
                        variant="standard"
                        sx={{
                          mb: 2,
                          backgroundColor: "secondary.main",
                          color: "text.secondary",
                          "&:hover": {
                            backgroundColor: "secondary.main",
                          },
                        }}
                      >
                        {t("employee.background.Remove")}
                      </Button>
                    </Grid>
                  )}
                </React.Fragment>
              ))}
              <Grid item xs={12}>
                <Button
                  type="button"
                  variant="standard"
                  onClick={handleAddLanguage}
                  sx={{
                    backgroundColor: "white",
                    color: "white",
                    "&:hover": {
                      backgroundColor: "white",
                    },
                  }}
                >
                  <AddIcon
                    sx={{
                      mt: -0.5,
                      ml: -3,
                      backgroundColor: "white",
                      color: "secondary.main",
                      "&:hover": {
                        backgroundColor: "white",
                      },
                    }}
                  />
                  <Typography
                    component="h2"
                    variant="body1"
                    sx={{
                      fontSize: "15px",
                      fontWeight: "bold",
                      textTransform: "none",
                      color: "secondary.main",
                      padding: "2px",
                    }}
                  >
                    {t("employee.background.Add More")}
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Box>

      <Box>
        <Typography
          ml={1}
          sx={{
            mt: 2,
            fontWeight: "600",
            fontSize: "22px",
            marginLeft: "-4px",
            marginBottom: "15px",
          }}
        >
          {t("employee.background.Additional Information")}
        </Typography>
        <Typography
          component="h2"
          variant="body1"
          sx={{
            alignSelf: "flex-start",
            fontSize: "14px",
            fontWeight: "bold",
            textTransform: "none",
            color: "black",
            paddingTop: "5px",
            mb: 0.5,
          }}
        >
          {t("employee.background.Objective")}
        </Typography>
        <TextField
          name="background.objective"
          value={values.background.objective}
          onChange={handleChange}
          placeholder={t("employee.background.Type here")}
          variant="outlined"
          fullWidth
          multiline
          rows={3}
          sx={{ mb: 2 }}
        />
        <Typography
          component="h2"
          variant="body1"
          sx={{
            alignSelf: "flex-start",
            fontSize: "14px",
            fontWeight: "bold",
            textTransform: "none",
            color: "black",
            paddingTop: "5px",
            mb: 0.5,
          }}
        >
          {t("employee.background.Achievements")}
        </Typography>
        <TextField
          name="background.achievements"
          value={values.background.achievements}
          onChange={handleChange}
          placeholder={t("employee.background.Type here")}
          variant="outlined"
          fullWidth
          multiline
          rows={3}
          sx={{ mb: 2 }}
        />
        <Typography
          component="h2"
          variant="body1"
          sx={{
            alignSelf: "flex-start",
            fontSize: "14px",
            fontWeight: "bold",
            textTransform: "none",
            color: "black",
            paddingTop: "5px",
            mb: 0.5,
          }}
        >
          {t("employee.background.Personal Characteristics")}
        </Typography>
        <TextField
          name="background.personal_characteristics"
          value={values.background.personal_characteristics}
          onChange={handleChange}
          placeholder={t("employee.background.Type here")}
          variant="outlined"
          fullWidth
          multiline
          rows={3}
          sx={{ mb: 2 }}
        />
      </Box>
    </form>
  );
};

const Background = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const [formikValues, setFormikValues] = useState({
    background: {
      work_experience: [
        {
          company_name: "",
          job_title: "",
          is_current: false,
          start: dayjs(),
          end: dayjs(),
          description: "",
        },
      ],

      education: [
        {
          university_or_collage: "",
          graduation_year: "",
          field_of_study: "",
          degree: "",
        },
      ],

      languages: [
        {
          language: "",
          language_level: "",
        },
      ],
      objective: "",
      achievements: "",
      personal_characteristics: "",
    },
  });
  const validationSchema = yup.object().shape({
    background: yup.object().shape({
      work_experience: yup.array().of(
        yup.object().shape({
          company_name: yup.string().required("Company name is required"),
          job_title: yup.string().required("Job title is required"),
        })
      ),
      education: yup.array().of(
        yup.object().shape({
          university_or_collage: yup
            .string()
            .required("University or college is required"),
          graduation_year: yup.string().required("Graduation year is required"),
          field_of_study: yup.string().required("Field of study is required"),
          degree: yup.string().required("Degree is required"),
        })
      ),
      languages: yup.array().of(
        yup.object().shape({
          language: yup.string().required("Language is required"),
          language_level: yup.string().required("Language level is required"),
        })
      ),
    }),
  });

  return (
    <>
      <Box sx={{ backgroundColor: "white", minHeight: "100vh", padding: 0 }}>
        <Container>
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              <Box sx={{ mx: { lg: "150px" } }}>
                <Formik
                  initialValues={formikValues}
                  validationSchema={validationSchema}
                  onSubmit={(values) => {
                    submitForm(values);
                  }}
                  validateOnChange={false}
                  validateOnBlur={false}
                >
                  {(formik) => (
                    <Form>
                      <BackgroundForm formik={formik} />
                      <Divider sx={{ my: 2 }} />

                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          mt: 3,
                        }}
                      >
                        <Button
                          type="button"
                          onClick={() => navigate("/v1/employee/about-you")}
                          variant="outlined"
                          sx={{
                            mt: 3,
                            mb: 2,
                            backgroundColor: "white",
                            borderColor: "primary.main",
                            color: "text.secondary",
                            "&:hover": {
                              backgroundColor: "text.secondary",
                            },
                          }}
                        >
                          <Typography
                            component="h2"
                            variant="body1"
                            sx={{
                              alignSelf: "flex-start",
                              fontSize: "15px",
                              fontWeight: "bold",
                              textTransform: "none",
                              color: "primary.main",
                              padding: "2px",
                            }}
                          >
                            {t("employee.background.Back")}
                          </Typography>
                        </Button>

                        <Button
                          type="submit"
                          variant="standard"
                          // onClick={() => navigate("/v1/employee/background")}
                          // onClick={() =>navigate("/v1/employee/preview-and-submit", { state: { formikValues: formik.values } })}
                          onClick={async () => {
                            const isValid = await formik.validateForm();
                            if (Object.keys(isValid).length === 0) {
                              try {
                                // Include profile_photo_url

                                await formik.submitForm();

                                // Navigate after the delay
                                navigate("/v1/employee/preview-and-submit", {
                                  state: { formikValues: formik.values },
                                });
                              } catch (error) {
                                console.error("Error submitting form:", error);
                              }
                            } else {
                              // Mark all fields as touched to display errors
                              formik.setTouched({
                                background: {
                                  work_experience: [
                                    {
                                      company_name: true,
                                      job_title: true,
                                    },
                                  ],

                                  education: [
                                    {
                                      university_or_collage: true,
                                      graduation_year: true,
                                      field_of_study: true,
                                      degree: true,
                                    },
                                  ],

                                  languages: [
                                    {
                                      language: true,
                                      language_level: true,
                                    },
                                  ],
                                },
                              });
                            }
                          }}
                          sx={{
                            mt: 3,
                            mb: 2,
                            backgroundColor: "secondary.main",
                            color: "white",
                            "&:hover": {
                              backgroundColor: "secondary.main",
                            },
                          }}
                        >
                          <Typography
                            component="h2"
                            variant="body1"
                            sx={{
                              alignSelf: "flex-start",
                              fontSize: "15px",
                              fontWeight: "bold",
                              textTransform: "none",
                              color: "primary.main",
                              padding: "2px",
                            }}
                          >
                            {t("employee.background.Next")}
                          </Typography>
                        </Button>
                      </Box>
                    </Form>
                  )}
                </Formik>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default Background;
