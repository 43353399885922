import React from 'react';
import { Container, Grid, Card, CardContent, Typography, Box, useMediaQuery } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
  card: {
    border: `1px solid black`,
    borderRadius: '30px !important',
    padding: theme.spacing(2),
    textAlign: 'left',
    height: "85%"
  },
  stepNumber: {
    fontSize: '2rem',
    fontWeight: 'bold',
    color: '#8EFFC2',
  },
  stepTitle: {
    fontWeight: 'bold',
    fontSize: '1.2rem',
    color: 'black',
    display: 'block',
  },
  stepDescription: {
    marginTop: theme.spacing(3),
    fontSize: '1rem',
    color: '#333',
  },
  header: {
    textAlign: 'center',
    marginBottom: theme.spacing(4),
  },
  headerTitle: {
    fontWeight: 'normal',
    fontSize: '3rem !important',
  },
  headerHighlight: {
    fontWeight: 'bold',
  },
}));

const Steps = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useTranslation();

  const steps = [
    {
      number: '1.',
      title: t('landing.steps.create_account'),
      description: t('landing.steps.create_account_desc'),
    },
    {
      number: '2.',
      title: t('landing.steps.complete_profile'),
      description: t('landing.steps.complete_profile_desc'),
    },
    {
      number: '3.',
      title: t('landing.steps.start_receiving_offers'),
      description: t('landing.steps.start_receiving_offers_desc'),
    },
    {
      number: '4.',
      title: t('landing.steps.accept_best_job'),
      description: t('landing.steps.accept_best_job_desc'),
    },
  ];

  return (
    <Container className={classes.root}>
      <Box className={classes.header}>
        <Typography
          variant="h4"
          sx={{ fontSize: isSmallScreen ? '1rem' : '2.5rem' }}
          className={classes.headerTitle}
        >
          {t('landing.header.easy')}
        </Typography>
        <Typography
          variant="h4"
          sx={{ fontSize: isSmallScreen ? '1rem' : '2.5rem' }}
          className={classes.headerTitle}
        >
          <span className={classes.headerHighlight}>{t('landing.header.hirio')} </span>{t('landing.header.works')} 
        </Typography>
      </Box>
      <br />
      <br />
      <Grid container spacing={3}>
        {steps.map((step, index) => (
          <Grid item xs={12} md={3} key={index}>
            <Card className={classes.card}>
              <CardContent>
                <Typography>
                  <span className={classes.stepNumber}>{step.number}</span>
                </Typography>
                {step.title.split('\n').map((line, i) => (
                  <Typography key={i}>
                    <span className={classes.stepTitle}>{line}</span>
                  </Typography>
                ))}
                <Typography className={classes.stepDescription}>{step.description}</Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      <br />
    </Container>
  );
};

export default Steps;
