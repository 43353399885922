import React from "react";
import { Box, Button, Container, Grid } from "@mui/material";
import RecuiterProfile from "../../Recruiter/Onboarding/RecruiterProfile/RecruiterProfile";
import { useFormik } from "formik";
import * as yup from "yup";

const Index = () => {

  const validationSchema = yup.object({
    recruiter_profile: yup.object({
      full_name: yup.string().required("Full name is required"),
      phone_number: yup.string().required("Phone number is required"),
    }),
    is_onboarding: yup.boolean(),
    is_active: yup.boolean(),
  });

  const formik = useFormik({
    initialValues: {
      recruiter_profile: {
        resume_url: "",
        profile_photo_url: "",
        full_name: "",
        phone_number: "",
        linkedin_url: ""
      },
      company_profile: {
        company_profile_photo_url: "",
        company_name: "",
        company_slogan: "",
        company_location: "",
        company_size: "",
        company_domain: "",
        company_overview: "",
        company_website: "",
        company_perks: {
          flexible_working_hours: false,
          remote_work: false,
          medical_insurance: false,
          paid_vacation: false,
          paid_sick_leaves: false,
          parental_leave: false,
          corporate_events: false,
          team_buildings: false,
          sport_activities: false,
          lunch_compensation: false,
          free_meals: false,
          wellness_program: false,
        },
      },
      is_onboarding: false,
      is_active: false,
    },
    validationSchema,
    onSubmit: (values) => {

    },
  });



  return (
    <>

      <Box sx={{ backgroundColor: 'white', minHeight: '100vh', padding: 0 }}>
        <Container>
          <Grid container justifyContent="center">
            <Grid item xs={12}>

              <Box sx={{ mx: { lg: "150px" } }}>
                <RecuiterProfile formik={formik} />
              </Box>

            </Grid>
          </Grid>
        </Container>
      </Box>
    </>


  );
};

export default Index;
