import React, { useState } from "react";
import {
  Typography,
  TextField,
  Button,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Divider,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useFormik } from "formik";
import * as yup from "yup";
import { JobPostApi } from "../../apis";
import { t } from "i18next";
import { useTranslation } from "react-i18next";

const CreateJobPostStep2 = ({ formData, setFormData, backStep, nextStep }) => {
  const { t } = useTranslation();
  // Validation schema for Formik
  const validationSchema = yup.object().shape({
    requirements: yup.object().shape({
      education: yup.string().required("Education is required"),
      languages: yup.array().of(
        yup.object().shape({
          language: yup.string().required("Language is required"),
          level: yup.string().required("Level is required"),
        })
      ),
    }),
  });

  // Formik instance for managing form state and validation
  const formik = useFormik({
    initialValues: formData,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        // const res = await JobPostApi.createJobPost(values);
        // // Update state or perform other actions based on API response
        // console.log("Form submitted successfully:", res);
        setFormData(values);
        nextStep();
      } catch (error) {
        // Handle API error
        console.error("Error submitting form:", error);
      }
    },
  });

  // Handle dynamic adding and removing of languages
  const [languages, setLanguages] = useState(
    formData.requirements.languages || [{ language: "", level: "" }]
  );
  const [addedLanguages, setAddedLanguages] = useState([false]);

  const handleEducationChange = (event) => {
    const { value } = event.target;
    formik.setFieldValue("requirements.education", value);
    setFormData({
      ...formData,
      requirements: {
        ...formData.requirements,
        education: value,
      },
    });
  };

  const handleLanguageChange = (index, field, value) => {
    const updatedLanguages = [...languages];
    updatedLanguages[index][field] = value;
    setLanguages(updatedLanguages);
    formik.setFieldValue(`requirements.languages[${index}].${field}`, value);
  };

  const handleAddLanguage = () => {
    setLanguages([...languages, { language: "", level: "" }]);
    setAddedLanguages([...addedLanguages, true]);
    formik.setFieldValue(
      `requirements.languages[${languages.length}].language`,
      ""
    );
    formik.setFieldValue(
      `requirements.languages[${languages.length}].level`,
      ""
    );
  };

  const handleRemoveLanguage = (index) => {
    const updatedLanguages = [...languages];
    updatedLanguages.splice(index, 1);
    setLanguages(updatedLanguages);

    const updatedAddedLanguages = [...addedLanguages];
    updatedAddedLanguages.splice(index, 1);
    setAddedLanguages(updatedAddedLanguages);

    // Update Formik values when removing language
    formik.setFieldValue(`requirements.languages`, updatedLanguages);
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid
        container
        spacing={3}
        sx={{ paddingTop: 1, paddingLeft: 1, backgroundColor: "#F5F5F5" }}
      >
        <Grid item xs={12}>
          <InputLabel
            mt={2}
            sx={{
              fontWeight: "600",
              fontSize: "16px",
              color: "text.primary",
              mb: 0.5,
            }}
          >
            {t("employee.background.Education")} *
          </InputLabel>
          <FormControl fullWidth>
            <Select
              name="requirements.education"
              value={formik.values.requirements.education}
              onChange={handleEducationChange}
              onBlur={formik.handleBlur}
              displayEmpty
              renderValue={(selected) => {
                if (selected === "") {
                  return (
                    <span style={{ color: "#aaa" }}>
                      {t("employee.background.Select Degree")}
                    </span>
                  );
                }
                return selected;
              }}
            >
              <MenuItem value="" disabled>
                <em>{t("employee.background.Select Degree")}</em>
              </MenuItem>
              <MenuItem value={t("employee.background.Bachelor's Degree")}>
                {t("employee.background.Bachelor's Degree")}
              </MenuItem>
              <MenuItem value={t("employee.background.Master's Degree")}>
                {t("employee.background.Master's Degree")}
              </MenuItem>
              <MenuItem value={t("employee.background.Doctorate Degree")}>
                {t("employee.background.Doctorate Degree")}
              </MenuItem>
            </Select>
            {formik.touched.requirements?.education &&
            formik.errors.requirements?.education ? (
              <Typography variant="body2" color="error">
                {formik.errors.requirements.education}
              </Typography>
            ) : null}
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <Box mt={2}>
            <Grid container spacing={2}>
              {languages.map((language, index) => (
                <React.Fragment key={index}>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <InputLabel
                      mt={2}
                      sx={{
                        fontWeight: "600",
                        fontSize: "14px",
                        color: "text.primary",
                        mb: 0.5,
                      }}
                    >
                      {t("employee.background.Language *")}
                    </InputLabel>
                    <FormControl fullWidth>
                      <Select
                        name={`requirements.languages[${index}].language`}
                        value={language.language}
                        onChange={(e) =>
                          handleLanguageChange(
                            index,
                            "language",
                            e.target.value
                          )
                        }
                        onBlur={formik.handleBlur}
                        renderValue={(selected) => {
                          if (selected === "") {
                            return (
                              <span style={{ color: "#aaa" }}>
                                {t("employee.background.Select Language")}
                              </span>
                            );
                          }
                          return selected;
                        }}
                        displayEmpty
                      >
                        <MenuItem value="" disabled>
                          <em> {t("employee.background.Select Language")}</em>
                        </MenuItem>
                        <MenuItem value={t("employee.background.English")}>
                          {t("employee.background.English")}
                        </MenuItem>
                        <MenuItem value={t("employee.background.Spanish")}>
                          {t("employee.background.Spanish")}
                        </MenuItem>
                        <MenuItem value={t("employee.background.French")}>
                          {t("employee.background.French")}
                        </MenuItem>
                        <MenuItem value={t("employee.background.German")}>
                          {t("employee.background.German")}
                        </MenuItem>
                        <MenuItem value={t("employee.background.Italian")}>
                          {t("employee.background.Italian")}
                        </MenuItem>
                        <MenuItem value={t("employee.background.Portuguese")}>
                          {t("employee.background.Portuguese")}
                        </MenuItem>
                        <MenuItem value="Mandarin Chinese">
                          {t("employee.background.Mandarin Chinese")}
                        </MenuItem>
                        <MenuItem value={t("employee.background.Japanese")}>
                          {t("employee.background.Japanese")}
                        </MenuItem>
                        <MenuItem value={t("employee.background.Russian")}>
                          {t("employee.background.Russian")}
                        </MenuItem>
                        <MenuItem value={t("employee.background.Arabic")}>
                          {t("employee.background.Arabic")}
                        </MenuItem>
                      </Select>
                      {formik.touched.requirements?.languages?.[index]
                        ?.language &&
                      formik.errors.requirements?.languages?.[index]
                        ?.language ? (
                        <Typography variant="body2" color="error">
                          {formik.errors.requirements.languages[index].language}
                        </Typography>
                      ) : null}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <InputLabel
                      mt={2}
                      sx={{
                        fontWeight: "600",
                        fontSize: "14px",
                        color: "text.primary",
                        mb: 0.5,
                      }}
                    >
                      {t("employee.background.Level *")}
                    </InputLabel>
                    <FormControl fullWidth>
                      <Select
                        name={`requirements.languages[${index}].level`}
                        value={language.level}
                        onChange={(e) =>
                          handleLanguageChange(index, "level", e.target.value)
                        }
                        onBlur={formik.handleBlur}
                        displayEmpty
                        renderValue={(selected) => {
                          if (selected === "") {
                            return (
                              <span style={{ color: "#aaa" }}>
                                {t("employee.background.Select Level")}
                              </span>
                            );
                          }
                          return selected;
                        }}
                      >
                        <MenuItem value="" disabled>
                          <em>{t("employee.background.Select Level")}</em>
                        </MenuItem>
                        <MenuItem value={t("employee.background.Beginner")}>
                          {t("employee.background.Beginner")}
                        </MenuItem>
                        <MenuItem value={t("employee.background.Intermediate")}>
                          {t("employee.background.Intermediate")}
                        </MenuItem>
                        <MenuItem value={t("employee.background.Advanced")}>
                          {t("employee.background.Advanced")}
                        </MenuItem>
                        <MenuItem value={t("employee.background.Native")}>
                          {t("employee.background.Native")}
                        </MenuItem>
                      </Select>
                      {formik.touched.requirements?.languages?.[index]?.level &&
                      formik.errors.requirements?.languages?.[index]?.level ? (
                        <Typography variant="body2" color="error">
                          {formik.errors.requirements.languages[index].level}
                        </Typography>
                      ) : null}
                    </FormControl>
                  </Grid>
                  {addedLanguages[index] && ( // Only show remove button for added languages
                    <Grid item xs={12}>
                      <Button
                        onClick={() => handleRemoveLanguage(index)}
                        variant="standard"
                        sx={{
                          mb: 2,
                          backgroundColor: "secondary.main",
                          color: "text.secondary",
                          "&:hover": {
                            backgroundColor: "secondary.main",
                          },
                        }}
                      >
                        {t("employee.background.Remove")}
                      </Button>
                    </Grid>
                  )}
                </React.Fragment>
              ))}
              <Grid item xs={12}>
                <Button
                  type="button"
                  onClick={handleAddLanguage}
                  variant="standard"
                  sx={{
                    backgroundColor: "#F5F5F5",
                    color: "primary.main",
                    "&:hover": {
                      backgroundColor: "#F5F5F5",
                    },
                  }}
                >
                  <AddIcon sx={{ mt: -0.5, ml: -3, color: "primary.main" }} />
                  <Typography
                    component="span"
                    variant="body1"
                    sx={{
                      fontSize: "15px",
                      fontWeight: "bold",
                      textTransform: "none",
                      color: "primary.main",
                      padding: "2px",
                      backgroundColor: "#F5F5F5",
                    }}
                  >
                    {t("employee.background.Add More")}
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Divider />
          <Grid container spacing={2} justifyContent="space-between">
            <Grid item>
              <Button
                type="button"
                onClick={backStep}
                variant="outlined"
                sx={{
                  mt: 3,
                  mb: 2,
                  backgroundColor: "white",
                  borderColor: "primary.main",
                  color: "text.secondary",
                  "&:hover": {
                    backgroundColor: "text.secondary",
                  },
                }}
              >
                <Typography
                  component="h2"
                  variant="body1"
                  sx={{
                    alignSelf: "flex-start",
                    fontSize: "15px",
                    fontWeight: "bold",
                    textTransform: "none",
                    color: "primary.main",
                    padding: "2px",
                  }}
                >
                  {t("employee.background.Back")}
                </Typography>
              </Button>
            </Grid>
            <Grid item>
              <Button
                type="submit"
                variant="standard"
                sx={{
                  mt: 3,
                  mb: 2,
                  backgroundColor: "secondary.main",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "secondary.main",
                  },
                }}
              >
                <Typography
                  component="h2"
                  variant="body1"
                  sx={{
                    fontSize: "15px",
                    fontWeight: "bold",
                    textTransform: "none",
                    color: "text.primary",
                    padding: "2px",
                  }}
                >
                  {t("recruiter.create-job-post.Next")}
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default CreateJobPostStep2;
