import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Chip,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { useTranslation } from "react-i18next";

const ProfessionSpecialtySelect = ({
  selectedSpecialty,
  specialties,
  selectedProfession,
  handleSpecialtyChange,
  professionList,
  loading,
  error,
  formik,
  module = "default", // Default module prop
}) => {
  const [selectedSpecialties, setSelectedSpecialties] = useState([]);
  const { t } = useTranslation();
  useEffect(() => {
    const specialtiesFromFormik =
      module === "employee"
        ? formik.values.preferences?.top_specialities
        : formik.values.formData?.top_specialities;

    if (Array.isArray(specialtiesFromFormik)) {
      setSelectedSpecialties(specialtiesFromFormik);
    } else if (typeof specialtiesFromFormik === "string") {
      try {
        const parsedSpecialties = JSON.parse(specialtiesFromFormik);
        setSelectedSpecialties(Array.isArray(parsedSpecialties) ? parsedSpecialties : []);
      } catch (error) {
        console.error("Error parsing top_specialities:", error);
        setSelectedSpecialties([]);
      }
    }
  }, [module, formik.values]);

  const handleChipClick = (specialty) => {
    const newSelectedSpecialties = selectedSpecialties.includes(specialty)
      ? selectedSpecialties.filter((item) => item !== specialty)
      : [...selectedSpecialties, specialty];

    setSelectedSpecialties(newSelectedSpecialties);

    const fieldName =
      module === "employee" ? "preferences.top_specialities" : "formData.top_specialities";
    formik.setFieldValue(fieldName, newSelectedSpecialties);

    selectedSpecialty(newSelectedSpecialties);
  };

  const professionalArea =
    module === "employee"
      ? formik.values.preferences.professional_area
      : formik.values.formData?.professional_area;
  return (
    <Box>
      <InputLabel sx={{ fontWeight: "600", fontSize: "16px", color: "text.primary" }}>
        {t("onboarding.preferences.professional_area")}
      </InputLabel>
      <FormControl fullWidth variant="outlined" sx={{ position: "relative", zIndex: 1 }}>
        <Select
          value={professionalArea || ""}
          onChange={(event) => {
            const selected = event.target.value;

            if (module === "employee") {
              formik.setFieldValue("preferences.professional_area", selected);
            } else if (module === "recruiter") {
              formik.setFieldValue("formData.professional_area", selected);
            }

            handleSpecialtyChange(event);
            setSelectedSpecialties([]); // Reset specialties on profession change
          }}
        >
          {Object.keys(professionList).length > 0 ? (
            Object.keys(professionList).map((profession) => (
              <MenuItem key={profession} value={profession}>
                {profession}
              </MenuItem>
            ))
          ) : (
            <MenuItem value="" disabled>
              {loading ? "Loading..." : error || "No professions available"}
            </MenuItem>
          )}
        </Select>
      </FormControl>

      {module !== "default" && (
        <Box mt={2}>
          {professionList[professionalArea]?.length > 0 && (
            <Box mt={2}>
              <Grid container spacing={1}>
                {
                professionList[professionalArea].map((specialty) => (
                  <Grid item key={specialty}>
                    <Chip
                      label={specialty}
                      onClick={() => handleChipClick(specialty)}
                      sx={{
                        backgroundColor: selectedSpecialties.includes(specialty)
                          ? "darkblue"
                          : "lightgray",
                        color: selectedSpecialties.includes(specialty) ? "white" : "black",
                        borderRadius: "16px",
                        cursor: "pointer",
                      }}
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

export default ProfessionSpecialtySelect;