import React from 'react';
import { Container, Grid, Typography, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import worldMap from '../../assets/images/image.png'; // Use the actual path to your world map image
import laptopImage from '../../assets/images/hirio-laptop.png'; // Use the actual path to your laptop image
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#bdffda', // Light green background
    padding: theme.spacing(8, 0),
  },
  header: {
    textAlign: 'center',
    marginBottom: theme.spacing(4),
  },
  title: {
    fontSize: '3rem !important',
    fontWeight: '400',
    color: '#333',
  },
  mapContainer: {
    position: 'relative',
    textAlign: 'center',
    marginBottom: theme.spacing(4),
  },
  map: {
    width: '100%',
    height: 'auto',
    position: 'relative',
  },
  marker: {
    position: 'absolute',
    top: '40%', // Adjust based on where you want the marker
    left: '50%',
    transform: 'translate(-50%, -50%)',
    color: '#00cc6a',
    fontSize: '2.5rem',
  },
  description: {
    textAlign: 'center',
    fontSize: '1rem',
    color: '#333',
    marginBottom: theme.spacing(4),
  },
  image: {
    width: '100%',
    borderRadius: '15px',
  },
}));

const DesdeEspana = () => {
  const { t } = useTranslation(); // Use translation hook
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Container>
        <Grid container spacing={4}>
          <Grid item xs={12} md={7}>
            <Box className={classes.header}>
              <Typography><span className={classes.title}>{t('desde_espana.title')}</span></Typography>
            </Box>

            <Box className={classes.mapContainer}>
              <img src={worldMap} alt={t('desde_espana.world_map')} className={classes.map} />
              {/* Marker for Murcia */}
              <LocationOnIcon className={classes.marker} />
            </Box>

            <Typography className={classes.description}>
              {t('desde_espana.description')}
            </Typography>
          </Grid>

          <Grid item xs={12} md={5}>
            <Box
              sx={{
                width: "80%",
                height: "510px",
                border: "1px dotted transparent",
                borderRadius: "100px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: "15px",
                margin: "auto",
              }}
            >
              <img
                src={laptopImage}
                alt={t('desde_espana.laptop')}
                className={classes.image}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: "30px",
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default DesdeEspana;
