import { useState, useRef } from "react";
import {
  Card,
  CardHeader,
  Avatar,
  CardContent,
  Typography,
  Divider,
  Box,
  CardMedia,
  Button,
  TextField,
  Menu,
  MenuItem,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import CommentIcon from "@mui/icons-material/Comment";
import { PostApi, UploadImageApi } from "../../apis";
import { Upload } from "@mui/icons-material";
import UploadImage from "../GlobalComponent/UploadImage";
import { useTranslation } from "react-i18next";
// Define a PostCard component

const formatHour = (timeString) => {
  // get the time spent form now to the time the post was created
  const currentTime = new Date();
  const postTime = new Date(timeString);
  const timeDifference = Math.abs(currentTime - postTime);
  const timeDifferenceInHours = timeDifference / (1000 * 3600);
  if (timeDifferenceInHours < 1) {
    return Math.floor(timeDifferenceInHours * 60) + " minutes";
  } else if (timeDifferenceInHours < 24) {
    return Math.floor(timeDifferenceInHours) + " hours";
  } else {
    return Math.floor(timeDifferenceInHours / 24) + " days";
  }
};

const PostCard = ({
  post,
  handleLike,
  likes,
  handleAddComment,
  postId,
  handleUpdate,
  setPosts,
  fetchPosts,
}) => {
  const { t } = useTranslation();
  const [showComments, setShowComments] = useState(false);
  const inputRef = useRef(null); // For focusing the input
  const [anchorEl, setAnchorEl] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [comment, setComment] = useState("");
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [commentText, setCommentText] = useState("");
  // Add this in the component where you're managing comments
  const [profilePhoto, setProfilePhoto] = useState(null);
  const [profilePhotoPreview, setProfilePhotoPreview] = useState(null);
  const fileInputRef = useRef(null);

  // Handle profile photo change
  const handleProfilePhotoChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setProfilePhoto(file);
      setProfilePhotoPreview(URL.createObjectURL(file));
    }
  };

  // Handle profile photo upload
  const handleProfilePhotoUpload = async (file) => {
    if (file) {
      try {
        const formData = new FormData();
        formData.append("file", file);

        const res = await UploadImageApi.uploadImage(formData);
        return res.data.data.name; // Return the name of the uploaded photo
      } catch (error) {
        console.error("Error uploading profile photo:", error);
        return null;
      }
    }
    return null;
  };

  const [updatedContent, setUpdatedContent] = useState(post.content);

  const handleSave = async (postId) => {
    setIsEditing(false);

    const uploadedPhotoName = await handleProfilePhotoUpload(profilePhoto);
    const image = uploadedPhotoName || post.image;
    const res = await PostApi.editPost(postId, updatedContent, image);
    // Update the local posts state to reflect the new content
    setPosts((prevPosts) =>
      prevPosts.map((post) =>
        post.id === postId
          ? { ...post, content: updatedContent, image: image }
          : post
      )
    );
  };

  const user = JSON.parse(localStorage.getItem("user"));
  const userId = user.id;

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleEdit = () => {
    setIsEditing(true); // Enter edit mode
  };

  const handleEditClick = () => {
    setIsEditing(true); // Enable editing for this post
    setUpdatedContent(post.content); // Reset content to the post's current content
    handleMenuClose(); // Close the menu
  };

  const handleContentChange = (event) => {
    setUpdatedContent(event.target.value); // Update the content state
  };

  const handlePostDelete = async (postId) => {
    try {
      await PostApi.deletePost(postId);
      // Update the posts state to remove the deleted post
      setPosts((prevPosts) => prevPosts.filter((post) => post.id !== postId));
    } catch (error) {
      console.error("Error deleting post:", error);
    }
  };

  const handleDelete = () => {
    setOpenDeleteDialog(true); // Open the confirmation dialog
    handleMenuClose();
  };

  const handleCommentClick = () => {
    setShowComments((prev) => !prev);
  };

  const handleSubmitComment = async () => {
    if (commentText.trim()) {
      await handleAddComment(post.id, commentText); // Add comment logic
      setCommentText(""); // Clear the input after comment is submittedt
      await fetchPosts();
    }
  };

  return (
    <Card
      sx={{
        width: "100%",
        margin: "auto",
        mb: 2,
        maxWidth: "840px",
        border: "1px solid #e0e0e0",
        boxShadow: "none",
        borderRadius: "16px",
      }}
    >
      <CardHeader
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
        avatar={
          <Avatar
            src={`${process.env.REACT_APP_API_BASE_URL}/public/image/${post?.poster?.profile?.profile_photo_url}`}
            aria-label="profile picture"
          >
            {post.poster && post.poster.name ? post.poster.name[0] : "R"}
          </Avatar>
        }
        title={post?.poster?.profile?.full_name || t("common.Unknown")}
        subheader={
          <Typography sx={{ color: "gray", fontSize: "12px" }}>
            {post.jobTitle}
            <Box sx={{ display: "flex" }}>
              <Typography
                variant="body2"
                sx={{
                  cursor: "pointer",
                  color: "gray",
                  fontSize: "12px",
                  mr: 1,
                }}
              >
                {formatHour(post.createdAt)} ago
              </Typography>
              {post.isFollowing && (
                <Typography
                  variant="body2"
                  sx={{
                    cursor: "pointer",
                    color: "secondary.main",
                    fontSize: "12px",
                  }}
                >
                  Following
                </Typography>
              )}
            </Box>
          </Typography>
        }
        action={
          post?.poster?.id === userId && (
            <>
              <IconButton aria-label="settings" onClick={handleMenuOpen}>
                <MoreVertIcon />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
              >
                <MenuItem onClick={handleEditClick}>Edit Post</MenuItem>
                <MenuItem onClick={handleDelete}>Delete Post</MenuItem>
              </Menu>
            </>
          )
        }
      />
      <Divider sx={{ width: "95%", color: "text.secondary", mx: "auto" }} />
      <CardContent sx={{ ml: 1 }}>
        {isEditing ? (
          <>
            <TextField
              fullWidth
              variant="outlined"
              value={updatedContent}
              onChange={handleContentChange}
              sx={{ fontSize: "13px" }}
            />
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                width: "100%",
              }}
            >
              <Grid container spacing={2}>
                <Grid item>
                  <Box
                    sx={{
                      width: "100px",
                      height: "100px",
                      border: "1px dotted black",
                      borderRadius: "16px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginBottom: "15px",
                      ml: 1,
                      mt: 3,
                      backgroundColor: "lightgray",
                      cursor: "pointer", // Add cursor pointer to indicate it's clickable
                    }}
                    onClick={() => fileInputRef.current.click()} // Opens file input on click
                  >
                    {/* Display image if exists, otherwise show upload icon */}
                    {profilePhotoPreview || post.image ? (
                      <img
                        src={
                          profilePhotoPreview ||
                          `${process.env.REACT_APP_API_BASE_URL}/public/image/${post.image}`
                        }
                        alt="Uploaded"
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                          borderRadius: "16px",
                        }}
                      />
                    ) : (
                      <Upload
                        sx={{ color: "gray", fontSize: "32px" }} // Upload icon if no image
                      />
                    )}
                  </Box>
                </Grid>
              </Grid>

              <input
                type="file"
                hidden
                ref={fileInputRef}
                onChange={handleProfilePhotoChange}
              />
            </Box>

            <Box
              sx={{ display: "flex", justifyContent: "flex-end", mt: "10px" }}
            >
              <Button
                onClick={() => handleSave(post.id)}
                variant="contained"
                color="primary"
                sx={{
                  backgroundColor: "primary.main",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "primary.dark",
                  },
                  borderRadius: "4px",
                  padding: "10px 16px",
                }}
              >
                Save
              </Button>
            </Box>
          </>
        ) : (
          <Typography variant="body2" color="black" sx={{ fontSize: "13px" }}>
            {post.content || ""}
          </Typography>
        )}
      </CardContent>
      {post.image && (
        <Box sx={{ alignItems: "center" }}>
          <CardMedia
            component="img"
            height="300"
            image={`${process.env.REACT_APP_API_BASE_URL}/public/image/${post.image}`}
            alt={post.image}
          />
        </Box>
      )}
      {post.likes.length > 0 && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            p: 1,
          }}
        >
          <Typography
            sx={{ ml: "20px", fontSize: "16px", color: "primary.main" }}
          >
            {post.likes.length} {post.likes.length === 1 ? "like" : "likes"}
          </Typography>
          <Typography sx={{ mr: "9px", fontSize: "16px" }}>
            {/* {post.comments.length}{" "} */}
            {/* {post.comments.length === 1 ? "Comment" : "Comments"} */}
            <Button
              onClick={handleCommentClick}
              sx={{
                display: "flex",
                alignItems: "center",
                textTransform: "none",
                fontSize: "14px",
                ml: 2,
                color: showComments ? "primary.main" : "gray",
                backgroundColor: showComments ? "white" : "transparent",
                borderRadius: "8px",
                padding: "8px 12px",
                "&:hover": {
                  backgroundColor: "white",
                  color: "primary.dark",
                },
              }}
            >
              {/* Show the comment count and adjust based on singular/plural */}
              <Typography sx={{ mr: "9px" }}>
                {post.comments.length}{" "}
                {post.comments.length === 1
                  ? t("common.Comment")
                  : t("common.Comments")}
              </Typography>

              {/* Optionally show hide/show text based on state */}
              <Typography variant="body2" sx={{ fontSize: "12px" }}>
                {/* {showComments ? "Hide" : "Show"} */}
                {showComments}
              </Typography>
            </Button>
          </Typography>
        </Box>
      )}
      <Divider sx={{ width: "95%", color: "text.secondary", mx: "auto" }} />

      {/* Like and Comment Buttons */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          ml: 1,
          mt: 1,
          mb: 1,
        }}
      >
        {/* Like Button */}
        <Button
          variant="text"
          startIcon={
            <ThumbUpIcon
              sx={{
                color: likes[post.id] ? "#1434A4" : "gray",
                fontSize: "18px",
                ml: 2,
              }}
            />
          }
          onClick={() => handleLike(post.id)}
          sx={{
            textTransform: "none",
            fontSize: "14px",
            color: likes[post.id] ? "#1434A4" : "gray",
          }}
        >
          {likes[post.id] ? t("common.Liked") : t("common.Like")}
        </Button>

        {/* Comment Button */}
        <Button
          variant="text"
          startIcon={
            <CommentIcon
              sx={{
                color: showComments ? "gray" : "gray",
                fontSize: "18px",
              }}
            />
          }
          onClick={handleCommentClick}
          sx={{
            textTransform: "none",
            fontSize: "14px",
            ml: 2,
            color: showComments ? "gray" : "gray",
          }}
        >
          {t("common.Comment")}
        </Button>
      </Box>
      {showComments && (
        <Box sx={{ ml: 1, mt: -5, p: 2, backgroundColor: "white" }}>
          <Button
            sx={{
              textTransform: "none",
              backgroundColor: "transparent",
              "&:hover": {
                backgroundColor: "transparent",
              },
            }}
          ></Button>

          {/* Comment Input */}
          <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
            <TextField
              variant="outlined"
              size="small"
              placeholder="Write a comment..."
              value={commentText}
              onChange={(e) => setCommentText(e.target.value)}
              sx={{
                flexGrow: 1,
                borderRadius: "20px",
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#cccccc",
                  },
                  "&:hover fieldset": {
                    borderColor: "#a5a5a5",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "primary.main",
                  },
                },
              }}
            />
            <Button
              onClick={handleSubmitComment}
              sx={{
                ml: 1,
                backgroundColor: "primary.main",
                color: "white",
                borderRadius: "20px",
                "&:hover": {
                  backgroundColor: "primary.dark",
                },
              }}
              disabled={!commentText.trim()}
              variant="contained"
            >
              {t("common.Comment")}
            </Button>
          </Box>

          {/* Render existing comments */}
          {post.comments.length > 0 ? (
            post.comments.map((data, commentIndex) => (
              <Box
                key={commentIndex}
                sx={{ display: "flex", alignItems: "flex-start", mb: 2, mt: 2 }}
              >
                <Avatar
                  src={`${process.env.REACT_APP_API_BASE_URL}/public/image/${data?.commenter?.profile?.profile_photo_url}`}
                  aria-label="profile picture"
                  sx={{ width: 32, height: 32 }} // Adjust avatar size
                />
                <Box sx={{ ml: 1 }}>
                  <Typography variant="body2">
                    <strong>{data?.commenter?.profile?.full_name}</strong>
                  </Typography>
                  <Typography variant="body2" sx={{ color: "gray" }}>
                    {data?.comment}
                  </Typography>
                </Box>
              </Box>
            ))
          ) : (
            <Typography variant="body2" sx={{ ml: 1, mt: 2, color: "gray" }}>
              No comments yet.
            </Typography>
          )}
        </Box>
      )}

      <Dialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
      >
        <DialogTitle sx={{ fontWeight: "bold", fontSize: "20px" }}>
          Confirm Delete
        </DialogTitle>

        <DialogContent>
          <Typography sx={{ fontSize: "16px", marginBottom: "16px" }}>
            Are you sure you want to delete this post? This action cannot be
            undone.
          </Typography>
        </DialogContent>

        <DialogActions
          sx={{
            padding: "0 16px 16px",
            display: "flex",
            justifyContent: "space-between",
            borderRadius: "15px",
          }}
        >
          <Button
            onClick={() => setOpenDeleteDialog(false)}
            sx={{
              backgroundColor: "#f5f5f5",
              color: "black",
              "&:hover": {
                backgroundColor: "#e0e0e0",
              },
              borderRadius: "8px",
              padding: "8px 16px",
            }}
          >
            Cancel
          </Button>

          <Button
            onClick={() => {
              handlePostDelete(post.id);
              setOpenDeleteDialog(false);
            }}
            sx={{
              backgroundColor: "#d32f2f",
              color: "white",
              "&:hover": {
                backgroundColor: "#b71c1c",
              },
              borderRadius: "8px",
              padding: "8px 16px",
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};
export default PostCard;
