import React, { useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Link,
  TextField,
  Button,
  IconButton,
  Divider,
  useMediaQuery,
} from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import Logo from "../../assets/images/logo.png";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";

function Footer() {
  const { t } = useTranslation();
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://bilaljee684.systeme.io/6f07ce62",
        {
          email,
        }
      );

      if (response.status === 200) {
        setSuccess(true);
        setError(null);
      }
    } catch (error) {
      console.error("Error adding subscriber:", error);
      setError("There was an issue with your subscription. Please try again.");
      setSuccess(false);
    }
  };
  return (
    <Box
      sx={{
        bgcolor: "#0a074f",
        color: "white",
        py: 4,
        px: isSmallScreen ? 4 : 10,
      }}
    >
      <Grid container spacing={2} justifyContent="space-between">
        <Grid item xs={12} sm={4}>
          <Box sx={{ marginRight: "2%", mb: 0.5 }}>
            <img src={Logo} alt="logo" style={{ width: "100px" }} />
          </Box>
          <Typography variant="body2" sx={{ color: "#8E96AB" }}>
            {t("employee.footer.subheading")}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography variant="h6" sx={{ fontWeight: "bold", mb: 2 }}>
            {t("employee.footer.Useful Links")}
          </Typography>
          <Link
            href="#"
            color="inherit"
            sx={{
              display: "block",
              mb: 1,
              color: "#8E96AB",
              textDecoration: "none",
            }}
          >
            {t("employee.home.navlink_Home")}
          </Link>
          <Box
            sx={{
              display: "block",
              mb: 1,
              color: "#8E96AB",
              textDecoration: "none",
            }}
          >
            <Link
              href="/login"
              color="inherit"
              sx={{ textDecoration: "none", color: "#8E96AB" }}
            >
              {t("landing.Login")}
            </Link>
            {" / "}
            <Link
              href="/register"
              color="inherit"
              sx={{ textDecoration: "none", color: "#8E96AB" }}
            >
              {t("landing.Register")}
            </Link>
          </Box>
          <Link
            href="#"
            color="inherit"
            sx={{
              display: "block",
              mb: 1,
              color: "#8E96AB",
              textDecoration: "none",
            }}
          >
            {t("employee.footer.Terms of conditions")}
          </Link>
          <Link
            href="#"
            color="inherit"
            sx={{
              display: "block",
              mb: 1,
              color: "#8E96AB",
              textDecoration: "none",
            }}
          >
            {t("employee.footer.Privacy Policy")}
          </Link>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography variant="h6" sx={{ fontWeight: "bold", mb: 2 }}>
            {t("employee.footer.Get job notifications")}
          </Typography>
          <Typography variant="body2" sx={{ color: "#8E96AB" }}>
            <Typography variant="body2" sx={{ color: "#8E96AB" }}>
              {t("employee.footer.latest-job")}
            </Typography>
          </Typography>
          <Grid xs={12}>
            <Box
              sx={{
                mt: 2,
                display: "flex",
                flexDirection: isSmallScreen ? "column" : "row",
                alignItems: isSmallScreen ? "stretch" : "center",
                gap: 1, // This adds space between the elements
              }}
            >
              <TextField
                variant="outlined"
                placeholder={t("employee.footer.placeholder")}
                sx={{
                  flex: 1,
                  mr: isSmallScreen ? 0 : 1,
                  mb: isSmallScreen ? 1 : 0,
                  bgcolor: "white",
                  borderRadius: "10px",
                  justifyContent: "center",
                }}
              />
              <Button
                variant="contained"
                sx={{
                  bgcolor: "#8EFFC2",
                  color: "#031141",
                  textTransform: "none",
                  height: "55px",
                  borderRadius: "10px",
                  fontWeight: "bold",
                  "&:hover": {
                    backgroundColor: "#8EFFC2",
                  },
                }}
              >
                {t("employee.footer.Subscribe")}
              </Button>

              {/* <form onSubmit={handleSubmit} className="mt-4">
                <div>
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Enter your email"
                    required
                    className="p-2 w-full rounded mb-4"
                  />
                </div>
                <button
                  type="submit"
                  className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
                >
                  Subscribe
                </button>
                {success && (
                  <p className="text-green-500 mt-4">
                    Subscribed successfully!
                  </p>
                )}
                {error && <p className="text-red-500 mt-4">{error}</p>}
              </form> */}
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Divider sx={{ mt: 5, borderColor: "#062F53" }} />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          mt: 2,
          justifyContent: "space-between",
        }}
      >
        <Typography
          variant="caption"
          sx={{ display: "block", mt: 2, color: "#8E96AB" }}
        >
          {t("employee.footer.allrigths")}
        </Typography>
        <Box>
          <IconButton
            color="inherit"
            onClick={() =>
              window.open(
                "https://www.facebook.com/people/Hirio/61568307422187/",
                "_blank"
              )
            }
          >
            <FacebookIcon />
          </IconButton>
          <IconButton
            color="inherit"
            onClick={() =>
              window.open("https://www.instagram.com/hirio.app/", "_blank")
            }
          >
            <InstagramIcon />
          </IconButton>
          <IconButton
            color="inherit"
            onClick={() =>
              window.open(
                "https://www.linkedin.com/company/hirio-app/",
                "_blank"
              )
            }
          >
            <LinkedInIcon />
          </IconButton>
          <IconButton
            color="inherit"
            onClick={() => window.open("https://x.com/HirioApp", "_blank")}
          >
            <TwitterIcon />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
}

export default Footer;
