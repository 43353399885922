import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  Button,
  Divider,
  Container,
  Grid,
} from "@mui/material";
import { Formik, Form } from "formik";
import { OnBoardingApi } from "../../../../apis";
import PreviewAndSubmitForm from "./PreviewAndSubmitForm";
import { useTranslation } from "react-i18next";

const PreviewAndSubmit = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [formikValues, setFormikValues] = useState(
    location.state?.formikValues || {}
  );

  useEffect(() => {
    if (!formikValues || Object.keys(formikValues).length === 0) {
      console.error("No form data available");
    }
  }, [formikValues]);

  const submitForm = async (values) => {
    try {
      const res = await OnBoardingApi.OnBoarding(values);
    } catch (err) {
      console.error("API Error:", err);
    }
  };

  return (
    <>
      <Box sx={{ backgroundColor: "white", minHeight: "100vh", padding: 0 }}>
        <Container>
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              <Box sx={{ mx: { lg: "150px" } }}>
                <Formik
                  initialValues={formikValues}
                  onSubmit={async (values) => {
                    values.is_onboarding = true;
                    localStorage.setItem("onboarding", true);
                    await submitForm(values);
                    window.location.href = "/v1/recruiter/home";
                  }}
                >
                  {(formik) => (
                    <Form>
                      <PreviewAndSubmitForm formik={formik} />
                      <Divider sx={{ my: 2 }} />
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          mt: 3,
                        }}
                      >
                        <Button
                          type="button"
                          onClick={() =>
                            navigate("/v1/recruiter/company-profile")
                          }
                          variant="outlined"
                          sx={{
                            mt: 3,
                            mb: 2,
                            backgroundColor: "white",
                            borderColor: "primary.main",
                            color: "text.secondary",
                            "&:hover": {
                              backgroundColor: "text.secondary",
                            },
                          }}
                        >
                          <Typography
                            component="h2"
                            variant="body1"
                            sx={{
                              alignSelf: "flex-start",
                              fontSize: "15px",
                              fontWeight: "bold",
                              textTransform: "none",
                              color: "primary.main",
                              padding: "2px",
                            }}
                          >
                            {t("employee.background.Back")}
                          </Typography>
                        </Button>
                        <Button
                          type="submit"
                          variant="standard"
                          sx={{
                            mt: 3,
                            mb: 2,
                            backgroundColor: "secondary.main",
                            color: "white",
                            "&:hover": {
                              backgroundColor: "secondary.main",
                            },
                          }}
                        >
                          <Typography
                            component="h2"
                            variant="body1"
                            sx={{
                              alignSelf: "flex-start",
                              fontSize: "15px",
                              fontWeight: "bold",
                              textTransform: "none",
                              color: "primary.main",
                              padding: "2px",
                            }}
                          >
                            {t("employee.preview.submit")}
                          </Typography>
                        </Button>
                      </Box>
                    </Form>
                  )}
                </Formik>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default PreviewAndSubmit;
