import React, { useState, useContext, useRef, useEffect } from "react";
import {
  Paper,
  InputBase,
  Button,
  Box,
  Divider,
  Typography,
  Grid,
} from "@mui/material";
import PhotoCameraOutlinedIcon from "@mui/icons-material/PhotoCameraOutlined";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import UploadImage from "../GlobalComponent/UploadImage";
import { UploadImageApi, PostApi } from "../../apis";
import PostContext from "../../context/PostContext";
import { useTranslation } from "react-i18next";

export default function CreatePost() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true); // State for loading
  const { addPost } = useContext(PostContext);

  const [profilePhoto, setProfilePhoto] = useState(null);
  const [profilePhotoPreview, setProfilePhotoPreview] = useState(null);
  const [postContent, setPostContent] = useState("");
  const fileInputRef = useRef(null);

  const handleProfilePhotoChange = (event) => {
    const file = event.target.files[0];
    setProfilePhoto(file);
    setProfilePhotoPreview(URL.createObjectURL(file));
  };

  const handleProfilePhotoUpload = async () => {
    if (profilePhoto) {
      try {
        const formData = new FormData();
        formData.append("file", profilePhoto);

        const res = await UploadImageApi.uploadImage(formData);

        return res.data.data.name; // Return only the name of the uploaded photo
      } catch (error) {
        console.error("Error uploading profile photo:", error);
      }
    }
    return null;
  };

  const handleSubmitPost = async () => {
    const uploadedPhotoName = await handleProfilePhotoUpload();

    const postData = {
      content: postContent,
      image: uploadedPhotoName,
    };

    try {
      const response = await PostApi.createPost(postData);
      // Handle the response data here
      if (response) {
        // Clear form fields after successful post submission
        setPostContent("");
        setProfilePhoto(null);
        setProfilePhotoPreview(null);
        const res = await PostApi.getPost();
        addPost(res.data.data);
        // You can also show a success message or redirect the user
      }
    } catch (error) {
      console.error("Error creating post:", error);
    }
  };

  return (
    <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
      <Paper
        component="form"
        sx={{
          p: "12px 22px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          boxShadow: "none",
          border: "1px solid #e0e0e0",
          borderRadius: "16px",

          width: "100%",
          maxWidth: "800px",
          mt: "11px",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
          <InputBase
            sx={{ flex: 1, fontSize: "1rem" }}
            placeholder={t("common.Create Post")}
            inputProps={{ "aria-label": "Create Post" }}
            value={postContent}
            onChange={(e) => setPostContent(e.target.value)}
            multiline
            minRows={1} // Starts with 1 row
            maxRows={10} // Limits the number of rows to 10 before scrolling
            fullWidth
          />
        </Box>
        {profilePhotoPreview && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              width: "100%",
            }}
          >
            <Grid container spacing={2}>
              <Grid item>
                <Box
                  sx={{
                    width: "100px",
                    height: "100px",
                    border: "1px dotted black",
                    borderRadius: "16px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginBottom: "15px",
                    ml: 1,
                    mt: 3,
                    backgroundColor: "lightgray",
                  }}
                >
                  <UploadImage
                    imageUrl={profilePhotoPreview}
                    altText="Profile Photo"
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        )}
        <input
          type="file"
          hidden
          ref={fileInputRef}
          onChange={handleProfilePhotoChange}
        />
        <Divider
          sx={{ width: "100%", color: "text.secondary", mt: 3, mb: 1 }}
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            mr: 1,
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Button onClick={() => fileInputRef.current.click()}>
              <PhotoCameraOutlinedIcon sx={{ color: "green", mr: 0.5 }} />
              <Typography
                variant="body1"
                sx={{ color: "black", textTransform: "none", fontSize: "14px" }}
              >
                {t("common.Photo")}
              </Typography>
            </Button>
            {/* <Button>
              <ModeEditOutlineOutlinedIcon sx={{ color: 'red', mr: 0.5 }} />
              <Typography variant="body1" sx={{ color: 'black', textTransform: 'none', fontSize: '14px' }}>Write Articles</Typography>
            </Button> */}
          </Box>

          <Button
            onClick={handleSubmitPost}
            disabled={!postContent.trim() && !profilePhoto} // Enable button if either postContent or profilePhoto is present
            sx={{
              backgroundColor: "secondary.main",
              borderColor: "primary.main",
              fontWeight: "600",
              color: "primary.main",
              "&:hover": { backgroundColor: "secondary.main" },
              opacity: !postContent.trim() && !profilePhoto ? 0.5 : 1,
            }}
          >
            {t("common.Post")}
          </Button>
        </Box>
      </Paper>
    </Box>
  );
}
