import React from 'react';
import { Container, Grid, Typography, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PeopleIcon from '@mui/icons-material/People';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(8, 0),
  },
  header: {
    textAlign: 'center',
    marginBottom: theme.spacing(4),
  },
  title: {
    fontWeight: 'bold',
    textAlign: "left",
    justifyContent: "left",
    fontSize: "45px"
  },
  subtitle: {
    fontWeight: 'normal',
    fontSize: "45px"
  },
  icon: {
    fontSize: '5rem',
    color: '#8EFFC2',
  },
  percentage: {
    fontSize: '2rem',
    fontWeight: '500',
    color: 'black',
    position: 'relative',
  },
  percentage70: {
    fontSize: '2rem',
    fontWeight: '500',
    color: 'black',
    position: 'absolute',
    marginBottom: "40px",
    top: "25px",
    right: "35px"
  },
  progress: {
    position: 'relative',
    margin: '4px',
    float: 'left',
    textAlign: 'center',
    marginBottom: "20px",
    height: "50px"
  },
  barOverflow: {
    position: 'relative',
    overflow: 'hidden',
    width: '150px',
    height: '65px',
    marginBottom: '-5px',
  },
  barcircle: {
    position: 'absolute',
    top: '0',
    left: '0',
    width: '150px',
    height: '150px',
    borderRadius: '50%',
    boxSizing: 'border-box',
    border: '10px solid #8EFFC2',
    borderBottomColor: '#eee',
    borderRightColor: '#eee',
    padding: "20px"
  },
  titles: {
    fontSize: '1rem',
    fontWeight: 'bold',
    color: 'black',
  },
  section: {
    textAlign: 'center',
  },
  progressContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: theme.spacing(2),
  },
  bar: {
    width: '15%',
    height: '10px',
    backgroundColor: '#8EFFC2',
    borderTopLeftRadius: "20px",
    borderBottomLeftRadius: "20px",
    margin: '0',
  },
  greyBar: {
    width: '15%',
    height: '10px',
    backgroundColor: '#ccc',
    borderTopRightRadius: "20px",
    borderBottomRightRadius: "20px",
    marginRight: "12px"
  },
  greyCircle: {
    display: 'inline-block',
    width: '10px',
    height: '10px',
    borderRadius: '50%',
    backgroundColor: '#ccc',
  },
}));

const WhyHirio = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box className={classes.root}>
      <Container>
        <Box className={classes.header}>
          <Typography><span className={classes.subtitle}>{t('whyHirio.subtitle')}</span></Typography>
          <Typography><span className={classes.title}>{t('whyHirio.title')}</span></Typography>
        </Box>
        <Grid container spacing={4}>
          {/* Section 1 */}
          <Grid item xs={12} md={4} className={classes.section}>
            <Box className={classes.progressContainer} sx={{ display: "flex", mb: 1 }}>
              <Box className={classes.progress}>
                <Box className={classes.barOverflow}>
                  <Box className={classes.barcircle}></Box>
                  <Typography><span className={classes.percentage70}>70 %</span></Typography>
                </Box>
              </Box>
            </Box>
            <br />
            <Typography><span className={classes.titles}>{t('whyHirio.acceleration')}</span></Typography>
            <Typography>{t('whyHirio.findBest')}</Typography>
          </Grid>

          {/* Section 2 */}
          <Grid item xs={12} md={4} className={classes.section}>
            <Box className={classes.progressContainer} sx={{ display: "flex", mb: 1 }}>
              <Box className={classes.bar} />
              <Box className={classes.greyBar} />
              <Typography><span className={classes.percentage}>50 %</span></Typography>
            </Box>
            <br />
            <Typography><span className={classes.titles}>{t('whyHirio.saveMore')}</span></Typography>
            <Typography>{t('whyHirio.cheaper')}</Typography>
          </Grid>

          {/* Section 3 */}
          <Grid item xs={12} md={4} className={classes.section}>
            <Box>
              <PeopleIcon style={{ fontSize: '4rem', color: '#8EFFC2', marginBottom: "10px" }} />
              <Typography><span className={classes.titles}>{t('whyHirio.talentIncrease')}</span></Typography>
              <Typography>{t('whyHirio.moreProfessionals')}</Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default WhyHirio;
