import axios from "axios";
import { toast } from "react-toastify";

const toastOptions = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  newestOnTop: false,
  rtl: false,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  draggable: true,
  pauseOnHover: true,
  progress: undefined,
  theme: "light",
};

const instance = axios.create({
  baseURL: `https://api.hirio.app`,
  // baseURL: `http://localhost:4500/`,
});

instance.interceptors.request.use(
  (config) => {
    // Set the Content-Type header based on the type of data being sent
    if (config.data instanceof FormData) {
      config.headers["Content-Type"] = "multipart/form-data";
    } else {
      config.headers["Content-Type"] = "application/json";
    }

    // Retrieve the auth token from localStorage
    const authToken = localStorage.getItem("token");

    if (authToken) {
      config.headers["Authorization"] = `Bearer ${authToken}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    if (response.data.message) {
      toast.success(response.data.message, toastOptions);
    }
    return response;
  },
  (error) => {
    if (error.response?.status === 401) {
      // Handle unauthorized error
      if (localStorage.getItem("token")) {
        localStorage.clear();
        window.location.href = "/";
      }
    }
    if (error.response?.data?.message) {
      toast.error(error.response.data.message, toastOptions);
    } else if (error.message) {
      toast.error(error.message, toastOptions);
    } else if (error.response?.data?.error) {
      toast.error(error.response.data.error, toastOptions);
    }
    return Promise.reject(error);
  }
);

export default instance;
