import React, { useState, useEffect } from "react";
import { Box, Button } from "@mui/material";
import Preferences from "../../Employee/Onboarding/preferences/preferences";
import { useFormik } from "formik";
import * as yup from "yup";
import dayjs from "dayjs";

const Index = () => {
  const validationSchema = yup.object({
    preferences: yup.object({
      total_years_experience: yup
        .string()
        .required("Total Years Experience is required"),
      job_search_status: yup.string().required("Job search status is required"),
    }),
  });

  const formik = useFormik({
    initialValues: {
      preferences: {
        professional_area: "",
        total_years_experience: "",
        top_specialities: {
      
        },
        experienceYears: {
        
        },

        employment_type: {
          fullTime: false,
          partTime: false,
        },
        job_search_status: "",
        remote_work: "",
        relocation: "",
      },
      about_you: {
        resume_url: "",
        profile_photo_url: "",
        full_name: "",
        phone_number: "",
        whatsapp_number: "",
        current_city: "",
        salary_range: {
          from: "",
          to: "",
        },
        linkedin_url: "",
        personal_blog_url: "",
      },
      background: {
        work_experience: [
          {
            company_name: "",
            job_title: "",
            is_current: false,
            start: dayjs(), // Initial state for start date and time
            end: dayjs(), // Initial state for end date and time (current time)
            description: "",
          },
        ],

        education: [
          {
            university_or_collage: "",
            graduation_year: "",
            field_of_study: "",
            degree: "",
          },
        ],

        language: [
          {
            language: "",
            language_level: "",
          },
        ],
        objective: "",
        achievements: "",
        personal_characteristics: "",
      },
      is_onboarding: false,
      is_active: false,
    },
    validationSchema,
    onSubmit: (values) => {},
  });

  return (
    <Box>
      <Preferences formik={formik} />
    </Box>
  );
};

export default Index;

