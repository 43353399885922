import React, { useState, useEffect } from "react";
import {
  Typography,
  TextField,
  Grid,
  Radio,
  RadioGroup,
  FormControlLabel,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Divider,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  IconButton,
} from "@mui/material";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
import * as yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import { JobPostApi, OnBoardingApi } from "../../apis";
import UploadImage from "../GlobalComponent/UploadImage";
import AddIcon from "@mui/icons-material/Add";
import { UploadImageApi } from "../../apis";
import Loader from "../GlobalComponent/Loader";
// import data from "../JobPost/data.json";
import ExperienceForm from "../GlobalComponent/EditExperienceForm";
import DynamicAutocomplete from "../GlobalComponent/CityFinder";
import ProfessionApi from "../../apis/routes/professionRoutes";
import ProfessionSpecialtySelect from "../CustomSelector/ProfessionSpecialtySelect";
import { useTranslation } from "react-i18next";

const validationSchema = yup.object({});

const JobDetail = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [jobData, setJobData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [openPersonalInfo, setOpenPersonalInfo] = useState(false);
  const [languages, setLanguages] = useState([{ language: "", level: "" }]);
  const [addedLanguages, setAddedLanguages] = useState([false]);
  const [profilePhoto, setProfilePhoto] = useState(null);
  const [profilePhotoPreview, setProfilePhotoPreview] = useState(null);
  const [selectedSpecialty, setSelectedSpecialty] = useState("");

  const [showAutocomplete, setShowAutocomplete] = useState(false);
  const [professions, setProfessions] = useState({});
  const [selectedProfession, setSelectedProfession] = useState("");
  const [specialties, setSpecialties] = useState([]);
  const [error, setError] = useState(null);

  const fetchProfessions = async () => {
    try {
      setLoading(true);
      const response = await ProfessionApi.getAllProfessions();
      const professionList = response.data?.subcategories || {};
      setProfessions(professionList);
    } catch (err) {
      setError("Failed to load professions.");
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchProfessions();
  }, []);

  const handleSpecialtyChange = (event) => {
    const selected = event.target.value;
    setSelectedProfession(selected);
    if (selected && professions[selected]) {
      setSpecialties(professions[selected]);
    }
    formik.setFieldValue("formData.professional_area", selected);
    formik.setFieldValue("formData.top_specialities", []);
    formik.setFieldValue("jobDetails.professional_area", selected);
    formik.setFieldValue("jobDetails.top_specialities", []);
  };
  const handleSpecialtySelect = (specialty) => {
    setSelectedSpecialty(specialty);
    if (specialty && specialties[specialty]) {
      setSelectedSpecialty(specialties[specialty]);
    }
    formik.setFieldValue("jobDetails.top_specialities", specialty);
  };

  const handleLocationChange = (event) => {
    const { value } = event.target;
    formik.setFieldValue("location", value);

    // Show DynamicAutocomplete if location is "onsite" or"hybrid"
    setShowAutocomplete(value === "onsite" || value === "hybrid");
  };

  const professionalArea = jobData?.jobDetails?.professional_area;
  const top_specialities = jobData?.jobDetails?.top_specialities;

  // Ensure professionalArea is an array before accessing the first element
  const singleCategory = Array.isArray(professionalArea)
    ? professionalArea[0]
    : undefined;

  const [selectedCategories, setSelectedCategories] = useState({});
  const [selectedCategory, setSelectedCategory] = useState("");

  const handleSpecialityCheckboxChange = (event) => {
    const { name, checked } = event.target;

    formik.setFieldValue(`specialties.${name}`, checked);
    setSelectedCategories((prev) => {
      const newSelected = { ...prev };
      if (checked) {
        newSelected[name] = [];
      } else {
        delete newSelected[name];
      }
      return newSelected;
    });
  };

  const parseJSON = (jsonString) => {
    try {
      // Check if jsonString is already an object
      if (typeof jsonString === "object") {
        return jsonString; // Return as-is if already an object
      }
      return jsonString ? JSON.parse(jsonString) : {}; // Parse JSON if jsonString is not null or undefined
    } catch (error) {
      console.error("Error parsing JSON:", error);
      return {}; // Return empty object if parsing fails
    }
  };
  let user;
  try {
    const userString = localStorage.getItem("user");
    user = JSON.parse(userString);
  } catch (error) {
    console.error("Error parsing user from localStorage:", error);
  }

  const isEmployee = user && user?.role?.name === "employee";
  const isRecruiter = user && user?.role?.name === "recruiter";
  const handleInputSalaryChange = (event) => {
    const { name, value } = event.target;
    let formattedValue = value.replace(/,/g, ""); // Remove existing commas
    // Add commas to the number
    formik.setFieldValue(name, formattedValue);
  };

  const handleInputChange = (event) => {
    const { name, checked } = event.target;
    formik.setFieldValue(`specialties.${name}`, checked);
  };

  const handleExperienceChange = (event) => {
    const { name, value } = event.target;
    formik.setFieldValue(`jobDetails.experienceYears.${name}`, value);
  };

  const handleTotalExperienceChange = (event) => {
    const { name, value } = event.target;
    formik.setFieldValue(name, value);
  };

  const handleProfilePhotoChange = (event) => {
    const file = event.target.files[0];
    setProfilePhoto(file);
    setProfilePhotoPreview(URL.createObjectURL(file));
  };

  const handleProfilePhotoUpload = async () => {
    if (profilePhoto) {
      try {
        const formData = new FormData();
        formData.append("file", profilePhoto);

        const res = await UploadImageApi.uploadImage(formData);

        formik.setFieldValue("photo", res.data.data.name);
        return res.data.data.name; // Return only the name of the uploaded photo
      } catch (error) {
        console.error("Error uploading profile photo:", error);
      }
    }
    return null;
  };
  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    const [field, subfield] = name.split(".");
    const otherSubfield = subfield === "partTime" ? "fullTime" : "partTime";

    formik.setFieldValue(`${field}.${subfield}`, checked);
    if (checked) {
      formik.setFieldValue(`${field}.${otherSubfield}`, false);
    }
  };

  const formik = useFormik({
    initialValues: {
      photo: jobData?.jobDetails?.photo || "",
      positionName: jobData?.jobDetails?.positionName || "",
      location: jobData?.jobDetails?.location || "",
      city: jobData?.jobDetails?.city || "",
      employmentType: {
        partTime: jobData?.jobDetails?.employmentType?.partTime || false,
        fullTime: jobData?.jobDetails?.employmentType?.fullTime || false,
      },
      remoteCandidates: jobData?.jobDetails?.remoteCandidates || "",
      willingToRelocate: jobData?.jobDetails?.willingToRelocate || "",
      salaryRange: {
        from: jobData?.jobDetails?.salaryRange?.from || "",
        to: jobData?.jobDetails?.salaryRange?.to || "",
      },
      professional_area: jobData?.jobDetails?.professional_area || "",
      top_specialities: jobData?.jobDetails?.top_specialities || "",
      totalYearsExperience: jobData?.jobDetails?.totalYearsExperience || "",
      requirements: {
        education: jobData?.requirements?.education || "",
        languages:
          jobData?.requirements?.languages?.length > 0
            ? jobData.requirements.languages.map((lang) => ({
                language: lang.language || "",
                level: lang.level || "",
              }))
            : [{ language: "", level: "" }],
      },
      formData: {
        professional_area: "",
        top_specialities: [],
      },
      description: {
        description: jobData?.description?.description || "",
        additionalInfo: jobData?.description?.additionalInfo || "",
      },
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        let res;
        const profilePhotoName = await handleProfilePhotoUpload(); // Upload the profile photo and get the URL data
        if (profilePhotoName) {
          values.photo = profilePhotoName; // Set profile_photo_url to the photo name
        }
        if (id) {
          // Update existing job post
          res = await JobPostApi.updateJobPost(id, {
            jobDetails: {
              photo: values?.photo || jobData.jobDetails.photo,
              positionName: values?.positionName || "",
              location: values?.location || "",
              city: values?.city || "",
              employmentType: {
                partTime: values?.employmentType?.partTime || false,
                fullTime: values?.employmentType?.fullTime || false,
              },
              remoteCandidates: values?.remoteCandidates || "",
              willingToRelocate: values?.willingToRelocate || "",
              salaryRange: {
                from: values?.salaryRange?.from || "",
                to: values?.salaryRange?.to || "",
              },
              professional_area: values?.formData?.professional_area || "",
              top_specialities: values?.formData?.top_specialities || [],
              totalYearsExperience: values?.totalYearsExperience || "",
            },
            requirements: values.requirements,
            description: values.description,
          });

          if (res.data.jobPost) {
            // Update the jobData state with the response data
            setJobData((prevState) => ({
              ...prevState,
              jobDetails: res.data.jobPost.jobDetails,
              requirements: res.data.jobPost.requirements,
              description: res.data.jobPost.description,
            }));
          }
        } else {
          // Create new job post
          res = await JobPostApi.createJobPost({
            jobDetails: values.positionName,
            requirements: values.location,
            description: values.employmentType,
          });
        }

        window.location.reload();
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    },
  });

  useEffect(() => {
    setLoading(true);
    JobPostApi.getJobPostById(id)
      .then((res) => {
        const jobPost = res?.data?.data;

        if (jobPost) {
          const parsedJobPost = {
            ...jobPost,
            jobDetails: parseJSON(jobPost.jobDetails),
            requirements: parseJSON(jobPost.requirements),
            description: parseJSON(jobPost.description),
            company_profile_photo_url:
              jobPost.Profile.company_profile_photo_url,
          };

          formik.setFieldValue(
            "jobDetails.professional_area",
            parseJSON(jobPost.jobDetails)?.professional_area
          );
          formik.setFieldValue(
            "jobDetails.top_specialities",
            parseJSON(jobPost.jobDetails)?.top_specialities
          );

          setJobData(parsedJobPost);
        } else {
          console.error(`Job post with ID ${id} not found.`);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching job post:", error);
        setLoading(false);
      });
  }, [id]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    formik.setFieldValue(name, value);
  };

  const handleClose = () => {
    setOpenPersonalInfo(false);
  };

  if (loading) {
    return (
      <div>
        <Loader loading={loading} />
      </div>
    );
  }

  if (!jobData) {
    return <Typography>No job post found.</Typography>;
  }

  const handleChipToggle = (name, checked) => {
    formik.setFieldValue(`jobDetails.specialties.${name}`, checked); // Correctly update Formik field value

    setSelectedCategories((prev) => {
      const newSelected = { ...prev };
      if (checked) {
        newSelected[name] = []; // If checked, add category with an empty subcategory list
      } else {
        delete newSelected[name]; // If unchecked, remove category
      }
      return newSelected;
    });
  };

  // Function to handle subcategory changes
  const handleSubcategoryChange = (category, subcategory) => (event) => {
    const value = event.target.value;

    // Update Formik's state for subcategories dynamically
    formik.setFieldValue(
      `jobDetails.experienceYears.${category}.${subcategory}`,
      value
    );

    // Optionally update selected subcategories in state for UI updates
    setSelectedCategories((prev) => ({
      ...prev,
      [category]: {
        ...prev[category],
        [subcategory]: value, // Update the specific subcategory value
      },
    }));
  };
  const handleLanguageChange = (index, key, value) => {
    const newLanguages = [...languages];
    newLanguages[index][key] = value;
    setLanguages(newLanguages);
    formik.setFieldValue(`requirements.languages[${index}].${key}`, value);
  };

  const handleAddLanguage = () => {
    const newLanguages = [
      ...formik.values.requirements.languages,
      { language: "", level: "" },
    ];
    formik.setFieldValue("requirements.languages", newLanguages);

    const newAddedLanguages = [...addedLanguages, true];
    setAddedLanguages(newAddedLanguages);
  };

  const handleRemoveLanguage = (index) => {
    const newLanguages = [...formik.values.requirements.languages];
    newLanguages.splice(index, 1);
    formik.setFieldValue("requirements.languages", newLanguages);

    const newAddedLanguages = [...addedLanguages];
    newAddedLanguages.splice(index, 1);
    setAddedLanguages(newAddedLanguages);
  };

  const getCityColor = (city) => {
    return city === "Enter City" ? "#aaa" : "black";
  };

  // Safely extract true specialties
  const trueTopCity = formik.values.city;
  const formattedCity = trueTopCity
    ? trueTopCity // Add space between words
    : "Enter City"; // Default value when no true speciality

  const cityColor = getCityColor(formattedCity);

  const getSpecialityColor = (speciality) => {
    return speciality === "Select Speciality" ? "#aaa" : "black";
  };

  const experienceList = [
    t("recruiter.create-job-post.experience.Less than 1 year"),
    t("recruiter.create-job-post.experience.1-3 years"),
    t("recruiter.create-job-post.experience.3-5 years"),
    t("recruiter.create-job-post.experience.5+ years"),
  ];
  return (
    <>
      <Grid item xs={12} sx={{ marginTop: "10px" }}>
        {isRecruiter && (
          <Button
            variant="text"
            onClick={() => navigate("/v1/recruiter/my-job-posts")}
            sx={{
              backgroundColor: "#F5F5F5",
              color: "#F5F5F5",
              "&:hover": {
                backgroundColor: "#F5F5F5",
              },
            }}
          >
            <ArrowBackIcon
              sx={{
                mt: -0.5,
                mv: 1,
                ml: -2,
                backgroundColor: "#F5F5F5",
                color: "primary.main",
                "&:hover": {
                  backgroundColor: "#F5F5F5",
                },
              }}
            />

            <Typography
              component="h2"
              variant="body1"
              sx={{
                fontSize: "15px",
                fontWeight: "bold",
                textTransform: "none",
                color: "primary.main",
                padding: "2px",
              }}
            >
              {t("employee.background.Back")}
            </Typography>
          </Button>
        )}
        {isEmployee && (
          <Button
            variant="text"
            onClick={() => navigate("/v1/employee/job-offers")}
            sx={{
              backgroundColor: "#F5F5F5",
              color: "#F5F5F5",
              "&:hover": {
                backgroundColor: "#F5F5F5",
              },
            }}
          >
            <ArrowBackIcon
              sx={{
                mt: -0.5,
                mv: 1,
                ml: -2,
                backgroundColor: "#F5F5F5",
                color: "primary.main",
                "&:hover": {
                  backgroundColor: "#F5F5F5",
                },
              }}
            />

            <Typography
              component="h2"
              variant="body1"
              sx={{
                fontSize: "15px",
                fontWeight: "bold",
                textTransform: "none",
                color: "primary.main",
                padding: "2px",
              }}
            >
              {t("employee.background.Back")}{" "}
            </Typography>
          </Button>
        )}
      </Grid>
      {isRecruiter && (
        <Grid container justifyContent="flex-end" xs={12}>
          <Button
            variant="text"
            onClick={() => {
              formik.setFieldValue(
                "formData.professional_area",
                jobData?.jobDetails?.professional_area
              );
              formik.setFieldValue(
                "formData.top_specialities",
                jobData?.jobDetails?.top_specialities
              );
              setOpenPersonalInfo(true);
            }}
            sx={{
              backgroundColor: "#F5F5F5",
              color: "#F5F5F5",
              "&:hover": {
                backgroundColor: "#F5F5F5",
              },
              mt: -5,
            }}
          >
            <EditIcon
              sx={{
                backgroundColor: "#F5F5F5",
                color: "primary.main",
                "&:hover": {
                  backgroundColor: "#F5F5F5",
                },
              }}
            />
            <Typography
              component="h2"
              variant="body1"
              sx={{
                fontSize: "15px",
                fontWeight: "bold",
                textTransform: "none",
                color: "primary.main",
                padding: "2px",
              }}
            >
              {t("employee.job-offers.Edit")}
            </Typography>
          </Button>
        </Grid>
      )}
      <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
        <Box sx={{ position: "relative" }}>
          {/* Job Image */}

          <Box
            sx={{
              width: 100,
              height: 100,
              backgroundColor: jobData?.company_profile_photo_url
                ? "transparent"
                : "lightgray", // Gray background if no image
              borderRadius: "8px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: jobData?.company_profile_photo_url
                ? "transparent"
                : "white", // White text when no image
            }}
          >
            {jobData?.company_profile_photo_url ? (
              <Box
                component="img"
                src={`${process.env.REACT_APP_API_BASE_URL}/public/image/${jobData.company_profile_photo_url}`}
                alt="Company Profile Photo"
                sx={{
                  width: "100%",
                  height: "100%",
                  borderRadius: "8px",
                  objectFit: "cover",
                }}
              />
            ) : (
              <Typography variant="body2">{t("recruiter.No image")}</Typography>
            )}
          </Box>
        </Box>
        <Box sx={{ flexGrow: 1 }}>
          <Typography
            sx={{ fontSize: { sm: "18px", md: "22px" } }}
            variant="h6"
          >
            {jobData.jobDetails?.positionName || "N/A"}
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            {jobData.company}
          </Typography>
        </Box>
      </Box>

      <Dialog width="md" open={openPersonalInfo} onClose={handleClose}>
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography ml={1} sx={{ fontWeight: "600", fontSize: "20px" }}>
            {t("recruiter.job-details.Edit Job Details")}
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Divider sx={{ width: "90%", color: "text.secondary", mb: 1, ml: 3 }} />
        <DialogContent>
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2}></Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <InputLabel
                  mt={2}
                  sx={{
                    fontWeight: "600",
                    fontSize: "14px",
                    color: "text.primary",
                  }}
                >
                  {t("recruiter.create-job-post.Position Name *")}
                </InputLabel>
                <TextField
                  fullWidth
                  placeholder={t("recruiter.create-job-post.Position Name *")}
                  name="positionName"
                  value={formik.values.positionName}
                  onChange={handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.positionName &&
                    Boolean(formik.errors.positionName)
                  }
                  helperText={
                    formik.touched.positionName && formik.errors.positionName
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <ProfessionSpecialtySelect
                  selectedSpecialty={handleSpecialtySelect}
                  specialties={specialties}
                  selectedProfession={selectedProfession}
                  handleSpecialtyChange={handleSpecialtyChange}
                  professionList={professions}
                  loading={loading}
                  error={error}
                  getSpecialityColor={getSpecialityColor}
                  formik={formik}
                  module="recruiter"
                />
              </Grid>

              <Grid item xs={12}>
                <Box mt={2}>
                  <InputLabel
                    sx={{
                      fontWeight: "600",
                      fontSize: "16px",
                      color: "text.primary",
                      marginTop: "10px",
                    }}
                  >
                    {t("onboarding.preferences.total_years_experience")}?
                  </InputLabel>
                  <FormControl fullWidth>
                    <Select
                      name="totalYearsExperience"
                      value={formik.values.totalYearsExperience}
                      onChange={formik.handleChange}
                      displayEmpty
                      renderValue={(selected) => {
                        if (!selected) {
                          return (
                            <span style={{ color: "#aaa" }}>
                              Select Total Experience
                            </span>
                          );
                        }
                        return selected;
                      }}
                    >
                      <MenuItem value="" disabled>
                        <em>Select Total Experience</em>
                      </MenuItem>
                      {experienceList.map((experience) => (
                        <MenuItem key={experience} value={experience}>
                          {experience}
                        </MenuItem>
                      ))}
                    </Select>
                    {formik.touched.totalYearsExperience &&
                      formik.errors.totalYearsExperience && (
                        <Typography variant="body2" color="error">
                          {formik.errors.totalYearsExperience}
                        </Typography>
                      )}
                  </FormControl>
                </Box>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <InputLabel
                      mt={2}
                      sx={{
                        fontWeight: "600",
                        fontSize: "16px",
                        color: "text.primary",
                        mb: 0.5,
                      }}
                    >
                      {t("employee.background.Education")}*
                    </InputLabel>
                    <FormControl fullWidth>
                      <Select
                        name="requirements.education"
                        value={formik.values.requirements.education}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        displayEmpty
                        error={
                          formik.touched.requirements?.education &&
                          Boolean(formik.errors.requirements?.education)
                        }
                      >
                        <MenuItem value="" disabled>
                          <em>{t("employee.profile.Education.Degree")}</em>
                        </MenuItem>
                        <MenuItem
                          value={t("employee.background.Bachelor's Degree")}
                        >
                          {t("employee.background.Bachelor's Degree")}
                        </MenuItem>
                        <MenuItem
                          value={t("employee.background.Master's Degree")}
                        >
                          {t("employee.background.Master's Degree")}
                        </MenuItem>
                        <MenuItem
                          value={t("employee.background.Doctorate Degree")}
                        >
                          {t("employee.background.Doctorate Degree")}
                        </MenuItem>
                      </Select>
                      {formik.touched.requirements?.education &&
                      formik.errors.requirements?.education ? (
                        <Typography variant="body2" color="error">
                          {formik.errors.requirements.education}
                        </Typography>
                      ) : null}
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <Box mt={2}>
                      <Grid container spacing={2}>
                        {formik.values.requirements.languages.map(
                          (language, index) => (
                            <Grid item xs={12} key={index}>
                              <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                  <InputLabel
                                    mt={2}
                                    sx={{
                                      fontWeight: "600",
                                      fontSize: "14px",
                                      color: "text.primary",
                                      mb: 0.5,
                                    }}
                                  >
                                    {t("employee.background.Language *")}
                                  </InputLabel>
                                  <FormControl fullWidth>
                                    <Select
                                      name={`requirements.languages[${index}].language`}
                                      value={language.language}
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                      displayEmpty
                                      error={
                                        formik.touched.requirements
                                          ?.languages?.[index]?.language &&
                                        Boolean(
                                          formik.touched.requirements
                                            ?.languages?.[index]?.language
                                        )
                                      }
                                    >
                                      <MenuItem value="" disabled>
                                        <em>
                                          {t(
                                            "employee.background.Select Language"
                                          )}
                                        </em>
                                      </MenuItem>
                                      <MenuItem
                                        value={t("employee.background.English")}
                                      >
                                        {t("employee.background.English")}
                                      </MenuItem>
                                      <MenuItem
                                        value={t("employee.background.Spanish")}
                                      >
                                        {t("employee.background.Spanish")}
                                      </MenuItem>
                                      <MenuItem
                                        value={t("employee.background.French")}
                                      >
                                        {t("employee.background.French")}
                                      </MenuItem>
                                      <MenuItem
                                        value={t("employee.background.German")}
                                      >
                                        {t("employee.background.German")}
                                      </MenuItem>
                                      <MenuItem
                                        value={t("employee.background.Italian")}
                                      >
                                        {t("employee.background.Italian")}
                                      </MenuItem>
                                      <MenuItem
                                        value={t(
                                          "employee.background.Portuguese"
                                        )}
                                      >
                                        {t("employee.background.Portuguese")}
                                      </MenuItem>
                                      <MenuItem value="Mandarin Chinese">
                                        {t(
                                          "employee.background.Mandarin Chinese"
                                        )}
                                      </MenuItem>
                                      <MenuItem
                                        value={t(
                                          "employee.background.Japanese"
                                        )}
                                      >
                                        {t("employee.background.Japanese")}
                                      </MenuItem>
                                      <MenuItem
                                        value={t("employee.background.Russian")}
                                      >
                                        {t("employee.background.Russian")}
                                      </MenuItem>
                                      <MenuItem
                                        value={t("employee.background.Arabic")}
                                      >
                                        {t("employee.background.Arabic")}
                                      </MenuItem>
                                    </Select>
                                    {formik.touched.requirements?.languages?.[
                                      index
                                    ]?.language &&
                                      formik.errors.requirements?.languages?.[
                                        index
                                      ]?.language && (
                                        <Typography
                                          variant="body2"
                                          color="error"
                                        >
                                          {
                                            formik.errors.requirements
                                              .languages[index].language
                                          }
                                        </Typography>
                                      )}
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <InputLabel
                                    mt={2}
                                    sx={{
                                      fontWeight: "600",
                                      fontSize: "14px",
                                      color: "text.primary",
                                      mb: 0.5,
                                    }}
                                  >
                                    {t("employee.background.Level *")}
                                  </InputLabel>
                                  <FormControl fullWidth>
                                    <Select
                                      name={`requirements.languages[${index}].level`}
                                      value={language.level}
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                      displayEmpty
                                      error={
                                        formik.touched.requirements
                                          ?.languages?.[index]?.level &&
                                        Boolean(
                                          formik.touched.requirements
                                            ?.languages?.[index]?.level
                                        )
                                      }
                                    >
                                      <MenuItem value="" disabled>
                                        <em>
                                          {t(
                                            "employee.background.Select Level"
                                          )}
                                        </em>
                                      </MenuItem>
                                      <MenuItem
                                        value={t(
                                          "employee.background.Beginner"
                                        )}
                                      >
                                        {t("employee.background.Beginner")}
                                      </MenuItem>
                                      <MenuItem
                                        value={t(
                                          "employee.background.Intermediate"
                                        )}
                                      >
                                        {t("employee.background.Intermediate")}
                                      </MenuItem>
                                      <MenuItem
                                        value={t(
                                          "employee.background.Advanced"
                                        )}
                                      >
                                        {t("employee.background.Advanced")}
                                      </MenuItem>
                                      <MenuItem
                                        value={t("employee.background.Native")}
                                      >
                                        {t("employee.background.Native")}
                                      </MenuItem>
                                    </Select>
                                    {formik.touched.requirements?.languages?.[
                                      index
                                    ]?.level &&
                                      formik.errors.requirements?.languages?.[
                                        index
                                      ]?.level && (
                                        <Typography
                                          variant="body2"
                                          color="error"
                                        >
                                          {
                                            formik.errors.requirements
                                              .languages[index].level
                                          }
                                        </Typography>
                                      )}
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                  <Button
                                    onClick={() => handleRemoveLanguage(index)}
                                    variant="standard"
                                    sx={{
                                      mb: 2,
                                      backgroundColor: "secondary.main",
                                      color: "text.secondary",
                                      "&:hover": {
                                        backgroundColor: "secondary.main",
                                      },
                                    }}
                                  >
                                    {t("employee.background.Remove")}
                                  </Button>
                                </Grid>
                              </Grid>
                            </Grid>
                          )
                        )}
                        <Grid item xs={12}>
                          <Button
                            type="button"
                            onClick={handleAddLanguage}
                            variant="standard"
                            sx={{
                              backgroundColor: "white",
                              color: "primary.main",
                              "&:hover": {
                                backgroundColor: "white",
                              },
                            }}
                          >
                            <AddIcon
                              sx={{
                                mt: -0.5,
                                ml: -3,
                                color: "primary.main",
                              }}
                            />
                            <Typography
                              component="span"
                              variant="body1"
                              sx={{
                                fontSize: "15px",
                                fontWeight: "bold",
                                textTransform: "none",
                                color: "primary.main",
                                padding: "2px",
                                backgroundColor: "white",
                              }}
                            >
                              {t("employee.background.Add More")}
                            </Typography>
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>

                  <Grid item xs={12}>
                    <InputLabel
                      mt={2}
                      sx={{
                        fontWeight: "600",
                        fontSize: "16px",
                        color: "text.primary",
                      }}
                    >
                      {t("employee.background.Description")}
                    </InputLabel>
                    <TextField
                      fullWidth
                      multiline
                      rows={4}
                      placeholder={t("employee.background.Description")}
                      name="description.description"
                      value={formik.values.description.description}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.description?.description &&
                        Boolean(formik.errors.description?.description)
                      }
                      helperText={
                        formik.touched.description?.description &&
                        formik.errors.description?.description
                      }
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <InputLabel
                      mt={2}
                      sx={{
                        fontWeight: "600",
                        fontSize: "16px",
                        color: "text.primary",
                      }}
                    >
                      {t("employee.background.Additional Information")}
                    </InputLabel>
                    <TextField
                      fullWidth
                      multiline
                      rows={4}
                      placeholder={t("employee.background.Type here")}
                      name="description.additionalInfo"
                      value={formik.values.description.additionalInfo}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.description?.additionalInfo &&
                        Boolean(formik.errors.description?.additionalInfo)
                      }
                      helperText={
                        formik.touched.description?.additionalInfo &&
                        formik.errors.description?.additionalInfo
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={12}>
                <InputLabel
                  mt={2}
                  sx={{
                    fontWeight: "600",
                    fontSize: "14px",
                    color: "text.primary",
                  }}
                  shrink={false}
                >
                  {t("onboarding.preferences.location")} *
                </InputLabel>
                <FormControl fullWidth>
                  <Select
                    name="location"
                    value={formik.values.location}
                    onChange={handleLocationChange} // Use custom change handler
                    onBlur={formik.handleBlur}
                    displayEmpty
                    renderValue={(selected) => {
                      if (selected === "") {
                        return (
                          <span style={{ color: "#aaa" }}>
                            {t("recruiter.create-job-post.Select Location")}
                          </span>
                        );
                      }
                      return selected;
                    }}
                    error={
                      formik.touched?.location &&
                      Boolean(formik.errors?.location)
                    }
                  >
                    <MenuItem value="" disabled>
                      <em>{t("recruiter.create-job-post.Select Location")}</em>
                    </MenuItem>
                    <MenuItem value={t("recruiter.create-job-post.Remote")}>
                      {t("recruiter.create-job-post.Remote")}
                    </MenuItem>
                    <MenuItem value={t("recruiter.create-job-post.On-site")}>
                      {t("recruiter.create-job-post.On-site")}
                    </MenuItem>
                    <MenuItem value={t("recruiter.create-job-post.Hybrid")}>
                      {t("recruiter.create-job-post.Hybrid")}
                    </MenuItem>
                  </Select>
                  {formik.touched.location && formik.errors.location && (
                    <Typography variant="body2" color="error">
                      {formik.errors.location}
                    </Typography>
                  )}
                </FormControl>

                {/* Conditionally render DynamicAutocomplete based on location */}
                {formik.values.location === "onsite" ||
                formik.values.location === "hybrid" ? (
                  <>
                    <InputLabel
                      mt={2}
                      sx={{
                        fontWeight: "600",
                        fontSize: "14px",
                        color: "text.primary",
                        ml: 0.3,
                        mt: 1,
                      }}
                      shrink={false}
                    >
                      {t("recruiter.job-details.Select City")}
                    </InputLabel>
                    <DynamicAutocomplete
                      color={cityColor}
                      placeHolder={formattedCity}
                      formik={formik}
                      fieldName="city"
                      value={formik.values.city}
                      // fetchOptions={fetchCities}
                    />
                  </>
                ) : null}
              </Grid>

              <Grid item xs={12}>
                <InputLabel
                  mt={3}
                  sx={{
                    fontWeight: "600",
                    fontSize: "16px",
                    color: "text.primary",
                  }}
                >
                  {t("onboarding.preferences.employment_type")}
                </InputLabel>
                <Box style={{ display: "flex", flexWrap: "wrap" }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="employmentType.partTime"
                        checked={formik.values.employmentType.partTime}
                        onChange={handleCheckboxChange}
                        sx={{
                          color: "gray",
                          "&.Mui-checked": { color: "secondary.main" },
                        }}
                      />
                    }
                    label={t("onboarding.preferences.part_time")}
                    sx={{ mr: 5, mb: 1 }}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="employmentType.fullTime"
                        checked={formik.values.employmentType.fullTime}
                        onChange={handleCheckboxChange}
                        sx={{
                          color: "gray",
                          "&.Mui-checked": { color: "secondary.main" },
                        }}
                      />
                    }
                    label={t("onboarding.preferences.full_time")}
                    sx={{ mr: 5, mb: 1 }}
                  />
                </Box>
                {formik.touched.employmentType &&
                  formik.errors.employmentType && (
                    <Typography variant="body2" color="error">
                      {formik.errors.employmentType}
                    </Typography>
                  )}
              </Grid>

              <Grid item xs={12}>
                <InputLabel
                  mt={2}
                  sx={{
                    fontWeight: "600",
                    fontSize: "16px",
                    color: "text.primary",
                  }}
                >
                  {t("recruiter.create-job-post.removecandidates")}
                </InputLabel>
                <FormControl
                  component="fieldset"
                  sx={{ display: "flex", flexDirection: "column" }}
                >
                  <RadioGroup
                    aria-label="remoteCandidates"
                    name="remoteCandidates"
                    value={formik.values.remoteCandidates}
                    onChange={handleChange}
                    sx={{ flexDirection: "row" }}
                  >
                    <FormControlLabel
                      value={t("common.yes")}
                      control={
                        <Radio
                          sx={{
                            color: "gray",
                            "&.Mui-checked": { color: "secondary.main" },
                          }}
                        />
                      }
                      label={t("common.yes")}
                      sx={{ marginRight: "20px" }}
                    />
                    <FormControlLabel
                      value={t("common.no")}
                      control={
                        <Radio
                          sx={{
                            color: "gray",
                            "&.Mui-checked": { color: "secondary.main" },
                          }}
                        />
                      }
                      label={t("common.no")}
                      sx={{ marginRight: "20px" }}
                    />
                    <FormControlLabel
                      value={t("recruiter.create-job-post.Remote only")}
                      control={
                        <Radio
                          sx={{
                            color: "gray",
                            "&.Mui-checked": { color: "secondary.main" },
                          }}
                        />
                      }
                      label={t("recruiter.create-job-post.Remote only")}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <InputLabel
                  mt={2}
                  sx={{
                    fontWeight: "600",
                    fontSize: "16px",
                    color: "text.primary",
                  }}
                >
                  {t("recruiter.create-job-post.relocate")}
                </InputLabel>
                <FormControl
                  component="fieldset"
                  sx={{ display: "flex", flexDirection: "column" }}
                >
                  <RadioGroup
                    aria-label="willingToRelocate"
                    name="willingToRelocate"
                    value={formik.values.willingToRelocate}
                    onChange={formik.handleChange}
                    sx={{ flexDirection: "row" }}
                  >
                    <FormControlLabel
                      value={t("common.yes")}
                      control={
                        <Radio
                          sx={{
                            color: "gray",
                            "&.Mui-checked": { color: "secondary.main" },
                          }}
                        />
                      }
                      label={t("common.yes")}
                      sx={{ marginRight: "20px" }}
                    />
                    <FormControlLabel
                      value={t("common.no")}
                      control={
                        <Radio
                          sx={{
                            color: "gray",
                            "&.Mui-checked": { color: "secondary.main" },
                          }}
                        />
                      }
                      label={t("common.no")}
                      sx={{ marginRight: "20px" }}
                    />
                  </RadioGroup>
                </FormControl>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <InputLabel
                      mt={2}
                      sx={{
                        fontWeight: "600",
                        fontSize: "14px",
                        color: "text.primary",
                      }}
                    >
                      {t("recruiter.create-job-post.salary-range")}
                    </InputLabel>
                    <TextField
                      type="number"
                      fullWidth
                      placeholder={t("employee.aboutyou.From")}
                      name="salaryRange.from"
                      value={formik.values.salaryRange.from}
                      onChange={handleInputSalaryChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.salaryRange?.from &&
                        Boolean(formik.errors.salaryRange?.from)
                      }
                      helperText={
                        formik.touched.salaryRange?.from &&
                        formik.errors.salaryRange?.from
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <InputLabel
                      mt={2}
                      sx={{
                        fontWeight: "600",
                        fontSize: "14px",
                        color: "text.primary",
                      }}
                    >
                      {t("employee.aboutyou.To")} *
                    </InputLabel>
                    <TextField
                      type="number"
                      fullWidth
                      placeholder={t("employee.aboutyou.To")}
                      name="salaryRange.to"
                      value={formik.values.salaryRange.to}
                      onChange={handleInputSalaryChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.salaryRange?.to &&
                        Boolean(formik.errors.salaryRange?.to)
                      }
                      helperText={
                        formik.touched.salaryRange?.to &&
                        formik.errors.salaryRange?.to
                      }
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12}></Grid>
              </Grid>
            </Grid>
            <DialogActions>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  mr: 2,
                  mb: 1,
                }}
              >
                <Button
                  type="submit"
                  variant="contained"
                  disabled={!formik.isValid}
                  sx={{
                    backgroundColor: "primary.main",
                    color: "#F5F5F5",
                    "&:hover": {
                      backgroundColor: "primary.main",
                    },
                  }}
                >
                  <Typography
                    component="span"
                    variant="body1"
                    sx={{
                      fontSize: "15px",
                      fontWeight: "bold",
                      textTransform: "none",
                    }}
                  >
                    {t("employee.job-offers.btn_Save")}
                  </Typography>
                </Button>
              </Box>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>

      <Divider sx={{ width: "100%", color: "text.secondary", mt: 1, mb: 1 }} />
      <Typography
        variant="h6"
        component="h3"
        gutterBottom
        sx={{ fontWeight: "bold" }}
      >
        {t("recruiter.create-job-post.jobDetails")}:
      </Typography>
      <Typography variant="body1" paragraph>
        <strong>{t("recruiter.create-job-post.Position Name *")}:</strong>{" "}
        {jobData.jobDetails?.positionName || "N/A"}
      </Typography>
      <Typography variant="body1" paragraph>
        <strong>{t("onboarding.preferences.location")}:</strong>{" "}
        {jobData.jobDetails?.location || "N/A"}
      </Typography>
      <Typography variant="body1" paragraph>
        <strong>{t("recruiter.job-details.City")}:</strong>{" "}
        {jobData.jobDetails?.city || "N/A"}
      </Typography>
      <Typography variant="body1" paragraph>
        <strong>{t("onboarding.preferences.employment_type")}:</strong>{" "}
        {jobData.jobDetails?.employmentType?.fullTime
          ? "Full-Time"
          : jobData.jobDetails?.employmentType?.partTime
          ? "Part-Time"
          : "Not Specified"}
      </Typography>
      <Typography variant="body1" paragraph>
        <strong>{t("recruiter.job-details.Remote Candidates")}:</strong>{" "}
        {jobData.jobDetails?.remoteCandidates || "N/A"}
      </Typography>
      <Typography variant="body1" paragraph>
        <strong>{t("recruiter.job-details.Willing to Relocate")}:</strong>{" "}
        {formik.values.willingToRelocate || "N/A"}
      </Typography>
      <Typography variant="body1" paragraph>
        <strong>{t("recruiter.job-details.Salary Range")}:</strong> €
        {jobData.jobDetails?.salaryRange?.from || "N/A"} -{" "}
        {jobData.jobDetails?.salaryRange?.to || "N/A"}
      </Typography>
      <Typography variant="body1" paragraph>
        <strong>
          {t("recruiter.job-details.Profession")}:{" "}
          {jobData.jobDetails?.professional_area}{" "}
        </strong>
      </Typography>

      <Typography variant="body1" paragraph>
        <Typography variant="body1" paragraph>
          <strong>{t("recruiter.job-details.Specialities")}:</strong>
          <Box component="span">
            {top_specialities && top_specialities.length > 0 ? (
              top_specialities.map((specialty, index) => (
                <Typography
                  key={index}
                  variant="body2"
                  paragraph
                  sx={{ display: "inline", mr: 1 }}
                >
                  <br /> {specialty}
                </Typography>
              ))
            ) : (
              <Typography
                variant="body2"
                paragraph
                sx={{ display: "inline", mr: 1 }}
              >
                <br /> N/A
              </Typography>
            )}
          </Box>
        </Typography>
      </Typography>

      <Typography variant="body1" paragraph>
        <strong>{t("recruiter.job-details.TotalExperience")}:</strong>{" "}
        {jobData.jobDetails?.totalYearsExperience || "N/A"}
      </Typography>
      <Typography
        variant="h6"
        component="h3"
        gutterBottom
        sx={{ fontWeight: "bold" }}
      >
        {t("recruiter.job-details.Job Requirements")}:
      </Typography>
      <Typography variant="body1" paragraph>
        <strong>{t("employee.background.Education")}:</strong>{" "}
        {jobData.requirements?.education || "N/A"}
      </Typography>
      <Typography variant="body1" paragraph>
        <strong>{t("employee.profile.Edit Languages")}:</strong>{" "}
        {jobData?.requirements?.languages?.length
          ? jobData.requirements.languages
              .map((lang) => `${lang.language} (${lang.level})`)
              .join(", ")
          : "N/A"}
      </Typography>
      <Typography
        variant="h6"
        component="h3"
        gutterBottom
        sx={{ fontWeight: "bold" }}
      >
        {t("recruiter.job-details.Job Description")}:
      </Typography>
      <Typography variant="body1" paragraph>
        {jobData.description?.description || "Job Description is not available"}
      </Typography>
      <Typography variant="body1" paragraph>
        <strong>{t("employee.background.Additional Information")}:</strong>{" "}
        {jobData.description?.additionalInfo || "N/A"}
      </Typography>
    </>
  );
};

export default JobDetail;
