import React, { useState, useEffect, useContext } from "react";
import {
  Typography,
  Box,
  Card,
  CardContent,
  CardActions,
  IconButton,
  Button,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import CloseIcon from "@mui/icons-material/Close";
import UploadImage from "../GlobalComponent/UploadImage";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import JobContext from "../../context/JobContext";
import { Link } from "react-router-dom";
import ApplyForm from "./CandidateList";
import { JobPostApi, OnBoardingApi } from "../../apis";

const JobCard = ({ offer, offer_company_profile_photo_url, offerId }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [showCandidates, setShowCandidates] = useState(false);
  const [loading, setLoading] = useState(true);
  let user = null;

  try {
    const userString = localStorage.getItem("user");
    user = JSON.parse(userString);
  } catch (error) {
    console.error("Error parsing user from localStorage:", error);
  }

  const isEmployee = user && user.role && user.role.name === "employee";
  const isRecruiter = user && user.role && user.role.name === "recruiter";
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleViewCandidates = () => {
    setShowCandidates(true);
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  return (
    <>
      <Card
        sx={{
          display: { sm: "flex" },
          justifyContent: "space-between",
          alignItems: "center",
          padding: 2,
          marginBottom: 2,
        }}
      >
        <Box sx={{ display: { sm: "flex" }, alignItems: "center", gap: 2 }}>
          <Box
            sx={{
              position: "relative", // Enable the overlay
              width: { xs: "100%", sm: 80, md: 110 },
              height: { xs: 200, sm: 80, md: 110 },
              backgroundColor: "lightgray",
              borderRadius: "8px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              m: 1,
            }}
          >
            {/* Job Profile Picture */}
            {offer_company_profile_photo_url ? (
              <Box
                component="img"
                src={`${process.env.REACT_APP_API_BASE_URL}/public/image/${offer_company_profile_photo_url}`}
                alt="Company Profile Photo"
                sx={{
                  width: "100%",
                  height: "100%",
                  borderRadius: "8px",
                  objectFit: "cover", // Ensure it fits the container
                }}
              />
            ) : (
              <Typography variant="body1" sx={{ color: "text.secondary" }}>
                No Image
              </Typography>
            )}

            {/* Company Profile Photo (Overlayed) */}
            {/* <Box
              component="img"
              src={`${process.env.REACT_APP_API_BASE_URL}/public/image/${offer_company_profile_photo_url}`}
              alt="Company Profile Photo"
              sx={{
                position: "absolute",
                top: 10, // Adjust positioning
                right: 10, // Adjust positioning
                width: 30, // Adjust size
                height: 30,
                borderRadius: "50%", // Circular image
                border: "2px solid white", // Optional border
              }}
            /> */}
          </Box>

          <CardContent sx={{ flex: { md: "1 0 auto" } }}>
            <Typography
              sx={{ fontSize: { sm: "18px", md: "22px" } }}
              variant="h6"
            >
              {offer.positionName || "N/A"}
            </Typography>
            <Typography variant="body1" paragraph>
              <Box component="span">
                {offer?.specialties &&
                Object.keys(offer?.specialties).length > 0 ? (
                  Object.keys(offer?.specialties)
                    .filter((specialty) => offer?.specialties[specialty])
                    .map((specialty, index) => (
                      <Typography
                        variant="body2"
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          mb: 0.5,
                          color: "gray",
                        }}
                      >
                        {specialty.replace(/([A-Z])/g, " $1").trim()}
                      </Typography>
                    ))
                ) : (
                  <Typography component="span" variant="body1">
                    {offer.professional_area || "N/A"}
                  </Typography>
                )}
              </Box>
            </Typography>
            <Typography
              variant="body2"
              color="text.primary"
              sx={{ display: "flex", alignItems: "center", mt: -2 }}
            >
              {/* <LocationOnIcon sx={{mt:{xs:-1.5,sm:0 }}} />  */}
              {offer.location || "N/A"} • Full time • €
              {offer.salaryRange?.from || "N/A"}-
              {offer.salaryRange?.to || "N/A"}
            </Typography>
          </CardContent>
        </Box>
        <CardActions
          sx={{ justifyContent: "flex-end", flexDirection: "column" }}
        >
          <IconButton
            disableRipple
            aria-label="settings"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
          >
            <MoreHorizIcon />
          </IconButton>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            {isEmployee && (
              <MenuItem
                component={Link}
                to={`/v1/employee/job-details/${offerId.id}`}
                onClick={handleClose}
              >
                View job details
              </MenuItem>
            )}
            {isRecruiter && (
              <MenuItem
                component={Link}
                to={`/v1/recruiter/job-details/${offerId.id}`}
                onClick={handleClose}
              >
                View job details
              </MenuItem>
            )}
          </Menu>
          <Button
            variant="standard"
            fullWidth
            onClick={handleViewCandidates}
            sx={{
              mt: 2,
              mb: 2,
              width: { sm: "150px", md: "200px" },
              backgroundColor: "white",
              color: "primary.main",
              border: "2px solid #031141",
              "&:hover": {
                backgroundColor: "white",
              },
            }}
          >
            <Typography
              component="h2"
              variant="body1"
              sx={{
                fontSize: { sm: "12px", md: "15px" },
                fontWeight: "bold",
                textTransform: "none",
                padding: "4px",
              }}
            >
              View Candidates
            </Typography>
          </Button>
        </CardActions>
      </Card>

      <Dialog
        open={showCandidates}
        onClose={() => setShowCandidates(false)}
        fullWidth
        maxWidth="md"
        sx={{
          "& .MuiPaper-root": {
            borderRadius: 4,
            border: "1px solid #e0e0e0",
            backgroundColor: "white",
          },
        }}
      >
        <DialogTitle>
          <IconButton
            sx={{ position: "absolute", top: 6, right: 6, color: "black" }}
            onClick={() => setShowCandidates(false)}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <ApplyForm
            close={() => setShowCandidates(false)}
            jobId={offerId.id}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowCandidates(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default JobCard;
