import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Drawer,
  List,
  ListItem,
  ListItemText,
  useMediaQuery,
  useTheme,
  Box,
  Avatar,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate } from "react-router-dom";
import Logo from "../../assets/images/logo.png";
import hirio_Logo from "../../assets/images/HirioBlue.png";
import AccountCircle from "@mui/icons-material/AccountCircle";
import CloseIcon from "@mui/icons-material/Close";
import ChangePassword from "../ChangePassword/changePassword";
import { OnBoardingApi } from "../../apis";
import { useTranslation } from "react-i18next";
import LanguageSwitcher from "../GlobalComponent/languageSwitcher";
function isAuthenticated() {
  return localStorage.getItem("token") !== null;
}
const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: "transparent",
    color: "#FFF",
    boxShadow: "none",
    transition: "top 0.5s, background-color 0.5s, color 0.5s",
    top: 0,
    zIndex: 9999,
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px 20px",
  },
  navLinks: {
    display: "flex",
    justifyContent: "flex-start",
    "& > *": {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
  },
  actionButtons: {
    display: "flex",
    alignItems: "center",
  },
  drawer: {
    width: 240,
  },
}));

function NavBar() {
  const classes = useStyles();
  const theme = useTheme();
  const navigate = useNavigate();
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedNavItem, setSelectedNavItem] = useState("");
  const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);
  const [visible, setVisible] = useState(true);
  const [scrolled, setScrolled] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [changePasswordOpen, setChangePasswordOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState(null);
  const { t } = useTranslation();
  let user;

  try {
    const userString = localStorage.getItem("user");

    user = JSON.parse(userString);
  } catch (error) {
    console.error("Error parsing user from localStorage:", error);
  }

  const isEmployee = user && user.role?.name === "employee";
  const isRecruiter = user && user.role?.name === "recruiter";
  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };
  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    navigate("/");
    window.location.reload();
  };
  const handleOpenChangePassword = () => {
    setChangePasswordOpen(true);
    handleClose(); // Close the menu when opening change password dialog
  };

  const handleCloseChangePassword = () => {
    setChangePasswordOpen(false);
  };
  const handleNavigationItemClick = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
    setSelectedNavItem(id);
  };
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleScroll = () => {
    const currentScrollPos = window.scrollY;

    if (currentScrollPos > 100) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }

    if (isTablet) {
      if (currentScrollPos > prevScrollPos) {
        setVisible(false);
      } else if (currentScrollPos < prevScrollPos) {
        setVisible(false);
      }

      if (currentScrollPos <= 100) {
        setVisible(true); // If at the top of the page, show the navbar
      }
    } else {
      // Desktop mode logic
      if (currentScrollPos > 100 && currentScrollPos > prevScrollPos) {
        setVisible(true); // If scrolling down on desktop, show the navbar
      } else if (currentScrollPos > 100 && currentScrollPos < prevScrollPos) {
        setVisible(true); // If scrolling up on desktop, show the navbar
      } else if (currentScrollPos <= 100) {
        setVisible(true); // If at the top of the page, show the navbar
      }
    }

    setPrevScrollPos(currentScrollPos);
  };

  const fetchOnBoardedUserData = async () => {
    try {
      const res = await OnBoardingApi.getOnBoarding(); // Adjust API call as needed

      setUserData(res?.data?.profile);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching on-boarded user data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    {
      isAuthenticated() && fetchOnBoardedUserData();
    }
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [prevScrollPos]);

  const drawerContent = (
    <div className={classes.drawer}>
      <List>
        {["home", "how-it-works", "testimonials"].map((text) => (
          <ListItem
            button
            key={text}
            onClick={() => handleNavigationItemClick(text)}
          >
            <ListItemText
              primary={
                text.charAt(0).toUpperCase() + text.slice(1).replace(/-/g, " ")
              }
            />
          </ListItem>
        ))}
      </List>
    </div>
  );

  return (
    <AppBar
      className={classes.appBar}
      elevation={0}
      style={{
        top: visible ? 0 : -100,
        backgroundColor: scrolled ? "#0a074f" : "transparent",
        color: scrolled ? theme.palette.secondary.main : "#FFF",
      }}
    >
      <Toolbar className={classes.toolbar}>
        <Box sx={{ marginRight: "2%", marginLeft: "2%" }}>
          <img src={Logo} alt="logo" style={{ width: "100px" }} />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >

          {!isAuthenticated() && (

            <Box sx={{ marginLeft: "auto", display: "flex" }}>
              <Box sx={{ mr: 1, }}>
                <LanguageSwitcher />
              </Box>
              <Button
                type="submit"
                variant="standard"
                onClick={() => navigate("/register")}
                sx={{
                  mr: 1,
                  color: scrolled ? theme.palette.secondary.main : "black",
                  backgroundColor: scrolled
                    ? "secondary.main"
                    : "secondary.main",
                  "&:hover": {
                    backgroundColor: scrolled
                      ? "secondary.main"
                      : "secondary.main",
                  },
                }}
              >
                <Typography
                  component="h2"
                  variant="body1"
                  sx={{
                    fontSize: isSmallScreen ? "10px" : "15px",
                    fontWeight: "bold",
                    textTransform: "none",
                    color: theme.palette.primary.main,
                    padding: "2px",
                  }}
                >
                  {t("landing.Register")}
                </Typography>
              </Button>
              <Button
                type="submit"
                variant="outlined"
                onClick={() => navigate("/login")}
                sx={{
                  borderColor: scrolled
                    ? theme.palette.secondary.main
                    : "secondary.main",
                  color: scrolled
                    ? theme.palette.secondary.main
                    : "secondary.main",
                  "&:hover": {
                    backgroundColor: "transparent",
                    borderColor: scrolled
                      ? theme.palette.secondary.main
                      : "secondary.main",
                    color: scrolled
                      ? theme.palette.secondary.main
                      : "secondary.main",
                  },
                }}
              >
                <Typography
                  component="h2"
                  variant="body1"
                  sx={{
                    fontSize: isSmallScreen ? "10px" : "15px",
                    fontWeight: "bold",
                    textTransform: "none",
                    color: "inherit",
                    padding: "2px",
                  }}
                >
                  {t("landing.Login")}
                </Typography>
              </Button>
            </Box>
          )}
          {isAuthenticated() && (
            <Box sx={{ marginLeft: "auto" }}>
              <IconButton
                size="large"
                edge="end"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                sx={{
                  "&:hover": {
                    backgroundColor: "primary.main",
                  },
                  width: "50px",
                }}
              >
                {userData && userData.profile_photo_url ? (
                  <Avatar
                    src={`${process.env.REACT_APP_API_BASE_URL}/public/image/${userData.profile_photo_url}`}
                    alt={userData.profile_photo_url}
                    sx={{ width: 35, height: 35 }}
                  />
                ) : (
                  <AccountCircle
                    sx={{
                      color: "gray",
                      width: 40,
                      height: 40,
                    }}
                  />
                )}
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                sx={{
                  marginTop: "50px",
                }}
              >
                {isRecruiter && (
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      navigate("/v1/recruiter/my-profile");
                    }}
                  >
                    {t("employee.home.View Profile")}
                  </MenuItem>
                )}
                {isEmployee && (
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      navigate("/v1/employee/my-profile");
                    }}
                  >
                    {t("employee.home.View Profile")}
                  </MenuItem>
                )}
                <MenuItem onClick={handleOpenChangePassword}>
                  {t("recruiter.job-details.Change Password")}
                </MenuItem>
                <MenuItem onClick={handleLogout}>
                  {t("recruiter.job-details.Logout")}
                </MenuItem>
                <MenuItem>
                  <LanguageSwitcher />
                </MenuItem>
              </Menu>
              <Dialog
                open={changePasswordOpen}
                onClose={handleCloseChangePassword}
              >
                <DialogTitle
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    ml={1}
                    sx={{ fontWeight: "600", fontSize: "20px", ml: 2 }}
                  >
                    {t("recruiter.job-details.Change Password")}
                  </Typography>

                  <IconButton onClick={handleCloseChangePassword}>
                    <CloseIcon />
                  </IconButton>
                </DialogTitle>
                <DialogContent>
                  <ChangePassword />
                </DialogContent>
              </Dialog>
            </Box>
          )}
        </Box>
        {/* )} */}
      </Toolbar>
    </AppBar>
  );
}

export default NavBar;
