import React, { useState, useEffect } from "react";
import {
  Box,
  CssBaseline,
  Drawer,
  List,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
  Button,
  IconButton,
  useMediaQuery,
  useTheme,
  ListItem,
} from "@mui/material";
import { CheckCircleOutline as CheckCircleOutlineIcon } from "@mui/icons-material";
import { useNavigate, useLocation, Link } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import MenuIcon from "@mui/icons-material/Menu";
import Logo from "../../assets/images/logo.png";
import LanguageSwitcher from "../../components/GlobalComponent/languageSwitcher";
import { useTranslation } from "react-i18next";

const drawerWidth = 280;

const RecruiterSidebar = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedMenuItem, setSelectedMenuItem] = useState("");
  const [visitedMenuItems, setVisitedMenuItems] = useState([]);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    const pathname = location.pathname;
    const currentMenuItem = getSelectedMenuItem(pathname);
    setSelectedMenuItem(currentMenuItem);

    // Update visited items only if it's a new selection
    if (currentMenuItem && !visitedMenuItems.includes(currentMenuItem)) {
      setVisitedMenuItems([...visitedMenuItems, currentMenuItem]);
    }
  }, [location.pathname]); // Trigger effect when pathname changes

  const getSelectedMenuItem = (pathname) => {
    switch (pathname) {
      case "/v1/recruiter/recruiter-profile":
        // return "Recruiter Profile";
        return "recruiter.nav_link_Recruiter Profile";
      case "/v1/recruiter/company-profile":
        // return "Company Profile";
        return "recruiter.nav_link_Company Profile";
      case "/v1/recruiter/preview-and-submit":
        // return "Preview And Submit";
        return "recruiter.nav_link_Preview And Submit";

      default:
        return "";
    }
  };

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const handleBackButtonClick = () => {
    const lastVisitedIndex = visitedMenuItems.lastIndexOf(selectedMenuItem);

    if (lastVisitedIndex > 0) {
      const updatedVisitedMenuItems = [
        ...visitedMenuItems.slice(0, lastVisitedIndex),
      ];
      setVisitedMenuItems(updatedVisitedMenuItems);

      const previousMenuItem = updatedVisitedMenuItems[lastVisitedIndex - 1];
      setSelectedMenuItem(previousMenuItem);

      const path = `/v1/recruiter/${previousMenuItem
        .toLowerCase()
        .replace(/\s/g, "-")}`;
      navigate(path);
    } else {
      navigate("/v1/recruiter/recruiter-profile");
    }
  };

  const renderMenuItem = (menuItemKey, path) => {
    const translatedMenuItem = t(menuItemKey); // Translate the menu item key
    return (
      <ListItem
        button
        key={menuItemKey}
        selected={selectedMenuItem === menuItemKey}
        onClick={() => handleMenuItemClick(path, menuItemKey)}
        sx={{
          "&.Mui-selected": {
            backgroundColor: theme.palette.primary.main,
          },
        }}
      >
        <ListItemIcon>
          <CheckCircleOutlineIcon
            sx={{
              color:
                selectedMenuItem === menuItemKey
                  ? "gray" // If the current step is selected
                  : visitedMenuItems.includes(menuItemKey)
                  ? theme.palette.secondary.main // Change to green when step is completed
                  : "gray", // Default gray color when unvisited
            }}
          />
        </ListItemIcon>
        <ListItemText
          primary={translatedMenuItem} // Use the translated text here
          sx={{
            color:
              selectedMenuItem === menuItemKey
                ? "gray" // Active step
                : visitedMenuItems.includes(menuItemKey)
                ? theme.palette.secondary.main // Completed step
                : "gray", // Default for unvisited items
          }}
        />
      </ListItem>
    );
  };
  // const handleMenuItemClick = (menuItem) => {
  //   const path = `/v1/recruiter/${menuItem.toLowerCase().replace(/\s/g, "-")}`;
  //   navigate(path);
  // };
  const handleMenuItemClick = (path, menuItemKey) => {
    navigate(path); // Use the fixed routing path
    setSelectedMenuItem(menuItemKey); // Set the selected menu item
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      {isTablet ? (
        <Drawer
          variant="temporary"
          open={drawerOpen}
          onClose={handleDrawerClose}
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box",
              bgcolor: theme.palette.primary.main,
              color: theme.palette.text.secondary,
            },
          }}
        >
          <Box sx={{ width: "260px", paddingLeft: "10px" }}>
            <Box sx={{ display: "flex", p: 1 }}>
              <Box sx={{ marginRight: "2%", my: 4 }}>
                <Link to="/">
                  <img src={Logo} alt="logo" style={{ width: "100px" }} />
                </Link>
              </Box>
            </Box>
            <Typography
              ml={1}
              sx={{ fontWeight: "600", fontSize: "20px", marginBottom: "16px" }}
            >
              {t("employee.sidebar.heading")}
            </Typography>
            <Typography
              ml={1}
              component="h2"
              variant="subtitle1"
              sx={{ fontSize: "12.5px" }}
            >
              {t("employee.sidebar.subheading")}
            </Typography>
          </Box>
          <Box sx={{ overflow: "auto", flexGrow: 1 }}>
            <List>
              {[
                "Recruiter Profile",
                "Company Profile",
                "Preview And Submit",
              ].map((menuItem) => renderMenuItem(menuItem))}
            </List>
          </Box>

          <Box sx={{ mt: "auto", ml: 4, mb: 2 }}>
            <LanguageSwitcher />
            <Button
              type="submit"
              onClick={handleBackButtonClick}
              variant="standard"
              sx={{
                backgroundColor: theme.palette.secondary.main,
                color: "white",
                "&:hover": {
                  backgroundColor: theme.palette.secondary.main,
                },
              }}
            >
              <ArrowBackIcon
                sx={{ color: theme.palette.primary.main, mr: 1 }}
              />
              <Typography
                component="h2"
                variant="body1"
                sx={{
                  fontSize: "15px",
                  fontWeight: "bold",
                  textTransform: "none",
                  color: theme.palette.primary.main,
                  padding: "2px",
                }}
              >
                {t("employee.sidebar.backtohome")}
              </Typography>
            </Button>
          </Box>
        </Drawer>
      ) : (
        <Drawer
          variant="permanent"
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box",
              bgcolor: theme.palette.primary.main,
              color: theme.palette.text.secondary,
            },
          }}
        >
          <Box sx={{ width: "260px", paddingLeft: "10px" }}>
            <Box sx={{ display: "flex", p: 1 }}>
              <Box sx={{ marginRight: "2%", my: 4 }}>
                <Link to="/">
                  <img src={Logo} alt="logo" style={{ width: "100px" }} />
                </Link>
              </Box>
            </Box>
            <Typography
              ml={1}
              sx={{ fontWeight: "600", fontSize: "20px", marginBottom: "16px" }}
            >
              {t("employee.sidebar.heading")}
            </Typography>
            <Typography
              ml={1}
              component="h2"
              variant="subtitle1"
              sx={{ fontSize: "12.5px" }}
            >
              {t("employee.sidebar.subheading")}
            </Typography>
          </Box>
          <Box sx={{ overflow: "auto", flexGrow: 1 }}>
            <List>
              {[
                {
                  key: "recruiter.nav_link_Recruiter Profile",
                  path: "/v1/recruiter/recruiter-profile",
                },
                {
                  key: "recruiter.nav_link_Company Profile",
                  path: "/v1/recruiter/company-profile",
                },
                {
                  key: "recruiter.nav_link_Preview And Submit",
                  path: "/v1/recruiter/preview-and-submit",
                },
              ].map(({ key, path }) => renderMenuItem(key, path))}
            </List>
          </Box>
          ;
          <Box sx={{ mt: "auto", ml: 4, mb: 2 }}>
            <LanguageSwitcher sx={{ mb: 4 }} />

            <Button
              type="submit"
              onClick={() => navigate("/v1/recruiter/recruiter-profile")}
              variant="standard"
              sx={{
                backgroundColor: theme.palette.secondary.main,
                color: "white",
                "&:hover": {
                  backgroundColor: theme.palette.secondary.main,
                },
              }}
            >
              <ArrowBackIcon
                sx={{ color: theme.palette.primary.main, mr: 1 }}
              />
              <Typography
                component="h2"
                variant="body1"
                sx={{
                  fontSize: "15px",
                  fontWeight: "bold",
                  textTransform: "none",
                  color: theme.palette.primary.main,
                  padding: "2px",
                }}
              >
                {t("employee.sidebar.backtohome")}
              </Typography>
            </Button>
          </Box>
        </Drawer>
      )}
      <Box
        component="main"
        sx={{ flexGrow: 1, bgcolor: theme.palette.background.default, p: 3 }}
      >
        <Toolbar>
          {isTablet && (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={() => setDrawerOpen(!drawerOpen)}
              sx={{ mr: 2 }}
            >
              <MenuIcon />
            </IconButton>
          )}
        </Toolbar>
        {props.children}
      </Box>
    </Box>
  );
};

export default RecruiterSidebar;
