import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  Grid,
  FormControl,
  TextField,
  InputLabel,
  MenuItem,
  Select,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Divider,
  FormControlLabel,
  Checkbox,
  RadioGroup,
  Radio,
} from "@mui/material";
import dayjs from "dayjs";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import { OnBoardingApi, UploadImageApi } from "../../apis";
import { useFormik } from "formik";
import * as Yup from "yup";
import UploadImage from "../GlobalComponent/UploadImage";
import RadioButton from "../GlobalComponent/RadioButtons";
import Loader from "../GlobalComponent/Loader";
import ExperienceForm from "../GlobalComponent/EditExperienceForm";
import DynamicAutocomplete from "../GlobalComponent/CityFinder";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker.css";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import PhoneInput from "react-phone-input-2"; // Import the PhoneInput component
import "react-phone-input-2/lib/style.css"; // Import the default styles
import ProfessionApi from "../../apis/routes/professionRoutes";
import ProfessionSpecialtySelect from "../CustomSelector/ProfessionSpecialtySelect";
import { t } from "i18next";
import { useTranslation } from "react-i18next";

const MyProfile = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState(null);
  const [selectedCategories, setSelectedCategories] = useState({});
  const [selectedSpecialty, setSelectedSpecialty] = useState("");
  const [professions, setProfessions] = useState({});
  const [specialties, setSpecialties] = useState([]);
  const [selectedProfession, setSelectedProfession] = useState("");

  const [error, setError] = useState(null);
  const fetchProfessions = async () => {
    try {
      setLoading(true);
      const response = await ProfessionApi.getAllProfessions();
      const professionList = response.data?.subcategories || {};
      setProfessions(professionList);
    } catch (err) {
      setError("Failed to load professions.");
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchProfessions();
  }, []);

  const handleSpecialtySelect = (specialty) => {
    // You can add your own logic to handle the selected specialty
  };

  const getSpecialityColor = (speciality) => {
    return speciality === "Select Speciality" ? "#aaa" : "black";
  };

  // Handle Specialty Selection from Dropdown
  const handleSpecialtyChange = (event) => {
    const selected = event.target.value;
    setSelectedProfession(selected);
    if (selected && professions[selected]) {
      setSpecialties(professions[selected]);
    }
    // Update formik value for professional_area
    formik.setFieldValue("preferences.professional_area", selected);
  };

  const handleChipClick = (subcategory) => {
    // Toggle the chip selection state
    setSelectedCategories((prevSelectedCategories) => {
      const updatedCategories = {
        ...prevSelectedCategories,
        [subcategory]: !prevSelectedCategories[subcategory], // Toggle the selection
      };
      return updatedCategories;
    });

    // Optionally update the Formik state or any other relevant state here
    const currentExperience =
      formik.values.preferences.experienceYears[selectedSpecialty] || {};

    const updatedExperience = {
      ...currentExperience,
      [subcategory]: !currentExperience[subcategory],
    };

    formik.setFieldValue(
      `preferences.experienceYears.${selectedSpecialty}`,
      updatedExperience
    );
  };

  const specialtiesList = Object.keys(specialties || {});
  const subcategories = specialties[selectedSpecialty]?.subcategories || []; // Dynamically get subcategories

  const specialtiesObject = specialtiesList.reduce((acc, specialty) => {
    acc[specialty] = true; // or any relevant details
    return acc;
  }, {});

  const formatPhoneNumber = (phoneNumber) => {
    // Remove any non-digit characters
    const cleaned = ("" + phoneNumber).replace(/\D/g, "");

    // Check if the cleaned number has the correct length (11 digits including country code)
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

    if (match) {
      return `+${match[0].slice(0, 3)} (${match[1]}) ${match[2]}-${match[3]}`; // Format as +XXX (XXX) XXX-XXXX
    }

    return phoneNumber; // Return original number if it doesn't match the pattern
  };

  const fetchOnBoardedUserData = async () => {
    try {
      const res = await OnBoardingApi.getOnBoarding();
      function multiParse(input) {
        let result = input;
        try {
          while (typeof result === "string") {
            result = JSON.parse(result);
          }
        } catch (e) {
          console.error("Error parsing input:", e);
        }
        return result;
      }
      setUserData(res?.data?.profile || {});
      if (res.data && res.data.profile) {
        const parsedTopSpecialities = multiParse(
          res?.data?.profile?.top_specialities || ""
        );
        const parsedExperienceYears = multiParse(
          res?.data?.profile?.experienceYears || {}
        );

        const parsedEmployementType = multiParse(
          res?.data?.profile?.employment_type || "{}"
        );
        const salaryRange = multiParse(
          res?.data?.profile?.salary_range || "{}"
        );
        const workExperience = multiParse(
          res?.data?.profile?.work_experience || "[]"
        );
        const education = multiParse(
          JSON.stringify(res?.data?.profile?.education) || "[]"
        );
        const languages = multiParse(
          JSON.stringify(res?.data?.profile?.languages) || "[]"
        );
        const experienceYears = multiParse(
          res?.data?.profile?.experienceYears || {}
        );

        formik.setValues({
          ...formik.values,
          preferences: {
            professional_area: res?.data?.profile?.professional_area || "",
            total_years_experience:
              res?.data?.profile.total_years_experience || "",
            top_specialities: parsedTopSpecialities,
            experienceYears: multiParse(
              res?.data?.profile?.experienceYears || "{}"
            ),

            employment_type: {
              fullTime: parsedEmployementType?.fullTime || false,
              partTime: parsedEmployementType?.partTime || false,
            },
            job_search_status: res?.data?.profile?.job_search_status || "",
            remote_work: res?.data?.profile?.remote_work ? "1" : "0" || "",
            relocation: res?.data?.profile?.relocation ? "1" : "0" || "",
          },
          about_you: {
            resume_url: res?.data?.profile.resume_url || "",
            profile_photo_url: res?.data?.profile?.profile_photo_url || "",
            full_name: res?.data?.profile?.full_name || "",
            phone_number: res?.data?.profile?.phone_number || "",
            whatsapp_number: res?.data?.profile?.whatsapp_number || "",
            current_city: res?.data?.profile?.current_city || "",
            salary_range: {
              from: salaryRange?.from || "",
              to: salaryRange?.to || "",
            },
            linkedin_url: res?.data?.profile?.linkedin_url || "",
            personal_blog_url: res?.data?.profile?.personal_blog_url || "",
          },
          background: {
            work_experience:
              workExperience?.length > 0
                ? workExperience
                : [
                    {
                      company_name: workExperience?.company_name || "",
                      job_title: workExperience?.job_title || "",
                      is_current: workExperience?.is_current || false,
                      start: workExperience?.start || "",
                      end: workExperience?.end || "",
                      description: workExperience?.description || "",
                    },
                  ],
            education:
              education?.length > 0
                ? education
                : [
                    {
                      university_or_collage:
                        education?.university_or_collage || "",
                      graduation_year: education?.graduation_year || "",
                      field_of_study: education?.field_of_study || "",
                      degree: education?.degree || "",
                    },
                  ],
            languages:
              languages?.length > 0
                ? languages
                : [
                    {
                      language: languages?.language || "",
                      language_level: languages?.language_level || "",
                    },
                  ],
            objective: res?.data?.profile?.objective || "",
            achievements: res?.data?.profile?.achievements || "",
            personal_characteristics:
              res?.data?.profile?.personal_characteristics || "",
          },
        });
        const parsedSpecialties = multiParse(
          formik.values?.preferences?.top_specialities
        );

        const specialtiesArray = Object.entries(parsedSpecialties).filter(
          ([_, value]) => value
        );
        setSelectedSpecialty(specialtiesArray);

        specialtiesArray.map(([specialty]) => setSelectedSpecialty(specialty));

        setUserData(multiParse(res?.data?.profile));
      } else {
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching on-boarded user data:", error);
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchOnBoardedUserData();
  }, []);

  const experienceList = [
    "Less than 1 year",
    "1-3 years",
    "3-5 years",
    "5+ years",
  ];
  const designList = [
    "Software Engineer",
    "Business Analyst",
    "Product Manager",
  ];
  const jobStatusList = [
    "Not seeking to change jobs, but may consider",
    "Actively looking for a job",
    "Currently employed and not looking",
  ];

  const [openQualifications, setOpenQualification] = useState(false);
  const [openPreferences, setOpenPreferences] = useState(false);
  const [openLocation, setOpenLocation] = useState(false);
  const [openPersonalInfo, setOpenPersonalInfo] = useState(false);
  const [profilePhoto, setProfilePhoto] = useState(null);
  const [profilePhotoPreview, setProfilePhotoPreview] = useState(null);

  const [employmentType, setEmploymentType] = useState("");
  const [jobSearchStatus, setJobSearchStatus] = useState("");
  const [openSalary, setOpenSalary] = useState(false);
  const [openLinks, setOpenLinks] = useState(false);
  const [openWorkExperience, setOpenWorkExperience] = useState(false);
  const [openEducation, setOpenEducation] = useState(false);
  const [education, setEducation] = useState("");
  const [openLanguage, setOpenLanguage] = useState(false);
  const [openAdditionalInfo, setOpenAdditionalInfo] = useState(false);

  const [selectedOption, setSelectedOption] = useState("");
  const handleInputSalaryChange = (event) => {
    const { name, value } = event.target;
    let formattedValue = value.replace(/,/g, ""); // Remove existing commas
    // Add commas to the number
    formik.setFieldValue(name, formattedValue);
  };
  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };
  const handleInputChange = (event) => {
    const { name, checked } = event.target;
    formik.setFieldValue(`preferences.top_specialities.${name}`, checked);
  };
  const handleChange = (event) => {
    formik.setFieldValue(event.target.name, event.target.value);
  };
  const [workExperiences, setWorkExperiences] = useState([
    {
      company_name: "",
      job_title: "",
      is_current: false,
      start: null, // Initialize with null or a default date object
      end: null, // Initialize end date with null or a default date object
      description: "",
    },
  ]);
  const [addedWorkExperiences, setAddedWorkExperiences] = useState([false]);

  const handleWorkExperienceChange = (index, field, value) => {
    const updatedExperiences = [...workExperiences];
    updatedExperiences[index][field] = value;
    setWorkExperiences(updatedExperiences);
    formik.setFieldValue(
      `background.work_experience[${index}].${field}`,
      value
    );
  };

  const handleAddWorkExperience = () => {
    const newWorkExperience = [
      ...formik.values.background.work_experience,
      {
        company_name: "",
        job_title: "",
        is_current: false,
        start: null, // Initialize with null or a default date object
        end: null, // Initialize end date with null or a default date object
        description: "",
      },
    ];
    formik.setFieldValue("background.work_experience", newWorkExperience);

    const newAddedWorkExperience = [...addedWorkExperiences, true];
    setAddedWorkExperiences(newAddedWorkExperience);
  };

  const handleRemoveWorkExperience = (index) => {
    const updatedExperiences = [...formik.values.background.work_experience];
    updatedExperiences.splice(index, 1);
    formik.setFieldValue("background.work_experience", updatedExperiences);

    const updatedAddedExperience = [...addedWorkExperiences];
    updatedAddedExperience.splice(index, 1);
    setAddedLanguages(updatedAddedExperience);
  };
  const handleStartDateChange = (index, date) => {
    const formattedDate = dayjs(date).format("YYYY-MM-DD");

    const updatedWorkExperiences = [
      ...formik.values.background.work_experience,
    ];
    updatedWorkExperiences[index].start = formattedDate;

    formik.setFieldValue(
      `background.work_experience[${index}].start`,
      formattedDate
    );
  };

  const handleEndDateChange = (index, date) => {
    const formattedDate = dayjs(date).format("YYYY-MM-DD");

    const updatedWorkExperiences = [
      ...formik.values.background.work_experience,
    ];
    updatedWorkExperiences[index].end = formattedDate;

    formik.setFieldValue(
      `background.work_experience[${index}].end`,
      formattedDate
    );
  };
  const [languages, setLanguages] = useState([
    { language: "", language_level: "" },
  ]);
  const [addedLanguages, setAddedLanguages] = useState([false]);

  const handleLanguageChange = (index, key, value) => {
    const newLanguages = [...languages];
    newLanguages[index][key] = value;
    setLanguages(newLanguages);
    formik.setFieldValue(`background.languages[${index}].${key}`, value);
  };

  const handleAddLanguage = () => {
    const newLanguages = [
      ...formik.values.background.languages,
      { language: "", level: "" },
    ];
    formik.setFieldValue("background.languages", newLanguages);

    const newAddedLanguages = [...addedLanguages, true];
    setAddedLanguages(newAddedLanguages);
  };

  const handleRemoveLanguage = (index) => {
    const newLanguages = [...formik.values.background.languages];
    newLanguages.splice(index, 1);
    formik.setFieldValue("background.languages", newLanguages);

    const newAddedLanguages = [...addedLanguages];
    newAddedLanguages.splice(index, 1);
    setAddedLanguages(newAddedLanguages);
  };

  const [educations, setEducations] = useState([
    {
      university_or_collage: "",
      graduation_year: "",
      field_of_study: "",
      degree: "",
    },
  ]);
  const [addedEducations, setAddedEducations] = useState([false]);

  const handleEducationChange = (index, field, value) => {
    const updatedEducations = [...educations];
    updatedEducations[index][field] = value;
    setEducations(updatedEducations);
    formik.setFieldValue(`background.education[${index}].${field}`, value);
  };

  const handleAddEducation = () => {
    const newEducation = [
      ...formik.values.background.education,
      {
        university_or_collage: "",
        graduation_year: "",
        field_of_study: "",
        degree: "",
      },
    ];
    formik.setFieldValue("background.education", newEducation);

    const newAddedEducations = [...addedEducations, true];
    setAddedEducations(newAddedEducations);
  };

  const handleRemoveEducation = (index) => {
    const newEducations = [...formik.values.background.education];
    newEducations.splice(index, 1);
    formik.setFieldValue("background.education", newEducations);

    const newAddedEducations = [...addedEducations];
    newAddedEducations.splice(index, 1);
    setAddedLanguages(newAddedEducations);
  };

  const formik = useFormik({
    initialValues: {
      company_profile: {
        company_location: "",
        latitude: "",
        longitude: "",
        // other fields
      },
      preferences: {
        professional_area: "",
        total_years_experience: "",
        top_specialities: [],
        experienceYears: {},

        employment_type: {
          fullTime: false,
          partTime: false,
        },
        job_search_status: "",
        remote_work: "",
        relocation: "",
      },
      about_you: {
        resume_url: "",
        profile_photo_url: "",
        full_name: "",
        phone_number: "",
        whatsapp_number: "",
        current_city: "",
        salary_range: {
          from: "",
          to: "",
        },
        linkedin_url: "",
        personal_blog_url: "",
      },
      background: {
        work_experience: [],
        education: [],
        languages: [],
        objective: "",
        achievements: "",
        personal_characteristics: "",
      },
      // is_onboarding: false,
      // is_active: false,
    },
    validationSchema: Yup.object({}),

    onSubmit: async (values) => {
      try {
        const profilePhotoName = await handleProfilePhotoUpload(); // Upload the profile photo and get the URL data
        if (profilePhotoName) {
          values.about_you.profile_photo_url = profilePhotoName; // Set profile_photo_url to the photo name
        }

        const updatedValues = {
          ...values,
          preferences: {
            ...values.preferences,
            top_specialities: JSON.stringify(
              values.preferences.top_specialities
            ),
            employment_type: JSON.stringify(values.preferences.employment_type),
          },
        };

        const res = await OnBoardingApi.OnBoarding(updatedValues);

        setOpenPersonalInfo(false);
        setOpenQualification(false);
        setOpenPreferences(false);
        setOpenLocation(false);
        setOpenLinks(false);
        setOpenSalary(false);
        setOpenSalary(false);
        setOpenWorkExperience(false);
        setOpenEducation(false);
        setOpenLanguage(false);
        setOpenAdditionalInfo(false);
        fetchOnBoardedUserData();
      } catch (err) {
        console.error(err);
      }
    },
  });

  const trueTopSpecialities = Object.entries(
    formik.values.preferences.top_specialities
  )
    .filter(([_, value]) => value)
    .map(([key]) => key) // Extract the key (specialty name)
    .join(", "); // Join the names into a single string

  function multiParse(input) {
    let result = input;
    try {
      while (typeof result === "string") {
        result = JSON.parse(result);
      }
    } catch (e) {
      console.error("Error parsing input:", e);
    }
    return result;
  }
  if (loading) {
    <Loader loading={loading} />;
  }
  const handleEmploymentTypeChange = (event) => {
    const { name, checked } = event.target;

    formik.setFieldValue(`preferences.employment_type.${name}`, checked);

    const otherEmploymentType = name === "partTime" ? "fullTime" : "partTime";

    // Uncheck the other checkbox if the clicked one is checked
    if (checked) {
      formik.setFieldValue(
        `preferences.employment_type.${otherEmploymentType}`,
        false
      );
    }
  };

  const handleProfilePhotoChange = (event) => {
    const file = event.target.files[0];
    setProfilePhoto(file);
    setProfilePhotoPreview(URL.createObjectURL(file));
  };

  const handleProfilePhotoUpload = async () => {
    if (profilePhoto) {
      try {
        const formData = new FormData();
        formData.append("file", profilePhoto);

        const res = await UploadImageApi.uploadImage(formData);
        return res.data.data.name;
      } catch (error) {
        console.error("Error uploading profile photo:", error);
      }
    }
  };
  const getCityColor = (city) => {
    return city === "Enter City" ? "#aaa" : "black";
  };
  const trueTopCity = formik.values.about_you.current_city;
  const formattedCity = trueTopCity ? trueTopCity : "Enter City";

  const cityColor = getCityColor(formattedCity);

  return (
    <Box sx={{ maxWidth: "100%", backgroundColor: "#F5F5F5" }}>
      <Typography
        ml={1}
        sx={{ fontWeight: "Bold", fontSize: "24px", marginLeft: "-5px", mt: 6 }}
      >
        {t("employee.job-offers.myprofile")}
      </Typography>
      <Box
        sx={{
          background: "#F5F5F5",
          borderRadius: "14px",
          paddingTop: "20px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography
            ml={1}
            sx={{
              fontWeight: "600",
              fontSize: "22px",
              marginLeft: "-3px",
              marginBottom: "15px",
            }}
          >
            {t("employee.aboutyou.title")}
          </Typography>

          <Typography
            component="span"
            variant="body1"
            onClick={setOpenPersonalInfo}
            sx={{
              backgroundColor: "#F5F5F5",
              color: "primary.main",
              "&:hover": {
                backgroundColor: "inherit",
              },
              marginLeft: "auto",
              mt: { xs: 0.5, sm: 0 },
              fontSize: "15px",
              fontWeight: "bold",
              textTransform: "none",
              color: "primary.main",
              padding: "2px",
            }}
          >
            {t("employee.job-offers.Edit")}
            <EditIcon sx={{ mr: 1, ml: 1, fontSize: "16px" }} />
          </Typography>
        </Box>

        <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
          <Grid container spacing={2}>
            <Grid item>
              <Box
                sx={{
                  width: "100px",
                  height: "100px",
                  border: "1px dotted black",
                  borderRadius: "5px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: "15px",
                  backgroundColor: "lightgray",
                }}
              >
                {formik.values.about_you?.profile_photo_url ? (
                  <UploadImage
                    imageUrl={`${process.env.REACT_APP_API_BASE_URL}/public/image/${formik.values.about_you?.profile_photo_url}`}
                    altText={formik.values.about_you?.profile_photo_url}
                  />
                ) : (
                  <Typography variant="body1" sx={{ color: "text.secondary" }}>
                    {t("common.Upload")}
                  </Typography>
                )}
              </Box>
            </Grid>

            <Grid item>
              <Typography variant="subtitle1" sx={{ fontWeight: "600" }}>
                {t("employee.aboutyou.Profile Photo")}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  mr: 0,
                  textDecoration: "none",
                  color: "gray",
                  fontSize: "11px",
                }}
              >
                {t("employee.aboutyou.maxsize")}
              </Typography>
            </Grid>
          </Grid>
        </Box>

        <Box>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <InputLabel
                mt={2}
                sx={{
                  fontWeight: "600",
                  fontSize: "14px",
                  color: "text.primary",
                }}
              >
                {t("employee.job-offers.lable_ Full Name")}
              </InputLabel>
              <Box
                sx={{
                  mb: 2,
                  p: 2,
                  border: "1px solid #d9d9d9",
                  borderRadius: "4px",
                  backgroundColor: "#f5f5f5",
                  color: "black",
                }}
              >
                <Typography variant="body1">
                  {formik.values.about_you.full_name}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <InputLabel
                mt={2}
                sx={{
                  fontWeight: "600",
                  fontSize: "14px",
                  color: "text.primary",
                }}
              >
                {t("employee.job-offers.lable_Phone No")}
              </InputLabel>
              <Box
                sx={{
                  mb: 2,
                  p: 2,
                  border: "1px solid #d9d9d9",
                  borderRadius: "4px",
                  backgroundColor: "#f5f5f5",
                  color: "black",
                }}
              >
                <Typography variant="body1">
                  +{formik.values.about_you.phone_number}
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <InputLabel
                mt={2}
                sx={{
                  fontWeight: "600",
                  fontSize: "14px",
                  color: "text.primary",
                }}
              >
                {t("employee.job-offers.lable_Your Location")}
              </InputLabel>
              <Box
                sx={{
                  mb: 2,
                  p: 2,
                  border: "1px solid #d9d9d9",
                  borderRadius: "4px",
                  backgroundColor: "#f5f5f5",
                  color: "black",
                }}
              >
                <Typography variant="body1">
                  {formik.values.about_you.current_city}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Dialog
          width="md"
          open={openPersonalInfo}
          onClose={() => setOpenPersonalInfo(false)}
        >
          <DialogTitle
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography ml={1} sx={{ fontWeight: "600", fontSize: "20px" }}>
              {t("employee.job-offers.lable_Edit Personal Information")}
            </Typography>
            <IconButton onClick={() => setOpenPersonalInfo(false)}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <Divider
            sx={{ width: "90%", color: "text.secondary", mb: 1, mx: "auto" }}
          />
          <DialogContent>
            <Box component="form" onSubmit={formik.handleSubmit}>
              <Grid container spacing={2}>
                <Grid item>
                  <input
                    accept="image/*"
                    style={{ display: "none" }}
                    id="profile-photo"
                    type="file"
                    onChange={handleProfilePhotoChange}
                  />
                  <label htmlFor="profile-photo">
                    <Button
                      component="span"
                      sx={{
                        "&:hover": {
                          backgroundColor: "transparent", // Set the background color to transparent on hover
                        },
                      }}
                    >
                      <Box
                        sx={{
                          width: "100px",
                          height: "100px",
                          border: "1px dotted black",
                          borderRadius: "5px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginBottom: "15px",
                          backgroundColor: "lightgray",
                        }}
                      >
                        {profilePhotoPreview ? (
                          <UploadImage
                            imageUrl={profilePhotoPreview}
                            altText="Profile Photo"
                          />
                        ) : formik.values.about_you?.profile_photo_url ? (
                          <UploadImage
                            imageUrl={
                              profilePhotoPreview ||
                              `${process.env.REACT_APP_API_BASE_URL}/public/image/${formik.values.about_you?.profile_photo_url}`
                            }
                            altText={formik.values.about_you?.profile_photo_url}
                          />
                        ) : (
                          <Typography
                            variant="body1"
                            sx={{ color: "text.secondary" }}
                          >
                             {t("common.Upload")}
                          </Typography>
                        )}
                      </Box>
                    </Button>
                  </label>
                </Grid>
                <Grid item>
                  <Typography variant="subtitle1" sx={{ fontWeight: "600" }}>
                    {t("employee.aboutyou.Profile Photo")}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      mr: 0,
                      textDecoration: "none",
                      color: "gray",
                      fontSize: "11px",
                    }}
                  >
                    {t("employee.aboutyou.maxsize")}
                  </Typography>
                </Grid>
              </Grid>
              <Box>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <InputLabel
                      mt={2}
                      sx={{
                        fontWeight: "600",
                        fontSize: "14px",
                        color: "text.primary",
                      }}
                    >
                      {t("employee.job-offers.lable_ Full Name")} *
                    </InputLabel>
                    <TextField
                      InputLabelProps={{
                        shrink: false,
                        style: {
                          fontSize: "14px",
                          color: "gray",
                        },
                      }}
                      placeholder="Enter Name"
                      variant="outlined"
                      fullWidth
                      name="about_you.full_name"
                      value={formik.values.about_you.full_name}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.about_you?.full_name &&
                        Boolean(formik.errors.about_you?.full_name)
                      }
                      helperText={
                        formik.touched.about_you?.full_name &&
                        formik.errors.about_you?.full_name
                      }
                      sx={{
                        mb: 2,
                        "& .MuiInputBase-input::placeholder": {
                          color: "black",
                          opacity: 1,
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <InputLabel
                      mt={2}
                      sx={{
                        fontWeight: "600",
                        fontSize: "14px",
                        color: "text.primary",
                      }}
                    >
                      {t("employee.job-offers.lable_Phone No")} *
                    </InputLabel>
                    <PhoneInput
                      placeholder="Enter Phone No"
                      variant="outlined"
                      fullWidth
                      name="about_you.phone_number"
                      value={formik.values.about_you.phone_number}
                      onChange={(phone) =>
                        formik.handleChange({
                          target: {
                            name: "about_you.phone_number",
                            value: phone,
                          },
                        })
                      }
                      error={
                        formik.touched.about_you?.phone_number &&
                        Boolean(formik.errors.about_you?.phone_number)
                      }
                      helperText={
                        formik.touched.about_you?.phone_number &&
                        formik.errors.about_you?.phone_number
                      }
                      sx={{
                        mb: 2,
                        "& .MuiInputBase-input::placeholder": {
                          color: "black",
                          opacity: 1,
                        },
                      }}
                      inputStyle={{
                        width: "100%",
                        fontSize: "14px",
                        padding: "10px 10px 10px 40px", // Add left padding to prevent overlap with the flag
                        height: "55px", // Set the desired height
                        border: "1px solid #ccc",
                        borderRadius: "4px",
                      }}
                      InputLabelProps={{
                        shrink: false,
                        style: {
                          fontSize: "14px",
                          color: "gray",
                        },
                      }}
                      inputProps={{
                        maxLength: 15, // Set max length for the input
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <InputLabel
                      mt={2}
                      sx={{
                        fontWeight: "600",
                        fontSize: "14px",
                        color: "text.primary",
                      }}
                    >
                      {t("employee.job-offers.lable_Your Location")} *
                    </InputLabel>

                    <DynamicAutocomplete
                      color={cityColor}
                      placeHolder={formattedCity}
                      formik={formik}
                      fieldName="about_you.current_city"
                      value={formik.values.about_you.current_city}
                      // fetchOptions={fetchCities}
                    />
                  </Grid>
                </Grid>
              </Box>

              <DialogActions>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    mr: 2,
                    mb: 1,
                  }}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{
                      backgroundColor: "primary.main",
                      color: "white",
                      "&:hover": {
                        backgroundColor: "primary.main",
                      },
                    }}
                  >
                    <Typography
                      component="span"
                      variant="body1"
                      sx={{
                        fontSize: "15px",
                        fontWeight: "bold",
                        textTransform: "none",
                      }}
                    >
                      {t("employee.job-offers.btn_Save")}
                    </Typography>
                  </Button>
                </Box>
              </DialogActions>
            </Box>
          </DialogContent>
        </Dialog>
        <Box>
          <Box sx={{ display: "flex" }}>
            <Typography
              ml={1}
              sx={{
                mt: 2,
                fontWeight: "600",
                fontSize: "22px",
                marginLeft: "-4px",
                marginBottom: "15px",
              }}
            >
              {t("onboarding.preferences.qualification")}
            </Typography>

            <Typography
              component="span"
              variant="body1"
              onClick={() => setOpenQualification(true)}
              sx={{
                backgroundColor: "#F5F5F5",
                color: "primary.main",
                "&:hover": {
                  backgroundColor: "inherit",
                },
                marginLeft: "auto",
                mt: { xs: 0.5, sm: 0 },
                fontSize: "15px",
                fontWeight: "bold",
                textTransform: "none",
                color: "primary.main",
                padding: "2px",
                mt: 2,
              }}
            >
              {t("employee.job-offers.Edit")}
              <EditIcon sx={{ mr: 1, ml: 1, fontSize: "16px" }} />
            </Typography>
          </Box>

          <Box>
            <Box>
              <Typography>
                <strong>{t("onboarding.preferences.professional_area")}</strong>
                <Box>{userData?.professional_area}</Box>
              </Typography>
              <Box>
                <Typography variant="body1" paragraph>
                  <strong>{t("employee.preview.Top Specialties")}</strong>
                  <Box component="span">
                    {(() => {
                      const specialties = userData?.top_specialities
                        ? multiParse(userData.top_specialities)
                        : [];

                      const specialtiesArray = Array.isArray(specialties)
                        ? specialties
                        : [];

                      return specialtiesArray.length > 0 ? (
                        specialtiesArray.map((specialty, index) => (
                          <Typography
                            key={index}
                            variant="body1"
                            component="span"
                            sx={{ display: "inline", mr: 1 }}
                          >
                            <br />
                            {specialty}
                          </Typography>
                        ))
                      ) : (
                        <Typography variant="body1" component="span">
                          N/A
                        </Typography>
                      );
                    })()}
                  </Box>
                </Typography>
              </Box>

              {/* Other fields, like total years of experience */}
            </Box>

            {/* Other fields, like total years of experience */}
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <InputLabel
                mt={2}
                sx={{
                  fontWeight: "600",
                  fontSize: "16px",
                  color: "text.primary",
                  mb: 0.5,
                }}
              >
                {t("onboarding.preferences.total_years_experience")}
              </InputLabel>

              <Typography variant="body1">
                {userData?.total_years_experience || "N/A"}
              </Typography>
            </Grid>
          </Box>
        </Box>
        <Dialog
          maxWidth="md"
          open={openQualifications}
          onClose={() => setOpenQualification(false)}
        >
          <DialogTitle
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography ml={1} sx={{ fontWeight: "600", fontSize: "20px" }}>
              {t("employee.job-offers.Edit Qualifications")}
            </Typography>

            <IconButton onClick={() => setOpenQualification(false)}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <Divider
            sx={{ width: "90%", color: "text.secondary", mb: 1, mx: "auto" }}
          />
          <DialogContent>
            <Box component="form" onSubmit={formik.handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <ProfessionSpecialtySelect
                    selectedSpecialty={handleSpecialtySelect}
                    specialties={specialties}
                    selectedProfession={selectedProfession}
                    handleSpecialtyChange={handleSpecialtyChange}
                    professionList={professions}
                    loading={loading}
                    error={error}
                    getSpecialityColor={getSpecialityColor}
                    formik={formik}
                    module="employee"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputLabel
                    sx={{
                      fontWeight: "600",
                      fontSize: "16px",
                      color: "text.primary",
                    }}
                  >
                    {t("onboarding.preferences.total_years_experience")}
                  </InputLabel>
                  <FormControl fullWidth>
                    <Select
                      name="preferences.total_years_experience"
                      value={formik.values.preferences.total_years_experience}
                      onChange={handleChange}
                      displayEmpty
                      renderValue={(selected) =>
                        selected || (
                          <span style={{ color: "#aaa" }}>
                            Select Experience
                          </span>
                        )
                      }
                    >
                      {experienceList.map((experience) => (
                        <MenuItem key={experience} value={experience}>
                          {experience}
                        </MenuItem>
                      ))}
                    </Select>
                    {formik.touched.preferences?.total_years_experience &&
                      formik.errors.preferences?.total_years_experience && (
                        <Typography variant="caption" color="error">
                          {formik.errors.preferences.total_years_experience}
                        </Typography>
                      )}
                  </FormControl>
                </Grid>
              </Grid>

              <DialogActions>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    mr: 2,
                    mb: 1,
                  }}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{
                      backgroundColor: "primary.main",
                      color: "white",
                      "&:hover": {
                        backgroundColor: "primary.main",
                      },
                    }}
                  >
                    <Typography
                      component="span"
                      variant="body1"
                      sx={{
                        fontSize: "15px",
                        fontWeight: "bold",
                        textTransform: "none",
                      }}
                    >
                      {t("employee.job-offers.btn_Save")}
                    </Typography>
                  </Button>
                </Box>
              </DialogActions>
            </Box>
          </DialogContent>
        </Dialog>
      </Box>

      <Box>
        <Box sx={{ display: "flex" }}>
          <Typography
            ml={1}
            sx={{
              mt: 2,
              fontWeight: "600",
              fontSize: "22px",
              marginLeft: "-4px",
              marginBottom: "15px",
            }}
          >
            {t("employee.job-offers.Job Preferences")}
          </Typography>

          <Typography
            component="span"
            variant="body1"
            onClick={() => setOpenPreferences(true)}
            sx={{
              backgroundColor: "#F5F5F5",
              color: "primary.main",
              "&:hover": {
                backgroundColor: "inherit",
              },
              marginLeft: "auto",
              mt: { xs: 0.5, sm: 0 },
              fontSize: "15px",
              fontWeight: "bold",
              textTransform: "none",
              color: "primary.main",
              padding: "2px",
              mt: 2,
            }}
          >
            {t("employee.job-offers.Edit")}
            <EditIcon sx={{ mr: 1, ml: 1, fontSize: "16px" }} />
          </Typography>
        </Box>
        <Box>
          <InputLabel
            mt={2}
            sx={{
              fontWeight: "600",
              fontSize: "16px",
              color: "text.primary",
              ml: -0.1,
            }}
          >
            {t("employee.job-offers.Type of Employment")}
          </InputLabel>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Box
              sx={{
                mb: 2,
                p: 3,
                border: "1px solid #d9d9d9",
                borderRadius: "4px",
                backgroundColor: "#f5f5f5",
                color: "black",
              }}
            >
              <Typography variant="body1">
                <Box mt={2}>
                  {formik.values.preferences?.employment_type ? (
                    <Typography
                      ml={1}
                      sx={{
                        fontSize: "14px",
                        color: "text.primary",
                        mt: -1,
                        ml: -0.1,
                      }}
                    >
                      {formik.values.preferences.employment_type["fullTime"]
                        ? "Full Time"
                        : ""}
                      {formik.values.preferences.employment_type["fullTime"] &&
                      formik.values.preferences.employment_type["partTime"]
                        ? ", "
                        : ""}
                      {formik.values.preferences.employment_type["partTime"]
                        ? "Part Time"
                        : ""}
                    </Typography>
                  ) : (
                    <Typography
                      ml={1}
                      sx={{ fontSize: "14px", color: "text.primary" }}
                    >
                      N/A
                    </Typography>
                  )}
                </Box>
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <InputLabel
              mt={2}
              sx={{
                fontWeight: "600",
                fontSize: "16px",
                color: "text.primary",
                ml: -0.1,
              }}
            >
              {t("employee.job-offers.Job Search Status")}
            </InputLabel>
            <Box
              sx={{
                mb: 2,
                p: 2,
                border: "1px solid #d9d9d9",
                borderRadius: "4px",
                backgroundColor: "#f5f5f5",
                color: "black",
              }}
            >
              <Typography variant="body1">
                {formik.values.preferences?.job_search_status || "N/A"}
              </Typography>
            </Box>
          </Grid>
        </Box>

        <Dialog
          sx={{ maxWidth: "100%" }}
          open={openPreferences}
          onClose={() => setOpenPreferences(false)}
        >
          <DialogTitle
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography ml={1} sx={{ fontWeight: "600", fontSize: "20px" }}>
              {t("employee.job-offers.Edit")} Job Preferences
            </Typography>

            <IconButton onClick={() => setOpenPreferences(false)}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <Divider
            sx={{ width: "90%", color: "text.secondary", mb: 1, mx: "auto" }}
          />

          <DialogContent>
            <Box component="form" onSubmit={formik.handleSubmit}>
              <Grid item xs={12}>
                <InputLabel
                  mt={3}
                  sx={{
                    fontWeight: "600",
                    fontSize: "16px",
                    color: "text.primary",
                  }}
                >
                  {t("employee.job-offers.Type of Employment")}
                </InputLabel>
                <Box style={{ display: "flex", flexWrap: "wrap" }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="partTime"
                        checked={
                          formik.values.preferences.employment_type.partTime
                        }
                        onChange={handleEmploymentTypeChange}
                        sx={{
                          color: "gray",
                          "&.Mui-checked": { color: "secondary.main" },
                        }}
                      />
                    }
                    label={t("onboarding.preferences.part_time")}
                    sx={{ mr: 5, mb: 1 }}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="fullTime"
                        checked={
                          formik.values.preferences.employment_type.fullTime
                        }
                        onChange={handleEmploymentTypeChange}
                        sx={{
                          color: "gray",
                          "&.Mui-checked": { color: "secondary.main" },
                        }}
                      />
                    }
                    label={t("onboarding.preferences.full_time")}
                    sx={{ mr: 5, mb: 1 }}
                  />
                </Box>

                {formik.touched.preferences?.employment_type &&
                  formik.errors.preferences?.employment_type && (
                    <Typography variant="body2" color="error">
                      {formik.errors.jobDetails.employment_type}
                    </Typography>
                  )}
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputLabel
                  mt={3}
                  sx={{
                    fontWeight: "600",
                    fontSize: "16px",
                    color: "text.primary",
                    mb: 0.5,
                  }}
                >
                  {t("onboarding.preferences.job_search_status")}
                </InputLabel>
                <FormControl fullWidth>
                  <Select
                    name="preferences.job_search_status"
                    value={formik.values.preferences.job_search_status}
                    onChange={handleChange}
                    error={
                      formik.touched.preferences?.job_search_status &&
                      Boolean(formik.errors.preferences?.job_search_status)
                    }
                    displayEmpty
                    renderValue={(selected) =>
                      selected || (
                        <span style={{ color: "#aaa" }}>Select Status</span>
                      )
                    }
                  >
                    <MenuItem value="" disabled>
                      <em>Select Status</em>
                    </MenuItem>
                    {jobStatusList.map((jobStatus) => (
                      <MenuItem key={jobStatus} value={jobStatus}>
                        {jobStatus}
                      </MenuItem>
                    ))}
                  </Select>
                  {formik.touched.preferences?.job_search_status &&
                    formik.errors.preferences?.job_search_status && (
                      <Typography variant="caption" color="error">
                        {formik.errors.preferences.job_search_status}
                      </Typography>
                    )}
                </FormControl>
              </Grid>
              <DialogActions>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    mr: 2,
                    mb: 1,
                  }}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{
                      backgroundColor: "primary.main",
                      color: "white",
                      "&:hover": {
                        backgroundColor: "primary.main",
                      },
                    }}
                  >
                    <Typography
                      component="span"
                      variant="body1"
                      sx={{
                        fontSize: "15px",
                        fontWeight: "bold",
                        textTransform: "none",
                      }}
                    >
                      {t("employee.job-offers.btn_Save")}
                    </Typography>
                  </Button>
                </Box>
              </DialogActions>
            </Box>
          </DialogContent>
        </Dialog>
      </Box>

      <Box>
        <Box sx={{ display: "flex" }}>
          <Typography
            ml={1}
            sx={{
              mt: 2,
              fontWeight: "600",
              fontSize: "22px",
              marginLeft: "-4px",
              marginBottom: "15px",
            }}
          >
            {t("onboarding.preferences.location")}
          </Typography>

          <Typography
            component="span"
            variant="body1"
            onClick={() => setOpenLocation(true)}
            sx={{
              backgroundColor: "#F5F5F5",
              color: "primary.main",
              "&:hover": {
                backgroundColor: "inherit",
              },
              marginLeft: "auto",
              mt: { xs: 0.5, sm: 0 },
              fontSize: "15px",
              fontWeight: "bold",
              textTransform: "none",
              color: "primary.main",
              padding: "2px",
              mt: 2,
            }}
          >
            {t("employee.job-offers.Edit")}
            <EditIcon sx={{ mr: 1, ml: 1, fontSize: "16px" }} />
          </Typography>
        </Box>

        <InputLabel
          mt={2}
          sx={{
            fontWeight: "600",
            fontSize: "16px",
            color: "text.primary",
            ml: -0.5,
          }}
        >
          {t("onboarding.preferences.remote_work_question")}
        </InputLabel>
        <Box
          sx={{
            mb: 2,
            p: 2,
            border: "1px solid #d9d9d9",
            borderRadius: "4px",
            backgroundColor: "#f5f5f5",
            color: "black",
          }}
        >
          <Typography variant="body1">
            {formik.values.preferences?.remote_work === "0" ? "No" : "Yes"}
          </Typography>
        </Box>
        <InputLabel
          mt={2}
          sx={{
            fontWeight: "600",
            fontSize: "16px",
            color: "text.primary",
            ml: -0.5,
          }}
        >
          {t("onboarding.preferences.relocation")}
        </InputLabel>
        <Box
          sx={{
            mb: 2,
            p: 2,
            border: "1px solid #d9d9d9",
            borderRadius: "4px",
            backgroundColor: "#f5f5f5",
            color: "black",
          }}
        >
          <Typography variant="body1">
            {formik.values.preferences?.relocation === "0" ? "No" : "Yes"}
          </Typography>
        </Box>

        <Dialog open={openLocation} onClose={() => setOpenLocation(false)}>
          <DialogTitle
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography ml={1} sx={{ fontWeight: "600", fontSize: "20px" }}>
              {t("employee.job-offers.Edit Location")}
            </Typography>

            <IconButton onClick={() => setOpenLocation(false)}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <Divider
            sx={{ width: "90%", color: "text.secondary", mb: 1, mx: "auto" }}
          />
          <DialogContent>
            <Box component="form" onSubmit={formik.handleSubmit}>
              <InputLabel
                mt={2}
                sx={{
                  fontWeight: "600",
                  fontSize: "16px",
                  color: "text.primary",
                }}
              >
                {t("onboarding.preferences.remote_work_question")}
              </InputLabel>
              <Grid item xs={12}>
                <FormControl
                  component="fieldset"
                  sx={{ display: "flex", flexDirection: "column" }}
                >
                  <RadioGroup
                    aria-label="remoteCandidates"
                    name="preferences.remote_work"
                    value={formik.values.preferences.remote_work}
                    onChange={(event) => {
                      formik.setFieldValue(
                        "preferences.remote_work",
                        event.target.value
                      );
                    }}
                    sx={{ flexDirection: "row" }}
                  >
                    <FormControlLabel
                      value="1"
                      control={
                        <Radio
                          sx={{
                            color: "gray",
                            "&.Mui-checked": { color: "secondary.main" },
                          }}
                        />
                      }
                      label={t("common.yes")}
                      sx={{ marginRight: "20px" }}
                    />
                    <FormControlLabel
                      value="0"
                      control={
                        <Radio
                          sx={{
                            color: "gray",
                            "&.Mui-checked": { color: "secondary.main" },
                          }}
                        />
                      }
                      label={t("common.no")}
                      sx={{ marginRight: "20px" }}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>

              <InputLabel
                mt={2}
                sx={{
                  fontWeight: "600",
                  fontSize: "16px",
                  color: "text.primary",
                }}
              >
                {t("onboarding.preferences.relocation")}
              </InputLabel>
              <Grid item xs={12}>
                <FormControl
                  component="fieldset"
                  sx={{ display: "flex", flexDirection: "column" }}
                >
                  <RadioGroup
                    aria-label="relocation"
                    name="preferences.relocation"
                    value={formik.values.preferences.relocation}
                    onChange={(event) => {
                      formik.setFieldValue(
                        "preferences.relocation",
                        event.target.value
                      );
                    }}
                    sx={{ flexDirection: "row" }}
                  >
                    <FormControlLabel
                      value="1"
                      control={
                        <Radio
                          sx={{
                            color: "gray",
                            "&.Mui-checked": { color: "secondary.main" },
                          }}
                        />
                      }
                      label={t("common.yes")}
                      sx={{ marginRight: "20px" }}
                    />
                    <FormControlLabel
                      value="0"
                      control={
                        <Radio
                          sx={{
                            color: "gray",
                            "&.Mui-checked": { color: "secondary.main" },
                          }}
                        />
                      }
                      label={t("common.no")}
                      sx={{ marginRight: "20px" }}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>

              <DialogActions>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    mr: 2,
                    mb: 1,
                  }}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{
                      backgroundColor: "primary.main",
                      color: "white",
                      "&:hover": {
                        backgroundColor: "primary.main",
                      },
                    }}
                  >
                    <Typography
                      component="span"
                      variant="body1"
                      sx={{
                        fontSize: "15px",
                        fontWeight: "bold",
                        textTransform: "none",
                      }}
                    >
                      {t("employee.job-offers.btn_Save")}
                    </Typography>
                  </Button>
                </Box>
              </DialogActions>
            </Box>
          </DialogContent>
        </Dialog>
      </Box>

      <Box>
        <Box sx={{ display: "flex", mt: 4 }}>
          <Typography
            ml={1}
            sx={{
              mt: 2,
              fontWeight: "600",
              fontSize: "22px",
              marginLeft: "-4px",
              marginBottom: "15px",
            }}
          >
            {t("employee.aboutyou.salaryexpectation")}
          </Typography>

          <Typography
            component="span"
            variant="body1"
            onClick={() => setOpenSalary(true)}
            sx={{
              backgroundColor: "#F5F5F5",
              color: "primary.main",
              "&:hover": {
                backgroundColor: "inherit",
              },
              marginLeft: "auto",
              mt: { xs: 0.5, sm: 0 },
              fontSize: "15px",
              fontWeight: "bold",
              textTransform: "none",
              color: "primary.main",
              padding: "2px",
              mt: 2,
            }}
          >
            {t("employee.job-offers.Edit")}
            <EditIcon sx={{ mr: 1, ml: 1, fontSize: "16px" }} />
          </Typography>
        </Box>

        <strong> {t("employee.aboutyou.From")}:</strong>
        <Box
          sx={{
            mb: 2,
            p: 2,
            border: "1px solid #d9d9d9",
            borderRadius: "4px",
            backgroundColor: "#f5f5f5",
            color: "black",
          }}
        >
          <Typography variant="body1">
            {formik.values.about_you?.salary_range ? (
              <Typography
                ml={1}
                sx={{ fontSize: "14px", color: "text.primary", ml: -0.3 }}
              >
                {formik.values.about_you.salary_range.from}
              </Typography>
            ) : (
              <Typography
                ml={1}
                sx={{ fontSize: "14px", color: "text.primary" }}
              >
                N/A
              </Typography>
            )}
          </Typography>
        </Box>
        <strong> {t("employee.aboutyou.To")}:</strong>
        <Box
          sx={{
            mb: 2,
            p: 2,
            border: "1px solid #d9d9d9",
            borderRadius: "4px",
            backgroundColor: "#f5f5f5",
            color: "black",
          }}
        >
          <Typography variant="body1">
            {formik.values.about_you?.salary_range ? (
              <Typography
                ml={1}
                sx={{ fontSize: "14px", color: "text.primary", ml: -0.3 }}
              >
                {formik.values.about_you.salary_range.to}
              </Typography>
            ) : (
              <Typography
                ml={1}
                sx={{ fontSize: "14px", color: "text.primary" }}
              >
                N/A
              </Typography>
            )}
          </Typography>
        </Box>
        <Dialog open={openSalary} onClose={() => setOpenSalary(false)}>
          <DialogTitle
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography ml={1} sx={{ fontWeight: "600", fontSize: "20px" }}>
              {t("employee.job-offers.Edit Salary Expectation")}
            </Typography>
            <IconButton onClick={() => setOpenSalary(false)}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <Divider
            sx={{ width: "90%", color: "text.secondary", mb: 1, mx: "auto" }}
          />
          <DialogContent>
            <Box>
              <Box component="form" onSubmit={formik.handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <InputLabel
                      mt={2}
                      sx={{
                        fontWeight: "600",
                        fontSize: "14px",
                        color: "text.primary",
                        mb: 0.5,
                      }}
                    >
                      {t("employee.aboutyou.range")}
                    </InputLabel>
                    <TextField
                      type="number"
                      fullWidth
                      placeholder="From"
                      name="about_you.salary_range.from"
                      value={formik.values.about_you.salary_range.from}
                      onChange={handleInputSalaryChange}
                    />

                    {formik.touched.about_you?.salary_range?.from &&
                      formik.errors.about_you?.salary_range?.from && (
                        <Typography
                          variant="body2"
                          sx={{
                            color: "#C70039",
                            textAlign: "left",
                            fontSize: "12px",
                          }}
                        >
                          {formik.errors.about_you.salary_range.from}
                        </Typography>
                      )}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <InputLabel
                      mt={2}
                      sx={{
                        fontWeight: "600",
                        fontSize: "14px",
                        color: "text.primary",
                        mb: 0.5,
                      }}
                    >
                      {t("employee.aboutyou.To")} *
                    </InputLabel>
                    <TextField
                      type="number"
                      fullWidth
                      InputLabelProps={{
                        shrink: false,
                        style: { fontSize: "14px", color: "gray" },
                      }}
                      placeholder="To"
                      name="about_you.salary_range.to"
                      value={formik.values.about_you.salary_range.to}
                      onChange={handleInputSalaryChange}
                    />
                    {formik.touched.about_you?.salary_range?.to &&
                      formik.errors.about_you?.salary_range?.to && (
                        <Typography
                          variant="body2"
                          sx={{
                            color: "#C70039",
                            textAlign: "left",
                            fontSize: "12px",
                          }}
                        >
                          {formik.errors.about_you.salary_range.to}
                        </Typography>
                      )}
                  </Grid>
                </Grid>
                <DialogActions>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      mr: 2,
                      mb: 1,
                    }}
                  >
                    <Button
                      type="submit"
                      variant="contained"
                      sx={{
                        backgroundColor: "primary.main",
                        color: "white",
                        "&:hover": {
                          backgroundColor: "primary.main",
                        },
                      }}
                    >
                      <Typography
                        component="span"
                        variant="body1"
                        sx={{
                          fontSize: "15px",
                          fontWeight: "bold",
                          textTransform: "none",
                        }}
                      >
                        {t("employee.job-offers.btn_Save")}
                      </Typography>
                    </Button>
                  </Box>
                </DialogActions>
              </Box>
            </Box>
          </DialogContent>
        </Dialog>
      </Box>

      <Box>
        <Box sx={{ display: "flex", mt: 4 }}>
          <Typography
            ml={1}
            sx={{
              mt: 2,
              fontWeight: "600",
              fontSize: "22px",
              marginLeft: "-4px",
              marginBottom: "15px",
            }}
          >
            {t("employee.aboutyou.Links")}
          </Typography>

          <Typography
            component="span"
            variant="body1"
            onClick={() => setOpenLinks(true)}
            sx={{
              backgroundColor: "#F5F5F5",
              color: "primary.main",
              "&:hover": {
                backgroundColor: "inherit",
              },
              marginLeft: "auto",
              mt: { xs: 0.5, sm: 0 },
              fontSize: "15px",
              fontWeight: "bold",
              textTransform: "none",
              color: "primary.main",
              padding: "2px",
              mt: 2,
            }}
          >
            {t("employee.job-offers.Edit")}
            <EditIcon sx={{ mr: 1, ml: 1, fontSize: "16px" }} />
          </Typography>
        </Box>
        <Box>
          <Typography
            component="h2"
            variant="body1"
            sx={{
              alignSelf: "flex-start",
              fontSize: "14px",
              fontWeight: "bold",
              textTransform: "none",
              color: "black",
              paddingTop: "5px",
              mb: 0.5,
            }}
          >
            {t("employee.aboutyou.LinkedIn Profile URL")}
          </Typography>
          <Box
            sx={{
              mb: 2,
              p: 2,
              border: "1px solid #d9d9d9",
              borderRadius: "4px",
              backgroundColor: "#f5f5f5",
              color: "black",
            }}
          >
            <Typography variant="body1">
              {formik.values.about_you?.linkedin_url || "N/A"}
            </Typography>
          </Box>

          <Typography
            component="h2"
            variant="body1"
            sx={{
              alignSelf: "flex-start",
              fontSize: "14px",
              fontWeight: "bold",
              textTransform: "none",
              color: "black",
              paddingTop: "5px",
              mb: 0.5,
            }}
          >
            {t("employee.aboutyou.Personal Blog URL")}
          </Typography>
          <Box
            sx={{
              mb: 2,
              p: 2,
              border: "1px solid #d9d9d9",
              borderRadius: "4px",
              backgroundColor: "#f5f5f5",
              color: "black",
            }}
          >
            <Typography variant="body1">
              {formik.values.about_you?.personal_blog_url || "N/A"}
            </Typography>
          </Box>
        </Box>

        <Dialog open={openLinks} onClose={() => setOpenLinks(false)}>
          <DialogTitle
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography ml={1} sx={{ fontWeight: "600", fontSize: "20px" }}>
              {t("employee.job-offers.Edit Links")}
            </Typography>
            <IconButton onClick={() => setOpenLinks(false)}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <Divider
            sx={{ width: "90%", color: "text.secondary", mb: 1, mx: "auto" }}
          />
          <DialogContent>
            <Box component="form" onSubmit={formik.handleSubmit}>
              <Typography
                component="h2"
                variant="body1"
                sx={{
                  alignSelf: "flex-start",
                  fontSize: "14px",
                  fontWeight: "bold",
                  textTransform: "none",
                  color: "black",
                  paddingTop: "5px",
                  mb: 0.5,
                }}
              >
                {t("employee.aboutyou.LinkedIn Profile URL")}
              </Typography>
              <TextField
                name="about_you.linkedin_url"
                value={formik.values.about_you.linkedin_url}
                onChange={handleChange}
                placeholder={t("employee.aboutyou.LinkedIn Profile URL")}
                variant="outlined"
                InputLabelProps={{
                  shrink: false,
                  style: {
                    fontSize: "14px",
                    color: "gray",
                  },
                }}
                fullWidth
                sx={{ mb: 2 }}
              />
              <Typography
                component="h2"
                variant="body1"
                sx={{
                  alignSelf: "flex-start",
                  fontSize: "14px",
                  fontWeight: "bold",
                  textTransform: "none",
                  color: "black",
                  paddingTop: "5px",
                  mb: 0.5,
                }}
              >
                {t("employee.aboutyou.Personal Blog URL")}
              </Typography>
              <TextField
                name="about_you.personal_blog_url"
                value={formik.values.about_you.personal_blog_url}
                onChange={handleChange}
                placeholder="Personal Blog URL"
                variant="outlined"
                InputLabelProps={{
                  shrink: false,
                  style: {
                    fontSize: "14px",
                    color: "gray",
                  },
                }}
                fullWidth
                sx={{ mb: 2 }}
              />
              <DialogActions>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    mr: 2,
                    mb: 1,
                  }}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{
                      backgroundColor: "primary.main",
                      color: "white",
                      "&:hover": {
                        backgroundColor: "primary.main",
                      },
                    }}
                  >
                    <Typography
                      component="span"
                      variant="body1"
                      sx={{
                        fontSize: "15px",
                        fontWeight: "bold",
                        textTransform: "none",
                      }}
                    >
                      {t("employee.job-offers.btn_Save")}
                    </Typography>
                  </Button>
                </Box>
              </DialogActions>
            </Box>
          </DialogContent>
        </Dialog>
      </Box>

      {/* Work Experience Section */}
      <Box sx={{ display: "flex", mt: 4 }}>
        <Typography
          ml={1}
          sx={{
            mt: 2,
            fontWeight: "600",
            fontSize: "22px",
            marginLeft: "-4px",
            marginBottom: "15px",
          }}
        >
          {t("employee.job-offers.Work Experience")}
        </Typography>

        <Typography
          component="span"
          variant="body1"
          onClick={() => setOpenWorkExperience(true)}
          sx={{
            backgroundColor: "#F5F5F5",
            color: "primary.main",
            "&:hover": {
              backgroundColor: "inherit",
            },
            marginLeft: "auto",
            mt: { xs: 0.5, sm: 0 },
            fontSize: "15px",
            fontWeight: "bold",
            textTransform: "none",
            color: "primary.main",
            padding: "2px",
            mt: 2,
          }}
        >
          {t("employee.job-offers.Edit")}
          <EditIcon sx={{ mr: 1, ml: 1, fontSize: "16px" }} />
        </Typography>
      </Box>
      {formik.values.background?.work_experience ? (
        (() => {
          const parsedWorkExperience = formik.values.background.work_experience;
          const allCompanies = parsedWorkExperience
            .map((exp) => exp.company_name || "N/A")
            .join(", ");
          const allJobTitles = parsedWorkExperience
            .map((exp) => exp.job_title || "N/A")
            .join(", ");
          const allStartDates = parsedWorkExperience
            .map((exp) => exp.start || "N/A")
            .join(", ");
          const allEndDates = parsedWorkExperience
            .map((exp) => exp.end || "N/A")
            .join(", ");
          const allDescriptions = parsedWorkExperience
            .map((exp) => exp.description || "N/A")
            .join(", ");
          return (
            <Box mt={2}>
              <Typography
                ml={1}
                sx={{ fontSize: "14px", color: "text.primary", ml: -0.5 }}
              >
                <strong>{t("employee.job-offers.Company Name")}:</strong>{" "}
                {allCompanies}
              </Typography>
              <Typography
                ml={1}
                sx={{ fontSize: "14px", color: "text.primary", ml: -0.5 }}
              >
                <strong>{t("employee.jobg-offers.Job Title")}:</strong>{" "}
                {allJobTitles}
              </Typography>

              <Typography
                ml={1}
                sx={{ fontSize: "14px", color: "text.primary", ml: -0.5 }}
              >
                <strong>{t("employee.jobg-offers.Start Date")}:</strong>{" "}
                {isNaN(new Date(allStartDates)) ? "N/A" : allStartDates}
              </Typography>
              <Typography
                ml={1}
                sx={{ fontSize: "14px", color: "text.primary", ml: -0.5 }}
              >
                <strong>{t("employee.jobg-offers.End Date")}:</strong>{" "}
                {isNaN(new Date(allEndDates)) ? "N/A" : allEndDates}
              </Typography>
              <Typography
                ml={1}
                sx={{ fontSize: "14px", color: "text.primary", ml: -0.5 }}
              >
                <strong>{t("employee.background.Description")}:</strong>{" "}
                {allDescriptions}
              </Typography>
            </Box>
          );
        })()
      ) : (
        <Typography ml={1} sx={{ fontSize: "14px", color: "text.primary" }}>
          N/A
        </Typography>
      )}

      <Box>
        <Dialog
          width="md"
          open={openWorkExperience}
          onClose={() => setOpenWorkExperience(false)}
        >
          <DialogTitle
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography ml={1} sx={{ fontWeight: "600", fontSize: "20px" }}>
              {t("employee.job-offers.Edit Work Experience")}
            </Typography>
            <IconButton onClick={() => setOpenWorkExperience(false)}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <Divider
            sx={{ width: "90%", color: "text.secondary", mb: 1, mx: "auto" }}
          />
          <DialogContent>
            <Box component="form" onSubmit={formik.handleSubmit} mt={2}>
              <Grid item xs={12}>
                <Box mt={2}>
                  <Grid container spacing={2}>
                    {formik.values.background.work_experience.map(
                      (experience, index) => (
                        <Grid item xs={12} key={index}>
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                              <InputLabel
                                mt={2}
                                sx={{
                                  alignSelf: "flex-start",
                                  fontWeight: "600",
                                  fontSize: "14px",
                                  color: "text.primary",
                                  mb: 0.5,
                                }}
                              >
                                {t("employee.background.Company Name")}
                              </InputLabel>
                              <TextField
                                name={`background.work_experience[${index}].company_name`}
                                placeholder={t(
                                  "employee.background.Company Name"
                                )}
                                variant="outlined"
                                fullWidth
                                value={experience.company_name}
                                onChange={(e) =>
                                  handleWorkExperienceChange(
                                    index,
                                    "company_name",
                                    e.target.value
                                  )
                                }
                                sx={{ mb: 2 }}
                              />
                              {formik.touched.background?.work_experience?.[
                                index
                              ]?.company_name &&
                              formik.errors.background?.work_experience?.[index]
                                ?.company_name ? (
                                <Typography
                                  variant="body2"
                                  sx={{
                                    color: "#C70039",
                                    textAlign: "left",
                                    fontSize: "12px",
                                  }}
                                >
                                  {
                                    formik.errors.background.work_experience[
                                      index
                                    ].company_name
                                  }
                                </Typography>
                              ) : null}
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                              <InputLabel
                                mt={2}
                                sx={{
                                  alignSelf: "flex-start",
                                  fontWeight: "600",
                                  fontSize: "14px",
                                  color: "text.primary",
                                }}
                              >
                                {t("employee.background.Job Title")}
                              </InputLabel>
                              <TextField
                                name={`background.work_experience[${index}].job_title`}
                                placeholder={t("employee.background.Job Title")}
                                variant="outlined"
                                fullWidth
                                value={experience.job_title}
                                onChange={(e) =>
                                  handleWorkExperienceChange(
                                    index,
                                    "job_title",
                                    e.target.value
                                  )
                                }
                                sx={{ mb: 2 }}
                              />
                              {formik.touched.background?.work_experience?.[
                                index
                              ]?.job_title &&
                              formik.errors.background?.work_experience?.[index]
                                ?.job_title ? (
                                <Typography
                                  variant="body2"
                                  sx={{
                                    color: "#C70039",
                                    textAlign: "left",
                                    fontSize: "12px",
                                  }}
                                >
                                  {
                                    formik.errors.background.work_experience[
                                      index
                                    ].job_title
                                  }
                                </Typography>
                              ) : null}
                            </Grid>

                            {!experience.is_current && (
                              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <InputLabel
                                  mt={2}
                                  sx={{
                                    fontWeight: "600",
                                    fontSize: "16px",
                                    color: "text.primary",
                                    mb: 0.5,
                                  }}
                                >
                                  {t("employee.background.Started")}
                                </InputLabel>

                                <Box
                                  sx={{
                                    position: "relative",
                                    py: "20px",
                                    mr: 2,
                                  }}
                                >
                                  <div className="calendar">
                                    <Grid item xs={12}>
                                      <LocalizationProvider
                                        dateAdapter={AdapterDayjs}
                                      >
                                        <DatePicker
                                          renderInput={(props) => (
                                            <TextField {...props} />
                                          )}
                                          name={`background.work_experience[${index}].start`}
                                          value={
                                            experience.start
                                              ? dayjs(experience.start)
                                              : null
                                          } // Ensure `value` is a `dayjs` object
                                          onChange={(newValue) =>
                                            handleStartDateChange(
                                              index,
                                              newValue
                                            )
                                          }
                                          inputFormat="MM/dd/yyyy"
                                          views={["month", "year"]}
                                          sx={{ width: "100%" }}
                                        />
                                      </LocalizationProvider>
                                    </Grid>
                                  </div>
                                </Box>
                              </Grid>
                            )}
                            <Box></Box>
                            {!experience.is_current && (
                              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <InputLabel
                                  mt={2}
                                  sx={{
                                    fontWeight: "600",
                                    fontSize: "16px",
                                    color: "text.primary",
                                    mb: 0.5,
                                  }}
                                >
                                  Finished
                                </InputLabel>

                                <Box
                                  sx={{
                                    position: "relative",
                                    py: "20px",
                                    mr: 2,
                                  }}
                                >
                                  <div className="calendar">
                                    <Grid item xs={12}>
                                      <LocalizationProvider
                                        dateAdapter={AdapterDayjs}
                                      >
                                        <DatePicker
                                          renderInput={(props) => (
                                            <TextField {...props} />
                                          )}
                                          name={`background.work_experience[${index}].end`}
                                          value={
                                            experience.end
                                              ? dayjs(experience.end)
                                              : null
                                          } // Ensure `value` is a `dayjs` object
                                          onChange={(newValue) =>
                                            handleEndDateChange(index, newValue)
                                          }
                                          inputFormat="MM/dd/yyyy"
                                          views={["month", "year"]}
                                          sx={{ width: "100%" }}
                                        />
                                      </LocalizationProvider>
                                    </Grid>
                                  </div>
                                </Box>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      sx={{
                                        alignSelf: "flex-start",
                                        color: "gray",
                                        "&.Mui-checked": {
                                          color: "secondary.main",
                                        },
                                      }}
                                      name={`background.work_experience[${index}].is_current`}
                                      checked={experience.is_current}
                                      onChange={(e) =>
                                        handleWorkExperienceChange(
                                          index,
                                          "is_current",
                                          e.target.checked
                                        )
                                      }
                                    />
                                  }
                                  label={t("employee.background.current")}
                                />
                              </Grid>
                            )}
                            {experience.is_current && (
                              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <InputLabel
                                  mt={2}
                                  sx={{
                                    fontWeight: "600",
                                    fontSize: "16px",
                                    color: "text.primary",
                                    mb: 0.5,
                                  }}
                                >
                                  {t("employee.background.Started")}
                                </InputLabel>

                                <Box
                                  sx={{
                                    position: "relative",
                                    py: "20px",
                                    mr: 2,
                                  }}
                                >
                                  <div className="calendar">
                                    <Grid item xs={12}>
                                      <LocalizationProvider
                                        dateAdapter={AdapterDayjs}
                                      >
                                        <DatePicker
                                          renderInput={(props) => (
                                            <TextField {...props} />
                                          )}
                                          name={`background.work_experience[${index}].start`}
                                          value={
                                            experience.start
                                              ? dayjs(experience.start)
                                              : null
                                          } // Ensure `value` is a `dayjs` object
                                          onChange={(newValue) =>
                                            handleStartDateChange(
                                              index,
                                              newValue
                                            )
                                          }
                                          inputFormat="MM/dd/yyyy"
                                          views={["month", "year"]}
                                          sx={{ width: "100%" }}
                                        />
                                      </LocalizationProvider>
                                    </Grid>
                                  </div>
                                </Box>
                              </Grid>
                            )}
                            <Box></Box>
                            {experience.is_current && (
                              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <Box
                                  sx={{
                                    position: "relative",
                                    py: "20px",
                                    ml: 10,
                                    mt: 4,
                                  }}
                                >
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        sx={{
                                          alignSelf: "flex-start",
                                          color: "gray",
                                          "&.Mui-checked": {
                                            color: "secondary.main",
                                          },
                                        }}
                                        name={`background.work_experience[${index}].is_current`}
                                        checked={experience.is_current}
                                        onChange={(e) =>
                                          handleWorkExperienceChange(
                                            index,
                                            "is_current",
                                            e.target.checked
                                          )
                                        }
                                      />
                                    }
                                    label={t("employee.background.current")}
                                  />
                                </Box>
                              </Grid>
                            )}

                            <Grid item xs={12}>
                              <Typography
                                component="h2"
                                variant="body1"
                                sx={{
                                  alignSelf: "flex-start",
                                  fontSize: "14px",
                                  fontWeight: "bold",
                                  textTransform: "none",
                                  color: "black",
                                  paddingTop: "5px",
                                  mb: 0.5,
                                }}
                              >
                                {t("employee.background.Description")}
                              </Typography>
                              <TextField
                                name={`background.work_experience[${index}].description`}
                                placeholder={t(
                                  "employee.background.Description"
                                )}
                                variant="outlined"
                                fullWidth
                                multiline
                                rows={3}
                                value={experience.description}
                                onChange={(e) =>
                                  handleWorkExperienceChange(
                                    index,
                                    "description",
                                    e.target.value
                                  )
                                }
                                sx={{ mb: 2 }}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <Button
                                onClick={() =>
                                  handleRemoveWorkExperience(index)
                                }
                                variant="standard"
                                sx={{
                                  mb: 2,
                                  backgroundColor: "secondary.main",
                                  color: "text.secondary",
                                  "&:hover": {
                                    backgroundColor: "secondary.main",
                                  },
                                }}
                              >
                                {t("employee.background.Remove")}
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                      )
                    )}
                    <Grid item xs={12}>
                      <Button
                        type="button"
                        onClick={handleAddWorkExperience}
                        variant="standard"
                        sx={{
                          backgroundColor: "white",
                          color: "primary.main",
                          "&:hover": {
                            backgroundColor: "white",
                          },
                        }}
                      >
                        <AddIcon
                          sx={{ mt: -0.5, ml: -3, color: "primary.main" }}
                        />
                        <Typography
                          component="span"
                          variant="body1"
                          sx={{
                            fontSize: "15px",
                            fontWeight: "bold",
                            textTransform: "none",
                            color: "primary.main",
                            padding: "2px",
                            backgroundColor: "white",
                          }}
                        >
                          {t("employee.background.Add More")}
                        </Typography>
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <DialogActions>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    mr: 2,
                    mb: 1,
                  }}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{
                      backgroundColor: "primary.main",
                      color: "white",
                      "&:hover": {
                        backgroundColor: "primary.main",
                      },
                    }}
                  >
                    <Typography
                      component="span"
                      variant="body1"
                      sx={{
                        fontSize: "15px",
                        fontWeight: "bold",
                        textTransform: "none",
                      }}
                    >
                      {t("employee.job-offers.btn_Save")}
                    </Typography>
                  </Button>
                </Box>
              </DialogActions>
            </Box>
          </DialogContent>
        </Dialog>
      </Box>

      {/* Education Section */}
      <Box sx={{ display: "flex", mt: 4 }}>
        <Typography
          component="span"
          variant="body1"
          onClick={() => setOpenEducation(true)}
          sx={{
            backgroundColor: "#F5F5F5",
            color: "primary.main",
            "&:hover": {
              backgroundColor: "inherit",
            },
            marginLeft: "auto",
            mt: { xs: 0.5, sm: 0 },
            fontSize: "15px",
            fontWeight: "bold",
            textTransform: "none",
            color: "primary.main",
            padding: "2px",
            mt: 2,
          }}
        >
          {t("employee.job-offers.Edit")}
          <EditIcon sx={{ mr: 1, ml: 1, fontSize: "16px" }} />
        </Typography>
      </Box>
      <Box>
        <Typography
          ml={1}
          sx={{
            mt: 2,
            fontWeight: "600",
            fontSize: "22px",
            marginLeft: "-4px",
            marginBottom: "15px",
          }}
        >
          {t("employee.background.Education")}
        </Typography>
        {formik.values.background?.education ? (
          (() => {
            const parsedEducation = formik.values.background.education;
            const allUniversities = parsedEducation
              .map((edu) => edu.university_or_collage || "N/A")
              .join(", ");
            const allGraduationYears = parsedEducation
              .map((edu) => edu.graduation_year || "N/A")
              .join(", ");
            const allFieldsOfStudy = parsedEducation
              .map((edu) => edu.field_of_study || "N/A")
              .join(", ");
            const allDegrees = parsedEducation
              .map((edu) => edu.degree || "N/A")
              .join(", ");
            return (
              <Box mt={2}>
                <Typography
                  ml={1}
                  sx={{ fontSize: "14px", color: "text.primary", ml: -0.5 }}
                >
                  <strong>
                    {t("employee.profile.Education.University or College")}:
                  </strong>{" "}
                  {allUniversities}
                </Typography>
                <Typography
                  ml={1}
                  sx={{ fontSize: "14px", color: "text.primary", ml: -0.5 }}
                >
                  <strong>
                    {t("employee.profile.Education.Graduation Year")}:
                  </strong>{" "}
                  {allGraduationYears}
                </Typography>
                <Typography
                  ml={1}
                  sx={{ fontSize: "14px", color: "text.primary", ml: -0.5 }}
                >
                  <strong>
                    {t("employee.profile.Education.Field of Study")}:
                  </strong>{" "}
                  {allFieldsOfStudy}
                </Typography>
                <Typography
                  ml={1}
                  sx={{ fontSize: "14px", color: "text.primary", ml: -0.5 }}
                >
                  <strong>{t("employee.profile.Education.Degree")}:</strong>{" "}
                  {allDegrees}
                </Typography>
              </Box>
            );
          })()
        ) : (
          <Typography ml={1} sx={{ fontSize: "14px", color: "text.primary" }}>
            N/A
          </Typography>
        )}

        {/* Add Education Details */}
      </Box>
      <Dialog open={openEducation} onClose={() => setOpenEducation(false)}>
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography ml={1} sx={{ fontWeight: "600", fontSize: "20px" }}>
            {t("employee.profile.Edit Education")}
          </Typography>
          <IconButton onClick={() => setOpenEducation(false)}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Divider
          sx={{ width: "90%", color: "text.secondary", mb: 1, mx: "auto" }}
        />
        <DialogContent>
          <Box component="form" onSubmit={formik.handleSubmit} mt={2}>
            <Grid item xs={12}>
              <Box mt={2}>
                <Grid container spacing={2}>
                  {formik.values.background.education.map(
                    (education, index) => (
                      <Grid item xs={12} key={index}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={6}>
                            <InputLabel
                              mt={2}
                              sx={{
                                fontWeight: "600",
                                fontSize: "14px",
                                color: "text.primary",
                                mb: 0.5,
                              }}
                            >
                              {t("employee.background.University/College *")}
                            </InputLabel>
                            <TextField
                              name={`background.education[${index}].university_or_collage`}
                              value={education.university_or_collage}
                              onChange={(e) =>
                                handleEducationChange(
                                  index,
                                  "university_or_collage",
                                  e.target.value
                                )
                              }
                              placeholder={t(
                                "employee.background.Universityplaceholder *"
                              )}
                              variant="outlined"
                              fullWidth
                              sx={{ mb: 2 }}
                            />
                            {formik.touched.background?.education?.[index]
                              ?.university_or_collage &&
                            formik.errors.background?.education?.[index]
                              ?.university_or_collage ? (
                              <Typography
                                variant="body2"
                                sx={{
                                  color: "#C70039",
                                  textAlign: "left",
                                  fontSize: "12px",
                                }}
                              >
                                {
                                  formik.errors.background.education[index]
                                    .university_or_collage
                                }
                              </Typography>
                            ) : null}
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <InputLabel
                              mt={2}
                              sx={{
                                fontWeight: "600",
                                fontSize: "14px",
                                color: "text.primary",
                                mb: 0.5,
                              }}
                            >
                              {t("employee.background.Graduation Year *")}
                            </InputLabel>
                            <TextField
                              name={`background.education[${index}].graduation_year`}
                              value={education.graduation_year}
                              onChange={(e) =>
                                handleEducationChange(
                                  index,
                                  "graduation_year",
                                  e.target.value
                                )
                              }
                              placeholder={t(
                                "employee.background.GraduationYearplaceholder"
                              )}
                              variant="outlined"
                              fullWidth
                              sx={{ mb: 2 }}
                            />
                            {formik.touched.background?.education?.[index]
                              ?.graduation_year &&
                            formik.errors.background?.education?.[index]
                              ?.graduation_year ? (
                              <Typography
                                variant="body2"
                                sx={{
                                  color: "#C70039",
                                  textAlign: "left",
                                  fontSize: "12px",
                                }}
                              >
                                {
                                  formik.errors.background.education[index]
                                    .graduation_year
                                }
                              </Typography>
                            ) : null}
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <InputLabel
                              mt={2}
                              sx={{
                                fontWeight: "600",
                                fontSize: "14px",
                                color: "text.primary",
                                mb: 0.5,
                              }}
                            >
                              {t("employee.background.Field of Study *")}
                            </InputLabel>
                            <TextField
                              name={`background.education[${index}].field_of_study`}
                              value={education.field_of_study}
                              onChange={(e) =>
                                handleEducationChange(
                                  index,
                                  "field_of_study",
                                  e.target.value
                                )
                              }
                              placeholder={t(
                                "employee.background.Fieldofstudy.placeholder"
                              )}
                              variant="outlined"
                              fullWidth
                              sx={{ mb: 2 }}
                            />
                            {formik.touched.background?.education?.[index]
                              ?.field_of_study &&
                            formik.errors.background?.education?.[index]
                              ?.field_of_study ? (
                              <Typography
                                variant="body2"
                                sx={{
                                  color: "#C70039",
                                  textAlign: "left",
                                  fontSize: "12px",
                                }}
                              >
                                {
                                  formik.errors.background.education[index]
                                    .field_of_study
                                }
                              </Typography>
                            ) : null}
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <InputLabel
                              mt={2}
                              sx={{
                                fontWeight: "600",
                                fontSize: "14px",
                                color: "text.primary",
                                mb: 0.5,
                              }}
                            >
                              {t("employee.background.Degree *")}
                            </InputLabel>
                            <TextField
                              name={`background.education[${index}].degree`}
                              value={education.degree}
                              onChange={(e) =>
                                handleEducationChange(
                                  index,
                                  "degree",
                                  e.target.value
                                )
                              }
                              placeholder={t(
                                "employee.background.Select Degree"
                              )}
                              variant="outlined"
                              fullWidth
                              sx={{ mb: 2 }}
                            />
                            {formik.touched.background?.education?.[index]
                              ?.degree &&
                            formik.errors.background?.education?.[index]
                              ?.degree ? (
                              <Typography
                                variant="body2"
                                sx={{
                                  color: "#C70039",
                                  textAlign: "left",
                                  fontSize: "12px",
                                }}
                              >
                                {
                                  formik.errors.background.education[index]
                                    .degree
                                }
                              </Typography>
                            ) : null}
                          </Grid>
                          <Grid item xs={12}>
                            <Button
                              onClick={() => handleRemoveEducation(index)}
                              variant="standard"
                              sx={{
                                mb: 2,
                                backgroundColor: "secondary.main",
                                color: "text.secondary",
                                "&:hover": {
                                  backgroundColor: "secondary.main",
                                },
                              }}
                            >
                              {t("employee.background.Remove")}
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    )
                  )}
                  <Grid item xs={12}>
                    <Button
                      type="button"
                      onClick={handleAddEducation}
                      variant="standard"
                      sx={{
                        backgroundColor: "white",
                        color: "primary.main",
                        "&:hover": {
                          backgroundColor: "white",
                        },
                      }}
                    >
                      <AddIcon
                        sx={{ mt: -0.5, ml: -3, color: "primary.main" }}
                      />
                      <Typography
                        component="span"
                        variant="body1"
                        sx={{
                          fontSize: "15px",
                          fontWeight: "bold",
                          textTransform: "none",
                          color: "primary.main",
                          padding: "2px",
                          backgroundColor: "white",
                        }}
                      >
                        {t("employee.background.Add More")}
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <DialogActions>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  mr: 2,
                  mb: 1,
                }}
              >
                <Button
                  type="submit"
                  variant="contained"
                  sx={{
                    backgroundColor: "primary.main",
                    color: "white",
                    "&:hover": {
                      backgroundColor: "primary.main",
                    },
                  }}
                >
                  <Typography
                    component="span"
                    variant="body1"
                    sx={{
                      fontSize: "15px",
                      fontWeight: "bold",
                      textTransform: "none",
                    }}
                  >
                    {t("employee.job-offers.btn_Save")}
                  </Typography>
                </Button>
              </Box>
            </DialogActions>
          </Box>
        </DialogContent>
      </Dialog>

      {/* Language Section */}
      <Box sx={{ display: "flex", mt: 4 }}>
        <Typography
          component="span"
          variant="body1"
          onClick={() => setOpenLanguage(true)}
          sx={{
            backgroundColor: "#F5F5F5",
            color: "primary.main",
            "&:hover": {
              backgroundColor: "inherit",
            },
            marginLeft: "auto",
            mt: { xs: 0.5, sm: 0 },
            fontSize: "15px",
            fontWeight: "bold",
            textTransform: "none",
            color: "primary.main",
            padding: "2px",
            mt: 2,
          }}
        >
          {t("employee.job-offers.Edit")}
          <EditIcon sx={{ mr: 1, ml: 1, fontSize: "16px" }} />
        </Typography>
      </Box>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Box>
          <Typography
            ml={1}
            sx={{
              mt: 2,
              fontWeight: "600",
              fontSize: "22px",
              marginLeft: "-4px",
              marginBottom: "15px",
            }}
          >
            {t("employee.profile.Edit Language")}
          </Typography>
          {formik.values.background?.languages ? (
            (() => {
              const parsedLanguages = JSON.parse(
                JSON.stringify(formik.values.background.languages)
              );
              const allLanguages = parsedLanguages
                .map((language) => language.language || "N/A")
                .join(", ");
              const allLevels = parsedLanguages
                .map((language) => language.language_level || "N/A")
                .join(", ");
              return (
                <Box mt={2}>
                  <Typography
                    ml={1}
                    sx={{ fontSize: "14px", color: "text.primary", ml: -0.5 }}
                  >
                    <strong>{t("employee.profile.Edit Languages")}:</strong>{" "}
                    {allLanguages}
                  </Typography>
                  <Typography
                    ml={1}
                    sx={{ fontSize: "14px", color: "text.primary", ml: -0.5 }}
                  >
                    <strong>{t("employee.profile.Edit Levels")}:</strong>{" "}
                    {allLevels}
                  </Typography>
                </Box>
              );
            })()
          ) : (
            <Typography ml={1} sx={{ fontSize: "14px", color: "text.primary" }}>
              N/A
            </Typography>
          )}
        </Box>
      </Grid>
      <Dialog open={openLanguage} onClose={() => setOpenLanguage(false)}>
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography ml={1} sx={{ fontWeight: "600", fontSize: "20px" }}>
            {t("employee.profile.Edit-Languages")}
          </Typography>
          <IconButton onClick={() => setOpenLanguage(false)}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Divider
          sx={{ width: "90%", color: "text.secondary", mb: 1, mx: "auto" }}
        />
        <DialogContent>
          <Box component="form" onSubmit={formik.handleSubmit} mt={2}>
            <Grid item xs={12}>
              <Box mt={2}>
                <Grid container spacing={2}>
                  {formik.values.background.languages.map((language, index) => (
                    <Grid item xs={12} key={index}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                          <InputLabel
                            mt={2}
                            sx={{
                              fontWeight: "600",
                              fontSize: "14px",
                              color: "text.primary",
                              mb: 0.5,
                            }}
                          >
                            {t("employee.background.Language *")}
                          </InputLabel>
                          <TextField
                            placeholder={t(
                              "employee.background.Select Language"
                            )}
                            variant="outlined"
                            fullWidth
                            name={`background.languages[${index}].language`}
                            value={language.language}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            InputLabelProps={{
                              shrink: Boolean(language.language),
                              style: {
                                fontSize: "14px",
                                color: language.language ? "inherit" : "gray",
                              },
                            }}
                            sx={{ mb: 2 }}
                          />
                          {formik.touched.background?.languages?.[index]
                            ?.language &&
                            formik.errors.background?.languages?.[index]
                              ?.language && (
                              <Typography variant="body2" color="error">
                                {
                                  formik.errors.background.languages[index]
                                    .language
                                }
                              </Typography>
                            )}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <InputLabel
                            mt={2}
                            sx={{
                              fontWeight: "600",
                              fontSize: "14px",
                              color: "text.primary",
                              mb: 0.5,
                            }}
                          >
                            {t("employee.background.Level *")}
                          </InputLabel>
                          <TextField
                            placeholder={t("employee.background.Select Level")}
                            variant="outlined"
                            fullWidth
                            name={`background.languages[${index}].language_level`}
                            value={language.language_level}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            InputLabelProps={{
                              shrink: Boolean(language.language_level),
                              style: {
                                fontSize: "14px",
                                color: language.language_level
                                  ? "inherit"
                                  : "gray",
                              },
                            }}
                            sx={{ mb: 2 }}
                          />
                          {formik.touched.background?.languages?.[index]
                            ?.language_level &&
                            formik.errors.background?.languages?.[index]
                              ?.language_level && (
                              <Typography variant="body2" color="error">
                                {
                                  formik.errors.background.languages[index]
                                    .language_level
                                }
                              </Typography>
                            )}
                        </Grid>
                        <Grid item xs={12}>
                          <Button
                            onClick={() => handleRemoveLanguage(index)}
                            variant="standard"
                            sx={{
                              mb: 2,
                              backgroundColor: "secondary.main",
                              color: "text.secondary",
                              "&:hover": {
                                backgroundColor: "secondary.main",
                              },
                            }}
                          >
                            {t("employee.background.Remove")}
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  ))}
                  <Grid item xs={12}>
                    <Button
                      type="button"
                      onClick={handleAddLanguage}
                      variant="standard"
                      sx={{
                        backgroundColor: "white",
                        color: "primary.main",
                        "&:hover": {
                          backgroundColor: "white",
                        },
                      }}
                    >
                      <AddIcon
                        sx={{ mt: -0.5, ml: -3, color: "primary.main" }}
                      />
                      <Typography
                        component="span"
                        variant="body1"
                        sx={{
                          fontSize: "15px",
                          fontWeight: "bold",
                          textTransform: "none",
                          color: "primary.main",
                          padding: "2px",
                          backgroundColor: "white",
                        }}
                      >
                        {t("employee.background.Add More")}
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <DialogActions>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  mr: 2,
                  mb: 1,
                }}
              >
                <Button
                  type="submit"
                  variant="contained"
                  sx={{
                    backgroundColor: "primary.main",
                    color: "white",
                    "&:hover": {
                      backgroundColor: "primary.main",
                    },
                  }}
                >
                  <Typography
                    component="span"
                    variant="body1"
                    sx={{
                      fontSize: "15px",
                      fontWeight: "bold",
                      textTransform: "none",
                    }}
                  >
                    {t("employee.job-offers.btn_Save")}
                  </Typography>
                </Button>
              </Box>
            </DialogActions>
          </Box>
        </DialogContent>
      </Dialog>

      {/* Additional Information Section */}
      <Box sx={{ display: "flex", mt: 4 }}>
        <Typography
          component="span"
          variant="body1"
          onClick={setOpenAdditionalInfo}
          sx={{
            backgroundColor: "#F5F5F5",
            color: "primary.main",
            "&:hover": {
              backgroundColor: "inherit",
            },
            marginLeft: "auto",
            mt: { xs: 0.5, sm: 0 },
            fontSize: "15px",
            fontWeight: "bold",
            textTransform: "none",
            color: "primary.main",
            padding: "2px",
          }}
        >
          {t("employee.job-offers.Edit")}
          <EditIcon sx={{ mr: 1, ml: 1, fontSize: "16px" }} />
        </Typography>
      </Box>
      <Box sx={{ mb: 2 }}>
        <Typography
          ml={1}
          sx={{
            mt: 2,
            fontWeight: "600",
            fontSize: "22px",
            marginLeft: "-4px",
            marginBottom: "15px",
          }}
        >
          {t("employee.background.Additional Information")}
        </Typography>
        <InputLabel
          mt={2}
          sx={{ fontWeight: "600", fontSize: "16px", color: "text.primary" }}
        >
          {t("employee.background.Objective")}
        </InputLabel>
        <InputLabel mt={2} sx={{ fontSize: "14px", color: "text.primary" }}>
          {formik.values.background?.objective || "N/A"}
        </InputLabel>
        <InputLabel
          mt={2}
          sx={{ fontWeight: "600", fontSize: "16px", color: "text.primary" }}
        >
          {t("employee.background.Achievements")}
        </InputLabel>
        <InputLabel mt={2} sx={{ fontSize: "14px", color: "text.primary" }}>
          {formik.values.background?.achievements || "N/A"}
        </InputLabel>
        <InputLabel
          mt={2}
          sx={{ fontWeight: "600", fontSize: "16px", color: "text.primary" }}
        >
          {t("employee.background.Personal Characteristics")}
        </InputLabel>
        <InputLabel mt={2} sx={{ fontSize: "14px", color: "text.primary" }}>
          {formik.values.background?.personal_characteristics || "N/A"}
        </InputLabel>
      </Box>
      <Dialog
        width="md"
        open={openAdditionalInfo}
        onClose={() => setOpenAdditionalInfo(false)}
      >
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography ml={1} sx={{ fontWeight: "600", fontSize: "20px" }}>
            {t("employee.profile.Edit-info")}
          </Typography>
          <IconButton onClick={() => setOpenAdditionalInfo(false)}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Divider
          sx={{ width: "90%", color: "text.secondary", mb: 1, mx: "auto" }}
        />
        <DialogContent>
          <Box component="form" onSubmit={formik.handleSubmit}>
            <Typography
              ml={1}
              sx={{
                mt: 2,
                fontWeight: "600",
                fontSize: "22px",
                marginLeft: "-4px",
                marginBottom: "15px",
              }}
            >
              {t("employee.background.Additional Information")}
            </Typography>
            <Typography
              component="h2"
              variant="body1"
              sx={{
                alignSelf: "flex-start",
                fontSize: "14px",
                fontWeight: "bold",
                textTransform: "none",
                color: "black",
                paddingTop: "5px",
                mb: 0.5,
              }}
            >
              {t("employee.background.Objective")}
            </Typography>
            <TextField
              name="background.objective"
              value={formik.values.background.objective}
              onChange={formik.handleChange}
              placeholder={t("employee.background.Type here")}
              variant="outlined"
              fullWidth
              multiline
              rows={3}
              sx={{ mb: 2 }}
            />
            <Typography
              component="h2"
              variant="body1"
              sx={{
                alignSelf: "flex-start",
                fontSize: "14px",
                fontWeight: "bold",
                textTransform: "none",
                color: "black",
                paddingTop: "5px",
                mb: 0.5,
              }}
            >
              {t("employee.background.Achievements")}
            </Typography>
            <TextField
              name="background.achievements"
              value={formik.values.background.achievements}
              onChange={formik.handleChange}
              placeholder={t("employee.background.Type here")}
              variant="outlined"
              fullWidth
              multiline
              rows={3}
              sx={{ mb: 2 }}
            />
            <Typography
              component="h2"
              variant="body1"
              sx={{
                alignSelf: "flex-start",
                fontSize: "14px",
                fontWeight: "bold",
                textTransform: "none",
                color: "black",
                paddingTop: "5px",
                mb: 0.5,
              }}
            >
              {t("employee.background.Personal Characteristics")}
            </Typography>
            <TextField
              name="background.personal_characteristics"
              value={formik.values.background.personal_characteristics}
              onChange={formik.handleChange}
              placeholder="Type here..."
              variant="outlined"
              fullWidth
              multiline
              rows={3}
              sx={{ mb: 2 }}
            />
            <DialogActions>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  mr: 2,
                  mb: 1,
                }}
              >
                <Button
                  type="submit"
                  variant="contained"
                  sx={{
                    backgroundColor: "primary.main",
                    color: "white",
                    "&:hover": {
                      backgroundColor: "primary.main",
                    },
                  }}
                >
                  <Typography
                    component="span"
                    variant="body1"
                    sx={{
                      fontSize: "15px",
                      fontWeight: "bold",
                      textTransform: "none",
                    }}
                  >
                    {t("employee.job-offers.btn_Save")}
                  </Typography>
                </Button>
              </Box>
            </DialogActions>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
    // </Box>
  );
};

export default MyProfile;