import React from 'react';
import { useTranslation } from 'react-i18next';
import { Select, MenuItem, FormControl } from '@mui/material';

const LanguageSwitcher = ({height=30}) => {
  const { i18n } = useTranslation();

  const handleChangeLanguage = (event) => {
    const selectedLanguage = event.target.value;
    i18n.changeLanguage(selectedLanguage); // Update the language
  };

  return (
    <FormControl
      variant="outlined"
      sx={{
        minWidth: 170,
        height: height,
        '.MuiOutlinedInput-root': {
          borderColor: '#8EFFC2', // Green border color
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#8EFFC2', // Green on hover
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#8EFFC2', // Green when focused
          },
        },
        mb: 1,
      }}
    >
      <Select
        value={i18n.language}
        onChange={handleChangeLanguage}
        displayEmpty
        inputProps={{ 'aria-label': 'Language Switcher' }}
        sx={{
          height: 30,
          color: '#8EFFC2', // Text color
          '.MuiOutlinedInput-notchedOutline': {
            borderColor: '#8EFFC2', // Green border
          },
          '.MuiSvgIcon-root': {
            color: '#8EFFC2', // Dropdown arrow color
          },
        }}
      >
        <MenuItem value="en">English</MenuItem>
        <MenuItem value="sp">Spanish</MenuItem>
      </Select>
    </FormControl>
  );
};

export default LanguageSwitcher;
