import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  Grid,
  TextField,
  Divider,
} from "@mui/material";
import { OnBoardingApi, UploadImageApi } from "../../../../apis";
import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import UploadImage from "../../../../components/GlobalComponent/UploadImage";
import PhoneInput from "react-phone-input-2"; // Import the PhoneInput component
import "react-phone-input-2/lib/style.css"; // Import the default styles
import { useTranslation } from "react-i18next";
const RecruiterProfile = ({ formik }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [profilePhoto, setProfilePhoto] = useState(null);
  const [profilePhotoPreview, setProfilePhotoPreview] = useState(null);
  const [companyProfilePhotoPreview, setCompanyProfilePhotoPreview] =
    useState(null);

  const submitForm = async (values) => {
    try {
      const profilePhotoName = await handleProfilePhotoUpload(); // Upload the profile photo and get the URL data
      if (profilePhotoName) {
        values.recruiter_profile.profile_photo_url = profilePhotoName; // Set profile_photo_url to the photo name
      }
      const res = await OnBoardingApi.OnBoarding(values);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    const fetchOnBoardedUserData = async () => {
      try {
        const res = await OnBoardingApi.getOnBoarding();

        if (res.data && res.data.profile) {
          let companyPerks = {};
          try {
            companyPerks = res.data.profile.company_perks
              ? JSON.parse(res.data.profile.company_perks)
              : {};
          } catch (error) {
            console.error("Error parsing company perks JSON:", error);
            companyPerks = {};
          }

          formik.setValues({
            ...formik.values,
            recruiter_profile: {
              full_name: res.data.profile.full_name || "",
              phone_number: res.data.profile.phone_number || "",
              linkedin_url: res.data.profile.linkedin_url || "",
              profile_photo_url: res.data.profile.profile_photo_url || "",
            },
            company_profile: {
              company_profile_photo_url:
                res.data.profile.company_profile_photo_url || "",
              company_name: res.data.profile.company_name || "",
              company_slogan: res.data.profile.company_slogan || "",
              company_location: res.data.profile.company_location || "",
              company_size: res.data.profile.company_size || "",
              company_domain: res.data.profile.company_domain || "",
              company_overview: res.data.profile.company_overview || "",
              company_website: res.data.profile.company_website || "",
              company_perks: res.data.profile.company_perks || companyPerks,
            },
          });

          setProfilePhotoPreview(
            res.data.profile.profile_photo_url
              ? res.data.profile.profile_photo_url.path
              : null
          );
          setCompanyProfilePhotoPreview(
            res.data.profile.company_profile_photo_url
              ? res.data.profile.company_profile_photo_url.path
              : null
          );
        } else {
          console.log("No data available in the database");
        }
        setLoading(false);
      } catch (error) {
        console.error("Error fetching on-boarded user data:", error);
        setLoading(false);
      }
    };

    fetchOnBoardedUserData();
  }, []);

  const handleChange = (event) => {
    formik.setFieldValue(event.target.name, event.target.value);
  };

  const handleProfilePhotoChange = (event) => {
    const file = event.target.files[0];
    setProfilePhoto(file);
    setProfilePhotoPreview(URL.createObjectURL(file));
  };

  const handleProfilePhotoUpload = async () => {
    if (profilePhoto) {
      try {
        const formData = new FormData();
        formData.append("file", profilePhoto);

        const res = await UploadImageApi.uploadImage(formData);

        formik.setFieldValue(
          "recruiter_profile.profile_photo_url",
          res.data.data.name
        );

        return res.data.data.name;
      } catch (error) {
        console.error("Error uploading profile photo:", error);
      }
    }
  };

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography
          ml={1}
          sx={{
            fontWeight: "600",
            fontSize: "22px",
            marginLeft: "-4px",
            marginBottom: "15px",
          }}
        >
          {t("employee.aboutyou.title")}
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
        }}
      >
        <Grid container spacing={2}>
          <Grid item>
            <Box
              sx={{
                width: "100px",
                height: "100px",
                border: "1px dotted black",
                borderRadius: "5px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: "15px",
              }}
            >
              <input
                type="file"
                accept="image/*"
                style={{ display: "none" }}
                id="profile-photo-upload"
                onChange={handleProfilePhotoChange}
              />
              <label htmlFor="profile-photo-upload">
                <Button component="span">
                  {profilePhotoPreview ? (
                    <UploadImage
                      imageUrl={profilePhotoPreview}
                      altText="Profile Photo"
                    />
                  ) : formik.values.recruiter_profile.profile_photo_url ? (
                    <UploadImage
                      imageUrl={
                        profilePhotoPreview ||
                        `${process.env.REACT_APP_API_BASE_URL}/public/image/${formik.values.recruiter_profile.profile_photo_url}`
                      }
                      altText={
                        formik.values.recruiter_profile.profile_photo_url
                      }
                    />
                  ) : (
                    <AddIcon sx={{ fontSize: 30, color: "black" }} />
                  )}
                </Button>
              </label>
            </Box>
          </Grid>
          <Grid item>
            <Typography variant="subtitle1" sx={{ fontWeight: "600" }}>
              {t("employee.aboutyou.Profile Photo")}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                mr: 0,
                textDecoration: "none",
                color: "gray",
                fontSize: "11px",
              }}
            >
              {t("employee.aboutyou.maxsize")}
            </Typography>
          </Grid>
        </Grid>
      </Box>

      <Box>
        <Typography
          component="h2"
          variant="body1"
          sx={{
            alignSelf: "flex-start",
            fontSize: "14px",
            fontWeight: "bold",
            textTransform: "none",
            color: "black",
            paddingTop: "5px",
            mb: 0.5,
          }}
        >
          {t("recruiter.recruiter-profile.Full Name")}
        </Typography>
        <TextField
          name="recruiter_profile.full_name"
          value={formik.values.recruiter_profile.full_name}
          onChange={handleChange}
          placeholder={t("recruiter.recruiter-profile.Full Name")}
          error={
            formik.touched.recruiter_profile?.full_name &&
            Boolean(formik.errors.recruiter_profile?.full_name)
          }
          helperText={
            formik.touched.recruiter_profile?.full_name &&
            formik.errors.recruiter_profile?.full_name
          }
          variant="outlined"
          fullWidth
          InputLabelProps={{
            shrink: false,
            style: {
              fontSize: "14px",
              color: "gray",
            },
          }}
          sx={{ mb: 2 }}
        />
      </Box>

      <Box>
        <Typography
          ml={1}
          sx={{
            mt: 2,
            fontWeight: "600",
            fontSize: "22px",
            marginLeft: "-4px",
            marginBottom: "15px",
          }}
        >
          {t("recruiter.recruiter-profile.Contacts")}
        </Typography>
        <Typography
          component="h2"
          variant="body1"
          sx={{
            alignSelf: "flex-start",
            fontSize: "14px",
            fontWeight: "bold",
            textTransform: "none",
            color: "black",
            paddingTop: "5px",
            mb: 0.5,
          }}
        >
          {t("employee.aboutyoulable.Phone No")}
        </Typography>
        <PhoneInput
          country={"es"}
          name="recruiter_profile.phone_number"
          value={formik.values.recruiter_profile.phone_number}
          onChange={(phone) =>
            handleChange({
              target: { name: "recruiter_profile.phone_number", value: phone },
            })
          }
          placeholder={t("employee.aboutyoulable.Phone No")}
          inputStyle={{
            width: "100%",
            fontSize: "14px",
            padding: "10px 10px 10px 40px", // Add left padding to prevent overlap with the flag
            height: "55px", // Set the desired height
            border: "1px solid #ccc",
            borderRadius: "4px",
          }}
          InputLabelProps={{
            shrink: false,
            style: {
              fontSize: "14px",
              color: "gray",
            },
          }}
          variant="outlined"
          fullWidth
          sx={{ mb: 2 }}
          inputProps={{
            maxLength: 15, // Set max length for the input
          }}
          error={
            formik.touched.recruiter_profile?.phone_number &&
            Boolean(formik.errors.recruiter_profile?.phone_number)
          }
          helperText={
            formik.touched.recruiter_profile?.phone_number &&
            formik.errors.recruiter_profile?.phone_number
          }
        />
        {formik.errors.recruiter_profile && (
          <Typography
            sx={{ color: "#C70039", textAlign: "left", fontSize: "12px" }}
          >
            {formik.errors.recruiter_profile.phone_number}
          </Typography>
        )}
        <Typography
          component="h2"
          variant="body1"
          sx={{
            alignSelf: "flex-start",
            fontSize: "14px",
            fontWeight: "bold",
            textTransform: "none",
            color: "black",
            paddingTop: "5px",
            mb: 0.5,
          }}
        >
          {t("employee.aboutyou.LinkedIn Profile URL")}
        </Typography>
        <TextField
          name="recruiter_profile.linkedin_url"
          value={formik.values.recruiter_profile.linkedin_url}
          onChange={handleChange}
          placeholder={t("employee.aboutyou.LinkedIn Profile URL")}
          error={
            formik.touched.recruiter_profile?.linkedin_url &&
            Boolean(formik.errors.recruiter_profile?.linkedin_url)
          }
          helperText={
            formik.touched.recruiter_profile?.linkedin_url &&
            formik.errors.recruiter_profile?.linkedin_url
          }
          variant="outlined"
          fullWidth
          InputLabelProps={{
            shrink: false,
            style: {
              fontSize: "14px",
              color: "gray",
            },
          }}
          sx={{
            mb: 2,
            "& label.Mui-focused": { color: "black" },
            "& .MuiInput-underline:after": { borderBottomColor: "black" },
          }}
        />
      </Box>

      <Divider sx={{ my: 2 }} />

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          mt: 3,
        }}
      >
        <Button
          type="button"
          variant="standard"
          onClick={async () => {
            const errors = await formik.validateForm();
            console.log("Form validation errors:", errors);

            if (Object.keys(errors).length === 0) {
              try {
                await handleProfilePhotoUpload();
                await submitForm(formik.values);

                navigate("/v1/recruiter/company-profile", {
                  state: { formikValues: formik.values },
                });
              } catch (error) {
                console.error("Error submitting form:", error);
              }
            } else {
              formik.setTouched({
                recruiter_profile: {
                  full_name: true,
                  phone_number: true,
                  linkedin_url: true,
                },
              });
            }
          }}
          sx={{
            mt: 3,
            mb: 2,
            backgroundColor: "secondary.main",
            color: "white",
            "&:hover": {
              backgroundColor: "secondary.main",
            },
            marginLeft: "auto",
          }}
        >
          <Typography
            component="h2"
            variant="body1"
            sx={{
              alignSelf: "flex-start",
              fontSize: "15px",
              fontWeight: "bold",
              textTransform: "none",
              color: "primary.main",
              padding: "2px",
            }}
          >
            {t("recruiter.recruiter-profile.Nextbtn")}
          </Typography>
        </Button>
      </Box>
    </Box>
  );
};

export default RecruiterProfile;
