import React, { useState, useEffect } from "react";
import { Typography, Paper, Grid, Button, Link, Divider } from "@mui/material";
import { Facebook, Twitter, LinkedIn } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import { JobPostApi, OnBoardingApi } from "../../apis";
import { useTranslation } from "react-i18next";
//import MapWithAddress from "./mapWithAddress";

const JobListing = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [jobData, setJobData] = useState(null);
  const [userData, setUserData] = useState(null);
  // Define job details object with specific data
  // const jobDetails = {
  //   publishedOn: "29 Oct 2023",
  //   vacancy: 1,
  //   jobType: "Full time",
  //   experience: "At least 5 Year(s)",
  //   jobLocation: "Madrid",
  //   category: "Engineer",
  //   gender: "Both",
  //   salary: "$1K - $3K",
  //   applicationDeadline: "15 Nov 2023",
  // };
  const { id } = useParams();
  useEffect(() => {
    setLoading(true);
    JobPostApi.getJobPostById(id)
      .then((res) => {
        const jobPost = res.data.data;

        if (jobPost) {
          const parsedJobPost = {
            ...jobPost,
            jobDetails: parseJSON(jobPost.jobDetails),
            requirements: parseJSON(jobPost.requirements),
            description: parseJSON(jobPost.description),
          };
          setJobData(parsedJobPost);
        } else {
          console.error(`Job post with ID ${id} not found.`);
        }

        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching job post:", error);
        setLoading(false);
      });
  }, [id]);
  useEffect(() => {
    const fetchOnBoardedUserData = async () => {
      try {
        const res = await OnBoardingApi.getOnBoarding(); // Adjust API call as needed

        setUserData(res.data.profile);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching on-boarded user data:", error);
        setLoading(false);
      }
    };

    fetchOnBoardedUserData();
  }, []);
  const SocialButton = ({ icon, text }) => (
    <Grid container alignItems="center" spacing={1}>
      <Grid item>
        <div
          style={{
            width: 40,
            height: 40,
            borderRadius: "50%",
            backgroundColor: "#1DA1F2",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {icon} {/* Render the icon */}
        </div>
      </Grid>
      <Grid item>
        <Typography variant="body1">{text}</Typography>
      </Grid>
    </Grid>
  );
  const parseJSON = (jsonString) => {
    try {
      // Check if jsonString is already an object
      if (typeof jsonString === "object") {
        return jsonString; // Return as-is if already an object
      }
      return jsonString ? JSON.parse(jsonString) : {}; // Parse JSON if jsonString is not null or undefined
    } catch (error) {
      console.error("Error parsing JSON:", error);
      return {}; // Return empty object if parsing fails
    }
  };
  return (
    <>
      <Paper
        elevation={3}
        style={{
          padding: "20px",
          backgroundColor: "#F0F6FE",
          marginTop: "10px",
        }}
      >
        <Typography variant="h6" gutterBottom sx={{ fontWeight: "bold" }}>
          {t("recruiter.job-details.Job summary")}
        </Typography>
        {/* Render specific job details */}
        <Typography variant="body1">
          <strong>{t("recruiter.job-details.Published on")}:</strong>{" "}
          {new Date(jobData?.createdAt).toLocaleDateString()}
          <br />
          {/* <strong>Vacancy:</strong> {jobDetails.vacancy}<br /> */}
          <strong>{t("recruiter.job-details.Job type")}:</strong>{" "}
          {jobData?.jobDetails?.employmentType?.fullTime
            ? "Full-Time"
            : jobData?.jobDetails?.employmentType?.partTime
            ? "Part-Time"
            : "Not Specified"}
          <br />
          <strong>{t("recruiter.job-details.Experience")}:</strong>{" "}
          {jobData?.jobDetails?.totalYearsExperience || "N/A"}
          <br />
          <strong>{t("recruiter.job-details.Job location")}:</strong>{" "}
          {userData?.company_location}
          <br />
          {/* <strong>Category:</strong> {jobDetails.category}<br /> */}
          {/* <strong>Gender:</strong> {jobDetails.gender}<br /> */}
          <strong>{t("recruiter.job-details.Salary")}:</strong> €
          {jobData?.jobDetails?.salaryRange?.from || "N/A"} -{" "}
          {jobData?.jobDetails?.salaryRange?.to || "N/A"}
          <br />
          {/* <strong>Application deadline:</strong>{" "}
          {jobDetails.applicationDeadline}<br /> */}
        </Typography>
        <Divider sx={{ width: "100%", color: "text.secondary", mt: 3 }} />
        <Typography
          variant="h6"
          gutterBottom
          style={{ marginTop: "20px", fontWeight: "bold" }}
        >
          {t("recruiter.job-details.Job location")}
        </Typography>
        {/* <MapWithAddress address={userData?.company_location} /> */}
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2483.290996191002!2d-0.007137684229519892!3d51.12867657957772!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4875f02c6a2c10eb%3A0x870b0ec9d0d7ea3!2sRoyal%20Mail!5e0!3m2!1sen!2sus!4v1649693249159"
          width="600"
          height="450"
          style={{ border: 0, width: "100%", height: "300px" }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
        <Divider sx={{ width: "100%", color: "text.secondary", mt: 3 }} />
        <Typography
          variant="h6"
          gutterBottom
          style={{ marginTop: "20px", fontWeight: "bold" }}
        >
          {t("recruiter.job-details.Share this job")}
        </Typography>
        <Grid container spacing={1}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Button
                variant="standard"
                color="primary"
                sx={{
                  textTransform: "none",
                  width: "fit-content",
                  paddingLeft: "14px",
                  backgroundColor: "#F0F6FE",
                  color: "black",
                }}
              >
                <SocialButton
                  icon={<Facebook style={{ color: "#fff", mr: 2 }} />}
                  text="Facebook"
                />
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="standard"
              color="primary"
              sx={{
                textTransform: "none",
                width: "fit-content",
                padding: "8px",
                backgroundColor: "#F0F6FE",
                color: "black",
              }}
            >
              <SocialButton
                icon={<Twitter style={{ color: "#fff" }} />}
                text={t("recruiter.job-details.Twitter")}
              />
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="standard"
              color="primary"
              sx={{
                textTransform: "none",
                width: "fit-content",
                padding: "8px",
                backgroundColor: "#F0F6FE",
                color: "black",
              }}
            >
              <SocialButton
                icon={<LinkedIn style={{ color: "#fff" }} />}
                text="LinkedIn"
              />
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default JobListing;
