import React from "react";
import {
  Modal,
  Box,
  Typography,
  IconButton,
  Divider,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next"; 
import UploadImage from "../GlobalComponent/UploadImage";

const CandidateProfileModal = ({ open, onClose, candidate }) => {
  const { t } = useTranslation(); 

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "relative",
          padding: 4,
          bgcolor: "white",
          borderRadius: 2,
          maxWidth: 600,
          mx: "auto",
          mt: 5,
          overflowY: "auto", 
          maxHeight: "80vh",
          "&::-webkit-scrollbar": {
            width: "8px", 
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: "#f1f1f1",
            borderRadius: "10px",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#888", 
            borderRadius: "10px",
            border: "2px solid transparent", 
            backgroundClip: "padding-box", 
          },
          "&::-webkit-scrollbar-thumb:hover": {
            backgroundColor: "#555", 
          },
          scrollbarWidth: "thin",
          scrollbarColor: "#888 #f1f1f1",
        }}
      >
        <IconButton
          onClick={onClose}
          sx={{
            position: "absolute",
            top: 8,
            right: 8,
          }}
        >
          <CloseIcon />
        </IconButton>

        <Box
          sx={{
            width: "100px",
            height: "100px",
            border: "1px solid black",
            borderRadius: "5px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "15px",
            mx: "auto",
          }}
        >
          {candidate?.profile?.profile_photo_url ? (
            <UploadImage
              imageUrl={`${process.env.REACT_APP_API_BASE_URL}/public/image/${candidate?.profile?.profile_photo_url}`}
              altText={candidate?.profile?.profile_photo_url}
            />
          ) : (
            <Typography>{t('no_image')}</Typography> 
          )}
        </Box>

        <Typography variant="h6">
          {candidate?.profile?.full_name || t('not_available')}
        </Typography>
        <Typography variant="body1">
          {t('phone_number')}: {candidate?.profile?.phone_number || t('not_available')}
        </Typography>
        <Typography variant="body1">
          {t('city')}: {candidate?.profile?.current_city || t('not_available')}
        </Typography>

        <Divider sx={{ my: 2 }} />
        <Typography variant="body1" sx={{ mt: 2, fontWeight: "600" }}>
          {t('qualification')}:
        </Typography>
        <Typography
          ml={1}
          sx={{
            mt: 2,
            fontWeight: "600",
            fontSize: "22px",
            marginLeft: "-4px",
            marginBottom: "15px",
          }}
        >
          <Box component="span">
            {candidate?.profile?.top_specialities ? (
              (() => {
                const specialties = candidate.profile.top_specialities;

                if (typeof specialties === 'object' && specialties !== null) {
                  const activeSpecialties = Object.keys(specialties).filter(specialty => specialties[specialty]);

                  return activeSpecialties.length > 0 ? (
                    activeSpecialties.map((specialty, index) => (
                      <Typography key={index} variant="body2">{specialty}</Typography>
                    ))
                  ) : (
                    <Typography>{t('no_specialties')}</Typography>
                  );
                }
                return null; // Default return to handle undefined case
              })()
            ) : (
              <Typography>{t('no_specialties')}</Typography>
            )}
          </Box>
        </Typography>
      </Box>
    </Modal>
  );
};

export default CandidateProfileModal;
