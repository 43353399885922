import React, { useState, useContext, useEffect } from "react";
import {
  Typography,
  Box,
  Card,
  CardContent,
  CardActions,
  IconButton,
  Button,
  Menu,
  MenuItem,
  Grid,
} from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import CloseIcon from "@mui/icons-material/Close";
import UploadImage from "../GlobalComponent/UploadImage";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatRounded";
import JobContext from "../../context/JobContext";
import { Link } from "react-router-dom";
import ApplyForm from "./ApplyForm";
import { JobPostApi, JobApi, ChatApi, OnBoardingApi } from "../../apis";
import { useNavigate } from "react-router-dom";

const JobCard = ({
  offer,
  offerId,
  offer_company_profile_photo_url,
  isPending,
  isAccepted,
  isRejected,
}) => {
  const navigate = useNavigate();
  const {
    jobDataArray,
    setJobDataArray,
    pendingJobData,
    setPendingJobData,
    acceptedJobData,
    setAcceptedJobData,
    rejectedJobData,
    setRejectedJobData,
    loading,
    setLoading,
    removeJobPost,
  } = useContext(JobContext);
  const fetchJobPosts = async () => {
    setLoading(true);
    try {
      const res = await JobPostApi.getUserJobsOffer();
      console.log("🚀 ~ fetchJobPosts ~ res:", res);
      if (res && res.data && res.data.data) {
        const parsedJobPosts = res.data.data.map((job) => ({
          ...job,
          jobDetails: parseJSON(job.jobDetails),
          requirements: parseJSON(job.requirements),
          description: parseJSON(job.description),
        }));

        const pendingJobs = parsedJobPosts.filter(
          (job) => job.jobOfferStatus === "Pending"
        );
        setPendingJobData(pendingJobs);
        const acceptedJobs = parsedJobPosts.filter(
          (job) => job.jobOfferStatus === "Accepted"
        );
        setAcceptedJobData(acceptedJobs);
        const rejectedJobs = parsedJobPosts.filter(
          (job) => job.jobOfferStatus === "Rejected"
        );
        setRejectedJobData(rejectedJobs);
      } else {
        console.error(
          "Error fetching job posts:",
          res ? res.message : "No response received"
        );
      }
    } catch (error) {
      console.error("Error fetching job posts:", error);
    } finally {
      setLoading(false);
    }
  };
  const parseJSON = (jsonString) => {
    try {
      // Check if jsonString is already an object
      if (typeof jsonString === "object") {
        return jsonString; // Return as-is if already an object
      }
      return jsonString ? JSON.parse(jsonString) : {}; // Parse JSON if jsonString is not null or undefined
    } catch (error) {
      console.error("Error parsing JSON:", error);
      return {}; // Return empty object if parsing fails
    }
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const [showCandidates, setShowCandidates] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleViewCandidates = () => {
    setShowCandidates(true);
    setAnchorEl(null);
  };

  const handleStatusChange = async (status) => {
    const data = {
      job_offer_id: offerId.id,
      status: status,
    };

    try {
      const response = await JobApi.updateOfferStatus(data);

      if (response.status === 200) {
        console.log("Status update successful");
        fetchJobPosts(); // Refresh job posts after update
      } else {
        console.error("Failed to update status:", response.data.message);
      }
    } catch (error) {
      console.error("Error sending job offer request:", error);
    }
  };

  const open = Boolean(anchorEl);

  const handleChat = async () => {
    const data = {
      job_id: offerId.id, // Adjust field name if required
      receiver_id: offerId.userId,
    };
    try {
      const response = await ChatApi.createConversation(data);

      if (response?.data) {
        const conversationId = JSON.stringify(response.data.data.conversation_id); // Adjust based on your API response structure
        navigate(`/v1/employee/chat?conversationId=${conversationId}`);

      }
      // Extract conversation ID from the response
      // Navigate to the chat page with the conversation ID passed as state
    } catch (error) {
      console.error("Error creating conversation:", error);

      // Show error message to the user
      alert("Failed to create a conversation. Please try again.");
    }
  };

  return (
    <>
      <Card
        sx={{
          display: { sm: "flex" },
          justifyContent: "space-between",
          alignItems: "center",
          padding: 2,
          mx: "auto",
          marginBottom: 2,
        }}
      >
        <Box sx={{ display: { sm: "flex" }, alignItems: "center", gap: 2 }}>
          <Box
            sx={{
              position: "relative", // Enable the overlay
              width: { xs: "100%", sm: 80, md: 110 },
              height: { xs: 200, sm: 80, md: 110 },
              backgroundColor: "lightgray",
              borderRadius: "8px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              m: 1,
            }}
          >
            {/* Job Profile Picture */}
            {offer_company_profile_photo_url ? (
              <Box
                component="img"
                src={`${process.env.REACT_APP_API_BASE_URL}/public/image/${offer_company_profile_photo_url}`}
                alt="Company Profile Photo"
                sx={{
                  width: "100%",
                  height: "100%",
                  borderRadius: "8px",
                  objectFit: "cover", // Ensure it fits the container
                }}
              />
            ) : (
              <Typography variant="body1" sx={{ color: "text.secondary" }}>
                No Image
              </Typography>
            )}
          </Box>
          <CardContent sx={{ flex: { md: "1 0 auto" } }}>
            <Typography
              sx={{ fontSize: { sm: "18px", md: "22px" } }}
              variant="h6"
            >
              {offer.positionName || "N/A"}
            </Typography>
            <Typography variant="body1" paragraph>
              <Box component="span">
                {offer?.specialties &&
                  Object.keys(offer?.specialties).length > 0 ? (
                  Object.keys(offer?.specialties)
                    .filter((specialty) => offer?.specialties[specialty])
                    .map((specialty, index) => (
                      <Typography
                        variant="body2"
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          mb: 0.5,
                          color: "gray",
                        }}
                      >
                        {specialty.replace(/([A-Z])/g, " $1").trim()}
                      </Typography>
                    ))
                ) : (
                  <Typography component="span" variant="body1">
                    {offer.professional_area || "N/A"}{" "}
                  </Typography>
                )}
              </Box>
            </Typography>
            <Typography
              variant="body2"
              color="text.primary"
              sx={{ display: "flex", alignItems: "center", mt: -2 }}
            >
              {/* <LocationOnIcon sx={{mt:{xs:-1.5,sm:0 }}} />  */}
              {offer.location || "N/A"} • Full time • €
              {offer.salaryRange?.from || "N/A"}-
              {offer.salaryRange?.to || "N/A"}
            </Typography>
          </CardContent>
        </Box>
        <CardActions
          sx={{ justifyContent: "flex-end", flexDirection: "column" }}
        >
          <IconButton
            disableRipple
            aria-label="settings"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
          >
            <MoreHorizIcon />
          </IconButton>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem
              component={Link}
              to={`/v1/employee/job-details/${offerId.id}`}
              onClick={handleClose}
            >
              View job details
            </MenuItem>
          </Menu>
          {isPending && (
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6}>
                <Button
                  variant="standard"
                  fullWidth
                  sx={{
                    width: { sm: "50px", md: "100px" },
                    backgroundColor: "white",
                    color: "green",
                    border: "2px solid green",
                    "&:hover": {
                      backgroundColor: "green",
                      color: "white",
                    },
                  }}
                  onClick={() => handleStatusChange("Accepted")}
                >
                  <Typography
                    component="h2"
                    variant="body1"
                    sx={{
                      fontSize: { sm: "10px", md: "15px" },
                      fontWeight: "bold",
                      textTransform: "none",
                      padding: "4px",
                    }}
                  >
                    Accepted
                  </Typography>
                </Button>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Button
                  variant="standard"
                  fullWidth
                  sx={{
                    ml: { sm: -1.5 },
                    width: { sm: "50px", md: "100px" },
                    backgroundColor: "white",
                    color: "#C70039",
                    border: "2px solid #C70039",
                    "&:hover": {
                      backgroundColor: "#C70039",
                      color: "white",
                    },
                  }}
                  onClick={() => handleStatusChange("Rejected")}
                >
                  <Typography
                    component="h2"
                    variant="body1"
                    sx={{
                      fontSize: { sm: "10px", md: "15px" },
                      fontWeight: "bold",
                      textTransform: "none",
                      padding: "4px",
                    }}
                  >
                    Rejected
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          )}
          {isAccepted && (
            <Button
              sx={{
                mt: 2,
                mb: 2,
                ml: -1,

                backgroundColor: "white",
                color: "green",
                border: "2px solid green",
                "&:hover": {
                  backgroundColor: "white",
                  color: "green",
                },
                "&:focus": {
                  outline: "5px solid white",
                  backgroundColor: "white",
                },
                "&:active": {
                  backgroundColor: "white",
                  color: "green",
                },
              }}
              onClick={handleChat}
            >
              Start Conversation
            </Button>
          )}
          {!isPending && !isAccepted && !isRejected && (
            <Button
              variant="standard"
              fullWidth
              onClick={handleViewCandidates}
              sx={{
                mt: 2,
                mb: 2,
                width: { sm: "150px", md: "200px" },
                backgroundColor: "white",
                color: "primary.main",
                border: "2px solid #031141",
                "&:hover": {
                  backgroundColor: "white",
                },
              }}
            >
              <Typography
                component="h2"
                variant="body1"
                sx={{
                  alignSelf: "flex-start",
                  fontSize: { sm: "12px", md: "15px" },
                  fontWeight: "bold",
                  textTransform: "none",
                  padding: "4px",
                }}
              >
                Apply
              </Typography>
            </Button>
          )}
        </CardActions>
      </Card>

      {showCandidates && (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1,
          }}
        >
          <Box
            sx={{
              backgroundColor: "white",
              padding: 2,
              borderRadius: 4,
              position: "relative",
            }}
          >
            <IconButton
              sx={{
                position: "absolute",
                top: 5,
                right: 5,
                color: "black",
              }}
              onClick={() => setShowCandidates(false)}
            >
              <CloseIcon />
            </IconButton>
            <ApplyForm
              close={() => setShowCandidates(false)}
              jobId={offerId.id}
            />
          </Box>
        </Box>
      )}
    </>
  );
};

export default JobCard;
