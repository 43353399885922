import React from "react";
import { Box, Typography, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import LanguageSwitcher from "../../components/GlobalComponent/languageSwitcher";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    height: "750px",
    backgroundColor: "#0a074f",
    backgroundSize: "cover",
    backgroundPosition: "center", // Center the background image
    color: "#FFF", // White text
    display: "flex",
    flexDirection: "column", // Default to column layout
    alignItems: "left",
    justifyContent: "center",
    textAlign: "left",
    padding: "20px",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row", // Switch to row layout for medium and larger screens
      justifyContent: "space-between", // Ensure space between items on larger screens
    },
  },
  buttonArea: {
    display: "flex",
    flexDirection: "column", // Stack buttons vertically
    alignItems: "flex-end", // Align buttons to the right
    paddingRight: "5%", // Right padding for alignment
    "& > *": {
      margin: "8px 0", // Vertical margin between buttons
    },
  },
}));

function Banner() {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleLoginClick = () => {
    navigate("/login"); // Change the path to your registration page
  };
  const handleRegisterClick = () => {
    navigate("/register"); // Change the path to your registration page
  };
  return (
    <Box className={classes.root}>
      <Box sx={{ ml: 4 }}>
        <Typography
          variant="h3"
          className={classes.headline1}
          sx={{
            fontSize: "3rem",
            mt: 3,

            [theme.breakpoints.up("md")]: {
              fontSize: "4rem",
              mt: 25,
            },
            [theme.breakpoints.up("lg")]: {
              fontSize: "6rem",
              mt: 45,
            },
          }}
        >
          {t("common.1st_app")}
        </Typography>
        <Typography
          variant="h3"
          className={classes.headline1}
          sx={{
            fontSize: "3rem",
            display: "inline-block",
            [theme.breakpoints.up("md")]: {
              fontSize: "4rem",
            },
            [theme.breakpoints.up("lg")]: {
              fontSize: "6rem",
            },
          }}
        >
          {t("landing.donde")}
          <Typography
            variant="h3"
            sx={{
              color: "#8EFFC2", // Highlight color
              textDecoration: "underline",
              fontSize: "3rem",
              display: "inline-block",
              ml: -0.4,
              [theme.breakpoints.up("sm")]: {
                ml: 1,
              },
              [theme.breakpoints.up("md")]: {
                fontSize: "4rem",
                ml: 1,
              },
              [theme.breakpoints.up("lg")]: {
                fontSize: "6rem",
                ml: 1,
              },
            }}
            className={classes.headline1}
          >
            {t("landing.employment")}
          </Typography>
        </Typography>
        {/* 
      <LanguageSwitcher/> */}
        <Typography
          variant="h3"
          sx={{
            color: "#8EFFC2", // Highlight color
            textDecoration: "underline",
            fontSize: "3rem",

            [theme.breakpoints.up("md")]: {
              fontSize: "4rem",
            },
            [theme.breakpoints.up("lg")]: {
              fontSize: "6rem",
            },
          }}
          className={classes.headline1}
        >
          {t("landing.empresa")}
        </Typography>
      </Box>

      <Box
        className={classes.buttonArea}
        sx={{
          [theme.breakpoints.up("md")]: {
            mt: 70,
          },
          [theme.breakpoints.up("xs")]: {
            mt: 4,
          },
        }}
      >
        <Button
          onClick={handleLoginClick}
          variant="outlined"
          color="primary"
          sx={{
            [theme.breakpoints.up("md")]: {
              px: "20px",
              fontSize: "18px",
            },
            [theme.breakpoints.up("lg")]: {
              px: "20px",
              fontSize: "20px",
            },

            mb: 1,
            color: "#8EFFC2",
            borderColor: "#8EFFC2",
            borderRadius: "35px", // Adjust the value as per your design
            "&:hover": {
              borderColor: "#8EFFC2",
            },
          }}
        >
          {t("landing.find")}
        </Button>
        <Button
          onClick={handleRegisterClick}
          variant="standard"
          className={classes.button}
          sx={{
            color: "#8EFFC2",
            [theme.breakpoints.up("md")]: {
              fontSize: "18px",
            },
            [theme.breakpoints.up("lg")]: {
              fontSize: "20px",
            },
            backgroundColor: "#0a074f",
            "&:hover": {
              color: "#8EFFC2",
              backgroundColor: "#0a074f",
            },
          }}
          >
          {t("landing.company")}
          </Button>
      </Box>
    </Box>
  );
}

export default Banner;
