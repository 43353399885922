import React from "react";
import NavBar from "../../../../components/Global/NavBar";
import { Grid, Box, Container } from "@mui/material";
import JobDetail from "../../../../components/JobCard/jobDetail";
import JobListing from "../../../../components/JobCard/jobListing";

const JobDetails = () => {
  return (
    <>
      <NavBar />
      <Box sx={{ backgroundColor: "#F5F5F5", minHeight: "100vh", mt: -6 }}>
        <Container>
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={8} lg={8} sx={{ mt: 2 }}>
                  <JobDetail sx={{ width: "100%" }} />
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      marginTop: "20px",
                      width: "100%",
                    }}
                  >
                    <JobListing />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default JobDetails;
