import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  Button,
  Grid,
  FormControl,
  TextField,
  InputLabel,
  FormControlLabel,
  Radio,
  RadioGroup,
  Divider,
  Container,
} from "@mui/material";
import { Formik, Form } from "formik";
import { OnBoardingApi, UploadImageApi } from "../../../../apis";
import AddIcon from "@mui/icons-material/Add";
import * as yup from "yup";
import UploadImage from "../../../../components/GlobalComponent/UploadImage";
import CustomAutocomplete from "../../../../components/GlobalComponent/customAutoComplete";
import DynamicAutocomplete from "../../../../components/GlobalComponent/CityFinder";
import PhoneInput from "react-phone-input-2"; // Import the PhoneInput component
import "react-phone-input-2/lib/style.css"; // Import the default styles
import { useTranslation } from "react-i18next";

const AboutYou = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [profilePhoto, setProfilePhoto] = useState(null);
  const [profilePhotoPreview, setProfilePhotoPreview] = useState(null);
  const { t } = useTranslation();

  const handleProfilePhotoChange = (event) => {
    const file = event.target.files[0];
    setProfilePhoto(file);
    setProfilePhotoPreview(URL.createObjectURL(file));
  };

  const handleProfilePhotoUpload = async () => {
    if (profilePhoto) {
      try {
        const formData = new FormData();
        formData.append("file", profilePhoto);

        const res = await UploadImageApi.uploadImage(formData);
        return res.data.data.name; // Return only the name of the uploaded photo
      } catch (error) {
        console.error("Error uploading profile photo:", error);
        throw error;
      }
    }
  };
  const AboutYouForm = ({ formik }) => {
    const { values, handleChange } = formik;

    const [loading, setLoading] = useState(true);

    useEffect(() => {
      const fetchOnBoardedUserData = async () => {
        try {
          const res = await OnBoardingApi.getOnBoarding();
          function multiParse(input) {
            let result = input;
            try {
              while (typeof result === "string") {
                result = JSON.parse(result);
              }
            } catch (e) {
              console.error("Error parsing input:", e);
            }
            return result;
          }
          if (res.data && res.data.profile) {
            const parsedTopSpecialities = multiParse(
              res?.data?.profile?.top_specialities || {}
            );
            const parsedEmployementType = multiParse(
              res?.data?.profile?.employment_type || {}
            );
            const salaryRange = multiParse(
              res?.data?.profile?.salary_range || "{}"
            );
            const workExperience = multiParse(
              res?.data?.profile?.work_experience || "[]"
            );
            const education = multiParse(res?.data?.profile?.education || "[]");
            const languages = multiParse(res?.data?.profile?.languages || "[]");
            const experienceYears = multiParse(
              res?.data?.profile?.experienceYears || "[]"
            );

            const remoteWorkValue = res?.data?.profile?.remote_work ? "1" : "0";
            const relocationValue = res?.data?.profile?.relocation ? "1" : "0";
            const trueTopSpecialities = Object.entries(parsedTopSpecialities)
              .filter(([_, value]) => value) // Keep only entries where value is true
              .map(([key]) => key) // Extract the key (specialty name)
              .join(", "); // Join the names into a single string

            // Result

            formik.setValues({
              ...formik.values,
              preferences: {
                professional_area: res?.data?.profile?.professional_area || "",
                total_years_experience:
                  res?.data?.profile.total_years_experience || "",
                top_specialities: parsedTopSpecialities,
                experienceYears: experienceYears || {},
                employment_type: {
                  fullTime: parsedEmployementType?.fullTime || false,
                  partTime: parsedEmployementType?.partTime || false,
                },
                job_search_status: res?.data?.profile?.job_search_status || "",
                remote_work: remoteWorkValue || "",
                relocation: relocationValue || "",
              },
              about_you: {
                resume_url: res?.data?.profile.resume_url || "",
                profile_photo_url: res?.data?.profile?.profile_photo_url || "",
                full_name: res?.data?.profile?.full_name || "",
                phone_number: res?.data?.profile?.phone_number || "",
                whatsapp_number: res?.data?.profile?.whatsapp_number || "",
                current_city: res?.data?.profile?.current_city || "",
                salary_range: {
                  from: salaryRange?.from || "",
                  to: salaryRange?.to || "",
                },
                linkedin_url: res?.data?.profile?.linkedin_url || "",
                personal_blog_url: res?.data?.profile?.personal_blog_url || "",
              },
              background: {
                work_experience:
                  workExperience?.length > 0
                    ? workExperience
                    : [
                      {
                        company_name: workExperience?.company_name || "",
                        job_title: workExperience?.job_title || "",
                        is_current: workExperience?.is_current || false,
                        start: workExperience?.start || "",
                        end: workExperience?.end || "",
                        description: workExperience?.description || "",
                      },
                    ],
                education:
                  education?.length > 0
                    ? education
                    : [
                      {
                        university_or_collage:
                          education?.university_or_collage || "",
                        graduation_year: education?.graduation_year || "",
                        field_of_study: education?.field_of_study || "",
                        degree: education?.degree || "",
                      },
                    ],
                languages:
                  languages?.length > 0
                    ? languages
                    : [
                      {
                        language: languages?.language || "",
                        language_level: languages?.language_level || "",
                      },
                    ],
                objective: res?.data?.profile?.objective || "",
                achievements: res?.data?.profile?.achievements || "",
                personal_characteristics:
                  res?.data?.profile?.personal_characteristics || "",
              },
            });
          } else {
          }
          setLoading(false);
        } catch (error) {
          console.error("Error fetching on-boarded user data:", error);
          setLoading(false);
        }
      };

      fetchOnBoardedUserData();
    }, []);
    const handleInputSalaryChange = (event) => {
      const { name, value } = event.target;
      let formattedValue = value.replace(/,/g, ""); // Remove existing commas
      // Add commas to the number
      formik.setFieldValue(name, formattedValue);
    };

    const getCityColor = (city) => {
      return city === "Enter City" ? "#aaa" : "black";
    };
    // Safely extract true specialties
    const trueTopCity = values.about_you.current_city;
    const formattedCity = trueTopCity
      ? trueTopCity // Add space between words
      : t("employee.aboutyou.entercity"); // Default value when no true speciality

    const cityColor = getCityColor(formattedCity);

    return (
      <div>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography
            ml={1}
            sx={{
              fontWeight: "600",
              fontSize: "22px",
              marginLeft: "-4px",
              marginBottom: "15px",
            }}
          >
            {t("employee.aboutyou.title")}
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start", // Align to the left
          }}
        >
          <Grid container spacing={2}>
            <Grid item>
              <Box
                sx={{
                  width: "100px",
                  height: "100px",
                  border: "1px dotted black",
                  borderRadius: "5px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: "15px",
                }}
              >
                <Button>
                  <input
                    type="file"
                    accept="image/*"
                    style={{ display: "none" }}
                    id="profile-photo-upload"
                    onChange={handleProfilePhotoChange}
                  />
                  <label htmlFor="profile-photo-upload">
                    <Button
                      component="span"
                      sx={{
                        "&:hover": {
                          backgroundColor: "transparent", // Set the background color to transparent on hover
                        },
                      }}
                    >
                      {profilePhotoPreview ? (
                        <UploadImage
                          imageUrl={profilePhotoPreview}
                          altText="Profile Image"
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                            borderRadius: "5px",
                          }}
                        />
                      ) : values.about_you.profile_photo_url ? (
                        <UploadImage
                          imageUrl={`${process.env.REACT_APP_API_BASE_URL}/public/image/${values.about_you.profile_photo_url}`}
                          altText={values.about_you.profile_photo_url}
                          style={{ maxWidth: "100%", maxHeight: "100%" }}
                        />
                      ) : (
                        <AddIcon sx={{ fontSize: 30, color: "black" }} />
                      )}
                    </Button>
                  </label>
                </Button>
              </Box>
            </Grid>
            <Grid item>
              <Typography variant="subtitle1" sx={{ fontWeight: "600" }}>
                {t("employee.aboutyou.Profile Photo")}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  mr: 0,
                  textDecoration: "none",
                  color: "gray",
                  fontSize: "11px",
                }}
              >
                {t("employee.aboutyou.maxsize")}
              </Typography>
            </Grid>
          </Grid>
        </Box>

        <Box>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <InputLabel
                mt={2}
                sx={{
                  fontWeight: "600",
                  fontSize: "14px",
                  color: "text.primary",
                  mb: 0.5,
                }}
              >
                {t("employee.aboutyoulable.Name & Surname")}
              </InputLabel>
              <TextField
                name="about_you.full_name"
                value={values.about_you.full_name}
                onChange={handleChange}
                placeholder={t("emoloyee.aboutyou.entername")}
                variant="outlined"
                InputLabelProps={{
                  shrink: false,
                  style: {
                    fontSize: "14px",
                    color: "gray",
                  },
                }}
                fullWidth
                sx={{ mb: 2 }}
              />
              {formik.errors.about_you && (
                <Typography
                  sx={{ color: "#C70039", textAlign: "left", fontSize: "12px" }}
                >
                  {formik.errors.about_you.full_name}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <InputLabel
                mt={2}
                sx={{
                  fontWeight: "600",
                  fontSize: "14px",
                  color: "text.primary",
                  mb: 0.5,
                }}
              >
                {t("employee.aboutyoulable.Phone No")}
              </InputLabel>
              <PhoneInput
                country={"es"}
                name="about_you.phone_number"
                value={values.about_you.phone_number}
                onChange={(phone) =>
                  handleChange({
                    target: { name: "about_you.phone_number", value: phone },
                  })
                }
                placeholder={t("emoloyee.aboutyou.phoneno")}
                inputStyle={{
                  width: "100%",
                  fontSize: "14px",
                  padding: "10px 10px 10px 40px", // Add left padding to prevent overlap with the flag
                  height: "55px", // Set the desired height
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                }}
                InputLabelProps={{
                  shrink: false,
                  style: {
                    fontSize: "14px",
                    color: "gray",
                  },
                }}
                variant="outlined"
                fullWidth
                sx={{ mb: 2 }}
                inputProps={{
                  maxLength: 15, // Set max length for the input
                }}
              />
              {formik.errors.about_you && (
                <Typography
                  sx={{ color: "#C70039", textAlign: "left", fontSize: "12px" }}
                >
                  {formik.errors.about_you.phone_number}
                </Typography>
              )}
            </Grid>
          </Grid>
        </Box>

        <Box mt={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <InputLabel
                mt={2}
                sx={{
                  fontWeight: "600",
                  fontSize: "14px",
                  color: "text.primary",
                  mb: 0.5,
                }}
              >
                {t("emoloyee.aboutyou.city")}
              </InputLabel>

              <DynamicAutocomplete
                color={cityColor}
                placeHolder={formattedCity}
                formik={formik}
                fieldName="about_you.current_city"
                // fetchOptions={fetchCities}
                value={values.about_you.current_city}
              />
            </Grid>
          </Grid>
        </Box>

        <Box>
          <Typography
            ml={1}
            sx={{
              mt: 2,
              fontWeight: "600",
              fontSize: "22px",
              marginLeft: "-4px",
              marginBottom: "15px",
            }}
          >
            {t("employee.aboutyou.salaryexpectation")}
          </Typography>

          <Box>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <InputLabel
                  mt={2}
                  sx={{
                    fontWeight: "600",
                    fontSize: "14px",
                    color: "text.primary",
                    mb: 0.5,
                  }}
                >
                  {t("employee.aboutyou.range")}
                </InputLabel>
                <TextField
                  fullWidth
                  placeholder={t("employee.aboutyou.From")}
                  name="about_you.salary_range.from"
                  value={values.about_you.salary_range.from}
                  onChange={handleInputSalaryChange}
                  type="number"
                />

                {formik.touched.about_you?.salary_range?.from &&
                  formik.errors.about_you?.salary_range?.from && (
                    <Typography
                      variant="body2"
                      sx={{
                        color: "#C70039",
                        textAlign: "left",
                        fontSize: "12px",
                      }}
                    >
                      {formik.errors.about_you.salary_range.from}
                    </Typography>
                  )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputLabel
                  mt={2}
                  sx={{
                    fontWeight: "600",
                    fontSize: "14px",
                    color: "text.primary",
                    mb: 0.5,
                  }}
                >
                  {t("employee.aboutyou.To")}*
                </InputLabel>
                <TextField
                  fullWidth
                  InputLabelProps={{
                    shrink: false,
                    style: { fontSize: "14px", color: "gray" },
                  }}
                  // number
                  type="number"
                  placeholder={t("employee.aboutyou.To")}
                  name="about_you.salary_range.to"
                  value={values.about_you.salary_range.to}
                  onChange={handleInputSalaryChange}
                />
                {formik.touched.about_you?.salary_range?.to &&
                  formik.errors.about_you?.salary_range?.to && (
                    <Typography
                      variant="body2"
                      sx={{
                        color: "#C70039",
                        textAlign: "left",
                        fontSize: "12px",
                      }}
                    >
                      {formik.errors.about_you.salary_range.to}
                    </Typography>
                  )}
              </Grid>
            </Grid>
          </Box>
        </Box>

        <Box>
          <Typography
            ml={1}
            sx={{
              mt: 2,
              fontWeight: "600",
              fontSize: "22px",
              marginLeft: "-4px",
              marginBottom: "15px",
            }}
          >
            {t("employee.aboutyou.Links")}
          </Typography>
          <Typography
            component="h2"
            variant="body1"
            sx={{
              alignSelf: "flex-start",
              fontSize: "14px",
              fontWeight: "bold",
              textTransform: "none",
              color: "black",
              paddingTop: "5px",
              mb: 0.5,
            }}
          >
            {t("employee.aboutyou.LinkedIn Profile URL")}
          </Typography>
          <TextField
            name="about_you.linkedin_url"
            value={values.about_you.linkedin_url}
            onChange={handleChange}
            placeholder={t("employee.aboutyou.LinkedIn Profile URL")}
            variant="outlined"
            InputLabelProps={{
              shrink: false,
              style: {
                fontSize: "14px",
                color: "gray",
              },
            }}
            fullWidth
            sx={{ mb: 2 }}
          />
          <Typography
            component="h2"
            variant="body1"
            sx={{
              alignSelf: "flex-start",
              fontSize: "14px",
              fontWeight: "bold",
              textTransform: "none",
              color: "black",
              paddingTop: "5px",
              mb: 0.5,
            }}
          >
            {t("employee.aboutyou.Personal Blog URL")}
          </Typography>
          <TextField
            name="about_you.personal_blog_url"
            value={values.about_you.personal_blog_url}
            onChange={handleChange}
            placeholder={t("employee.aboutyou.Personal Blog URL")}
            variant="outlined"
            InputLabelProps={{
              shrink: false,
              style: {
                fontSize: "14px",
                color: "gray",
              },
            }}
            fullWidth
            sx={{ mb: 2 }}
          />
        </Box>
      </div>
    );
  };
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  const submitForm = async (values) => {
    try {
      // Convert top_specialities and salary_range to JSON strings if necessary
      const updatedValues = {
        ...values,

        preferences: {
          ...values.preferences,
          employment_type: values.preferences?.employment_type
            ? JSON.stringify(values.preferences.employment_type)
            : {},
        },
        about_you: {
          ...values.about_you,
          salary_range: values.about_you?.salary_range
            ? JSON.stringify(values.about_you.salary_range)
            : {},
          profile_photo_url: values.about_you.profile_photo_url, // Include profile_photo_url
        },
      };

      const res = await OnBoardingApi.OnBoarding(updatedValues);
    } catch (err) {
      console.error(err);
    }
  };

  const [formikValues, setFormikValues] = useState({
    about_you: {
      resume_url: "",
      profile_photo_url: "",
      full_name: "",
      phone_number: "",
      whatsapp_number: "",
      salary_range: {
        from: "",
        to: "",
      },
      current_city: "",
      linkedin_url: "",
      personal_blog_url: "",
    },
  });
  const validationSchema = yup.object().shape({
    about_you: yup.object().shape({
      full_name: yup.string().required("Name & Surname is required"),
      phone_number: yup.string().required("Phone No is required"),
      // whatsapp_number: yup.string().required('WhatsApp or Telegram is required'),
      current_city: yup.string().required("Your Current City is required"),
      salary_range: yup.object().shape({
        from: yup
          .number("Salary must be a valid number")
          .required("Salary is Required"),
        to: yup
          .number("Salary must be a valid number")
          .required("Salary is Required"),
      }),

      // linkedin_url: yup.string().url('Invalid URL').required('LinkedIn Profile URL is required'),
      // github_url: yup.string().url('Invalid URL').required('Github Profile URL is required'),
    }),
  });
  const handleNavigation = async () => {
    // Add a delay of 2 seconds (2000 milliseconds) before navigating
    await delay(1000);
    navigate("/v1/employee/preferences"); // Perform the navigation
  };
  return (
    <>
      <Box sx={{ backgroundColor: "white", minHeight: "100vh", padding: 0 }}>
        <Container>
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              <Box sx={{ mx: { lg: "150px" } }}>
                <Formik
                  initialValues={formikValues}
                  validationSchema={validationSchema}
                  onSubmit={(values) => {
                    submitForm(values);
                  }}
                  validateOnChange={false}
                  validateOnBlur={false} // Disable validation on blur to prevent premature error display
                >
                  {(formik) => (
                    <Form>
                      <AboutYouForm formik={formik} />
                      <Divider sx={{ my: 2 }} />

                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          mt: 3,
                        }}
                      >
                        <Button
                          type="button"
                          onClick={() => navigate("/v1/employee/preferences")}
                          variant="outlined"
                          sx={{
                            mt: 3,
                            mb: 2,
                            backgroundColor: "white",
                            borderColor: "primary.main",
                            color: "text.secondary",
                            "&:hover": {
                              backgroundColor: "text.secondary",
                            },
                          }}
                        >
                          <Typography
                            component="h2"
                            variant="body1"
                            sx={{
                              alignSelf: "flex-start",
                              fontSize: "15px",
                              fontWeight: "bold",
                              textTransform: "none",
                              color: "primary.main",
                              padding: "2px",
                            }}
                          >
                            {t("employee.background.Back")}
                          </Typography>
                        </Button>

                        <Button
                          type="submit"
                          variant="standard"
                          onClick={async () => {
                            const isValid = await formik.validateForm();
                            if (Object.keys(isValid).length === 0) {
                              try {
                                const profilePhotoName =
                                  await handleProfilePhotoUpload(); // Upload the profile photo and get the URL data
                                if (profilePhotoName) {
                                  formik.values.about_you.profile_photo_url =
                                    profilePhotoName; // Set profile_photo_url to the photo name
                                }
                                await formik.submitForm();

                                // Navigate after the delay
                                navigate("/v1/employee/background", {
                                  state: { formikValues: formik.values },
                                });
                              } catch (error) {
                                console.error(t("error.submittingForm"), error);
                              }
                            } else {
                              // Mark all fields as touched to display errors
                              formik.setTouched({
                                about_you: {
                                  full_name: true,
                                  phone_number: true,
                                  whatsapp_number: true,
                                  current_city: true,
                                  linkedin_url: true,
                                  personal_blog_url: true,
                                },
                              });
                            }
                          }}
                          sx={{
                            mt: 3,
                            mb: 2,
                            backgroundColor: "secondary.main",
                            color: "white",
                            "&:hover": {
                              backgroundColor: "secondary.main",
                            },
                          }}
                        >
                          <Typography
                            component="h2"
                            variant="body1"
                            sx={{
                              alignSelf: "flex-start",
                              fontSize: "15px",
                              fontWeight: "bold",
                              textTransform: "none",
                              color: "primary.main",
                              padding: "2px",
                            }}
                          >
                            {t("employee.background.Next")}
                          </Typography>
                        </Button>
                      </Box>
                    </Form>
                  )}
                </Formik>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

    </>
  );
};

export default AboutYou;
