import i18n from "i18next";
import { initReactI18next } from "react-i18next";
export const getLanguage = () => {
  const storedLanguage =
    typeof localStorage !== "undefined" && localStorage.getItem("i18nextLng");
  return storedLanguage || "sp";
};

const setLanguage = (language) => {
  typeof localStorage !== "undefined" &&
    localStorage.setItem("i18nextLng", language);
};

export const changeLanguage = (language) => {
  i18n.changeLanguage(language);
  setLanguage(language);
};

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: require("./locales/en/translation.json"),
    },
    sp: {
      translation: require("./locales/sp/translation.json"),
    },
  },
  lng: getLanguage(),
  fallbackLng: "sp",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
