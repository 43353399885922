import React, { useState } from "react";
import { Container, Stepper, Typography } from "@mui/material";
import CreateJobPostStep1 from "../../../../components/JobPost/Step1";
import CreateJobPostStep2 from "../../../../components/JobPost/Step2";
import CreateJobPostStep3 from "../../../../components/JobPost/Step3";
import NavBar from "../../../../components/Global/NavBar";
import Footer from "../../../../components/Global/Footer";
import { useTranslation } from "react-i18next";

const steps = ["Job Details", "Requirements", "Description"];

const CreateJobPost = () => {
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = useState(0);
  const [formData, setFormData] = useState({
    jobDetails: {
      photo: "",
      positionName: "",
      location: "",
      city: "",
      employmentType: {
        fullTime: false,
        partTime: false,
      },
      remoteCandidates: "no",
      willingToRelocate: false,
      salaryRange: {
        from: "",
        to: "",
      },
      professional_area: "",
      top_specialities: [],

      experienceYears: {},

      totalYearsExperience: "",
    },
    requirements: {
      education: "",
      languages: [
        {
          language: "",
          level: "",
        },
      ],
    },
    description: {
      description: "",
      additionalInfo: "",
    },
  });
  const stepTexts = [
    t("recruiter.create-job-post.jobDetails"),
    t("recruiter.create-job-post.Requirements"),
    t("recruiter.create-job-post.Description"),
  ];

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return (
          <CreateJobPostStep1
            formData={formData}
            setFormData={setFormData}
            nextStep={handleNext}
          />
        );
      case 1:
        return (
          <CreateJobPostStep2
            formData={formData}
            setFormData={setFormData}
            nextStep={handleNext}
            backStep={handleBack}
          />
        );
      case 2:
        return (
          <CreateJobPostStep3
            formData={formData}
            setFormData={setFormData}
            backStep={handleBack}
          />
        );
    }
  };

  return (
    <>
      <Container
        component="main"
        maxWidth="100%"
        sx={{ backgroundColor: "#F5F5F5", padding: "0px" }}
      >
        <NavBar>
          <Typography
            ml={1}
            sx={{
              fontWeight: "600",
              fontSize: "22px",
              marginBottom: "10px",
              mt: 3,
            }}
          >
            {t("recruiter.my-job-post.createjobpost")}
          </Typography>
          <Typography ml={1} sx={{ fontSize: "16px", marginBottom: "20px" }}>
            {t("recruiter.create-job-post.subpara")}
          </Typography>
          <Typography
            ml={1}
            sx={{
              mb: 1,
              textDecoration: "none",
              color: "gray",
              fontSize: "12px",
            }}
          >
            Step {activeStep + 1} of 3
          </Typography>
          <Typography
            ml={1}
            sx={{ fontWeight: "Bold", fontSize: "18px", marginBottom: "10px" }}
          >
            {stepTexts[activeStep]}
          </Typography>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label, index) => (
              <div key={label} style={{ flexGrow: 2, padding: "0px" }}>
                <hr
                  style={{
                    borderRadius: "4px",
                    borderTop: `4px solid ${
                      activeStep === index ? "#8EFFC2" : "transparent"
                    }`,
                    marginTop: "10px",
                  }}
                />
              </div>
            ))}
          </Stepper>
          <div>{getStepContent(activeStep)}</div>
        </NavBar>
      </Container>
      <Footer />
    </>
  );
};

export default CreateJobPost;
