import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { CircularProgress, Typography, Container } from "@mui/material";
import { AuthApi } from "../../apis";
import { toast } from "react-toastify";

const EmailVerification = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState(null);

  const verifyEmail = async () => {
    try {
      const response = await AuthApi.verfiyEmail(token);
      navigate("/login");
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || "Invalid or expired token.";
      setStatus(errorMessage);
      toast.error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!token) {
      setStatus("Token is missing.");
      setLoading(false);
      return;
    }
    verifyEmail();
  }, [token, navigate]);

  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        textAlign: "center",
      }}
    >
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          <Typography variant="h4" sx={{ marginBottom: 2 }}>
            {status}
          </Typography>
        </>
      )}
    </Container>
  );
};

export default EmailVerification;
