import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Typography,
  Button,
  Grid,
  InputLabel,
  Divider,
} from "@mui/material";
import { useLocation } from "react-router-dom";

import { OnBoardingApi } from "../../../../apis";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import UploadImage from "../../../../components/GlobalComponent/UploadImage";
import AddIcon from "@mui/icons-material/Add";
import Loader from "../../../../components/GlobalComponent/Loader";

const PreviewAndSubmit = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate(); // Initialize navigate
  const location = useLocation();
  const [formikValues, setFormikValues] = useState(
    location.state?.formikValues || {}
  );
  const [workExperience, setWorkExperience] = useState(null);
  const [education, setEducation] = useState(null);
  const [languages, setLanguages] = useState(null);
  useEffect(() => {
    if (!formikValues || Object.keys(formikValues).length === 0) {
      console.error("No form data available");
    }
  }, [formikValues]);

  const submitForm = async (values) => {
    try {
      // Convert top_specialities to JSON string

      const updatedValues = {
        ...values,

        preferences: {
          ...values.preferences,
          employment_type: values.preferences?.employment_type
            ? JSON.stringify(values.preferences.employment_type)
            : {},
        },
        about_you: {
          ...values.about_you,
          salary_range: values.about_you?.salary_range
            ? JSON.stringify(values.about_you.salary_range)
            : {},
          profile_photo_url: values.about_you.profile_photo_url, // Include profile_photo_url
        },
      };

      const res = await OnBoardingApi.OnBoarding(updatedValues);
      localStorage.setItem("onboarding", true);
      localStorage.setItem("is_onboarding", true);
      window.location.href = "/v1/employee/home";
    } catch (err) {
      console.error(err);
    }
  };
  useEffect(() => {
    const fetchOnBoardedUserData = async () => {
      try {
        const res = await OnBoardingApi.getOnBoarding(); // Adjust API call as needed

        setUserData(res?.data?.profile);

        setLoading(false);
      } catch (error) {
        console.error("Error fetching on-boarded user data:", error);
        setLoading(false);
      }
    };
    fetchOnBoardedUserData();
  }, []);

  useEffect(() => {
    function multiParse(input) {
      let result = input;
      try {
        while (typeof result === "string") {
          result = JSON.parse(result);
        }
        return Array.isArray(result) ||
          (typeof result === "object" && result !== null)
          ? result
          : null;
      } catch (e) {
        console.error("Error parsing input:", e);
        return null;
      }
    }

    if (userData) {
      setWorkExperience(multiParse(userData.work_experience));
      setEducation(multiParse(userData.education));
      setLanguages(multiParse(userData.languages));
    }
  }, [userData]);

  const formatWorkExperience = (experience) => {
    if (!experience)
      return {
        companies: "N/A",
        titles: "N/A",
        startDates: "N/A",
        endDates: "N/A",
        descriptions: "N/A",
      };
    return {
      companies: experience.map((exp) => exp.company_name || "N/A").join(", "),
      titles: experience.map((exp) => exp.job_title || "N/A").join(", "),
      startDates: experience.map((exp) => exp.start || "N/A").join(", "),
      endDates: experience.map((exp) => exp.end || "N/A").join(", "),
      descriptions: experience
        .map((exp) => exp.description || "N/A")
        .join(", "),
    };
  };

  const formatEducation = (edu) => {
    if (!edu)
      return {
        universities: "N/A",
        graduationYears: "N/A",
        fieldsOfStudy: "N/A",
        degrees: "N/A",
      };
    return {
      universities: edu.map((e) => e.university_or_collage || "N/A").join(", "),
      graduationYears: edu.map((e) => e.graduation_year || "N/A").join(", "),
      fieldsOfStudy: edu.map((e) => e.field_of_study || "N/A").join(", "),
      degrees: edu.map((e) => e.degree || "N/A").join(", "),
    };
  };

  const formatLanguages = (lang) => {
    if (!lang) return { languages: "N/A", levels: "N/A" };
    return {
      languages: lang.map((l) => l.language || "N/A").join(", "),
      levels: lang.map((l) => l.language_level || "N/A").join(", "),
    };
  };

  const workExp = formatWorkExperience(workExperience);
  const edu = formatEducation(education);
  const lang = formatLanguages(languages);

  if (loading) {
    return (
      <div>
        <Loader loading={loading} />
      </div>
    );
  }

  function multiParse(input) {
    let result = input;
    try {
      while (typeof result === "string") {
        result = JSON.parse(result);
      }
    } catch (e) {
      console.error("Error parsing input:", e);
    }
    return result;
  }

  return (
    <Box>
      <Box
        sx={{
          background: "#FFFFFF",
          borderRadius: "14px",
          paddingLeft: "150px",
          paddingRight: "150px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography
            ml={1}
            sx={{
              fontWeight: "600",
              fontSize: "22px",
              marginLeft: "-4px",
              marginBottom: "15px",
            }}
          >
            {/* Profile Preview */}
            {t("employee.preview.profilePreview")}
          </Typography>
        </Box>
        <Typography
          ml={1}
          sx={{
            fontWeight: "600",
            fontSize: "16px",
            marginLeft: "-4px",
            marginBottom: "15px",
          }}
        >
          {/* Account */}
          {t("employee.preview.account")}
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start", // Align to the left
          }}
        >
          <Grid container spacing={2}>
            <Grid item>
              <Box
                sx={{
                  width: "100px",
                  height: "100px",
                  border: "1px solid black",
                  borderRadius: "5px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: "15px",
                }}
              >
                {userData?.profile_photo_url ? (
                  <UploadImage
                    imageUrl={`${process.env.REACT_APP_API_BASE_URL}/public/image/${userData?.profile_photo_url}`}
                    altText={userData?.profile_photo_url}
                    style={{
                      maxWidth: "100%",
                      maxHeight: "100%",
                      objectFit: "cover",
                      borderRadius: "5px",
                    }}
                  />
                ) : (
                  <AddIcon sx={{ fontSize: 30, color: "black" }} />
                )}
              </Box>
            </Grid>
            <Grid item>
              <Typography sx={{ fontWeight: "600", fontSize: "16px" }}>
                {" "}
                {userData?.full_name || "N/A"}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  mr: 0,
                  textDecoration: "none",
                  color: "gray",
                  fontSize: "11px",
                }}
              >
                {userData?.current_city || "N/A"}
              </Typography>

              <Typography
                variant="body2"
                sx={{
                  mr: 0,
                  textDecoration: "none",
                  color: "gray",
                  fontSize: "11px",
                }}
              >
                +{userData?.phone_number || "N/A"}
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Divider sx={{ my: 2 }} />
        <Box>
          <Typography
            ml={1}
            sx={{
              mt: 2,
              fontWeight: "600",
              fontSize: "22px",
              marginLeft: "-4px",
              marginBottom: "15px",
            }}
          >
            {/* Qualification */}
            {t("employee.preview.qulaification")}
          </Typography>

          <Typography variant="body1" paragraph>
            <strong>{t("onboarding.preferences.professional_area")}</strong>
            <Box component="span">
              {userData?.professional_area ? (
                <Typography variant="body1">
                  {userData.professional_area.replace(/([A-Z])/g, " $1").trim()}
                </Typography>
              ) : (
                <Typography variant="body1" component="span">
                  N/A
                </Typography>
              )}
            </Box>
          </Typography>

          <Typography variant="body1" paragraph>
            <strong>{t("employee.preview.Top Specialties")}</strong>
            <Box component="span">
              {(() => {
                const specialties = userData?.top_specialities
                  ? multiParse(userData.top_specialities)
                  : [];

                const specialtiesArray = Array.isArray(specialties)
                  ? specialties
                  : [];

                return specialtiesArray.length > 0 ? (
                  specialtiesArray.map((specialty, index) => (
                    <Typography
                      key={index}
                      variant="body1"
                      component="span"
                      sx={{ display: "inline", mr: 1 }}
                    >
                      <br />
                      {specialty}
                    </Typography>
                  ))
                ) : (
                  <Typography variant="body1" component="span">
                    N/A
                  </Typography>
                );
              })()}
            </Box>
          </Typography>

          <InputLabel
            mt={2}
            sx={{ fontWeight: "600", fontSize: "16px", color: "text.primary" }}
          >
            {/* Total Years of Experience */}
            {t("employee.preview.totalYearsExperience")}
          </InputLabel>
          <InputLabel mt={2} sx={{ fontSize: "14px", color: "text.primary" }}>
            {userData?.total_years_experience || "N/A"}
          </InputLabel>
        </Box>
        <Divider sx={{ my: 2 }} />
        <Box>
          <Typography
            ml={1}
            sx={{
              mt: 2,
              fontWeight: "600",
              fontSize: "22px",
              marginLeft: "-4px",
              marginBottom: "15px",
            }}
          >
            {/* Job Preferences */}
            {t("employee.preview.jobPreferences")}
          </Typography>

          <InputLabel
            mt={2}
            sx={{
              fontWeight: "600",
              fontSize: "16px",
              color: "text.primary",
              ml: -0.1,
            }}
          >
            {/* Type of Employment */}
            {t("employee.preview.typeOfEmployment")}
          </InputLabel>
          <Box mt={2}>
            {userData?.employment_type ? (
              <Typography
                ml={1}
                sx={{
                  fontSize: "14px",
                  color: "text.primary",
                  mt: -1,
                  ml: -0.1,
                }}
              >
                {multiParse(userData.employment_type)["fullTime"]
                  ? "Full Time"
                  : ""}
                {multiParse(userData.employment_type)["fullTime"] &&
                JSON.parse(userData.employment_type)["partTime"]
                  ? ", "
                  : ""}
                {multiParse(userData.employment_type)["partTime"]
                  ? "Part Time"
                  : ""}
              </Typography>
            ) : (
              <Typography
                ml={1}
                sx={{ fontSize: "14px", color: "text.primary" }}
              >
                {/* N/A */}
                {t("nA")}
              </Typography>
            )}
          </Box>
          <InputLabel
            mt={2}
            sx={{
              fontWeight: "600",
              fontSize: "16px",
              color: "text.primary",
              ml: -0.1,
            }}
          >
            {/* Job Search Status */}
            {t("employee.preview.jobSearchStatus")}
          </InputLabel>
          <InputLabel
            mt={2}
            sx={{ fontSize: "14px", color: "text.primary", ml: -0.1 }}
          >
            {userData?.job_search_status || "N/A"}
          </InputLabel>
        </Box>
        <Divider sx={{ my: 2 }} />
        <Box>
          <Typography
            ml={1}
            sx={{
              mt: 2,
              fontWeight: "600",
              fontSize: "22px",
              marginLeft: "-4px",
              marginBottom: "15px",
              ml: -1,
            }}
          >
            {/* Location */}
            {t("employee.preview.location")}
          </Typography>
          <InputLabel
            mt={2}
            sx={{
              fontWeight: "600",
              fontSize: "16px",
              color: "text.primary",
              ml: -0.5,
            }}
          >
            {/* Remote Work */}
            {t("employee.preview.remoteWork")}
          </InputLabel>
          <InputLabel
            mt={2}
            sx={{ fontSize: "14px", color: "text.primary", ml: -0.5 }}
          >
            {userData?.remote_work ? "Yes" : "No"}
          </InputLabel>
          <InputLabel
            mt={2}
            sx={{
              fontWeight: "600",
              fontSize: "16px",
              color: "text.primary",
              ml: -0.5,
            }}
          >
            {/* Relocation */}
            {t("employee.preview.relocation")}
          </InputLabel>
          <InputLabel
            mt={2}
            sx={{ fontSize: "14px", color: "text.primary", ml: -0.5 }}
          >
            {userData?.relocation ? "Yes" : "No"}
          </InputLabel>
        </Box>
        <Divider sx={{ my: 2 }} />
        <Box>
          <Typography
            ml={1}
            sx={{
              mt: 2,
              fontWeight: "600",
              fontSize: "22px",
              marginLeft: "-4px",
              marginBottom: "15px",
            }}
          >
            {/* Salary Expectation */}
            {t("employee.preview.salaryExpectation")}
          </Typography>
          <Box mt={2}>
            <InputLabel
              sx={{
                fontWeight: "600",
                fontSize: "16px",
                color: "text.primary",
                ml: -0.5,
              }}
            >
              {/* Your Preferred Net Salary */}
              {t("employee.preview.yourPreferredNetSalary")}
            </InputLabel>
            {userData?.salary_range ? (
              <Typography
                ml={1}
                sx={{ fontSize: "14px", color: "text.primary", ml: -0.3 }}
              >
                <strong> {t("employee.aboutyou.From")}:</strong>{" "}
                {multiParse(userData.salary_range).from}{" "}
                <strong> {t("employee.aboutyou.To")}:</strong>{" "}
                {multiParse(userData.salary_range).to}
              </Typography>
            ) : (
              <Typography
                ml={1}
                sx={{ fontSize: "14px", color: "text.primary" }}
              >
                {/* N/A */}
                {t("nA")}
              </Typography>
            )}
          </Box>
        </Box>

        <Divider sx={{ my: 2 }} />
        <Box>
          <Typography
            ml={1}
            sx={{
              mt: 2,
              fontWeight: "600",
              fontSize: "22px",
              marginLeft: "-4px",
              marginBottom: "15px",
            }}
          >
            {/* Links */}
            {t("employee.aboutyou.Links")}
          </Typography>
          <Typography
            ml={1}
            sx={{ fontSize: "14px", color: "text.primary", ml: -0.5 }}
          >
            {userData?.linkedin_url || "N/A"}
          </Typography>
          <Typography
            ml={1}
            sx={{ fontSize: "14px", color: "text.primary", ml: -0.5 }}
          >
            {userData?.personal_blog_url || "N/A"}
          </Typography>
          {/* Add Link Details */}
        </Box>
        <Divider sx={{ my: 2 }} />
        <Box>
          <Typography
            ml={1}
            sx={{
              mt: 2,
              fontWeight: "600",
              fontSize: "22px",
              marginLeft: "-4px",
              marginBottom: "15px",
            }}
          >
            {/* Work Experience */}
            {t("employee.preview.workExperience")}
          </Typography>
          <Box mt={2}>
            <Typography
              ml={1}
              sx={{ fontSize: "14px", color: "text.primary", ml: -0.5 }}
            >
              <strong>{t("employee.preview.companyName")}:</strong>{" "}
              {workExp.companies}
            </Typography>
            <Typography
              ml={1}
              sx={{ fontSize: "14px", color: "text.primary", ml: -0.5 }}
            >
              <strong>{t("employee.preview.jobTitle")}:</strong>{" "}
              {workExp.titles}
            </Typography>
            <Typography
              ml={1}
              sx={{ fontSize: "14px", color: "text.primary", ml: -0.5 }}
            >
              <strong>{t("employee.preview.startDate")}:</strong>{" "}
              {workExp.startDates}
            </Typography>
            <Typography
              ml={1}
              sx={{ fontSize: "14px", color: "text.primary", ml: -0.5 }}
            >
              <strong>{t("employee.preview.endDate")}:</strong>{" "}
              {workExp.endDates}
            </Typography>
            <Typography
              ml={1}
              sx={{ fontSize: "14px", color: "text.primary", ml: -0.5 }}
            >
              <strong>{t("employee.background.Description")}:</strong>{" "}
              {workExp.descriptions}
            </Typography>
          </Box>
        </Box>

        <Divider sx={{ my: 2 }} />

        <Box>
          <Typography
            ml={1}
            sx={{
              mt: 2,
              fontWeight: "600",
              fontSize: "22px",
              marginLeft: "-4px",
              marginBottom: "15px",
            }}
          >
            {/* Education */}
            {t("employee.preview.education")}
          </Typography>
          <Box mt={2}>
            <Typography
              ml={1}
              sx={{ fontSize: "14px", color: "text.primary", ml: -0.5 }}
            >
              <strong>{t("employee.preview.universityOrCollege")}:</strong>{" "}
              {edu.universities}
            </Typography>
            <Typography
              ml={1}
              sx={{ fontSize: "14px", color: "text.primary", ml: -0.5 }}
            >
              <strong>{t("employee.preview.graduationYear")}:</strong>{" "}
              {edu.graduationYears}
            </Typography>
            <Typography
              ml={1}
              sx={{ fontSize: "14px", color: "text.primary", ml: -0.5 }}
            >
              <strong>{t("employee.preview.fieldOfStudy")}:</strong>{" "}
              {edu.fieldsOfStudy}
            </Typography>
            <Typography
              ml={1}
              sx={{ fontSize: "14px", color: "text.primary", ml: -0.5 }}
            >
              <strong>{t("employee.preview.degree")}:</strong> {edu.degrees}
            </Typography>
          </Box>
        </Box>

        <Divider sx={{ my: 2 }} />

        <Box>
          <Typography
            ml={1}
            sx={{
              mt: 2,
              fontWeight: "600",
              fontSize: "22px",
              marginLeft: "-4px",
              marginBottom: "15px",
            }}
          >
            {t("employee.preview.language")}
          </Typography>
          <Box mt={2}>
            <Typography
              ml={1}
              sx={{ fontSize: "14px", color: "text.primary", ml: -0.5 }}
            >
              <strong>{t("employee.preview.languages")}:</strong>{" "}
              {lang.languages}
            </Typography>
            <Typography
              ml={1}
              sx={{ fontSize: "14px", color: "text.primary", ml: -0.5 }}
            >
              <strong>{t("employee.preview.levels")}:</strong> {lang.levels}
            </Typography>
          </Box>
        </Box>
        <Divider sx={{ my: 2 }} />
        <Box>
          <Typography
            ml={1}
            sx={{
              mt: 2,
              fontWeight: "600",
              fontSize: "22px",
              marginLeft: "-4px",
              marginBottom: "15px",
            }}
          >
            {t("employee.preview.additionalInformation")}
          </Typography>
          <InputLabel
            mt={2}
            sx={{ fontWeight: "600", fontSize: "16px", color: "text.primary" }}
          >
            {t("employee.background.Objective")}
          </InputLabel>
          <InputLabel mt={2} sx={{ fontSize: "14px", color: "text.primary" }}>
            {userData?.objective || t("na")}
          </InputLabel>
          <InputLabel
            mt={2}
            sx={{ fontWeight: "600", fontSize: "16px", color: "text.primary" }}
          >
            {t("employee.background.Achievements")}
          </InputLabel>
          <InputLabel mt={2} sx={{ fontSize: "14px", color: "text.primary" }}>
            {userData?.achievements || t("na")}
          </InputLabel>
          <InputLabel
            mt={2}
            sx={{ fontWeight: "600", fontSize: "16px", color: "text.primary" }}
          >
            {t("employee.preview.personalCharacteristics")}
          </InputLabel>
          <InputLabel mt={2} sx={{ fontSize: "14px", color: "text.primary" }}>
            {userData?.personal_characteristics || t("na")}
          </InputLabel>
        </Box>

        <Divider sx={{ my: 2 }} />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mt: 3,
          }}
        >
          <Button
            type="submit"
            onClick={() => navigate("/v1/employee/background")}
            variant="outlined"
            sx={{
              mt: 3,
              mb: 2,
              backgroundColor: "white",
              borderColor: "primary.main",
              color: "text.secondary",
              "&:hover": {
                backgroundColor: "text.secondary",
              },
            }}
          >
            <Typography
              component="h2"
              variant="body1"
              sx={{
                alignSelf: "flex-start",
                fontSize: "15px",
                fontWeight: "bold",
                textTransform: "none",
                color: "primary.main",
                padding: "2px",
              }}
            >
              {/* Back */}
              {t("employee.background.Back")}
            </Typography>
          </Button>
          <Button
            type="submit"
            variant="contained"
            onClick={async () => {
              formikValues.is_onboarding = true;

              setIsLoading(true);
              await submitForm(formikValues);
            }}
            disabled={isLoading}
            sx={{
              mt: 3,
              mb: 2,
              backgroundColor: "secondary.main",
              color: "white",
              "&:hover": {
                backgroundColor: "secondary.main",
              },
            }}
          >
            <Typography
              component="h2"
              variant="body1"
              sx={{
                alignSelf: "flex-start",
                fontSize: "15px",
                fontWeight: "bold",
                textTransform: "none",
                color: "primary.main",
                padding: "2px",
              }}
            >
              {/* Submit */}
              {t("employee.preview.submit")}
            </Typography>
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default PreviewAndSubmit;
