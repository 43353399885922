import React, { useEffect } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { ToastContainer, Slide, toast } from 'react-toastify';
import theme from './theme';
import AppRoute from './router';
import 'react-toastify/dist/ReactToastify.css';
import { useSocket } from './SocketProvider'; // Use the custom hook
import JobContext from "./context/JobContext";

const toastOptions = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  newestOnTop: false,
  rtl: false,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  draggable: true,
  pauseOnHover: true,
  progress: undefined,
  theme: "light",
  zIndex: 9999
};
// notificationRecieved
function App() {
  const socket = useSocket(); // Get socket instance from context
  const { addNewMessage } = React.useContext(JobContext);

  useEffect(() => {
    if (socket) {
      socket.on('connect', () => {
        // updateSocketId
        const userId = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).id : null;
        socket.emit('updateSocketId', { userId: userId, socketId: socket.id });
        localStorage.setItem("socketId", socket.id);
        socket.on('notificationRecieved', (data) => {
          toast.success(data.message);
        });

        socket.on('chatNotification', (data) => {
          addNewMessage(data);
          toast.success(data.message);
        }

        );
      });
    }
  }, [socket]);
  return (
    <ThemeProvider theme={theme}>
      <ToastContainer transition={Slide} {...toastOptions} />
      <AppRoute />
    </ThemeProvider>
  );
}

export default App;
