import React, { useState, useEffect } from "react";
import {
  Typography,
  TextField,
  Button,
  Grid,
  Radio,
  RadioGroup,
  FormControlLabel,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Divider,
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { JobPostApi, UploadImageApi } from "../../apis";
import { OnBoardingApi } from "../../apis";
import UploadImage from "../GlobalComponent/UploadImage";
// import data from "../../components/GlobalComponent/data.json";
import ExperienceForm from "../GlobalComponent/ExperienceForm";
import DynamicAutocomplete from "../GlobalComponent/CityFinder";
import ProfessionApi from "../../apis/routes/professionRoutes";
import ProfessionSpecialtySelect from "../CustomSelector/ProfessionSpecialtySelect";
import { useTranslation } from "react-i18next";

const validationSchema = yup.object({
  jobDetails: yup.object().shape({
    // positionName: yup.string().required('Position name is required'),
    location: yup.string().required("Location is required"),
    salaryRange: yup.object().shape({
      from: yup.string().required("Salary is required"),
      to: yup.string().required("Salary is Required"),
    }),
  }),
});

const CreateJobPostStep1 = ({ formData, setFormData, nextStep }) => {
  const { t } = useTranslation();
  const [selectedCategories, setSelectedCategories] = useState({});
  const [selectedCategory, setSelectedCategory] = useState("");
  const [profilePhoto, setProfilePhoto] = useState(null);
  const [profilePhotoPreview, setProfilePhotoPreview] = useState(null);
  const [selectedSpecialty, setSelectedSpecialty] = useState("");

  const [showAutocomplete, setShowAutocomplete] = useState(false);

  const [professions, setProfessions] = useState({});
  const [selectedProfession, setSelectedProfession] = useState("");
  const [specialties, setSpecialties] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchProfessions = async () => {
    try {
      setLoading(true);
      const response = await ProfessionApi.getAllProfessions();
      const professionList = response.data?.subcategories || {};
      setProfessions(professionList);
    } catch (err) {
      setError("Failed to load professions.");
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchProfessions();
  }, []);

  const handleLocationChange = (event) => {
    const { value } = event.target;
    formik.setFieldValue("jobDetails.location", value);

    // Show DynamicAutocomplete if location is "onsite" or "hybrid"
    setShowAutocomplete(value === "onsite" || value === "hybrid");
  };

  const handleProfilePhotoChange = (event) => {
    const file = event.target.files[0];
    setProfilePhoto(file);
    setProfilePhotoPreview(URL.createObjectURL(file));
  };

  const handleProfilePhotoUpload = async () => {
    if (profilePhoto) {
      try {
        const formData = new FormData();
        formData.append("file", profilePhoto);

        const res = await UploadImageApi.uploadImage(formData);
        formik.setFieldValue("jobDetails.photo", res.data.data.name);
        return res.data.data.name; // Return only the name of the uploaded photo
      } catch (error) {
        console.error("Error uploading profile photo:", error);
      }
    }
    return null;
  };

  const formik = useFormik({
    initialValues: formData,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        const profilePhotoName = await handleProfilePhotoUpload(); // Upload the profile photo and get the URL data
        if (profilePhotoName) {
          values.jobDetails.photo = profilePhotoName; // Set profile_photo_url to the photo name
        }

        setFormData(values);
        nextStep();
      } catch (error) {
        // Handle API error
        console.error("Error submitting form:", error);
      }
    },
  });
  const handleSpecialtyChange = (event) => {
    const selected = event.target.value;
    // Set the selected profession
    setSelectedProfession(selected);
    // If selected profession exists in the professions list, update available specialties
    if (selected && professions[selected]) {
      // Set the available specialties for the selected profession but do not pre-select them
      setSpecialties(professions[selected]);
    }
    formik.setFieldValue("jobDetails.professional_area", selected); // Clear top_specialities
    // Explicitly reset top_specialities when professional_area changes
    formik.setFieldValue("jobDetails.top_specialities", null); // Clear top_specialities
    formik.setFieldValue("formData.top_specialities", null); // Clear top_specialities
  };

  const handleSpecialtySelect = (specialty) => {
    // Update the selected specialty in the state
    setSelectedSpecialty(specialty);

    // If the specialty is valid and exists in the specialties object, set it
    if (specialty && specialties[specialty]) {
      setSelectedSpecialty(specialties[specialty]);
    }

    // Set the new `top_specialities` (overwrite previous selections)
    formik.setFieldValue("jobDetails.top_specialities", specialty); // Set to array with selected specialty

    // Optionally reset any other related fields if necessary
  };

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  const categories = Object.keys(formData.jobDetails.experienceYears);

  const handleInputSalaryChange = (event) => {
    const { name, value } = event.target;
    let formattedValue = value.replace(/,/g, ""); // Remove existing commas
    // Add commas to the number
    formik.setFieldValue(name, formattedValue);
  };

  const handleEmploymentTypeChange = (event) => {
    const { name, checked } = event.target;

    // Set the clicked checkbox value
    formik.setFieldValue(`jobDetails.employmentType.${name}`, checked);

    // Determine the other checkbox to uncheck
    const otherEmploymentType = name === "partTime" ? "fullTime" : "partTime";

    // Uncheck the other checkbox if the clicked one is checked
    if (checked) {
      formik.setFieldValue(
        `jobDetails.employmentType.${otherEmploymentType}`,
        false
      );
    }
  };
  const getSpecialityColor = (speciality) => {
    return speciality === "Select Speciality" ? "#aaa" : "black";
  };

  const handleExperienceChange = (event) => {
    const { name, value } = event.target;
    formik.setFieldValue(`jobDetails.experienceYears.${name}`, value);
  };

  const handleTotalExperienceChange = (event) => {
    const { name, value } = event.target;
    formik.setFieldValue(`jobDetails.${name}`, value);
  };
  const getCityColor = (city) => {
    return city === "Enter City" ? "#aaa" : "black";
  };
  // Safely extract true specialties
  const trueTopCity = formik.values.jobDetails.city;
  const formattedCity = trueTopCity
    ? trueTopCity // Add space between words
    : "Enter City"; // Default value when no true speciality

  const cityColor = getCityColor(formattedCity);
  const experienceList = [
    t("recruiter.create-job-post.experience.Less than 1 year"),
    t("recruiter.create-job-post.experience.1-3 years"),
    t("recruiter.create-job-post.experience.3-5 years"),
    t("recruiter.create-job-post.experience.5+ years"),
  ];
  return (
    <form onSubmit={formik.handleSubmit}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
        }}
      ></Box>

      <Grid
        container
        spacing={2}
        sx={{ paddingTop: 1, paddingLeft: 1, backgroundColor: "#F5F5F5" }}
      >
        <Grid item xs={12} sm={12}>
          <InputLabel
            mt={2}
            sx={{ fontWeight: "600", fontSize: "16px", color: "text.primary" }}
          >
            {t("recruiter.create-job-post.Position Name *")}
          </InputLabel>
          <FormControl fullWidth>
            <TextField
              placeholder={t("recruiter.create-job-post.Position Name *")}
              name="jobDetails.positionName"
              value={formik.values.jobDetails.positionName}
              onChange={formik.handleChange}
              error={
                formik.touched.jobDetails?.positionName &&
                Boolean(formik.errors.jobDetails?.positionName)
              }
              helperText={
                formik.touched.jobDetails?.positionName &&
                formik.errors.jobDetails?.positionName
                  ? formik.errors.jobDetails?.positionName
                  : null
              }
              variant="outlined"
            />
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <ProfessionSpecialtySelect
            selectedSpecialty={handleSpecialtySelect}
            specialties={specialties}
            selectedProfession={selectedProfession}
            handleSpecialtyChange={handleSpecialtyChange}
            professionList={professions}
            loading={loading}
            error={error}
            getSpecialityColor={getSpecialityColor}
            formik={formik}
            module="recruiter"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputLabel
            sx={{
              fontWeight: "600",
              fontSize: "16px",
              color: "text.primary",
              marginTop: "-4px",
            }}
          >
            {t("onboarding.preferences.location")} *
          </InputLabel>
          <FormControl fullWidth>
            <Select
              name="jobDetails.location"
              value={formik.values.jobDetails.location}
              onChange={handleLocationChange} // Custom change handler
              onBlur={formik.handleBlur}
              displayEmpty
              renderValue={(selected) => {
                if (selected === "") {
                  return (
                    <span style={{ color: "#aaa" }}>
                      {t("recruiter.create-job-post.Select Location")}
                    </span>
                  );
                }
                return selected;
              }}
              error={
                formik.touched.jobDetails?.location &&
                Boolean(formik.errors.jobDetails?.location)
              }
            >
              <MenuItem value="" disabled>
                <em>{t("recruiter.create-job-post.Select Location")}</em>
              </MenuItem>
              <MenuItem value={t("recruiter.create-job-post.Remote")}>
                {t("recruiter.create-job-post.Remote")}
              </MenuItem>
              <MenuItem value={t("recruiter.create-job-post.On-site")}>
                {t("recruiter.create-job-post.On-site")}
              </MenuItem>
              <MenuItem value={t("recruiter.create-job-post.Hybrid")}>
                {t("recruiter.create-job-post.Hybrid")}
              </MenuItem>
            </Select>
            {formik.touched.jobDetails?.location &&
              formik.errors.jobDetails?.location && (
                <Typography variant="body2" color="error">
                  {formik.errors.jobDetails.location}
                </Typography>
              )}
          </FormControl>

          {/* Conditionally render DynamicAutocomplete only if location is "onsite" or "hybrid" */}
          {formik.values.jobDetails.location === "On-site" || 
          formik.values.jobDetails.location === "Presencial" ||
          formik.values.jobDetails.location === "Híbrido" ||
          formik.values.jobDetails.location === "Hybrid" ? (
            <>
              <InputLabel
                mt={2}
                sx={{
                  fontWeight: "600",
                  fontSize: "14px",
                  color: "text.primary",
                  ml: 0.3,
                  mt: 1,
                }}
                shrink={false}
              >
                Select City
              </InputLabel>
              <DynamicAutocomplete
                color={cityColor}
                placeHolder={formattedCity}
                formik={formik}
                fieldName="jobDetails.city" // Reflects the correct city field in formik
                value={formik.values.jobDetails.city} // Adjusted to reflect the city field in formik
                // Uncomment fetchOptions to load city options dynamically
                // fetchOptions={fetchCities}
              />
            </>
          ) : null}
        </Grid>
        {/*   {/* Total Years of Experience */}
        <Grid item xs={12} sm={6}>
          <Box mt={2}>
            <InputLabel
              sx={{
                fontWeight: "600",
                fontSize: "15.5px",
                color: "text.primary",
                marginTop: "-20px",
              }}
            >
              {t("onboarding.preferences.total_years_experience")}*
            </InputLabel>
            <FormControl fullWidth>
              <Select
                name="totalYearsExperience"
                value={formik.values.jobDetails.totalYearsExperience}
                onChange={handleTotalExperienceChange}
                displayEmpty
                renderValue={(selected) => {
                  if (!selected) {
                    return (
                      <span style={{ color: "#aaa" }}>
                        {t("recruiter.create-job-post.selecttotalexperience")}
                      </span>
                    );
                  }
                  return selected;
                }}
              >
                <MenuItem value="" disabled>
                  <em>
                    {" "}
                    {t("recruiter.create-job-post.selecttotalexperience")}
                  </em>
                </MenuItem>
                {experienceList.map((experience) => (
                  <MenuItem key={experience} value={experience}>
                    {experience} years
                  </MenuItem>
                ))}
              </Select>
              {formik.touched.jobDetails?.totalYearsExperience &&
                formik.errors.jobDetails?.totalYearsExperience && (
                  <Typography variant="body2" color="error">
                    {formik.errors.jobDetails.totalYearsExperience}
                  </Typography>
                )}
            </FormControl>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <InputLabel
            mt={3}
            sx={{ fontWeight: "600", fontSize: "16px", color: "text.primary" }}
          >
            {t("onboarding.preferences.employment_type")}
          </InputLabel>
          <Box style={{ display: "flex", flexWrap: "wrap" }}>
            <FormControlLabel
              control={
                <Checkbox
                  name="partTime"
                  checked={formik.values.jobDetails.employmentType.partTime}
                  onChange={handleEmploymentTypeChange}
                  sx={{
                    color: "gray",
                    "&.Mui-checked": { color: "secondary.main" },
                  }}
                />
              }
              label={t("onboarding.preferences.part_time")}
              sx={{ mr: 5, mb: 1 }}
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="fullTime"
                  checked={formik.values.jobDetails.employmentType.fullTime}
                  onChange={handleEmploymentTypeChange}
                  sx={{
                    color: "gray",
                    "&.Mui-checked": { color: "secondary.main" },
                  }}
                />
              }
              label={t("onboarding.preferences.full_time")}
              sx={{ mr: 5, mb: 1 }}
            />
          </Box>

          {formik.touched.jobDetails?.employmentType &&
            formik.errors.jobDetails?.employmentType && (
              <Typography variant="body2" color="error">
                {formik.errors.jobDetails.employmentType}
              </Typography>
            )}
        </Grid>

        <Grid item xs={12}>
          <InputLabel
            mt={2}
            sx={{
              fontWeight: "600",
              fontSize: { xs: "12px", sm: "16px" },
              color: "text.primary",
              whiteSpace: { xs: "normal", sm: "nowrap" }, // Allow wrapping on small screens
              overflow: "hidden", // Ensure no overflow
              textOverflow: "ellipsis", // Add ellipsis for long text if necessary
            }}
          >
            {t("recruiter.create-job-post.removecandidates")}
          </InputLabel>
          <FormControl
            component="fieldset"
            sx={{ display: "flex", flexDirection: { xs: "column", sm: "row" } }}
          >
            <RadioGroup
              aria-label="remoteCandidates"
              name="jobDetails.remoteCandidates"
              value={formik.values.jobDetails.remoteCandidates}
              onChange={formik.handleChange}
              sx={{
                flexDirection: { xs: "column", sm: "row" },
                marginTop: { xs: 1, sm: 0 },
              }} // Stack on small screens
            >
              <FormControlLabel
                value={t("common.yes")}
                control={
                  <Radio
                    sx={{
                      color: "gray",
                      "&.Mui-checked": { color: "secondary.main" },
                    }}
                  />
                }
                label={t("common.yes")}
                sx={{
                  marginRight: { sm: "20px" },
                  marginBottom: { xs: 1, sm: 0 },
                }} // Adjust margin for small screens
              />
              <FormControlLabel
                value={t("common.no")}
                control={
                  <Radio
                    sx={{
                      color: "gray",
                      "&.Mui-checked": { color: "secondary.main" },
                    }}
                  />
                }
                label={t("common.no")}
                sx={{
                  marginRight: { sm: "20px" },
                  marginBottom: { xs: 1, sm: 0 },
                }} // Adjust margin for small screens
              />
              <FormControlLabel
                value={t("recruiter.create-job-post.Remote only")}
                control={
                  <Radio
                    sx={{
                      color: "gray",
                      "&.Mui-checked": { color: "secondary.main" },
                    }}
                  />
                }
                label={t("recruiter.create-job-post.Remote only")}
                sx={{ marginBottom: { xs: 1, sm: 0 } }} // Adjust margin for small screens
              />
            </RadioGroup>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <InputLabel
            mt={2}
            sx={{
              fontWeight: "600",
              fontSize: { xs: "12px", sm: "16px" },
              color: "text.primary",
              whiteSpace: { xs: "normal", sm: "nowrap" }, // Allow wrapping on small screens
              overflow: "hidden", // Ensure no overflow
              textOverflow: "ellipsis", // Add ellipsis for long text if necessary
            }}
          >
            {t("recruiter.create-job-post.relocate")}
          </InputLabel>
          <FormControl
            component="fieldset"
            sx={{ display: "flex", flexDirection: { xs: "column", sm: "row" } }}
          >
            <RadioGroup
              aria-label="willingToRelocate"
              name="jobDetails.willingToRelocate"
              value={formik.values.jobDetails.willingToRelocate}
              onChange={formik.handleChange}
              sx={{
                flexDirection: { xs: "column", sm: "row" },
                marginTop: { xs: 1, sm: 0 },
              }} // Stack on small screens
            >
              <FormControlLabel
                value={t("common.yes")}
                control={
                  <Radio
                    sx={{
                      color: "gray",
                      "&.Mui-checked": { color: "secondary.main" },
                    }}
                  />
                }
                label={t("common.yes")}
                sx={{
                  marginRight: { sm: "20px" },
                  marginBottom: { xs: 1, sm: 0 },
                }} // Adjust margin for small screens
              />
              <FormControlLabel
                value={t("common.no")}
                control={
                  <Radio
                    sx={{
                      color: "gray",
                      "&.Mui-checked": { color: "secondary.main" },
                    }}
                  />
                }
                label={t("common.no")}
                sx={{
                  marginRight: { sm: "20px" },
                  marginBottom: { xs: 1, sm: 0 },
                }} // Adjust margin for small screens
              />
            </RadioGroup>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
          <InputLabel
            mt={2}
            sx={{ fontWeight: "600", fontSize: "14px", color: "text.primary" }}
          >
            {t("recruiter.create-job-post.salary-range")}
          </InputLabel>
          <TextField
            type="number"
            fullWidth
            placeholder={t("employee.aboutyou.From")}
            name="jobDetails.salaryRange.from"
            value={formik.values.jobDetails.salaryRange.from}
            onChange={handleInputSalaryChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.jobDetails?.salaryRange?.from &&
              Boolean(formik.errors.jobDetails?.salaryRange?.from)
            }
            helperText={
              formik.touched.jobDetails?.salaryRange?.from &&
              formik.errors.jobDetails?.salaryRange?.from
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputLabel
            mt={2}
            sx={{ fontWeight: "600", fontSize: "14px", color: "text.primary" }}
          >
            {t("employee.aboutyou.To")} *
          </InputLabel>
          <TextField
            type="number"
            fullWidth
            placeholder={t("employee.aboutyou.To")}
            name="jobDetails.salaryRange.to"
            value={formik.values.jobDetails.salaryRange.to}
            onChange={handleInputSalaryChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.jobDetails?.salaryRange?.to &&
              Boolean(formik.errors.jobDetails?.salaryRange?.to)
            }
            helperText={
              formik.touched.jobDetails?.salaryRange?.to &&
              formik.errors.jobDetails?.salaryRange?.to
            }
          />
        </Grid>

        <Grid item xs={12}>
          <Divider />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "20px",
            }}
          >
            <Button
              type="Submit"
              variant="contained"
              sx={{
                mt: 3,
                mb: 2,
                backgroundColor: "secondary.main",
                color: "white",
                "&:hover": {
                  backgroundColor: "secondary.dark",
                },
                marginLeft: "auto",
              }}
            >
              <Typography
                component="h2"
                variant="body1"
                sx={{
                  fontSize: "15px",
                  fontWeight: "bold",
                  textTransform: "none",
                  color: "text.primary",
                  padding: "2px",
                }}
              >
                {t("recruiter.create-job-post.next")}
              </Typography>
            </Button>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
};

export default CreateJobPostStep1;
