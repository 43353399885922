import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  Grid,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Divider,
  InputLabel,
  MenuItem,
  Select,
  FormControl,
  Chip,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import { OnBoardingApi, UploadImageApi } from "../../apis";
import { useFormik } from "formik";
import * as Yup from "yup";
import UploadImage from "../GlobalComponent/UploadImage";
import CustomAutocomplete from "../GlobalComponent/customAutoComplete";
import Loader from "../GlobalComponent/Loader";
import DynamicAutocomplete from "../GlobalComponent/CityFinder";
import PhoneInput from "react-phone-input-2"; // Import the PhoneInput component
import "react-phone-input-2/lib/style.css"; // Import the default styles
import { useTranslation } from "react-i18next";

const MyProfile = () => {
  const { t } = useTranslation();
  const [openPersonalInfo, setOpenPersonalInfo] = useState(false);
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState(null);
  const [profilePhoto, setProfilePhoto] = useState(null);
  const [profilePhotoPreview, setProfilePhotoPreview] = useState(null);
  const [companyProfilePhoto, setCompanyProfilePhoto] = useState(null);
  const [companyProfilePhotoPreview, setCompanyProfilePhotoPreview] =
    useState(null);
  const [openCompanyInfo, setOpenCompanyInfo] = useState(false);

  const fetchOnBoardedUserData = async () => {
    try {
      const res = await OnBoardingApi.getOnBoarding();
      // Check if company_perks exists and is a non-empty string before parsing
      const companyPerks =
        res.data.profile.company_perks &&
        typeof res.data.profile.company_perks === "string"
          ? JSON.parse(res.data.profile.company_perks)
          : {};

      formik.setValues({
        recruiter_profile: {
          full_name: res.data.profile.full_name,
          phone_number: res.data.profile.phone_number,
          linkedin_url: res.data.profile.linkedin_url,
          profile_photo_url: res.data.profile.profile_photo_url || "",
        },
        company_profile: {
          company_profile_photo_url:
            res.data.profile.company_profile_photo_url || "",
          company_name: res.data.profile.company_name,
          company_slogan: res.data.profile.company_slogan,
          company_location: res.data.profile.company_location,
          company_size: res.data.profile.company_size,
          company_domain: res.data.profile.company_domain,
          company_overview: res.data.profile.company_overview,
          company_website: res.data.profile.company_website,
          company_perks: res.data.profile.company_perks || companyPerks,
        },
      });
      setUserData(res.data.profile);
      setProfilePhotoPreview(res.data.profile.profile_photo_url.path); // Assuming path is the URL to display
      setCompanyProfilePhotoPreview(
        res.data.profile.company_profile_photo_url.path
      );
      setLoading(false);
    } catch (error) {
      console.error("Error fetching on-boarded user data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchOnBoardedUserData();
  }, []);

  const renderCompanyPerks = () => {
    const perks = [];

    try {
      let companyPerks = {};

      // Check if userData and company_perks are available and it's an object
      if (
        userData &&
        userData?.company_perks &&
        typeof userData?.company_perks === "object"
      ) {
        companyPerks = userData?.company_perks;

        Object.keys(companyPerks).forEach((perk) => {
          if (companyPerks[perk]) {
            perks.push(
              <Chip
                key={perk}
                label={perk.replace(/_/g, " ")} // Replace underscores with spaces for display
                style={{ margin: "5px" }}
              />
            );
          }
        });
      }
    } catch (error) {
      console.error("Error parsing company perks:", error);
    }

    return perks;
  };
  const handleProfilePhotoChange = (event) => {
    const file = event.target.files[0];
    setProfilePhoto(file);
    setProfilePhotoPreview(URL.createObjectURL(file));
  };

  const handleCompanyProfilePhotoChange = (event) => {
    const file = event.target.files[0];
    setCompanyProfilePhoto(file);
    setCompanyProfilePhotoPreview(URL.createObjectURL(file));
  };
  const handlePerkChange = (perkName) => {
    const updatedPerks = {
      ...formik.values.company_profile.company_perks,
      [perkName]: !formik.values.company_profile.company_perks[perkName],
    };

    formik.handleChange({
      target: {
        name: "company_profile.company_perks",
        value: updatedPerks,
      },
    });
  };

  const companyPerksList = [
    {
      label: t("recruiter.company-profile.perks.Flexible working hours"),
      name: "flexible_working_hours",
    },
    {
      label: t("recruiter.company-profile.perks.Remote work"),
      name: "remote_work",
    },
    {
      label: t("recruiter.company-profile.perks.Medical insurance"),
      name: "medical_insurance",
    },
    {
      label: t("recruiter.company-profile.perks.Paid vacation"),
      name: "paid_vacation",
    },
    {
      label: t("recruiter.company-profile.perks.Paid sick leaves"),
      name: "paid_sick_leaves",
    },
    {
      label: t("recruiter.company-profile.perks.Parental leave"),
      name: "parental_leave",
    },
    {
      label: t("recruiter.company-profile.perks.Corporate events"),
      name: "corporate_events",
    },
    {
      label: t("recruiter.company-profile.perks.Team-buildings"),
      name: "team_buildings",
    },
    {
      label: t("recruiter.company-profile.perks.Sport activities"),
      name: "sport_activities",
    },
    {
      label: t("recruiter.company-profile.perks.Lunch compensation"),
      name: "lunch_compensation",
    },
    {
      label: t("recruiter.company-profile.perks.Free meals"),
      name: "free_meals",
    },
    {
      label: t("recruiter.company-profile.perks.Wellness program"),
      name: "wellness_program",
    },
  ];

  const handleProfilePhotoUpload = async () => {
    if (profilePhoto) {
      try {
        const formData = new FormData();
        formData.append("file", profilePhoto);

        const res = await UploadImageApi.uploadImage(formData);
        return res.data.data.name; // Return only the name of the uploaded photo
      } catch (error) {
        console.error("Error uploading profile photo:", error);
      }
    }
  };
  const handleCompanyProfilePhotoUpload = async () => {
    if (companyProfilePhoto) {
      try {
        const formData = new FormData();
        formData.append("file", companyProfilePhoto);

        const res = await OnBoardingApi.uploadImage(formData);
        return res.data.data.name; // Return only the name of the uploaded photo
      } catch (error) {
        console.error("Error uploading profile photo:", error);
      }
    }
  };
  const formik = useFormik({
    initialValues: {
      recruiter_profile: {
        full_name: "",
        phone_number: "",
        linkedin_url: "",
        profile_photo_url: "",
      },
      company_profile: {
        company_profile_photo_url: "",
        company_name: "",
        company_slogan: "",
        company_location: "",
        company_size: "",
        company_domain: "",
        company_overview: "",
        company_website: "",
      },
    },
    validationSchema: Yup.object({
      recruiter_profile: Yup.object({
        full_name: Yup.string().required("Full Name is required"),
        phone_number: Yup.string().required("Phone No is required"),
      }),
      company_profile: Yup.object({
        company_name: Yup.string().required("Company Name is required"),
      }),
    }),
    onSubmit: async (values) => {
      try {
        const profilePhotoName = await handleProfilePhotoUpload(); // Upload the profile photo and get the URL data
        const companyProfilePhotoName = await handleCompanyProfilePhotoUpload();
        if (profilePhotoName) {
          values.recruiter_profile.profile_photo_url = profilePhotoName; // Set profile_photo_url to the photo name
        }
        if (companyProfilePhotoName) {
          values.company_profile.company_profile_photo_url =
            companyProfilePhotoName;
        }
        const res = await OnBoardingApi.OnBoarding(values);

        setOpenPersonalInfo(false);
        setOpenCompanyInfo(false);
        fetchOnBoardedUserData();
      } catch (err) {
        console.error(err);
      }
    },
  });

  if (loading) {
    return (
      <div>
        <Loader loading={loading} />
      </div>
    );
  }
  const getCityColor = (city) => {
    return city === "Enter City" ? "#aaa" : "black";
  };
  // Safely extract true specialties
  const trueTopCity = formik.values.company_profile.company_location;
  const formattedCity = trueTopCity
    ? trueTopCity // Add space between words
    : "Enter City"; // Default value when no true speciality

  const cityColor = getCityColor(formattedCity);

  return (
    <Box sx={{ maxWidth: "100%" }}>
      <Typography
        ml={1}
        sx={{ fontWeight: "Bold", fontSize: "24px", marginLeft: "-5px", mt: 6 }}
      >
        {t("employee.job-offers.myprofile")}
      </Typography>
      <Box
        sx={{
          background: "#F5F5F5",
          borderRadius: "14px",
          paddingTop: "20px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography
            ml={1}
            sx={{
              fontWeight: "600",
              fontSize: "22px",
              marginLeft: "-3px",
              marginBottom: "15px",
            }}
          >
            {t("employee.aboutyou.title")}
          </Typography>
          <Typography
            component="span"
            variant="body1"
            onClick={() => setOpenPersonalInfo(true)}
            sx={{
              backgroundColor: "#F5F5F5",
              color: "primary.main",
              "&:hover": {
                backgroundColor: "inherit",
              },
              marginLeft: "auto",
              mt: { xs: 0.5, sm: 0 },
              fontSize: "15px",
              fontWeight: "bold",
              textTransform: "none",
              color: "primary.main",
              padding: "2px",
            }}
          >
            {t("employee.job-offers.Edit")}
            <EditIcon sx={{ mr: 1, ml: 1, fontSize: "16px" }} />
          </Typography>
          <Dialog
            width="md"
            open={openPersonalInfo}
            onClose={() => setOpenPersonalInfo(false)}
          >
            <DialogTitle
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography ml={1} sx={{ fontWeight: "600", fontSize: "20px" }}>
                {t("employee.job-offers.lable_Edit Personal Information")}
              </Typography>
              <IconButton onClick={() => setOpenPersonalInfo(false)}>
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <Divider
              sx={{ width: "90%", color: "text.secondary", mb: 1, mx: "auto" }}
            />
            <DialogContent>
              <Box component="form" onSubmit={formik.handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item>
                    <input
                      accept="image/*"
                      style={{ display: "none" }}
                      id="profile-photo"
                      type="file"
                      onChange={handleProfilePhotoChange}
                    />
                    <label htmlFor="profile-photo">
                      <Button
                        component="span"
                        sx={{
                          "&:hover": {
                            backgroundColor: "transparent", // Set the background color to transparent on hover
                          },
                        }}
                      >
                        <Box
                          sx={{
                            width: "100px",
                            height: "100px",
                            border: "1px dotted black",
                            borderRadius: "5px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginBottom: "15px",
                            ml: 1,
                            backgroundColor: "lightgray",
                          }}
                        >
                          {profilePhotoPreview ? (
                            <UploadImage
                              imageUrl={profilePhotoPreview}
                              altText="Profile Photo"
                            />
                          ) : userData.profile_photo_url ? (
                            <UploadImage
                              imageUrl={
                                profilePhotoPreview ||
                                `${process.env.REACT_APP_API_BASE_URL}/public/image/${userData.profile_photo_url}`
                              }
                              altText={userData.profile_photo_url}
                            />
                          ) : (
                            <Typography
                              variant="body1"
                              sx={{ color: "text.secondary" }}
                            >
                              {t("common.Upload")}
                            </Typography>
                          )}
                        </Box>
                      </Button>
                    </label>
                  </Grid>
                  <Grid item>
                    <Typography variant="subtitle1" sx={{ fontWeight: "600" }}>
                      {t("employee.aboutyou.Profile Photo")}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        mr: 0,
                        textDecoration: "none",
                        color: "gray",
                        fontSize: "11px",
                      }}
                    >
                      {t("employee.aboutyou.maxsize")}
                    </Typography>
                  </Grid>
                </Grid>
                <Box>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <InputLabel
                        mt={2}
                        sx={{
                          fontWeight: "600",
                          fontSize: "14px",
                          color: "text.primary",
                        }}
                      >
                        {t("employee.background.Company Name")}
                      </InputLabel>
                      <TextField
                        InputLabelProps={{
                          shrink: false,
                          style: {
                            fontSize: "14px",
                            color: "gray",
                          },
                        }}
                        placeholder="Enter Name"
                        variant="outlined"
                        fullWidth
                        name="recruiter_profile.full_name"
                        value={formik.values.recruiter_profile.full_name}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.recruiter_profile?.full_name &&
                          Boolean(formik.errors.recruiter_profile?.full_name)
                        }
                        helperText={
                          formik.touched.recruiter_profile?.full_name &&
                          formik.errors.recruiter_profile?.full_name
                        }
                        sx={{
                          mb: 2,
                          "& .MuiInputBase-input::placeholder": {
                            color: "black",
                            opacity: 1,
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <InputLabel
                        mt={2}
                        sx={{
                          fontWeight: "600",
                          fontSize: "14px",
                          color: "text.primary",
                        }}
                      >
                        {t("employee.aboutyoulable.Phone No")}
                      </InputLabel>
                      <PhoneInput
                        InputLabelProps={{
                          shrink: false,
                          style: {
                            fontSize: "14px",
                            color: "gray",
                          },
                        }}
                        placeholder="Enter Phone No"
                        variant="outlined"
                        fullWidth
                        name="recruiter_profile.phone_number"
                        value={formik.values.recruiter_profile.phone_number}
                        onChange={(phone) =>
                          formik.handleChange({
                            target: {
                              name: "recruiter_profile.phone_number",
                              value: phone,
                            },
                          })
                        }
                        error={
                          formik.touched.recruiter_profile?.phone_number &&
                          Boolean(formik.errors.recruiter_profile?.phone_number)
                        }
                        helperText={
                          formik.touched.recruiter_profile?.phone_number &&
                          formik.errors.recruiter_profile?.phone_number
                        }
                        sx={{
                          mb: 2,
                          "& .MuiInputBase-input::placeholder": {
                            color: "black",
                            opacity: 1,
                          },
                        }}
                        inputStyle={{
                          width: "100%",
                          fontSize: "14px",
                          padding: "10px 10px 10px 40px", // Add left padding to prevent overlap with the flag
                          height: "55px", // Set the desired height
                          border: "1px solid #ccc",
                          borderRadius: "4px",
                        }}
                        inputProps={{
                          maxLength: 15, // Set max length for the input
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>
                {/* <Box mt={2}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <InputLabel mt={2} sx={{ fontWeight: "600", fontSize: "14px", color: "text.primary" }}>
                                                Email *
                                            </InputLabel>
                                            <TextField
                                                InputLabelProps={{
                                                    shrink: false,
                                                    style: {
                                                        fontSize: '14px',
                                                        color: 'gray'
                                                    }
                                                }}
                                                placeholder="Enter Email"
                                                variant="outlined"
                                                fullWidth
                                                name="recruiter_profile.email"
                                                value={formik.values.recruiter_profile.email}
                                                onChange={formik.handleChange}
                                                error={formik.touched.recruiter_profile?.email && Boolean(formik.errors.recruiter_profile?.email)}
                                                helperText={formik.touched.recruiter_profile?.email && formik.errors.recruiter_profile?.email}
                                                sx={{
                                                    mb: 2,
                                                    '& .MuiInputBase-input::placeholder': {
                                                        color: 'black',
                                                        opacity: 1,
                                                    },
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <InputLabel mt={2} sx={{ fontWeight: "600", fontSize: "14px", color: "text.primary" }}>
                                                Your Location *
                                            </InputLabel>
                                            <TextField
                                                InputLabelProps={{
                                                    shrink: false,
                                                    style: {
                                                        fontSize: '14px',
                                                        color: 'gray'
                                                    }
                                                }}
                                                placeholder="Enter City"
                                                variant="outlined"
                                                fullWidth
                                                name="recruiter_profile.location"
                                                value={formik.values.recruiter_profile.location}
                                                onChange={formik.handleChange}
                                                error={formik.touched.recruiter_profile?.location && Boolean(formik.errors.recruiter_profile?.location)}
                                                helperText={formik.touched.recruiter_profile?.location && formik.errors.recruiter_profile?.location}
                                                sx={{
                                                    mb: 2,
                                                    '& .MuiInputBase-input::placeholder': {
                                                        color: 'black',
                                                        opacity: 1,
                                                    },
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Box> */}
                <DialogActions>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      mr: 2,
                      mb: 1,
                    }}
                  >
                    <Button
                      type="submit"
                      variant="contained"
                      sx={{
                        backgroundColor: "primary.main",
                        color: "white",
                        "&:hover": {
                          backgroundColor: "primary.main",
                        },
                      }}
                    >
                      <Typography
                        component="span"
                        variant="body1"
                        sx={{
                          fontSize: "15px",
                          fontWeight: "bold",
                          textTransform: "none",
                        }}
                      >
                        {t("employee.job-offers.btn_Save")}
                      </Typography>
                    </Button>
                  </Box>
                </DialogActions>
              </Box>
            </DialogContent>
          </Dialog>
        </Box>

        <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
          <Grid container spacing={2}>
            <Grid item>
              <Box
                sx={{
                  width: "100px",
                  height: "100px",
                  border: "1px dotted black",
                  borderRadius: "5px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: "15px",
                  ml: 1,
                  backgroundColor: "lightgray",
                }}
              >
                {userData.profile_photo_url ? (
                  <UploadImage
                    imageUrl={`${process.env.REACT_APP_API_BASE_URL}/public/image/${userData.profile_photo_url}`}
                    altText={userData.profile_photo_url}
                  />
                ) : (
                  <Typography variant="body1" sx={{ color: "text.secondary" }}>
                    {t("common.Upload")}
                  </Typography>
                )}
              </Box>
            </Grid>

            <Grid item>
              <Typography variant="subtitle1" sx={{ fontWeight: "600" }}>
                {t("employee.aboutyou.Profile Photo")}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  mr: 0,
                  textDecoration: "none",
                  color: "gray",
                  fontSize: "11px",
                }}
              >
                {t("employee.aboutyou.maxsize")}
              </Typography>
            </Grid>
          </Grid>
        </Box>

        <Box>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <InputLabel
                mt={2}
                sx={{
                  fontWeight: "600",
                  fontSize: "14px",
                  color: "text.primary",
                }}
              >
                {t("employee.background.Company Name")}
              </InputLabel>
              <Box
                sx={{
                  mb: 2,
                  p: 2,
                  border: "1px solid #d9d9d9",
                  borderRadius: "4px",
                  backgroundColor: "#f5f5f5",
                  color: "black",
                }}
              >
                <Typography variant="body1">{userData.full_name}</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <InputLabel
                mt={2}
                sx={{
                  fontWeight: "600",
                  fontSize: "14px",
                  color: "text.primary",
                }}
              >
                {t("employee.aboutyoulable.Phone No")}
              </InputLabel>
              <Box
                sx={{
                  mb: 2,
                  p: 2,
                  border: "1px solid #d9d9d9",
                  borderRadius: "4px",
                  backgroundColor: "#f5f5f5",
                  color: "black",
                }}
              >
                <Typography variant="body1">
                  +{userData.phone_number}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography
            ml={1}
            sx={{
              fontWeight: "600",
              fontSize: "22px",
              marginLeft: "-4px",
              marginBottom: "15px",
            }}
          >
            {t("recruiter.preview.Company Information")}
          </Typography>

          <Typography
            component="span"
            variant="body1"
            onClick={setOpenCompanyInfo}
            sx={{
              backgroundColor: "#F5F5F5",
              color: "primary.main",
              "&:hover": {
                backgroundColor: "inherit",
              },
              marginLeft: "auto",
              mt: { xs: 0.5, sm: 0 },
              fontSize: "15px",
              fontWeight: "bold",
              textTransform: "none",
              color: "primary.main",
              padding: "2px",
            }}
          >
            {t("employee.job-offers.Edit")}{" "}
            <EditIcon sx={{ mr: 1, ml: 1, fontSize: "16px" }} />
          </Typography>
          <Dialog
            width="md"
            open={openCompanyInfo}
            onClose={() => setOpenCompanyInfo(false)}
          >
            <DialogTitle
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography ml={1} sx={{ fontWeight: "600", fontSize: "20px" }}>
                {t("recruiter.profile.Edit Company Information")}
              </Typography>
              <IconButton onClick={() => setOpenCompanyInfo(false)}>
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <Divider
              sx={{ width: "90%", color: "text.secondary", mb: 1, mx: "auto" }}
            />
            <DialogContent>
              <Box component="form" onSubmit={formik.handleSubmit}>
                <Grid container spacing={2}>
                  <Grid container spacing={2}>
                    <Grid item>
                      <input
                        accept="image/*"
                        style={{ display: "none" }}
                        id="profile-photo"
                        type="file"
                        onChange={handleCompanyProfilePhotoChange}
                      />
                      <label htmlFor="profile-photo">
                        <Button
                          component="span"
                          sx={{
                            "&:hover": {
                              backgroundColor: "transparent",
                            },
                          }}
                        >
                          <Box
                            sx={{
                              width: "100px",
                              height: "100px",
                              border: "1px dotted black",
                              borderRadius: "5px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              marginBottom: "15px",
                              ml: 1,
                              backgroundColor: "lightgray",
                            }}
                          >
                            {companyProfilePhotoPreview ? (
                              <UploadImage
                                imageUrl={companyProfilePhotoPreview}
                                altText="Profile Photo"
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "cover",
                                  borderRadius: "5px",
                                }}
                              />
                            ) : userData.company_profile_photo_url ? (
                              <UploadImage
                                imageUrl={
                                  companyProfilePhotoPreview ||
                                  `${process.env.REACT_APP_API_BASE_URL}/public/image/${userData.company_profile_photo_url}`
                                }
                                altText={userData.company_profile_photo_url}
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "cover",
                                  borderRadius: "5px",
                                }}
                              />
                            ) : (
                              <Typography
                                variant="body1"
                                sx={{ color: "text.secondary" }}
                              >
                                {t("common.Upload")}
                              </Typography>
                            )}
                          </Box>
                        </Button>
                      </label>
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="subtitle1"
                        sx={{ fontWeight: "600" }}
                      >
                        {t("employee.aboutyou.Profile Photo")}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          mr: 0,
                          textDecoration: "none",
                          color: "gray",
                          fontSize: "11px",
                        }}
                      >
                        {t("employee.aboutyou.maxsize")}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Box>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <InputLabel
                        mt={2}
                        sx={{
                          fontWeight: "600",
                          fontSize: "14px",
                          color: "text.primary",
                        }}
                      >
                        {t("employee.background.Company Name")}
                      </InputLabel>
                      <TextField
                        InputLabelProps={{
                          shrink: false,
                          style: {
                            fontSize: "14px",
                            color: "gray",
                          },
                        }}
                        placeholder={t("employee.background.Company Name")}
                        variant="outlined"
                        fullWidth
                        name="company_profile.company_name"
                        value={formik.values.company_profile.company_name}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.company_profile?.company_name &&
                          Boolean(formik.errors.company_profile?.company_name)
                        }
                        helperText={
                          formik.touched.company_profile?.company_name &&
                          formik.errors.company_profile?.company_name
                        }
                        sx={{
                          mb: 2,
                          "& .MuiInputBase-input::placeholder": {
                            color: "black",
                            opacity: 1,
                          },
                        }}
                      />
                    </Grid>
                  </Grid>

                  <Box mt={2}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <InputLabel
                          mt={2}
                          sx={{
                            fontWeight: "600",
                            fontSize: "16px",
                            color: "text.primary",
                          }}
                        >
                          {t("onboarding.preferences.location")}*
                        </InputLabel>

                        <DynamicAutocomplete
                          color={cityColor}
                          placeHolder={formattedCity}
                          formik={formik}
                          fieldName="company_profile.company_location"
                          value={formik.values.company_profile.company_location}
                          // fetchOptions={fetchCities}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <InputLabel
                          mt={2}
                          sx={{
                            fontWeight: "600",
                            fontSize: "14px",
                            color: "text.primary",
                            mb: 0.5,
                          }}
                        >
                          {t("recruiter.company-profile.Company Size")}
                        </InputLabel>
                        <FormControl fullWidth variant="outlined">
                          <Select
                            name="company_profile.company_size"
                            value={formik.values.company_profile.company_size}
                            onChange={formik.handleChange}
                            displayEmpty
                            renderValue={(selected) => {
                              if (selected === "") {
                                return (
                                  <span style={{ color: "#aaa" }}>
                                    {t(
                                      "recruiter.company-profile.Select Company Size"
                                    )}
                                  </span>
                                );
                              }
                              return selected;
                            }}
                          >
                            <MenuItem value="" disabled>
                              {t(
                                "recruiter.company-profile.Select Company Size"
                              )}
                            </MenuItem>
                            <MenuItem
                              value={t("recruiter.company-profile.small")}
                            >
                              {t("recruiter.company-profile.small")}
                            </MenuItem>
                            <MenuItem
                              value={t("recruiter.company-profile.Medium")}
                            >
                              {t("recruiter.company-profile.Medium")}
                            </MenuItem>
                            <MenuItem
                              value={t("recruiter.company-profile.Big")}
                            >
                              {t("recruiter.company-profile.Big")}
                            </MenuItem>
                          </Select>
                        </FormControl>
                        {formik.errors.company_profile && (
                          <Typography
                            sx={{
                              color: "#C70039",
                              textAlign: "left",
                              fontSize: "12px",
                            }}
                          >
                            {formik.errors.company_profile.company_size}
                          </Typography>
                        )}
                      </Grid>
                    </Grid>
                  </Box>

                  <Box>
                    <Typography
                      component="h2"
                      variant="body1"
                      sx={{
                        alignSelf: "flex-start",
                        fontSize: "14px",
                        fontWeight: "bold",
                        textTransform: "none",
                        color: "black",
                        paddingTop: "5px",
                      }}
                    >
                      {t("recruiter.company-profile.Company Sector *")}
                    </Typography>
                    <TextField
                      InputLabelProps={{
                        shrink: false,
                        style: {
                          fontSize: "14px",

                          color: "gray",
                        },
                      }}
                      placeholder={userData.company_domain}
                      name="company_profile.company_domain"
                      value={formik.values.company_profile.company_domain}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.company_profile?.company_domain &&
                        Boolean(formik.errors.company_profile?.company_domain)
                      }
                      helperText={
                        formik.touched.company_profile?.company_domain &&
                        formik.errors.company_profile?.company_domain
                      }
                      variant="outlined"
                      fullWidth
                      sx={{
                        mb: 2,
                        "& .MuiInputBase-input::placeholder": {
                          color: "black",
                          opacity: 1,
                        },
                      }}
                    />
                    <Typography
                      component="h2"
                      variant="body1"
                      sx={{
                        alignSelf: "flex-start",
                        fontSize: "14px",
                        fontWeight: "bold",
                        textTransform: "none",
                        color: "black",
                        paddingTop: "5px",
                      }}
                    >
                      {t("recruiter.company-profile.Company Overview")} *
                    </Typography>
                    <TextField
                      placeholder={userData.company_overview}
                      name="company_profile.company_overview"
                      value={formik.values.company_profile.company_overview}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.company_profile?.company_overview &&
                        Boolean(formik.errors.company_profile?.company_overview)
                      }
                      helperText={
                        formik.touched.company_profile?.company_overview &&
                        formik.errors.company_profile?.company_overview
                      }
                      variant="outlined"
                      InputLabelProps={{
                        shrink: false,
                        style: {
                          fontSize: "14px",

                          color: "gray",
                        },
                      }}
                      fullWidth
                      multiline
                      rows={3}
                      sx={{
                        mb: 2,
                        "& .MuiInputBase-input::placeholder": {
                          color: "black",
                          opacity: 1,
                        },
                      }}
                    />

                    <Typography
                      component="h2"
                      variant="body1"
                      sx={{
                        alignSelf: "flex-start",
                        fontSize: "14px",
                        fontWeight: "bold",
                        textTransform: "none",
                        color: "black",
                        paddingTop: "5px",
                      }}
                    >
                      {t("recruiter.company-profile.Website *")}
                    </Typography>
                    <TextField
                      InputLabelProps={{
                        shrink: false,
                        style: {
                          fontSize: "14px",

                          color: "gray",
                        },
                      }}
                      placeholder={userData.company_website}
                      name="company_profile.company_website"
                      value={formik.values.company_profile.company_website}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.company_profile?.company_website &&
                        Boolean(formik.errors.company_profile?.company_website)
                      }
                      helperText={
                        formik.touched.company_profile?.company_website &&
                        formik.errors.company_profile?.company_website
                      }
                      variant="outlined"
                      fullWidth
                      sx={{
                        mb: 2,
                        "& .MuiInputBase-input::placeholder": {
                          color: "black",
                          opacity: 1,
                        },
                      }}
                    />
                  </Box>
                </Box>
                <Box>
                  <Typography
                    ml={1}
                    sx={{
                      mt: 2,
                      fontWeight: "600",
                      fontSize: "22px",
                      marginLeft: "-4px",
                      marginBottom: "15px",
                      mb: 0.5,
                    }}
                  >
                    {t("recruiter.company-profile.Company Perks")}
                  </Typography>
                  <Typography
                    component="h2"
                    variant="body1"
                    sx={{
                      alignSelf: "flex-start",
                      fontSize: "14px",
                      fontWeight: "bold",
                      textTransform: "none",
                      color: "black",
                      paddingTop: "5px",
                      mb: 0.5,
                    }}
                  >
                    {t("recruiter.profile.chooseperks")}
                  </Typography>
                  <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                    {companyPerksList.map((perk) => (
                      <Chip
                        key={perk.name}
                        label={perk.label}
                        onClick={() => handlePerkChange(perk.name)}
                        color={
                          formik.values.company_profile.company_perks[perk.name]
                            ? "primary"
                            : "default"
                        }
                        sx={{ mr: 1, mb: 1 }}
                      />
                    ))}
                  </Box>
                </Box>
                <DialogActions>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      mr: 2,
                      mb: 1,
                    }}
                  >
                    <Button
                      type="submit"
                      variant="contained"
                      sx={{
                        backgroundColor: "primary.main",
                        color: "white",
                        "&:hover": {
                          backgroundColor: "primary.main",
                        },
                      }}
                    >
                      <Typography
                        component="span"
                        variant="body1"
                        sx={{
                          fontSize: "15px",
                          fontWeight: "bold",
                          textTransform: "none",
                        }}
                      >
                        {t("employee.job-offers.btn_Save")}
                      </Typography>
                    </Button>
                  </Box>
                </DialogActions>
              </Box>
            </DialogContent>
          </Dialog>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
          }}
        >
          <Grid container spacing={2}>
            <Grid item>
              <Box
                sx={{
                  width: "100px",
                  height: "100px",
                  border: "1px dotted black",
                  borderRadius: "5px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: "15px",
                  ml: 1,
                  backgroundColor: "lightgray",
                }}
              >
                {userData.company_profile_photo_url ? (
                  <UploadImage
                    imageUrl={`${process.env.REACT_APP_API_BASE_URL}/public/image/${userData.company_profile_photo_url}`}
                    altText={userData.company_profile_photo_url}
                  />
                ) : (
                  <Typography variant="body1" sx={{ color: "text.secondary" }}>
                    {t("common.Upload")}
                  </Typography>
                )}
              </Box>
            </Grid>

            <Grid item>
              <Typography variant="subtitle1" sx={{ fontWeight: "600" }}>
                {t("employee.aboutyou.Profile Photo")}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  mr: 0,
                  textDecoration: "none",
                  color: "gray",
                  fontSize: "11px",
                }}
              >
                {t("employee.aboutyou.maxsize")}
              </Typography>
            </Grid>
          </Grid>
        </Box>

        <Box>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <InputLabel
                mt={2}
                sx={{
                  fontWeight: "600",
                  fontSize: "14px",
                  color: "text.primary",
                }}
              >
                {t("employee.background.Company Name")}
              </InputLabel>

              <Box
                sx={{
                  mb: 2,
                  p: 2,
                  border: "1px solid #d9d9d9",
                  borderRadius: "4px",
                  backgroundColor: "#f5f5f5",
                  color: "black",
                }}
              >
                <Typography variant="body1">{userData.company_name}</Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box mt={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <InputLabel
                mt={2}
                sx={{
                  fontWeight: "600",
                  fontSize: "14px",
                  color: "text.primary",
                }}
              >
                {t("onboarding.preferences.location")} *
              </InputLabel>
              <Box
                sx={{
                  mb: 2,
                  p: 2,
                  border: "1px solid #d9d9d9",
                  borderRadius: "4px",
                  backgroundColor: "#f5f5f5",
                  color: "black",
                }}
              >
                <Typography variant="body1">
                  {userData.company_location}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <InputLabel
                mt={2}
                sx={{
                  fontWeight: "600",
                  fontSize: "14px",
                  color: "text.primary",
                }}
              >
                {t("recruiter.company-profile.Company Size")}
              </InputLabel>
              <Box
                sx={{
                  mb: 2,
                  p: 2,
                  border: "1px solid #d9d9d9",
                  borderRadius: "4px",
                  backgroundColor: "#f5f5f5",
                  color: "black",
                }}
              >
                <Typography variant="body1">{userData.company_size}</Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box>
          <Typography
            component="h2"
            variant="body1"
            sx={{
              alignSelf: "flex-start",
              fontSize: "14px",
              fontWeight: "bold",
              textTransform: "none",
              color: "black",
              paddingTop: "5px",
            }}
          >
            {t("recruiter.company-profile.Company Sector *")}
          </Typography>
          <Box
            sx={{
              mb: 2,
              p: 2,
              border: "1px solid #d9d9d9",
              borderRadius: "4px",
              backgroundColor: "#f5f5f5",
              color: "black",
            }}
          >
            <Typography variant="body1">{userData.company_domain}</Typography>
          </Box>
          <Typography
            component="h2"
            variant="body1"
            sx={{
              alignSelf: "flex-start",
              fontSize: "14px",
              fontWeight: "bold",
              textTransform: "none",
              color: "black",
              paddingTop: "5px",
            }}
          >
            {t("recruiter.company-profile.Company Overview")} *
          </Typography>
          <Box
            sx={{
              mb: 2,
              p: 2,
              border: "1px solid #d9d9d9",
              borderRadius: "4px",
              backgroundColor: "#f5f5f5",
              color: "black",
            }}
          >
            <Typography variant="body1">{userData.company_overview}</Typography>
          </Box>

          <Typography
            component="h2"
            variant="body1"
            sx={{
              alignSelf: "flex-start",
              fontSize: "14px",
              fontWeight: "bold",
              textTransform: "none",
              color: "black",
              paddingTop: "5px",
            }}
          >
            {t("recruiter.company-profile.Website *")}
          </Typography>
          <Box
            sx={{
              mb: 2,
              p: 2,
              border: "1px solid #d9d9d9",
              borderRadius: "4px",
              backgroundColor: "#f5f5f5",
              color: "black",
            }}
          >
            <Typography variant="body1">{userData.company_website}</Typography>
          </Box>
          <Box>
            <Typography
              ml={1}
              mt={2}
              sx={{
                fontWeight: "600",
                fontSize: "22px",
                marginLeft: "-4px",
                marginBottom: "15px",
              }}
            >
              {t("recruiter.company-profile.Company Perks")}
            </Typography>

            {renderCompanyPerks()}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default MyProfile;
