import React, { useEffect, useState } from "react";
import ChatRooms from "./ChatRooms";

const Chat = () => {
  const userId = JSON.parse(localStorage.getItem("user")).id;

  // Replace with actual chat room ID
  return (
    <div>
      <ChatRooms userId={userId} />
    </div>
  );
};

export default Chat;
