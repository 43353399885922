// src/context/JobProvider.js

import React, { useState, useEffect } from 'react';
import JobContext from './JobContext';
import { JobPostApi } from '../../src/apis';
import { useSocket } from '../SocketProvider'; // Use the custom hook

const JobProvider = ({ children }) => {
    const [jobDataArray, setJobDataArray] = useState([]);
    const [pendingJobData, setPendingJobData] = useState([]);
    const [acceptedJobData, setAcceptedJobData] = useState([]);
    const [rejectedJobData, setRejectedJobData,] = useState([]);
    const [loading, setLoading] = useState(true);
    const [newMessage, setNewMessage] = useState(false);
    const socket = useSocket(); // Get socket instance from context
    const removeJobPost = (id) => {
        setJobDataArray(prevData => prevData.filter(job => job.id !== id));
    };

    const addNewMessage = (data) => {
        setNewMessage(data);
    }

    // useEffect(() => {
    //     if (socket) {
    //         socket.on('chatNotification', (data) => {
    //            console.log(data,"data");
    //             // setNewMessage(data);
    //         }
    //         );
    //     }
    // }, [socket])


    return (
        <JobContext.Provider value={{ allJobs: jobDataArray, jobDataArray, setJobDataArray, pendingJobData, setPendingJobData, acceptedJobData, setAcceptedJobData, rejectedJobData, setRejectedJobData, loading, setLoading, removeJobPost, newMessage, addNewMessage }}>
            {children}
        </JobContext.Provider>
    );
};

export default JobProvider;
