import React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
const RadioButton = (props) => {
    const { value, label1, label2, label3 } = props;
    const handleChange = (event) => {
        props.markSelected(event.target.value);
    };
    return (
        <div>
            <FormControl sx={{
                display: 'flex',
            }} component="fieldset">
                <RadioGroup
                    row
                    name="radio-buttons-group"
                    onChange={handleChange}
                    // defaultValue={DefaultValue}
                    value={value}

                >
                    <FormControlLabel value={label1} control={<Radio sx={{ color: 'gray', '&.Mui-checked': { color: 'secondary.main' } }} />} label={label1} />
                    <FormControlLabel value={label2} control={<Radio sx={{ color: 'gray', '&.Mui-checked': { color: 'secondary.main' } }} />} label={label2} />
                    {
                        label3 && <FormControlLabel value={label3} control={<Radio sx={{ color: 'gray', '&.Mui-checked': { color: 'secondary.main' } }} />} label={label3} />
                    }
                </RadioGroup>
            </FormControl>
        </div>
    );
}

export default RadioButton;
