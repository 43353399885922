import React from "react";
import {
  Box,
  Grid,
  Typography,
  Link,
  TextField,
  Button,
  IconButton,
  Divider,
  useMediaQuery,
} from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import Logo from "../../assets/images/logo.png";
import { useTranslation } from "react-i18next";

function Footer() {
  const { t } = useTranslation();
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  return (
    <Box sx={{ bgcolor: "#031141", color: "white", py: 2, px: 10 }}>
      <Grid container spacing={2} justifyContent="space-between">
        <Grid item xs={12} sm={4}>
          <Box sx={{ marginRight: "2%", mb: 0.5 }}>
            <img src={Logo} alt="logo" style={{ width: "100px" }} />
          </Box>
          <Typography variant="body2" sx={{ color: "#8E96AB" }}>
            {t("employee.footer.subheading")}{" "}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography variant="h6" sx={{ fontWeight: "bold", mb: 1 }}>
            {t("employee.footer.Useful Links")}
          </Typography>
          <Link
            href="#"
            color="inherit"
            sx={{
              display: "block",
              mb: 1,
              color: "#8E96AB",
              textDecoration: "none",
            }}
          >
            {t("employee.home.navlink_Home")}
          </Link>
          <Link
            href="#"
            color="inherit"
            sx={{
              display: "block",
              mb: 1,
              color: "#8E96AB",
              textDecoration: "none",
            }}
          >
            {t("employee.footer. Login / Register")}
          </Link>
          <Link
            href="#"
            color="inherit"
            sx={{
              display: "block",
              mb: 1,
              color: "#8E96AB",
              textDecoration: "none",
            }}
          >
            {t("employee.footer.Terms of conditions")}
          </Link>
          <Link
            href="#"
            color="inherit"
            sx={{
              display: "block",
              mb: 1,
              color: "#8E96AB",
              textDecoration: "none",
            }}
          >
            {t("employee.footer.Privacy Policy")}
          </Link>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography variant="h6" sx={{ fontWeight: "bold", mb: 2 }}>
            {t("employee.footer.Get job notifications")}
          </Typography>
          <Typography variant="body2" sx={{ color: "#8E96AB" }}>
            <Typography variant="body2" sx={{ color: "#8E96AB" }}>
              {t("employee.footer.latest-job")}
            </Typography>
            {t("employee.footer.inbox")}
          </Typography>
          <Grid xs={12}>
            <Box
              sx={{
                mt: 2,
                display: "flex",
                flexDirection: isSmallScreen ? "column" : "row",
                alignItems: isSmallScreen ? "stretch" : "center",
                gap: 1, // This adds space between the elements
              }}
            >
              <TextField
                variant="outlined"
                placeholder={t("employee.footer.placeholder")}
                sx={{
                  flex: 1,
                  mr: isSmallScreen ? 0 : 1,
                  mb: isSmallScreen ? 1 : 0,
                  bgcolor: "white",
                  borderRadius: "10px",
                  justifyContent: "center",
                }}
              />
              <Button
                variant="contained"
                sx={{
                  bgcolor: "#8EFFC2",
                  color: "#031141",
                  textTransform: "none",
                  height: "55px",
                  borderRadius: "10px",
                  fontWeight: "bold",
                  "&:hover": {
                    backgroundColor: "#8EFFC2",
                  },
                }}
              >
                {t("employee.footer.Subscribe")}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Divider sx={{ mt: 2, borderColor: "#062F53" }} />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          mt: 1,
          justifyContent: "space-between",
        }}
      >
        <Typography
          variant="caption"
          sx={{ display: "block", mt: 2, color: "#8E96AB" }}
        >
          {t("employee.footer.allrigths")}
        </Typography>
        <Box>
          <IconButton color="inherit">
            <FacebookIcon />
          </IconButton>
          <IconButton color="inherit">
            <InstagramIcon />
          </IconButton>
          <IconButton color="inherit">
            <LinkedInIcon />
          </IconButton>
          <IconButton color="inherit">
            <TwitterIcon />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
}

export default Footer;
