import axios from "../axiosConfig";

class Routes {
  login = (val) => {
    let data = axios.post("/auth/login", val);
    return data;
  };
  Register = (val) => {
    let data = axios.post("/auth/register", val);
    return data;
  };
  UpdatePassword = (val) => {
    let data = axios.post("/auth/reset", val);
    return data;
  };
  verfiyEmail = (token) => {
    let response = axios.get(`/auth/verify-email?token=${token}`);
    return response;
  };

  getNotifications = () => {
    let response = axios.get("/auth/get-notifications");
    return response;
  };
  // markNotificationAsRead
  markNotificationAsRead = () => {
    let response = axios.get(`/auth/mark-notification-as-read`);
    return response;
  };
}

export default new Routes();
