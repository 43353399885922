import React from 'react';
import { Container, Grid, Typography, Box, Paper } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';  // Import useTranslation

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#DEE4FA', // Light blue background
    padding: theme.spacing(8, 0),
  },
  header: {
    textAlign: 'left',
    marginBottom: theme.spacing(4),
  },
  mainTitle: {
    fontSize: '3rem !important',
    fontWeight: '500',
    marginBottom: theme.spacing(2),
  },
  description: {
    fontSize: '1rem',
    marginBottom: theme.spacing(6),
  },
  section: {
    padding: theme.spacing(3),
    borderRadius: '30px !important',
    border: '1px solid #000',
    textAlign: 'left',
    backgroundColor: '#DEE4FA !important',
    height:"83%",
  },
  title: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    marginBottom: theme.spacing(2),
  },
  content: {
    fontSize: '1rem',
  },
}));

const SobreLaCompania = () => {
  const { t } = useTranslation();  // Initialize useTranslation
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Container>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Typography>
              <span className={classes.mainTitle}>{t('aboutCompany')}</span>
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography className={classes.description}>
              {t('description')}
            </Typography>
          </Grid>
        </Grid>
        <br />
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Paper className={classes.section}>
              <Typography className={classes.title}>
                <strong>{t('missionVisionTitle')}</strong>
              </Typography>
              <Typography className={classes.content}>
                {t('missionVisionText')}
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper className={classes.section}>
              <Typography className={classes.title}>
                <strong>{t('ourAppTitle')}</strong>
              </Typography>
              <Typography className={classes.content}>
                {t('ourAppText')}
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default SobreLaCompania;
