import React, { useState, useEffect } from "react";
import {
  Container,
  CssBaseline,
  Box,
  Typography,
  TextField,
  Button,
  Link,
  Paper,
} from "@mui/material";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import PersonIcon from "@mui/icons-material/Person";
import BusinessIcon from "@mui/icons-material/Business";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import HirioLogo from "../../assets/images/HirioBlue.png";
import { useFormik } from "formik";
import * as yup from "yup";
import CircularProgress from "@mui/material/CircularProgress";
import { AuthApi } from "../../apis";
import { useNavigate, useLocation } from "react-router-dom"; // Ensure useLocation is imported
import { useTranslation } from "react-i18next";

const validationSchema = yup.object({
  email: yup
    .string()
    .email("Enter a valid email")
    .required("Email is required"),
  password: yup.string().required("Password is required"),
  type: yup.string().required("Type is required"),
});

const Register = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [employeePressed, setEmployeePressed] = useState(true);
  const [companyPressed, setCompanyPressed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const location = useLocation();

  useEffect(() => {
    localStorage.clear();
  }, []);

  // Extract the email from URL params

  const queryParams = new URLSearchParams(window.location.search);
  // Extract individual parameters
  const emailFromUrl = queryParams.get("email") || ""; // Default to empty string if not found
  const passwordFromUrl = queryParams.get("code") || "";
  const isLinkedInUserFromUrl = queryParams.get("is_LinkedinUser") === "true";

  const formik = useFormik({
    initialValues: {
      email: emailFromUrl,
      password: passwordFromUrl,
      type: "employee",
      is_linkedin_user: isLinkedInUserFromUrl,
    },
    validationSchema: validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values) => {
      const validationErrors = await formik.validateForm();
      if (Object.keys(validationErrors).length > 0) {
        formik.setErrors(validationErrors);
        return;
      }

      setLoading(true);
      setError(null);
      try {
        const res = await AuthApi.Register(values);
        if (res?.data?.status === 200) {
          localStorage.setItem("token", res.data.data.token);
          localStorage.setItem("user", JSON.stringify(res.data.data.user));
          navigate("/login");
        } else {
          setError(
            "Registration failed. Please check your credentials and try again."
          );
        }
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    },
  });
  const handleEmployeeClick = () => {
    setEmployeePressed(true);
    setCompanyPressed(false);
    formik.setFieldValue("type", "employee");
    formik.setFieldTouched("type", true);
  };

  const handleCompanyClick = () => {
    setEmployeePressed(false);
    setCompanyPressed(true);
    formik.setFieldValue("type", "recruiter");
    formik.setFieldTouched("type", true);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleLinkedInRegister = () => {
    window.location.href = "https://api.hirio.app/auth/linkedin";
  };

  return (
    <Container
      component="main"
      maxWidth="xl"
      sx={{
        backgroundImage: "linear-gradient(to right, #FFFFFF, #E4E4E4)",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {/* Logo Section */}
      <Box
        component={Link}
        href="/"
        sx={{
          marginBottom: 3, // Space between the logo and the form box
          display: "flex",
          justifyContent: "center",
        }}
      >
        <img src={HirioLogo} alt="logo" style={{ width: "100px" }} />
      </Box>

      {/* Form Section */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          backgroundColor: "white",
          padding: 3,
          borderRadius: 0,
          width: "30%",
          height: "100%",
          maxWidth: "600px",
          "@media (m ax-width: 1000px)": { width: "40%" },
          "@media (max-width: 800px)": { width: "50%" },
          "@media (max-width: 400px)": { width: "75%" },
        }}
      >
        <Box
          sx={{
            marginTop: 0,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <Typography
            component="h2"
            variant="subtitle1"
            sx={{
              ml: 2,
              alignSelf: "flex-start",
              fontWeight: "bold",
              fontSize: "20px",
            }}
          >
            {t("landing.Register")}
          </Typography>
          <Typography
            component="h2"
            variant="subtitle1"
            sx={{ ml: 2, alignSelf: "flex-start", fontSize: "12px" }}
          >
            {t("auth.register.account")}
          </Typography>
          <Box
            sx={{
              mt: 1,
              height: "480px",
              width: "450px",
              width: "100%",
              height: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                mt: 1, // Adjust the margin top as needed
              }}
            >
              <Box
                sx={{
                  display: { md: "flex" },
                  width: "100%",
                  height: "100%",
                }}
              >
                <Button
                  startIcon={
                    <PersonIcon
                      sx={{ color: employeePressed ? "#8EFFC2" : "#2C2C2C" }}
                    />
                  }
                  fullWidth
                  variant="outlined"
                  sx={{
                    mb: 2,
                    backgroundColor: "white",
                    color: employeePressed ? "#8EFFC2" : "#2C2C2C",
                    padding: "10px",
                    borderColor: employeePressed
                      ? "#8EFFC2"
                      : formik.touched.type && formik.errors.type
                      ? "red"
                      : "lightgrey",
                    "&:hover": { borderColor: "#8EFFC2" },
                  }}
                  onClick={handleEmployeeClick}
                >
                  <Typography
                    component="h2"
                    variant="body1"
                    sx={{
                      alignSelf: "flex-start",
                      fontSize: "12px",
                      fontWeight: "bold",
                      textTransform: "none",
                      color: employeePressed ? "#8EFFC2" : "#2C2C2C",
                      paddingTop: "1px",
                      paddingBottom: "5px",
                      mt: 1,
                    }}
                  >
                    {t("auth.Employee")}
                  </Typography>
                </Button>
                <Button
                  startIcon={
                    <BusinessIcon
                      sx={{ color: companyPressed ? "#8EFFC2" : "#2C2C2C" }}
                    />
                  }
                  fullWidth
                  variant="outlined"
                  sx={{
                    ml: { md: 1 },

                    mb: 2,
                    backgroundColor: "white",
                    color: companyPressed ? "#8EFFC2" : "#2C2C2C",
                    padding: "10px",
                    borderColor: companyPressed
                      ? "#8EFFC2"
                      : formik.touched.type && formik.errors.type
                      ? "red"
                      : "lightgrey",
                    "&:hover": { borderColor: "lightgrey" },
                  }}
                  onClick={handleCompanyClick}
                >
                  <Typography
                    component="h2"
                    variant="body1"
                    sx={{
                      alignSelf: "flex-start",
                      fontSize: "12px",
                      fontWeight: "bold",
                      textTransform: "none",
                      color: companyPressed ? "#8EFFC2" : "#2C2C2C",
                      paddingTop: "1px",
                      mt: 1,
                    }}
                  >
                    {t("auth.Company")}
                  </Typography>
                </Button>
              </Box>

              {formik.touched.type && formik.errors.type && (
                <Typography
                  sx={{
                    color: "#C70039",
                    textAlign: "center",
                    fontSize: "12px",
                    mt: -1,
                  }}
                >
                  {formik.errors.type}
                </Typography>
              )}
            </Box>

            <form onSubmit={formik.handleSubmit} noValidate>
              <Typography
                component="h2"
                variant="body1"
                sx={{
                  alignSelf: "flex-start",
                  fontSize: "12px",
                  fontWeight: "bold",
                  textTransform: "none",
                  color: "black",
                  paddingTop: "10px",
                }}
              >
                {t("employee.footer.placeholder")}
              </Typography>

              <TextField
                margin="normal"
                fullWidth
                id="email"
                placeholder={t("employee.footer.placeholder")}
                name="email"
                autoComplete="email"
                autoFocus
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                InputLabelProps={{
                  style: { fontSize: "14px" },
                }}
                sx={{
                  margin: "5px 0",
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                      borderColor: "#8EFFC2",
                    },
                  },
                  "& .MuiInputLabel-root": {
                    "&.Mui-focused": {
                      color: "#8EFFC2",
                    },
                  },
                }}
              />
              <Typography
                component="h2"
                variant="body1"
                sx={{
                  alignSelf: "flex-start",
                  fontSize: "12px",
                  fontWeight: "bold",
                  textTransform: "none",
                  color: "black",
                  paddingTop: "10px",
                }}
              >
                {t("auth.login.password")}
              </Typography>
              <TextField
                margin="normal"
                fullWidth
                name="password"
                placeholder={t("auth.login.password")}
                type={showPassword ? "text" : "password"}
                id="password"
                autoComplete="current-password"
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                        sx={{ p: "6px" }}
                      >
                        {showPassword ? (
                          <VisibilityOff sx={{ fontSize: "18px" }} />
                        ) : (
                          <Visibility sx={{ fontSize: "18px" }} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                sx={{
                  margin: "5px 0",
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                      borderColor: "#8EFFC2",
                    },
                  },
                  "& .MuiInputLabel-root": {
                    "&.Mui-focused": {
                      color: "#8EFFC2",
                    },
                  },
                }}
              />
              <Button
                type="submit"
                fullWidth
                variant="standard"
                disabled={formik.isSubmitting}
                sx={{
                  mt: 3,
                  mb: 2,
                  backgroundColor: "#8EFFC2",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#8EFFC2",
                  },
                }}
              >
                {formik.isSubmitting ? (
                  <CircularProgress size={24} />
                ) : (
                  <Typography
                    component="h2"
                    variant="body1"
                    sx={{
                      alignSelf: "flex-start",
                      fontSize: "15px",
                      fontWeight: "bold",
                      textTransform: "none",
                      color: "white",
                      padding: "2px",
                    }}
                  >
                    {t("landing.Register")}
                  </Typography>
                )}
              </Button>
            </form>
            <Box sx={{ mt: 0, justifyContent: "center", textAlign: "center" }}>
              <Box>
                <Typography
                  variant="body2"
                  component={Link}
                  href="#"
                  sx={{
                    mr: 1,
                    textDecoration: "none",
                    color: "inherit",
                    fontSize: "11px",
                  }}
                >
                  {t("auth.register.agree")}
                </Typography>
                <Typography
                  variant="body2"
                  component={Link}
                  href="#"
                  sx={{
                    mr: 1,
                    textDecoration: "none",
                    color: "#8EFFC2",
                    fontSize: "11px",
                  }}
                >
                  {t("auth.register.terms&condition")}
                </Typography>
                <Typography
                  variant="body2"
                  component={Link}
                  href="#"
                  sx={{
                    mr: 1,
                    textDecoration: "none",
                    color: "inherit",
                    fontSize: "11px",
                  }}
                >
                  {t("auth.register.and")}
                </Typography>
                <Typography
                  variant="body2"
                  component={Link}
                  href="#"
                  sx={{
                    mr: 1,
                    textDecoration: "none",
                    color: "#8EFFC2",
                    fontSize: "11px",
                  }}
                >
                  {t("employee.footer.Privacy Policy")}
                </Typography>
              </Box>

              <Box>
                <Typography
                  variant="body2"
                  component={Link}
                  href="#"
                  sx={{
                    mr: 0.5,
                    textDecoration: "none",
                    color: "inherit",
                    fontSize: "11px",
                  }}
                >
                  {t("auth.register.alrearyAccount")}
                </Typography>
                <Typography
                  variant="body2"
                  component={Link}
                  href="/login"
                  sx={{
                    mr: 1,
                    textDecoration: "none",
                    color: "#8EFFC2",
                    fontSize: "11px",
                  }}
                >
                  {t("auth.register.loginhere")}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default Register;
